import React, { useState, useEffect, useMemo, useContext } from "react";
import moment from "moment";
import { Button, Spin } from "antd";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { Link, Typography } from "@mui/material";
import { LoadingOutlined } from "@ant-design/icons";
import { UserContext } from "../../context/login/Context";
import Title from "./Title";
import { OrderAPI } from "../../API/order-API";
import { PatientAPI } from "../../API/patient-API";
import ReactTable from "../../components/forms/ReactTable";

const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

const fetchPatientsOrders = (patientId: string, currentClient: string) => {
  return OrderAPI.fetchOrdersByPatient(patientId, currentClient).then(
    (res: any) => res?.data
  );
};

const Orders = () => {
  const { id: patientId }: { id: string } = useParams();
  const { getCurrentClient } = useContext(UserContext);
  const [patient, setPatient] = useState([{}] as any);

  let currentClient = getCurrentClient();
  useEffect(() => {
    PatientAPI.getPatient(patientId, currentClient).then((res) => {
      const resPatient = res.data.patient;

      setPatient({ ...resPatient });
    });
  }, []);

  const { isLoading, data } = useQuery(
    ["patient-orders", patientId],
    () => fetchPatientsOrders(patientId, currentClient),
    { enabled: !!patientId }
  );

  const orders = data?.orders.map((data: any, index: number) => {
    return {
      name: data.orderNumber ?? data._id,
      createdAt: new Date(data.createdAt ?? ""),
      status: data.status,
      cpt: data.shortSummary,
      key: index,
      edit: (
        <Link href={`/order-panel/${data._id}/${data.type?.toLowerCase()}`}>
          Edit
        </Link>
      ),
      type:
        data.type === data.subtype
          ? data.type
          : `${data.type}${data.subtype ? ` - ${data.subtype}` : ""}`,
      _id: data._id,
    };
  });

  const columns = useMemo(
    () => [
      {
        Header: "Order ID",
        columnId: 0,
        accessor: "name",
        Cell: (props: any) => (
          <Link href={`/patient-board/orderDetail/${props.row.original._id}`}>
            {props.value}
          </Link>
        ),
        width: 40,
      },
      {
        Header: "Created Date",
        columnId: 1,
        accessor: "createdAt",
        Cell: (props: any) => (
          <span>{moment(props.value).format("MM/DD/YYYY")}</span>
        ),
        sortType: "datetime",
        width: 40,
      },
      {
        Header: "CPT Code",
        columnId: 2,
        accessor: "cpt",
        width: 40,
      },
      {
        Header: "Status",
        columnId: 3,
        accessor: "status",
        Cell: (props: any) => (
          <span>
            {(props.value === "Obstructed" && (
              <span style={{ color: "red" }}>{props.value}</span>
            )) ||
              (props.value === "Completed" && (
                <span style={{ color: "green" }}>{props.value}</span>
              )) ||
              (props.value === "Canceled" && (
                <span style={{ color: "red" }}>{props.value}</span>
              )) || <span>{props.value}</span>}
          </span>
        ),
        width: 40,
      },
      {
        Header: "Type",
        columnId: 4,
        accessor: "type",
        width: 40,
      },
      {
        columnId: 5,
        accessor: "edit",
        width: 40,
      },
    ],
    []
  );

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "30px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "90%",
            marginBottom: "30px",
          }}
        >
          <Title>
            <span style={{ fontSize: "30px" }}>Orders</span>
          </Title>
          <Link href={`/new-patientOrder/${patientId}`}>
            <div className="new-order-btn">
              <Button
                style={{
                  fontSize: "14px",
                  borderRadius: "10px",
                  borderColor: "#4276c5",
                  color: "#4276c5",
                }}
              >
                New Order
              </Button>
            </div>
          </Link>
        </div>
        <div
          style={{
            textAlign: "left",
            width: "90%",
            backgroundColor: "#ffffff",
            padding: "10px",
            boxShadow: "-2px 1px 3px 0px #d2d2d2",
          }}
        >
          <div style={{ width: "100%" }}>
            {isLoading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "80vw",
                  height: "20vh",
                }}
              >
                <Spin
                  size="large"
                  className={`loading-spin-btn ${"active"}`}
                  indicator={antIcon}
                />
              </div>
            )}
            {data?.orders && <ReactTable columns={columns} data={orders} />}

            {!isLoading && !data?.orders?.length && (
              <div
                style={{
                  textAlign: "center",
                  fontWeight: 500,
                  marginTop: "100px",
                }}
              >
                <Typography variant={"h5"}>
                  There are currently no orders for this patient
                </Typography>
              </div>
            )}
          </div>
        </div>
        {/*
          <Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 3 }}>
            See more orders
          </Link>
        */}
      </div>
    </>
  );
};

export default Orders;
