import React from 'react';
import { useHistory } from 'react-router-dom';
import { Auth0Provider as Provider } from '@auth0/auth0-react';

const {
  REACT_APP_AUTH0_DOMAIN: domain,
  REACT_APP_AUTH0_CLIENTID: clientId,
  REACT_APP_AUTH0_AUDIENCE: audience,
  REACT_APP_AUTH0_REDIRECTURI: redirectUri,
}: any = process.env;

const Auth0Provider: React.FC<{ children: any }> = ({ children }) => {
  const history = useHistory();

  const onRedirectCallback = (appState: any) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Provider
      domain={domain}
      clientId={clientId}
      audience={audience}
      redirectUri={redirectUri}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Provider>
  );
};

export default Auth0Provider;
