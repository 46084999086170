import React, { useState, useEffect, useContext } from "react";
// import { AuthAPI } from "../../API/auth-API";
import { UserContext } from "../../context/login/Context";
import { Box, Typography, TextField } from "@mui/material";
import { Button, message } from "antd";
import { useParams } from "react-router";
import { isNullOrEmpty } from "../../constants/utilities";
// import moment from "moment";
import "moment-timezone";
import Title from "./Title";
// import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { PatientAPI } from "../../API/patient-API";
import { AlertThresholdAPI } from "../../API/alertThreshold-API";

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}

export default function Thresholds({ order }: { order: any }) {
  const { profile, getCurrentClient } = useContext(UserContext);
  const [alertThreshold, setAlertThreshold] = useState([] as any);
  const [data, setData] = useState({} as any);
  const param: any = useParams();
  const [alertOne, setAlertOne] = useState();
  const [alertTwo, setAlertTwo] = useState();
  const [alertThree, setAlertThree] = useState();
  const [alertFour, setAlertFour] = useState();
  const [alertFive, setAlertFive] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

  let currentClient = getCurrentClient();
  useEffect(() => {
    AlertThresholdAPI.fetchATByOrder(param.id, currentClient).then((res) => {
      const viewAThreshold = [...res.data.alertThresholds];
      setData(viewAThreshold[0]);
      setIsLoading(false);
      if (
        viewAThreshold[0] !== undefined &&
        viewAThreshold[0].alertThresholds !== undefined
      ) {
        setAlertThreshold(viewAThreshold[0].alertThresholds);
        viewAThreshold[0].alertThresholds.map((data: any) => {
          switch (data.alertType) {
            case "Diag7DaysFromDay1FromAuthEx":
              setAlertOne(data.thresholdDays);
              break;
            case "WorkUp7DaysFromOrderSent":
              setAlertTwo(data.thresholdDays);
              break;
            case "NoAppointment":
              setAlertThree(data.thresholdDays);
              break;
            case "ResultsReceived3DaysLate":
              setAlertFour(data.thresholdDays);
              break;
            case "SurgeryCompleteIs30Days":
              setAlertFive(data.thresholdDays);
              break;
          }
        });
      }
    });
  }, []);

  function handleAlertChange(type: any, value: any) {
    let index = alertThreshold.findIndex((e: any) => e.alertType === type);
    if (index >= 0) {
      alertThreshold[index].thresholdDays = value;
      alertThreshold[index].updatedAt = new Date();
    } else {
      alertThreshold.push({
        alertType: type,
        thresholdDays: value,
      });
    }
  }

  const submitAlertThreshold = async () => {
    if (
      isNullOrEmpty(alertOne) &&
      isNullOrEmpty(alertTwo) &&
      isNullOrEmpty(alertFour) &&
      isNullOrEmpty(alertFive)
    ) {
      message.error("Please enter at least one alert threshold before saving.");
      return;
    }
    if (!data) {
      const body = {
        patientId: order.patientId,
        orderId: order._id,
        userId: profile?._id,
        alertThresholds: alertThreshold,
        clientId: currentClient,
      };
      await AlertThresholdAPI.newAlertThreshold({ data: body })
        .then((res) => {
          AlertThresholdAPI.fetchATByOrder(param.id, currentClient).then(
            (res) => {
              const viewAThreshold = [...res.data.alertThresholds];
              setData(viewAThreshold[0]);
            }
          );

          message.success("Alert threshold added successfully!");
        })
        .catch(() => {
          message.error("Adding threshold failed!");
          // setLoading(false);
        });
    } else {
      const body = {
        alertThresholds: alertThreshold,
      };
      await AlertThresholdAPI.putAlertThreshold(
        {
          data: body,
        },
        data._id
      )
        .then((res) => {
          message.success("Update successful!");
        })
        .catch(() => {
          message.error("Update failed!");
        });
    }
  };

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "30px",
        }}
      >
        <div
          style={{
            textAlign: "left",
            width: "90%",
          }}
        >
          <Title>
            <span style={{ fontSize: "24px" }}>Alert Thresholds</span>
          </Title>
          <Typography
            style={{ fontStyle: "italic", color: "red", fontSize: "17px" }}
          >
            If no custom thresholds are entered then default values will be used
          </Typography>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              textAlign: "left",
            }}
          >
            <div style={{ width: "50%" }}>
              <div className="row" style={{ justifyContent: "space-between" }}>
                <Typography
                  style={{
                    marginTop: "0px",
                    width: "70%",
                  }}
                >
                  Diagnostic order needs follow up or is delayed{" "}
                  <span style={{ fontStyle: "italic" }}>(DEFAULT: 7 days)</span>
                </Typography>
                <TextField
                  type="number"
                  style={{ width: "110px" }}
                  value={alertOne}
                  onChange={(e: any) => {
                    handleAlertChange(
                      "Diag7DaysFromDay1FromAuthEx",
                      e.target.value
                    );
                    setAlertOne(e.target.value);
                  }}
                />
              </div>
              <div className="row" style={{ justifyContent: "space-between" }}>
                <Typography
                  style={{
                    marginTop: "0px",
                    width: "70%",
                  }}
                >
                  Work up order needs follow up or is delayed{" "}
                  <span style={{ fontStyle: "italic" }}>(DEFAULT: 7 days)</span>
                </Typography>
                <TextField
                  type="number"
                  style={{ width: "110px" }}
                  value={alertTwo}
                  onChange={(e: any) => {
                    handleAlertChange(
                      "WorkUp7DaysFromOrderSent",
                      e.target.value
                    );
                    setAlertTwo(e.target.value);
                  }}
                />
              </div>
              <div className="row" style={{ display: "none" }}>
                <Typography
                  style={{
                    marginTop: "0px",
                    width: "70%",
                  }}
                >
                  Alert 3
                </Typography>
                <TextField
                  type="number"
                  style={{ width: "110px" }}
                  value={alertThree}
                  onChange={(e: any) => {
                    handleAlertChange("NoAppointment", e.target.value);
                    setAlertThree(e.target.value);
                  }}
                />
              </div>
              <div className="row" style={{ justifyContent: "space-between" }}>
                <Typography
                  style={{
                    marginTop: "0px",
                    width: "70%",
                  }}
                >
                  Order results have not been received by PCP{" "}
                  <span style={{ fontStyle: "italic" }}>(DEFAULT: 3 days)</span>
                </Typography>
                <TextField
                  type="number"
                  style={{ width: "110px" }}
                  value={alertFour}
                  onChange={(e: any) => {
                    handleAlertChange(
                      "ResultsReceived3DaysLate",
                      e.target.value
                    );
                    setAlertFour(e.target.value);
                  }}
                />
              </div>
              <div className="row" style={{ justifyContent: "space-between" }}>
                <Typography
                  style={{
                    marginTop: "0px",
                    width: "70%",
                  }}
                >
                  Surgery has not been completed within defined period{" "}
                  <span style={{ fontStyle: "italic" }}>
                    (DEFAULT: 30 days)
                  </span>
                </Typography>
                <TextField
                  type="number"
                  style={{ width: "110px" }}
                  value={alertFive}
                  onChange={(e: any) => {
                    handleAlertChange(
                      "SurgeryCompleteIs30Days",
                      e.target.value
                    );
                    setAlertFive(e.target.value);
                  }}
                />
              </div>
            </div>
            <div style={{ width: "50%" }}></div>
            <div style={{ width: "20%" }}></div>
          </div>
          <Button
            style={{
              marginTop: "30px",
              width: "15%",
            }}
            type="primary"
            onClick={submitAlertThreshold}
            className="accept-form-button"
          >
            Save
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}
