import React, { useMemo, useState, useEffect, useContext } from "react";
// import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
// import Link from "@mui/material/Link";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  Stepper,
  Step,
  StepLabel,
  Typography,
  StepContent,
} from "@mui/material";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { AuthAPI } from "../../API/auth-API";
import { useParams } from "react-router";
import { PatientAPI } from "../../API/patient-API";
import { PatientLogAPI } from "../../API/patientLog-API";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../context/login/Context";
import moment from "moment";
import "moment-timezone";
import Title from "./Title";

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}

export default function PatientLog(...order: any) {
  const [isLoading, setIsLoading] = useState(true);
  const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;
  const { getCurrentClient } = useContext(UserContext);
  const [logs, setLogs] = useState([{}] as any);
  const history = useHistory();

  const param: any = useParams();
  let currentClient = getCurrentClient();
  useEffect(() => {
    async function getData() {
      await PatientLogAPI.fetchPatientLogsByPatient(
        param.id,
        currentClient
      ).then((res) => {
        const logList = res.data.patientLogs.map((data: any, index: number) => {
          return {
            actionText: data.actionText,
            date: data.createdAt,
            keyDate: data.keyDate,
            patientId: data.patientId,
            comment: data.comment,
            logType: data.logType,
            key: index,
            author: data.author,
            _id: data._id,
          };
        });

        setLogs(logList);
      });
      setIsLoading(false);
    }
    getData();
  }, []);

  const sortedLogs = logs
    .sort((a: any, b: any) => {
      return a.createdAt - b.createdAt;
    })
    .reverse();

  const firstKeyDate = parseInt(moment(sortedLogs[0]?.keyDate).format("x"));
  const lastKeyDate = parseInt(
    moment(sortedLogs[logs.length - 1]?.keyDate).format("x")
  );

  const days = Math.ceil(
    Math.abs(lastKeyDate - firstKeyDate) / (1000 * 60 * 60 * 24)
  );

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "30px",
        }}
      >
        <div
          style={{
            textAlign: "left",
            width: "90%",
            padding: "10px",
          }}
        >
          <Title>
            <span style={{ fontSize: "30px" }}>Patient Journey</span>
          </Title>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "80vw",
                  height: "20vh",
                }}
              >
                <Spin
                  size="large"
                  className={`loading-spin-btn ${"active"}`}
                  indicator={antIcon}
                />
              </div>
            ) : (
              <div
                style={{
                  textAlign: "left",
                  width: "90%",
                  backgroundColor: "#ffffff",
                  padding: "10px",
                  boxShadow: "-2px 1px 3px 0px #d2d2d2",
                }}
              >
                <Table
                  style={{ backgroundColor: "#FFFFFF" }}
                  sx={{ minWidth: 700 }}
                  aria-label="customized table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <span
                          style={{
                            fontSize: "18px",
                            fontWeight: "bold",
                            margin: "0px",
                          }}
                        >
                          Patient ID
                        </span>
                      </TableCell>
                      <TableCell align="left">
                        <span
                          style={{
                            fontSize: "18px",
                            fontWeight: "bold",
                            margin: "0px",
                          }}
                        >
                          Type
                        </span>
                      </TableCell>
                      <TableCell align="left">
                        <span
                          style={{
                            fontSize: "18px",
                            fontWeight: "bold",
                            margin: "0px",
                          }}
                        >
                          Date Created
                        </span>
                      </TableCell>
                      <TableCell align="left">
                        <span
                          style={{
                            fontSize: "18px",
                            fontWeight: "bold",
                            margin: "0px",
                          }}
                        >
                          Author
                        </span>
                      </TableCell>
                      <TableCell align="left">
                        <span
                          style={{
                            fontSize: "18px",
                            fontWeight: "bold",
                            margin: "0px",
                          }}
                        >
                          Text
                        </span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedLogs.map((log: any) => (
                      <TableRow key={log._id}>
                        <TableCell>{log.patientId}</TableCell>
                        <TableCell align="left">{log.logType}</TableCell>
                        <TableCell align="left">
                          {moment(log.createdAt)
                            .tz("America/Los_Angeles")
                            .format("MM/DD/YYYY, h:mma")}
                        </TableCell>
                        <TableCell align="left">{log.author}</TableCell>
                        <TableCell style={{ width: "30%" }} align="left">
                          {log.comment}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {!isLoading && !sortedLogs?.length && (
                  <div
                    style={{
                      textAlign: "center",
                      fontWeight: 500,
                      marginTop: "100px",
                    }}
                  >
                    <Typography variant={"h5"}>
                      Patient journey data for this patient is currently being
                      loaded. Please check again in a few days
                    </Typography>
                  </div>
                )}
              </div>
            )}
            {sortedLogs.length > 0 && (
              <Card className="journey">
                <Stepper orientation="vertical">
                  {sortedLogs.map(
                    (step: any, index: any) =>
                      step.logType !== "Day 1 in XpediteMD" && (
                        <Step completed={true} key={step._id}>
                          <StepLabel
                          // optional={
                          //   index === 2 ? (
                          //     <Typography variant="caption">Last step</Typography>
                          //   ) : null
                          // }
                          >
                            {step.logType}
                          </StepLabel>
                          <Typography style={{ color: "#ffffff" }}>
                            {step.comment}
                          </Typography>
                          <StepContent>
                            {/* <Box sx={{ mb: 2 }}>
                      <div>
                        <Button
                          variant="contained"
                          onClick={handleNext}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          {index === steps.length - 1 ? "Finish" : "Continue"}
                        </Button>
                        <Button
                          disabled={index === 0}
                          onClick={handleBack}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          Back
                        </Button>
                      </div>
                    </Box> */}
                          </StepContent>
                        </Step>
                      )
                  )}
                  <Step
                    className="end"
                    completed={true}
                    style={{ color: "#569aff !important" }}
                  >
                    <StepLabel>
                      <span style={{ color: "#569aff" }}>
                        End ({days.toString() !== "NaN" ? days : 0} days)
                      </span>
                    </StepLabel>
                  </Step>
                </Stepper>
              </Card>
            )}
          </div>
          {/* <Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 3 }}>
        See more orders
      </Link> */}
        </div>
      </div>
    </React.Fragment>
  );
}
