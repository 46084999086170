import React, { useState, useEffect, useContext } from "react";
import "moment-timezone";
import moment from "moment";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Button, Form, Spin, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import {
  Box,
  Typography,
  TextField,
  FormControl,
  Popover,
} from "@mui/material";

import Title from "./Title";
import { AuthAPI } from "../../API/auth-API";
import { NoteAPI } from "../../API/note-API";
import { PatientAPI } from "../../API/patient-API";
import { getUserName } from "../../constants/utilities";
import { UserContext } from "../../context/login/Context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}

const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

export default function Notes() {
  const history = useHistory();
  const [form] = Form.useForm();
  const param: any = useParams();
  const { profile, getCurrentClient } = useContext(UserContext);
  const [notes, setNotes] = useState([]);
  const [search, setSearch] = useState("");
  const [newNote, setNewNote] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [patient, setPatient] = useState([{}] as any);
  const [newAuthor, setNewAuthor] = useState(profile?.fullName);

  let currentClient = getCurrentClient();
  useEffect(() => {
    PatientAPI.getPatient(param.id, currentClient).then((res) => {
      const resPatient = res.data.patient;
      setPatient({ ...resPatient });
      setSearch(resPatient.patientId);
    });
  }, []);

  useEffect(() => {
    if (!newAuthor && profile?.fullName) {
      setNewAuthor(profile?.fullName);
    }
    if (!newAuthor && !profile?.fullName) {
      getUserName().then((res: any) => setNewAuthor(res));
    }
  }, [profile?.fullName, newAuthor]);

  const handleClose = () => setAnchorEl(null);
  const handleClick = (e: any) => setAnchorEl(e.currentTarget);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const addNewNote = async () => {
    const body = {
      note: newNote,
      author: newAuthor,
      patientId: patient.patientId,
      userId: profile?._id,
      clientId: currentClient,
    };
    await NoteAPI.newNote({ data: body })
      .then((res) => {
        message.success("Annotation added successfully!");
      })
      .catch(() => {
        message.error("Adding annotation failed!");
        // setLoading(false);
      });
    await NoteAPI.fetchNotes(currentClient).then((res) => {
      setNotes(res.data.notes);
      form.resetFields();
    });

    // update the state to the updatedUsers
  };

  const deleteNote = async (note: any) => {
    await NoteAPI.deleteNote(note._id)
      .then((res) => {
        NoteAPI.fetchNotes(currentClient).then((res) => {
          setNotes(res.data.notes);
        });
        message.success("Annotation deleted successfully!");
      })
      .catch(() => {
        message.error("Deleting annotation failed!");
        // setLoading(false);
      });
    handleClose();
  };

  const change = (e: any) => setNewNote(e.target.value);

  useEffect(() => {
    async function getData() {
      await NoteAPI.fetchNotes(currentClient).then((res) => {
        setNotes(res.data.notes);
      });
      setIsLoading(false);
    }
    getData();
  }, []);

  const filteredNotes = notes.filter((note: any) => {
    return note.patientId.toLowerCase().includes(search?.toLowerCase());
  });

  const sortedNotes = filteredNotes
    .sort((a: any, b: any) => {
      return a.createdAt - b.createdAt;
    })
    .reverse();

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "30px",
          //   height: "100vh",
        }}
      >
        <div
          style={{
            width: "90%",
            textAlign: "left",
            // backgroundColor: "#ffffff",
            // padding: "10px",
            // boxShadow: "-2px 1px 3px 0px #d2d2d2",
          }}
        >
          <div style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // justifyContent: "space-between",
                marginBottom: "30px",
              }}
            >
              <Title>
                <span style={{ fontSize: "40px" }}>Annotations</span>
              </Title>
              {/* {alert && (
                <Alert severity="warning">Your note was empty</Alert>
              )} */}
              <Form form={form} onFinish={addNewNote} style={{ width: "100%" }}>
                <FormControl
                  style={{
                    width: "70%",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Form.Item
                    name="thisField"
                    style={{ width: "65%", marginBottom: "0px" }}
                  >
                    <TextField
                      className="ant-input"
                      required
                      label="Add Annotation"
                      multiline
                      rows={4}
                      onChange={change}
                    />
                  </Form.Item>
                  <Form.Item
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      width: "20%",
                      margin: "0px",
                    }}
                  >
                    <Button
                      style={{
                        width: "100%",
                        marginTop: "0px",
                        marginLeft: "20px",
                      }}
                      type="primary"
                      className="accept-form-button"
                      htmlType="submit"
                    >
                      Save
                    </Button>
                  </Form.Item>
                </FormControl>
              </Form>
            </div>
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "80vw",
                  height: "20vh",
                }}
              >
                <Spin
                  size="large"
                  className={`loading-spin-btn ${"active"}`}
                  indicator={antIcon}
                />
              </div>
            ) : filteredNotes.length === 0 ? (
              <div
                style={{
                  textAlign: "center",
                  fontWeight: 500,
                  marginTop: "100px",
                }}
              >
                <Typography variant={"h5"}>
                  There are currently no annotations for this patient
                </Typography>
              </div>
            ) : (
              <div>
                {filteredNotes.map((note: any) => (
                  <div
                    key={note._id}
                    style={{
                      display: "flex",
                      marginBottom: "30px",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      style={{
                        width: "95%",
                        borderLeft: "5px solid #569aff",
                        textAlign: "left",
                        backgroundColor: "#ffffff",

                        padding: "10px",
                        boxShadow: "-2px 1px 3px 0px #d2d2d2",
                      }}
                    >
                      <Typography variant="body1">{note.note}</Typography>
                      <Typography variant="caption">{note.author}</Typography>
                      <br />
                      <Typography variant="caption">
                        {moment(note.createdAt)
                          .tz("America/Los_Angeles")
                          .format("MM/DD/YYYY, h:mma")}
                      </Typography>
                    </Box>
                    {note.userId === profile?._id && (
                      <div>
                        <Popover
                          id={id}
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          <Typography
                            style={{ width: "200px", textAlign: "center" }}
                            sx={{ p: 2 }}
                          >
                            Are you sure you want to delete the comment?
                          </Typography>
                          <Button
                            className="cancel"
                            style={{ width: "50%" }}
                            onClick={handleClose}
                          >
                            Cancel
                          </Button>
                          <Button
                            className="delete"
                            style={{ width: "50%" }}
                            onClick={() => deleteNote(note)}
                          >
                            Delete
                          </Button>
                        </Popover>
                        <Button
                          aria-describedby={id}
                          onClick={handleClick}
                          // onClick={() => deleteNote(note)}
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                            margin: "0px",
                            padding: "0px",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faTimesCircle}
                            style={{
                              marginLeft: "20px",
                              fontSize: "24px",
                              fontWeight: 700,
                              color: "red",
                            }}
                          />
                        </Button>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
