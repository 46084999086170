import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router";
import Header from "../../components/layouts/Header";
import { useHistory } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
} from "@mui/material";
import FormSelectDiagnostic from "../new-order/DiagnosticPanel";
import moment from "moment";
import { PatientAPI } from "../../API/patient-API";
import { PanelContext } from "../../context/panel-context/PanelContext";
import TreatmentPanel from "../new-order/treatment/TreatmentPanel";
import { Form, Button, Spin, Modal } from "antd";
import { message } from "antd";
import { faFolderOpen } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingOutlined } from "@ant-design/icons";
import DiagnosticModal from "../new-order/treatment/DiagnosticModal";
import MainWorkup from "../new-order/MainWorkup";
import { typeParameterInstantiation } from "@babel/types";
import { UserContext } from "../../context/login/Context";

interface IProps {}
interface ITagsMap {
  [key: string]: string;
}
interface IQuizSubtypes {
  [key: string]: boolean;
}

const tagsLabelMap: ITagsMap = {
  diagnostic: "Diagnostic",
  "work-up": "Work-up",
  therapeutic: "Therapeutic",
};

const getSubtype = (quizSubtypes: IQuizSubtypes, subtypeLabelMap: ITagsMap) => {
  const finishedSubtype = Object.keys(quizSubtypes).find(
    (subtype) => quizSubtypes[subtype]
  );
  if (finishedSubtype) {
    return subtypeLabelMap[finishedSubtype];
  }
  return "";
};

function DisplayForm(props: { show: boolean; Component: any }) {
  const { show, Component } = props;

  return (
    <div
      className="diagnostic-container"
      style={{
        display: show ? "block" : "none",
      }}
    >
      <Component />
    </div>
  );
}
const PatientNewOrder: React.FC<IProps> = (props) => {
  const {
    clickTags,
    setFieldSelected,
    submitNewOrder,
    isLoading,
    setIsDisable,
    form,
    onChangeForm,
    isValidate,
    setNewOrderValue,
    listActive,
    isQuizFinish,
    setIsQuizFinish,
    subtypeLabelMap,
  } = useContext(PanelContext);
  const { profile, getCurrentClient } = useContext(UserContext);
  const { confirm } = Modal;

  const history = useHistory();
  const param: any = useParams();
  const query: any = new URLSearchParams(window.location.search);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const onFinish = (valueObj: any) => {
    const realvalue = {
      ...valueObj,
      radiationleft: form.getFieldValue("radiationleft"),
      radiationright: form.getFieldValue("radiationright"),
      surgeryleft: form.getFieldValue("surgeryleft"),
      surgeryright: form.getFieldValue("surgeryright"),
      medical: form.getFieldValue("medical"),
      imaging: form.getFieldValue("imaging"),
      surgeryWorkup: form.getFieldValue("surgeryWorkup"),
      social: form.getFieldValue("social"),
      radOncology: form.getFieldValue("radOncology"),
      medOncology: form.getFieldValue("medOncology"),
      genetic: form.getFieldValue("genetic"),
      nutrition: form.getFieldValue("nutrition"),
      therapy: form.getFieldValue("therapy"),
      patientNav: form.getFieldValue("patientNav"),
    };
    showConfirm(realvalue);
  };

  const [patient, setPatient] = useState([{}] as any);
  let currentClient = getCurrentClient();
  React.useEffect(() => {
    async function getData() {
      await PatientAPI.getPatient(param.id, currentClient).then((res) => {
        const resPatient = res.data.patient;
        setPatient({ ...resPatient });
        // setSearch(resPatient.patientId);
      });
    }
    getData();
  }, []);

  const onFinishFailed = (e: any) => {
    message.error("Please enter the red boxes and do not leave them blank !");
  };

  const onExit = () => {
    confirm({
      title: "Do you want to quit order?",
      icon: <ExclamationCircleOutlined />,
      content: "When clicked the OK button, unsaved data will be lost.",
      onOk() {
        history.push("/adminView");
      },
      onCancel() {},
    });
  };

  const showConfirm = (values: any) => {
    const newOrder = Object.keys(values).reduce((prev, key) => {
      return {
        id: { value: patient.patientId },
        clientId: currentClient,
        ...prev,
        name: {
          value: {
            first: patient.firstName,
            last: patient.lastName,
          },
        },
        dob: {
          value: patient.dob,
        },
        sex: {
          value: patient.sex,
        },
        [key]: {
          value: values[key],
          comments: [
            {
              userId: "",
              comment: "",
            },
          ],
        },
        userId: profile?._id,
        type: tagsLabelMap[param.isTags],
        subtype: getSubtype(isQuizFinish, subtypeLabelMap),
      };
    }, {});
    confirm({
      title: "Save confirmation",
      content:
        '"By clicking "accept", you are verifying that all data has been entered to the best of your knowledge.',
      async onOk() {
        await submitNewOrder(newOrder);
        history.push(`/patient-board/orders/${patient._id}`);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  // const onChangeForm = (values: any) => {
  //     console.log(values);
  //     form.setFieldsValue({
  //         panelICD10: ICD10CODES[1].value

  //     })
  //     const skinChange = values && values.find((field: any) => field.name.includes('involvement'));

  //     if (skinChange) {
  //         const fields = form.getFieldsValue();
  //         if (fields.involvement[fields.involvement.length - 1] === '0-0') {
  //             form.setFieldsValue({
  //                 involvement: ['0-0'],
  //             })
  //         } else {
  //             form.setFieldsValue({
  //                 involvement: fields.involvement.filter((item: any) => item !== '0-0'),
  //             })
  //         }

  //     }
  // }

  useEffect(() => {
    form.resetFields();
    setIsQuizFinish({
      radiationleft: false,
      radiationright: false,
      medical: false,
      surgeryleft: false,
      surgeryright: false,
      imaging: false,
      surgeryWorkup: false,
      social: false,
      radOncology: false,
      medOncology: false,
      genetic: false,
      patientNav: false,
      nutrition: false,
      therapy: false,
    });
    if (!isValidate)
      history.push(`/new-patientorder/${param.id}/${param.isTags}`);
    return () => {
      setFieldSelected(null);
      clickTags(param.isTags);
      setIsDisable(false);
    };
  }, []);

  let start = moment().format("YYYY");
  let end = moment(patient.dob).format("YYYY");

  let old = Math.abs(parseInt(end) - parseInt(start)).toString();
  //   const tagsClick = (tag: any) => {
  //     if (isValidate) {
  //       setIsDisable(false);
  //       clickTags(tag);
  //       history.push(tag);
  //     } else return;
  //   };

  //   const fieldChange = (changed: any, all: any) => {
  //     console.log("changed", changed);
  //     console.log("all", all);
  //   };

  //   const onChangeValue = (value: any) => {
  //     console.log(value);
  //   };

  return (
    <div style={{ height: "100%", width: "calc(100% - 240px)" }}>
      <div style={{ position: "fixed", zIndex: +2, top: 0, width: "100vw" }}>
        <Header />
      </div>
      <div
        className="side-nav"
        style={{
          height: "100%",
          width: "240px",
          position: "fixed",
          top: "90px",
          left: "0",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "10%",
            alignItems: "left",
            marginLeft: "10px",
            justifyContent: "center",
            color: "white",
            fontWeight: 900,
            fontSize: "20px",
          }}
        >
          <Typography variant={"h6"} style={{ color: "white" }}>
            <span style={{ fontSize: "inherit", fontWeight: "bold" }}>
              Patient ID:
            </span>{" "}
            {patient.patientId}
          </Typography>
          <Typography
            variant={"caption"}
            style={{ fontSize: "16px", color: "white" }}
          >
            <span style={{ fontSize: "16px", fontWeight: "bold" }}>DOB:</span>{" "}
            {moment(patient.dob).format("MM/DD/YYYY")}{" "}
            <span style={{ fontSize: "16px", fontWeight: "bold" }}>Age:</span>{" "}
            {old !== "NaN" && old}
          </Typography>
          <Typography variant={"caption"} style={{ color: "white" }}>
            <span style={{ fontSize: "16px", fontWeight: "bold" }}>Sex:</span>{" "}
            {patient.sex}
          </Typography>
        </div>
        {/* <Toolbar /> */}
        <Box>
          <List style={{ textAlign: "center" }}>
            <ListItem
              button
              onClick={() => history.goBack()}
              style={{
                fontSize: "24px",
                color: "#ffffff",
                textAlign: "center",
              }}
            >
              <FontAwesomeIcon icon={faFolderOpen} />
              <ListItemText primary="Back" />
            </ListItem>
            <Divider />
            {/* <Link to="/patientBoard/IMV001">
                <ListItem
                  button
                  style={{
                    fontSize: "24px",
                    color: "#ffffff",
                    textAlign: "center",
                  }}
                >
                  <FontAwesomeIcon icon={faClipboard} />
                  <ListItemText primary="Notes" />
                </ListItem>
              </Link>
              <Divider />
              <Link to="/patientBoard/IMV001">
                <ListItem
                  button
                  style={{
                    fontSize: "24px",
                    color: "#ffffff",
                    textAlign: "center",
                  }}
                >
                  <FontAwesomeIcon icon={faExclamationCircle} />
                  <ListItemText primary="Alerts" />
                </ListItem>
              </Link>
              <Divider />
              <Link to="/patientBoard/IMV001">
                <ListItem
                  button
                  style={{
                    fontSize: "24px",
                    color: "#ffffff",
                    textAlign: "center",
                  }}
                >
                  <FontAwesomeIcon icon={faStickyNote} />
                  <ListItemText primary="Patient Log" />
                </ListItem>
              </Link> */}
          </List>
        </Box>
      </div>
      <div
        className="orderpanel-container"
        style={{
          left: 260,
          top: 90,
          // width: "80%",
          marginLeft: "18%",
          // marginRight: "1260px !important",
          position: "fixed",
        }}
      >
        <div className="wrapper">
          <div className="orderpanel-left-container">
            <div className="order-lerf-item">
              <h1>
                {param.isTags === "diagnostic"
                  ? "Diagnostic"
                  : param.isTags === "work-up"
                  ? "Work-up"
                  : param.isTags === "therapeutic"
                  ? "Therapeutic"
                  : ""}{" "}
              </h1>
              <div
                className="form-select-qualify"
                style={{
                  minHeight: "auto",
                }}
              >
                <Form
                  form={form}
                  // onValuesChange={onChangeValue}
                  name="qualify-data"
                  className="qualify-form"
                  layout="vertical"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  initialValues={{
                    involvement: ["0-0"],
                    sex: "Female",
                    metastasis: "No",
                    lymph: "No evidence of lymph node involvement",
                  }}
                  onFieldsChange={onChangeForm}
                >
                  {/* <Form.Item shouldUpdate>
                  {() => (
                    <div className="select-item">
                      <div
                        onClick={() => (
                          setIsDisable(false),
                          clickTags("diagnostic"),
                          history.push(`diagnostic`)
                        )}
                        className={`select ${
                          param.isTags === "diagnostic" ? "active" : ""
                        } `}
                      >
                        Diagnostic
                      </div>
                      <div
                        onClick={() => tagsClick("workup")}
                        className={`select ${
                          param.isTags === "workup" ? "active" : ""
                        } `}
                        style={{
                          opacity: isValidate ? 1 : 0.3,
                        }}
                      >
                        Work-up
                      </div>
                      <div
                        onClick={() => tagsClick("treatment")}
                        className={`select ${
                          param.isTags === "treatment" ? "active" : ""
                        } referrals`}
                        style={{
                          opacity: isValidate ? 1 : 0.3,
                        }}
                      >
                        Therapeutic
                      </div>
                    </div>
                  )}
                </Form.Item> */}
                  <div
                    style={{
                      position: "fixed",
                      top: "100px",
                      left: 250,
                      width: "18%",
                      height: "100%",
                    }}
                  >
                    <DiagnosticModal />
                  </div>
                  <div style={{ width: "100%" }}>
                    <DisplayForm
                      show={param.isTags === "diagnostic"}
                      Component={FormSelectDiagnostic}
                    />
                    <DisplayForm
                      show={param.isTags === "work-up"}
                      Component={MainWorkup}
                    />
                    <div className="mainTreatment">
                      <DisplayForm
                        show={param.isTags === "therapeutic"}
                        Component={TreatmentPanel}
                      />
                    </div>
                  </div>
                  <div
                    className="submit-diagnostic"
                    style={{ marginTop: "30px" }}
                  >
                    <Form.Item className="accept-form">
                      <div
                        className="action-form-btn-group"
                        style={
                          param.isTags
                            ? !query.get("item")
                              ? { display: "block" }
                              : { display: "none" }
                            : { display: "none" }
                        }
                      >
                        <Button
                          type="link"
                          className="accept-form-button-link"
                          onClick={onExit}
                        >
                          Exit
                        </Button>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="accept-form-button"
                          onClick={() => setNewOrderValue("file", listActive)}
                        >
                          Save
                        </Button>
                      </div>
                      <Spin
                        className={`loading-spin-btn ${
                          !isLoading ? "" : "active"
                        }`}
                        indicator={antIcon}
                      />
                    </Form.Item>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientNewOrder;
