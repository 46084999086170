import React, { useRef, useState, createContext, useContext } from "react";
import moment from "moment";
import { Form, message } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";

import { UserContext } from "../login/Context";
import { OrderAPI } from "../../API/order-API";
import { PatientLogAPI } from "../../API/patientLog-API";
import { buildEditFormData } from "../../pages/order-panel/OrderPanel";

interface IPanelContext {
  isDisable: boolean;
  isTags: any;
  isLoading: boolean;
  newOrderFormData: any;
  editOrderFormData: any;
  isInputChoose: string;
  isInputValue: string;
  [key: string]: any;
}

interface ISubtypeLabel {
  [key: string]: string;
}

export const PanelContext = createContext<IPanelContext>({
  isTags: null,
  newOrderFormData: {},
  editOrderFormData: {},
  subtypeLabelMap: {},
  isInputChoose: "",
  isInputValue: "",
  isLoading: false,
  isDisable: false,
  clickTags: () => null,
  setNewOrderValue: () => null,
  setEditOrderValue: () => null,
  setEditOrderComment: () => null,
  submitNewOrder: () => null,
  submitEditOrder: () => null,
  setEditOrder: () => null,
  submitComment: () => null,
  onFormChangeListen: () => null,
});

const subtypeLabelMap: ISubtypeLabel = {
  imaging: "Diagnostic",
  surgeryWorkup: "Surgery",
  social: "Social Work",
  medOncology: "Medical Oncology",
  radOncology: "Radiation Oncology",
  patientNav: "Patient Navigation",
  therapy: "Physical/Occupational therapy",
  nutrition: "Nutrition",
  genetic: "Genetic counseling",
  medical: "Medical Oncology",
  radiationleft: "Radiation Oncology",
  radiationright: "Radiation Oncology",
  surgeryleft: "Surgery",
  surgeryright: "Surgery",
};

const buildFormData = (newOrder: any) => {
  return {
    patientId: newOrder.id.value,
    userId: newOrder?.userId,
    clientId: newOrder?.clientId,
    type: newOrder.type,
    subtype: newOrder.subtype,
    detailedSummary: newOrder.detailedSummary,
    shortSummary: newOrder.shortSummary,
    appointmentRecommendation: newOrder.appointmentRecommendation,
    appointmentResults: newOrder.appointmentResults,
    diagnosticPhase: {
      name: newOrder.name,
      dob: newOrder.dob,
      sex: newOrder.sex,
      ICDSIGNS: newOrder.ICDSIGNS,
      indications: newOrder.indications,
      pathology: newOrder.pathology,
      lymph: newOrder.lymph,
      metastasis: newOrder.metastasis,
      metastasisClinical: newOrder.metastasisClinical,
      menopausal: newOrder.menopausal,
      involvement: newOrder.involvement,
      other: newOrder.other,
    },
    workup: {
      tumorSize: newOrder.tumorSize,
      estrogen: newOrder.estrogen,
      progesterone: newOrder.progesterone,
      HER2: newOrder.HER2,
      ki67: newOrder.ki67,
      panelICD10: newOrder.panelICD10,
      preopOrders: newOrder.preopOrders,
      preopRadiology: newOrder.preopRadiology,
      file: newOrder.file,
      referrals: newOrder.referrals,
    },
    orderDetail: {
      orderSentDate: newOrder.orderDetail?.orderSentDate,
      appointmentRequestedDate: newOrder.orderDetail?.appointmentRequestedDate,
      appointmentConfirmationReceivedDate:
        newOrder.orderDetail?.appointmentConfirmationReceivedDate,
      patientAppointmentDate: newOrder.orderDetail?.patientAppointmentDate,
      appointmentCompletedDate: newOrder.orderDetail?.appointmentCompletedDate,
      resultsReceivedDate: newOrder.orderDetail?.resultsReceivedDate,
      resultsSentToPatient: newOrder.orderDetail?.resultsSentToPatient,
    },
    treatmentResult: {
      radiation: {
        left: {
          ...newOrder.radiationleft,
          value: newOrder.radiationleft.value,
        },
        right: {
          ...newOrder.radiationright,
          value: newOrder.radiationright.value,
        },
      },
      medical: {
        ...newOrder.medical,
        value: newOrder.medical.value,
      },
      surgery: {
        left: {
          ...newOrder.surgeryleft,
          value: newOrder.surgeryleft.value,
        },
        right: {
          ...newOrder.surgeryright,
          value: newOrder.surgeryright.value,
        },
      },
      imaging: {
        ...newOrder.imaging,
        value: newOrder.imaging.value,
      },
      surgeryWorkup: {
        ...newOrder.surgeryWorkup,
        value: newOrder.surgeryWorkup.value,
      },
      social: {
        ...newOrder.social,
        value: newOrder.social.value,
      },
      radOncology: {
        ...newOrder.radOncology,
        value: newOrder.radOncology.value,
      },
      genetic: {
        ...newOrder.genetic,
        value: newOrder.genetic.value,
      },
      medOncology: {
        ...newOrder.medOncology,
        value: newOrder.medOncology.value,
      },
      therapy: {
        ...newOrder.therapy,
        value: newOrder.therapy.value,
      },
      nutrition: {
        ...newOrder.nutrition,
        value: newOrder.nutrition.value,
      },
      patientNav: {
        ...newOrder.patientNav,
        value: newOrder.patientNav.value,
      },
    },
    comment: {
      comment: newOrder.comment,
    },
  };
};

// const buildPatientData = ({
//   firstName,
//   lastName,
//   dob,
//   sex,
// }: // ICDSIGNS,
// // indications,
// // menopausal,
// // involvement,
// // other,
// any) => {
//   return {
//     firstName: firstName.value,
//     lastName: lastName.value,
//     dob: dob.value,
//     sex: sex.value,
//     // diagnostic: {
//     // icd: ICDSIGNS.value,
//     // indications: indications.value,
//     // menopausal: menopausal.value,
//     // other: other.value,
//     // skin: involvement.value,
//     // },
//   };
// };

const buildFormDataPut = (newOrder: any) => {
  return {
    patientId: newOrder.id.value,
    userId: newOrder?.userId,
    clientId: newOrder?.clientId,
    subtype: newOrder.subtype,
    detailedSummary: newOrder.detailedSummary,
    shortSummary: newOrder.shortSummary,
    appointmentRecommendation: newOrder.appointmentRecommendation,
    appointmentResults: newOrder.appointmentResults,
    diagnosticPhase: {
      name: newOrder.name,
      dob: newOrder.dob,
      sex: newOrder.sex,
      ICDSIGNS: newOrder.ICDSIGNS,
      indications: newOrder.indications,
      pathology: newOrder.pathology,
      lymph: newOrder.lymph,
      metastasis: newOrder.metastasis,
      metastasisClinical: newOrder.metastasisClinical,
      menopausal: newOrder.menopausal,
      involvement: newOrder.involvement,
      other: newOrder.other,
    },
    workup: {
      tumorSize: newOrder.tumorSize,
      estrogen: newOrder.estrogen,
      progesterone: newOrder.progesterone,
      ki67: newOrder.ki67,
      HER2: newOrder.HER2,
      panelICD10: newOrder.panelICD10,
      preopOrders: newOrder.preopOrders,
      preopRadiology: newOrder.preopRadiology,
      file: newOrder.file,
      referrals: newOrder.referrals,
    },
    orderDetail: {
      orderSentDate: newOrder.orderDetail?.orderSentDate,
      appointmentRequestedDate: newOrder.orderDetail?.appointmentRequestedDate,
      appointmentConfirmationReceivedDate:
        newOrder.orderDetail?.appointmentConfirmationReceivedDate,
      patientAppointmentDate: newOrder.orderDetail?.patientAppointmentDate,
      appointmentCompletedDate: newOrder.orderDetail?.appointmentCompletedDate,
      resultsReceivedDate: newOrder.orderDetail?.resultsReceivedDate,
      resultsSentToPatient: newOrder.orderDetail?.resultsSentToPatient,
    },
    treatmentResult: {
      radiation: {
        left: {
          ...newOrder.radiationleft,
          value: newOrder.radiationleft.value,
        },
        right: {
          ...newOrder.radiationright,
          value: newOrder.radiationright.value,
        },
      },
      medical: {
        ...newOrder.medical,
        value: newOrder.medical.value,
      },
      surgery: {
        left: {
          ...newOrder.surgeryleft,
          value: newOrder.surgeryleft.value,
        },
        right: {
          ...newOrder.surgeryright,
          value: newOrder.surgeryright.value,
        },
      },
      imaging: {
        ...newOrder.imaging,
        value: newOrder.imaging.value,
      },
      surgeryWorkup: {
        ...newOrder.surgeryWorkup,
        value: newOrder.surgeryWorkup.value,
      },
      social: {
        ...newOrder.social,
        value: newOrder.social.value,
      },
      radOncology: {
        ...newOrder.radOncology,
        value: newOrder.radOncology.value,
      },
      genetic: {
        ...newOrder.genetic,
        value: newOrder.genetic.value,
      },
      medOncology: {
        ...newOrder.medOncology,
        value: newOrder.medOncology.value,
      },
      therapy: {
        ...newOrder.therapy,
        value: newOrder.therapy.value,
      },
      nutrition: {
        ...newOrder.nutrition,
        value: newOrder.nutrition.value,
      },
      patientNav: {
        ...newOrder.patientNav,
        value: newOrder.patientNav.value,
      },
    },
    comment: {
      comment: newOrder.comment,
    },
  };
};

const PanelProvider: React.FC<{ children: any }> = ({ children }) => {
  const { user } = useAuth0();
  const history = useHistory();
  const [form] = Form.useForm();
  const icd10 = form.getFieldValue("panelICD10");
  const preopLabs = form.getFieldValue("preopOrders");
  const preopStudies = form.getFieldValue("preopRadiology");

  const cbListFormChange = useRef<any>();
  const { profile, getCurrentClient } = useContext(UserContext);
  const [isLoading, setLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [newOrder, setNewOrder] = useState<any>({});
  const [editOrder, setEditOrder] = useState<any>({});
  const [isValidate, setIsValidate] = useState(false);
  const [isInputValue, setIsInputValue] = useState("");
  const [listActive, setlistActive] = useState<any>([]);
  const [isInputChoose, setIsInputChoose] = useState("");
  const [fieldSelected, setFieldSelected] = useState<any>();
  const [isTags, setIsTags] = useState<string | null>("Diagnostic");
  const [isQuizFinish, setIsQuizFinish] = useState({
    radiationleft: false,
    radiationright: false,
    medical: false,
    surgeryleft: false,
    surgeryright: false,
    imaging: false,
  });

  // const isValidate = () => {
  //   return (
  //     !form.isFieldsTouched(true) ||
  //     !!form.getFieldsError().filter(({ errors }) => errors.length).length
  //   );
  // };

  let currentClient = getCurrentClient();

  const clickTags = (e: any) => setIsTags(e);

  const setNewOrderValue = (name: string, value: any) => {
    setNewOrder((prev: any) => ({ ...prev, [name]: value }));
  };

  const setEditOrderValue = (name: string, value: any) => {
    setEditOrder((prev: any) => ({
      ...prev,
      [name]: { ...prev[name], value },
    }));
  };

  const setEditOrderComment = (
    name: string,
    userId: string,
    comment: string
  ) => {
    setEditOrder((prev: any) => {
      return {
        ...prev,
        [name]: {
          ...prev[name],
          comments: [
            {
              userId: userId,
              comment: comment,
            },
          ],
        },
      };
    });
  };

  const submitNewOrder = async (data: any) => {
    setLoading(true);
    const body = await buildFormData({
      ...data,
      file: newOrder.file,
      detailedSummary: newOrder.detailedSummary,
      shortSummary: newOrder.shortSummary,
      // comment: newOrder.comment
    });
    // const bodyPatient = await buildPatientData(data);
    await OrderAPI.newOrder({ data: body })
      .then(async (res) => {
        setLoading(false);
        form.resetFields();
        if (data.type !== "Therapeutic") {
          const body = {
            logType: `${res.data.order.type} order created`,
            userId: profile?._id,
            patientId: res.data.order.patientId,
            clientId: currentClient,
            keyDate: res.data.order.createdAt,
            orderId: res.data.order._id,
            orderNumber: res.data.order.orderNumber,
            author: profile?.fullName ?? user?.name,
            comment: `${res.data.order.type} order was requested on: ${moment(
              res.data.order.createdAt
            )
              .tz("America/Los_Angeles")
              .format("MM/DD/YYYY h:mma")}`,
          };
          await PatientLogAPI.newPatientLog({ data: body });
        } else {
          const body = {
            logType: `${res.data.order.type} order created`,
            userId: profile?._id,
            patientId: res.data.order.patientId,
            keyDate: res.data.order.createdAt,
            clientId: currentClient,
            orderId: res.data.order._id,
            orderNumber: res.data.order.orderNumber,
            author: profile?.fullName ?? user?.name,
            comment: `Date consult with surgeon initiated is: ${moment(
              res.data.order.createdAt
            )
              .tz("America/Los_Angeles")
              .format("MM/DD/YYYY h:mma")}`,
          };
          await PatientLogAPI.newPatientLog({ data: body });
        }
        message.success("Order successful!");
        // history.push(`/patientBoard/${res.data.order._id}`);
      })
      .catch(() => {
        message.error("Update failed!");
        setLoading(false);
      });
  };

  const submitEditOrder = async (_id: string, data: any) => {
    setLoading(true);
    const summary = {} as any;
    if (editOrder.detailedSummary?.value !== undefined) {
      summary.detailedSummary = editOrder.detailedSummary?.value;
    }
    if (editOrder.shortSummary?.value !== undefined) {
      summary.shortSummary = editOrder.shortSummary?.value;
    }
    const body = await buildFormDataPut({
      ...data,
      file: newOrder.file,
      ...summary,
    });
    await OrderAPI.editOrder({ data: body }, _id)
      .then(({ data }) => {
        const newData = buildEditFormData(data.order);
        setEditOrder(newData);
        setLoading(false);
        message.success("Update successful!");
      })
      .catch(() => {
        message.error("Update failed!");
        setLoading(false);
      });
  };

  const submitComment = async (_id: string, data: any) => {
    setLoading(true);
    const editData = buildFormDataPut(data);
    await OrderAPI.editOrder(
      {
        data: editData,
        // status: "Returned",
      },
      _id
    )
      .then((res) => {
        setLoading(false);
        message.success("Update successful!");
        history.push("/");
      })
      .catch(() => {
        setLoading(false);
        message.error("Update failed!");
      });
  };
  const getFieldByName = (name: string) => {
    return editOrder[name];
  };

  const onChangeForm = (values: any, all: any) => {
    if (cbListFormChange.current) {
      cbListFormChange.current(values);
    }

    // form.setFieldsValue({
    //     panelICD10: ICD10CODES[1].value
    // })

    // keep this
    const skinChange =
      values && values.find((field: any) => field.name.includes("involvement"));

    if (skinChange) {
      const fields = form.getFieldsValue();
      if (fields.involvement[fields.involvement.length - 1] === "0-0") {
        form.setFieldsValue({
          involvement: ["0-0"],
        });
      } else {
        form.setFieldsValue({
          involvement: fields.involvement.filter((item: any) => item !== "0-0"),
        });
      }
    }
    setIsValidate(
      all
        .filter(
          (ele: any) =>
            ele.name.includes("name") ||
            ele.name.includes("age") ||
            ele.name.includes("dob") ||
            ele.name.includes("sex") ||
            ele.name.includes("ICDSIGNS") ||
            ele.name.includes("menopausal") ||
            ele.name.includes("involvement")
        )
        .every((ele: any) => ele.value)
    );
  };

  const onFormChangeListen = (cb: any) => {
    cbListFormChange.current = cb;
  };

  return (
    <PanelContext.Provider
      value={{
        isTags: isTags,
        newOrderFormData: newOrder,
        editOrderFormData: editOrder,
        isInputChoose: isInputChoose,
        isInputValue,
        isLoading,
        isDisable,
        isValidate,
        icd10,
        preopLabs,
        preopStudies,
        setIsDisable,
        clickTags: clickTags,
        setNewOrderValue: setNewOrderValue,
        setNewOrder,
        setEditOrderValue: setEditOrderValue,
        setEditOrderComment,
        submitNewOrder: submitNewOrder,
        submitEditOrder: submitEditOrder,
        setIsInputChoose,
        setIsInputValue,
        setEditOrder,
        submitComment,
        fieldSelected,
        setFieldSelected,
        getFieldByName,
        form,
        onChangeForm,
        onFormChangeListen,
        listActive,
        setlistActive,
        isQuizFinish,
        setIsQuizFinish,
        subtypeLabelMap,
      }}
    >
      {children}
    </PanelContext.Provider>
  );
};

export default PanelProvider;
