import React, { useContext, useEffect, useState } from "react";
import { Menu, Modal, message } from "antd";
import { Link } from "react-router-dom";
import { MenuItem } from "@mui/material";
import SubMenu from "antd/lib/menu/SubMenu";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory, useLocation } from "react-router-dom";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Icon, { ExclamationCircleOutlined } from "@ant-design/icons";
import { ClientAPI } from "../../API/client-API";

import Logo from "../../images/xpeditemd.png";
import Photo from "../../images/layout/user-icon.png";
import { UserContext } from "../../context/login/Context";

const { confirm } = Modal;
const icon = <img src={Photo} alt="user" />;

const Header = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { user, logout } = useAuth0();
  const [logo, setLogo] = useState("");
  const { profile, setProfile, clients, getCurrentClient, setCurrentClient } =
    useContext(UserContext);
  let currentClient = getCurrentClient();

  const clickLogout = () => {
    logout();
  };

  const onExit = () => {
    if (pathname.includes("new-order") || pathname.includes("order-panel")) {
      confirm({
        title: "Do you want to quit order?",
        icon: <ExclamationCircleOutlined />,
        content: "When clicked the OK button, unsaved data will be lost.",
        onOk() {
          history.push("/");
        },
        onCancel() {},
      });
    } else {
      history.push("/");
    }
  };

  const isActive = (value: any) =>
    pathname === value ? "menu-link-active" : "menu-link";

  useEffect(() => {
    ClientAPI.getLogoLink({
      data: {
        link: clients?.find((client: any) => client?._id == currentClient)
          ?.logoURL,
      },
    })
      .then((res) => {
        setLogo(res.data.link);
      })
      .catch((err) => {
        console.log(err);
        // message.error("Something went wrong trying to view this document.");
      });
  }, [clients, currentClient]);

  return (
    <div
      className="header"
      style={{ zIndex: 1, backgroundColor: "#ffffff", alignItems: "center" }}
    >
      <div className={"wrapper"}>
        <div className="header-inner">
          <div onClick={onExit} className="header-logo">
            <div
              className="header-logo"
              style={{ display: "flex", alignItems: "center" }}
            >
              {currentClient && logo !== "" ? (
                <img
                  className="logo"
                  src={logo}
                  alt="Client"
                  style={{
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                  }}
                />
              ) : (
                <img className="logo" src={Logo} alt="XpediteMD" />
              )}
            </div>
          </div>
          <div className="sub-menu" style={{ justifyContent: "space-evenly" }}>
            <div className="menu" style={{ width: "100%" }}>
              <MenuItem
                to="/"
                component={Link}
                className={isActive("/")}
                style={{ color: "white", margin: "5px" }}
              >
                Summary
              </MenuItem>
              <MenuItem
                to="/adminView"
                component={Link}
                className={isActive("/adminView")}
                style={{ color: "white", margin: "5px" }}
              >
                Manage Patients & Orders
              </MenuItem>
              <MenuItem
                to="/patient/new"
                component={Link}
                className={isActive("/patient/new")}
                style={{ color: "white", margin: "5px" }}
              >
                Add New Patient
              </MenuItem>
              <MenuItem
                to="/follow-up"
                component={Link}
                className={isActive("/follow-up")}
                style={{ color: "white", margin: "5px" }}
              >
                Follow Ups
              </MenuItem>
              <MenuItem
                to="/weekly-report"
                component={Link}
                className={isActive("/weekly-report")}
                style={{ color: "white", margin: "5px" }}
              >
                Weekly Report
              </MenuItem>
            </div>
            <div>
              {window.location.host.toLowerCase() ===
                "dev-cloud.xpeditemd.com" && (
                <MenuItem className="menu-link-test">TEST SERVER</MenuItem>
              )}
            </div>
            <div className="header-user" style={{ marginLeft: "80px" }}>
              {clients && clients.length > 1 ? (
                <Menu
                  mode="horizontal"
                  className="menu-link"
                  selectedKeys={[currentClient]}
                  style={{
                    color: "white",
                    borderWidth: 0,
                    padding: "0px 15px",
                    marginRight: 15,
                    borderRadius: 10,
                    lineHeight: 2.5,
                    fontSize: "1rem",
                  }}
                >
                  <SubMenu
                    className="client-sub-menu"
                    title={
                      <>
                        <span>
                          {currentClient
                            ? clients.find(
                                (client: any) => client._id == currentClient
                              ).name
                            : "Clients"}
                        </span>
                        <FontAwesomeIcon
                          key="SubMenu"
                          icon={faCaretDown}
                          style={{
                            fontSize: "15px",
                            color: "white",
                            marginLeft: "10px",
                          }}
                        />
                      </>
                    }
                    key="client-sub-menu"
                  >
                    {clients.map((client: any) => {
                      return (
                        <Menu.Item
                          onClick={() => setCurrentClient(client._id)}
                          key={client._id}
                        >
                          {client.name}
                        </Menu.Item>
                      );
                    })}
                  </SubMenu>
                </Menu>
              ) : null}
              <p style={{ color: "white", marginTop: "0px" }}>
                {profile?.fullName ? profile?.fullName : user?.name}
              </p>
              <Menu
                mode="horizontal"
                style={{ backgroundColor: "transparent" }}
              >
                <SubMenu
                  key="SubMenu"
                  icon={
                    <FontAwesomeIcon
                      key="SubMenu"
                      icon={faUser}
                      style={{ fontSize: "22px", color: "white" }}
                    />
                  }
                >
                  <Menu.Item key="log-out" onClick={clickLogout}>
                    Log out
                  </Menu.Item>
                </SubMenu>
              </Menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
