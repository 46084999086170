import { AuthAPI } from "../API/auth-API";
import { PatientAPI } from "../API/patient-API";

export function isNullOrEmpty(text: any) {
  if (text) {
    if (text.replace(/ /g, "").replace(/\n/g, "") === "") {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
}

export async function getUserName() {
  return await AuthAPI.me()
    .then(({ data }) => {
      const userName = data.user.fullName;
      return userName;
    })
    .catch((err: any) => {});
}

export async function getPatientId(currentClient: string) {
  return await PatientAPI.getNextPatientId(currentClient)
    .then((res) => {
      const patientId = res.data.patientId;
      return patientId;
    })
    .catch((err: any) => {});
}
