import React, { useState, useEffect, createContext } from "react";
import { message } from "antd";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { AuthAPI } from "../../API/auth-API";
import { UserClientAccessAPI } from "../../API/userClientAccess-API";

interface IUserContext {
  profile:
    | {
        _id: string;
        email: string;
        fullName: string;
        role: string;
      }
    | undefined;
  clients: any;
  isLoggedIn: boolean;
  [key: string]: any;
}

export const UserContext = createContext<IUserContext>({
  profile: {
    _id: "",
    email: "",
    fullName: "",
    role: "",
  },
  clients: [],
  currentClient: localStorage.getItem("currentClient"),
  isLoggedIn: false,
  submitLogin: () => null,
  setProfile: () => null,
  setClients: () => null,
  setCurrentClient: () => null,
  getCurrentClient: () => null,
});

const LoginProvider: React.FC<{ children: any }> = ({ children }) => {
  const history = useHistory();
  const { isAuthenticated, getAccessTokenSilently, loginWithRedirect, logout } =
    useAuth0();

  const [error, setError] = useState<string | null>(null);
  const [profile, setProfile] = useState({
    _id: "",
    email: "",
    fullName: "",
    role: "",
  });
  const [clients, setClients] = useState<Array<[]> | null>(null);
  const [currentClient, setCurrentClient] = useState("");

  useQuery(
    ["user-profile"],
    () => {
      return AuthAPI.me().then(({ data }) => data);
    },
    {
      onSuccess: (data) => {
        setProfile({
          _id: data.user._id,
          fullName: data.user?.fullName,
          email: data.user?.email,
          role: data.user?.role,
        });
      },
    }
  );

  useEffect(() => {
    if (!profile._id) {
      return;
    } else {
      UserClientAccessAPI.fetchClientIdsByUserId(profile._id).then((res) => {
        if (res.data.clients !== undefined) {
          setClients(res.data.clients);
        }
      });
    }
  }, [profile._id]);

  useEffect(() => {
    getCurrentClient();

    const token = localStorage.getItem("auth");
    if (!token) {
      getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE })
        .then((data) => {
          localStorage.setItem("auth", data);
          localStorage.setItem("isAuth", "true");
        })
        .catch((error) => loginWithRedirect());
    }

    const isAuth = Boolean(localStorage.getItem("isAuth"));

    if (token && !isAuth) {
      loginWithRedirect();
    }

    // If session has expired, logout the user & delete the necessary keys.
    const fetchTimeout = setTimeout(() => {
      if (!isAuthenticated) {
        logout();
        localStorage.removeItem("auth");
        localStorage.removeItem("currentClient");
        localStorage.setItem("isAuth", "false");
        history.push("/login");
      }
    }, 7000);

    return () => clearTimeout(fetchTimeout);
  }, [isAuthenticated]);

  function setActiveClient(client_id: any) {
    localStorage.setItem("currentClient", client_id);
    setCurrentClient(client_id);
    window.location.reload();
  }

  function getCurrentClient() {
    const currentClient = localStorage.getItem("currentClient");
    if (currentClient) {
      setCurrentClient(currentClient);
    }
    return currentClient;
  }

  return (
    <UserContext.Provider
      value={{
        error,
        profile: profile,
        setProfile: setProfile,
        clients: clients,
        isLoggedIn: isAuthenticated,
        currentClient: currentClient,
        setCurrentClient: setActiveClient,
        setClients: setClients,
        getCurrentClient: getCurrentClient,
        open: clients && clients?.length > 1 ? true : false,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default LoginProvider;
