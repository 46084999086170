import { useState } from "react"
import { SKIN } from "../../../constants/inputValues"

import { Form, TreeSelect } from 'antd';
import React from "react";
import { PanelContext } from "../../../context/panel-context/PanelContext";


function SkinSelect(props: any) {
    const [options, setOptions] = useState(SKIN);

    const onChange = (values: any) => {

        // if (values[values.length - 1] === '0-0') {
        //     setValuesSkin(["0-0"]);
        // } else {
        //     setValuesSkin(values.filter((item: any) => item !== "0-0"));
        // }

        // setFieldSelected({
        //     name: props.name,
        //     isTree: props.isTree,
        //     treeData: options,
        //     field: getFieldByName(props.name)
        // })
    };

    const tProps = {
        treeData: options,
        // value: valuesSkin,
        placeholder: 'Please select',
        style: {
            width: '100%',
        },
        // defaultValue: valuesSkin,
        // showCheckedStrategy: SHOW_ALL,
        multiple: true,
        onChange: onChange
    };

    return (
        <Form.Item
            name="involvement"
            className="input status"
            label="Evidence of skin involvement"
            rules={[
                {
                    required: false,
                    message: 'Please select a item',
                },
            ]}
            {...props}
        >
            <TreeSelect
                {...tProps}
                // treeData={options}
                // value={valuesSkin}
                // placeholder='Please select'
                // style={{
                //     width: '100%',
                // }}
                // // defaultValue: valuesSkin,
                // // showCheckedStrategy: SHOW_ALL,
                // multiple={true}
                // onChange={onChange}
            />
        </Form.Item>


    )
}

{/* <TreeSelects
name="involvement"
label="Evidence of skin involvement"
options={options}
initialValue={SKIN[0]}
rq={true}
multiple={true}
isTree={true}
/> */}

export default SkinSelect