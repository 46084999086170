import React, { useContext, useState } from "react";
import InputText from "../../components/forms/InputText";
import OtherInputText from "../../components/forms/OtherInputText";
import SelectCustom from "../../components/forms/SelectCustom";
import MultiSelect from "../../components/forms/MultiSelect";
import { PatientAPI } from "../../API/patient-API";
import SkinSelect from "../new-order/specs/SkinSelect";
import { DatePickerCustom, Age } from "../../components/forms/DatePickerCustom";

import {
  SEX,
  ICDSIGNS,
  INDICATIONS,
  MENOPAUSAL,
  TUMOR,
  LYMPH,
  METASTASISCLINICAL,
} from "../../constants/inputValues";

import { Button } from "antd";
import { CheckOutlined, RightOutlined } from "@ant-design/icons";
import { PanelContext } from "../../context/panel-context/PanelContext";
import { UserContext } from "../../context/login/Context";
import { useHistory, useParams } from "react-router-dom";
import { DIANOSTICIMAGING } from "../../constants/treatment/SelectQuestions";
import TreatmentQuiz from "./treatment/TreatmentQuiz";

interface IProps {}

function DisplayFormItem(props: {
  name: string;
  quiz: any;
  show: boolean;
  Component: any;
  query: string;
}) {
  const { show, Component, name, quiz, query } = props;

  return (
    <div
      style={{
        display: show ? "block" : "none",
      }}
    >
      <Component name={name} quiz={quiz} query={query} />
    </div>
  );
}

const FormSelectDiagnostic: React.FC<IProps> = (props) => {
  const { setIsDisable, isQuizFinish } = useContext(PanelContext);
  const { getCurrentClient } = useContext(UserContext);
  const history = useHistory();
  const query = new URLSearchParams(window.location.search);
  const params: any = useParams();
  const [patient, setPatient] = useState([{}] as any);
  let currentClient = getCurrentClient();
  React.useEffect(() => {
    PatientAPI.getPatient(params.id, currentClient).then((res) => {
      const resPatient = res.data.patient;
      setPatient({ ...resPatient });
      // setSearch(resPatient.patientId);
    });
  }, []);

  return (
    <>
      {query.get("item") !== "imaging" && (
        <div className="info">
          {window.location.pathname.includes("/order-panel/") && (
            <div>
              <div
                className="age-status name-age-sex-id"
                style={{ display: "none" }}
              >
                <InputText name={"id"} label="Patient ID" />
                <InputText name={["name", "first"]} label="First Name" />
                <InputText name={["name", "last"]} label="Last Name" />
              </div>
              <div
                className="age-status name-age-sex"
                style={{ display: "none" }}
              >
                <DatePickerCustom
                  name="dob"
                  label="Date of birth (M/D/YYY)"
                  // rq={true}
                />
                <Age name="age" Label="Age" />

                <SelectCustom
                  name="sex"
                  options={SEX}
                  initialValue="Female"
                  // rq={true}
                />
              </div>
            </div>
          )}
          <MultiSelect
            name="indications"
            label="Indication(s) for Diagnostic Imaging"
            options={INDICATIONS}
            // rq={true}
          />

          {/* <SelectCustom
            name="lymph"
            label="Lymph Node Status"
            options={LYMPH}
            initialValue="No evidence of lymph node involvement"
            rq={true}
          /> */}
          <div className="panel-detail">
            <div className="form-detail"></div>
            <div className="form-right">
              {/* <SkinSelect /> */}
              {/* <SelectCustom
                name="tumorSize"
                label="Tumor Size (clinical)"
                options={TUMOR}
                rq={false}
              /> */}
              {/* <SelectCustom
                name="metastasisClinical"
                label="Evidence of distant metastasis"
                options={METASTASISCLINICAL}
                rq={false}
              /> */}
            </div>
          </div>
          <OtherInputText name="other" label="Other Notes" rq={false} />
          <div className="pre-op-orders-inner">
            <Button
              className={
                isQuizFinish[`imaging`]
                  ? "new-order-btn middle done"
                  : "new-order-btn middle"
              }
              onClick={() => (
                setIsDisable(true), history.push(`diagnostic?item=imaging`)
              )}
            >
              <p>
                Diagnostic
                {isQuizFinish[`imaging`] ? (
                  <CheckOutlined className="dashboard-item-arrow green" />
                ) : (
                  <RightOutlined className="dashboard-item-arrow" />
                )}
              </p>
            </Button>
            {/* <Button
              className={
                isQuizFinish[`imaging`]
                  ? "new-order-btn middle done"
                  : "new-order-btn middle"
              }
              onClick={() => (
                setIsDisable(true), history.push(`diagnostic?item=imaging`)
              )}
            >
              <p>
                Diagnostic Imaging
                {isQuizFinish[`imaging`] ? (
                  <CheckOutlined className="dashboard-item-arrow green" />
                ) : (
                  <RightOutlined className="dashboard-item-arrow" />
                )}
              </p>
            </Button> */}
          </div>
        </div>
      )}
      <DisplayFormItem
        show={params.isTags === "diagnostic" && query.get("item") === "imaging"}
        name="imaging"
        quiz={DIANOSTICIMAGING}
        query={`item=${query.get("item")}`}
        Component={TreatmentQuiz}
      />
    </>
  );
};

export default FormSelectDiagnostic;
