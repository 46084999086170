import reqAxios from "./reqAxios";

const fetchOrderNotesByOrder = (_id: string, clientId: string) => {
  return reqAxios().get(`/orderNotes/order/${_id}?clientId=${clientId}`);
};

const newOrderNote = (data: any) => {
  return reqAxios().post("/orderNotes", data);
};

const deleteOrderNote = (_id: any) => {
  return reqAxios().delete(`/orderNotes/${_id}`);
};

export const OrderNoteAPI = {
  fetchOrderNotesByOrder,
  newOrderNote,
  deleteOrderNote,
};
