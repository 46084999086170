import { Box, Grid } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { UserContext } from "../../context/login/Context";

interface Props {
  handleClose(): void;
}

const ClientPopup: React.FC<Props> = ({ handleClose }) => {
  const { clients, setCurrentClient } = useContext(UserContext);


  return (
    <div className="column center">
      <h1 style={{ fontWeight: 600, marginBottom: "20px", color: "white" }}>
        Select A Client
      </h1>
      <Box sx={{ width: "90%" }}>
        <Grid
          container
          rowGap={3}
          columnSpacing={3}
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ margin: 0, width: "100%" }}
        >
          {clients?.map((client: any) => {
            return (
              <Grid
                key={client._id}
                className="client-menu-link"
                item
                sx={{ width: "100%" }}
                onClick={() => {
                  setCurrentClient(client._id);
                  handleClose();
                }}
              >
                <strong>Client Id:</strong> {client.name}
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </div>
  );
};

export default ClientPopup;
