import reqAxios from "./reqAxios";

const fetchAlertThresholds = (clientId: string) => {
  return reqAxios().get(`/alertThresholds?clientId=${clientId}`);
};

const fetchATByOrder = (_id: string, clientId: string) => {
  return reqAxios().get(`/alertThresholds/order/${_id}?clientId=${clientId}`);
};

const newAlertThreshold = (data: any) => {
  return reqAxios().post("/alertThresholds", data);
};

const putAlertThreshold = (data: any, _id: string) => {
  return reqAxios().put(`/alertThresholds/${_id}`, data);
};

export const AlertThresholdAPI = {
  newAlertThreshold,
  fetchAlertThresholds,
  fetchATByOrder,
  putAlertThreshold,
};
