import React, { useEffect, useState, useContext } from "react";
import LogTable from "./LogTable";
import { Box, Typography, Button, Modal } from "@mui/material";
import { getUserName } from "../../constants/utilities";
import { UserContext } from "../../context/login/Context";
import { useParams } from "react-router";
import moment from "moment";
import "moment-timezone";
import Title from "./Title";
import { Spin, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { AlertAPI } from "../../API/alert-API";
import { AlertLogAPI } from "../../API/alertLog-API";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "60%",
  maxHeight: "60%",
  minHeight: "40%",
  overflow: "scroll",
  backgroundColor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface Props {
  patient: any;
  client: string;
}

export default function Alerts({ patient, client }: Props) {
  const [alerts, setAlerts] = useState([]);
  const param: any = useParams();
  const [open, setOpen] = React.useState(false);
  const { profile, getCurrentClient } = useContext(UserContext);
  const [selectedAlert, setSelectedAlert] = useState([{}] as any);
  const [fullName, setFullName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

  getUserName().then((res: any) => setFullName(res));
  let currentClient = getCurrentClient();

  useEffect(() => {
    async function getData() {
      await AlertAPI.fetchAlertsByPatient(param.id, client).then((res) => {
        setAlerts(res.data.alerts);
      });
      setIsLoading(false);
    }
    getData();
  }, [currentClient]);

  const updateState = async () => {
    setIsLoading(true);
    await AlertAPI.fetchAlertsByPatient(param.id, client).then((res) => {
      setAlerts(res.data.alerts);
    });
    setIsLoading(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    updateState();
  };

  const sortedAlerts = alerts
    .sort((a: any, b: any) => {
      return a.createdAt - b.createdAt;
    })
    .reverse();

  async function assign(alert: any) {
    const body = {
      ...alert,
      userId: profile?._id,
      userFullName: fullName,
    };
    await AlertAPI.updateAlert(
      {
        data: body,
      },
      alert._id
    )
      .then(async (res) => {
        const body = {
          logText: `Alert assigned to ${fullName}`,
          clientId: currentClient,
          patientId: alert.patientId,
          eventType: "Assignment",
          alertId: alert._id,
        };
        await AlertLogAPI.newAlertLog({ data: body }).then((res) => {});
        await AlertAPI.fetchAlertsByPatient(param.id, currentClient).then(
          (res) => {
            setAlerts(res.data.alerts);
          }
        );
        message.success("Alert successfully assigned!");
      })
      .catch(() => {
        message.error("Assignment failed!");
      });
  }

  async function deAssign(alert: any) {
    const body = {
      ...alert,
      userId: null,
      userFullName: "",
    };
    await AlertAPI.updateAlert(
      {
        data: body,
      },
      alert._id
    )
      .then(async (res) => {
        const body = {
          logText: `Alert de-assigned by ${fullName}`,
          patientId: alert.patientId,
          clientId: currentClient,
          eventType: "Assignment",
          alertId: alert._id,
        };
        await AlertLogAPI.newAlertLog({ data: body }).then((res) => {});
        await AlertAPI.fetchAlertsByPatient(patient._id, currentClient).then(
          (res) => {
            setAlerts(res.data.alerts);
          }
        );
        message.success("Alert has been de-assigned!");
      })
      .catch(() => {
        message.error("De-assignment failed!");
      });
  }

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "30px",
          //   height: "100vh",
        }}
      >
        <div
          style={{
            textAlign: "left",
            width: "90%",
          }}
        >
          <div style={{ width: "100%" }}>
            <Title>
              <span style={{ color: "red", fontSize: "40px" }}>Alerts</span>
            </Title>
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "80vw",
                  height: "20vh",
                }}
              >
                <Spin
                  size="large"
                  className={`loading-spin-btn ${"active"}`}
                  indicator={antIcon}
                />
              </div>
            ) : sortedAlerts.length === 0 ? (
              <div
                style={{
                  textAlign: "center",
                  fontWeight: 500,
                  marginTop: "100px",
                }}
              >
                <Typography variant={"h5"}>
                  There are currently no alerts for this patient
                </Typography>
              </div>
            ) : (
              <div>
                {sortedAlerts.map((alert: any) => (
                  <Box
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      borderLeft:
                        alert.isActive === true
                          ? "4px solid red"
                          : "4px solid #7ed957",
                      textAlign: "left",
                      backgroundColor: "#ffffff",
                      marginBottom: "30px",
                      padding: "10px",
                      boxShadow: "-2px 1px 3px 0px #d2d2d2",
                    }}
                    key={alert._id}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        style={{ marginBottom: "20px" }}
                        variant="body1"
                      >
                        {alert.alertType}
                      </Typography>
                      {alert.userId && (
                        <Typography variant="caption">
                          <span style={{ fontWeight: "bold" }}>
                            Assigned To:
                          </span>{" "}
                          {alert.userFullName}
                        </Typography>
                      )}
                      <Typography variant="caption">
                        <span style={{ fontWeight: "bold" }}>
                          Date Created:
                        </span>{" "}
                        {moment(alert.createdAt)
                          .tz("America/Los_Angeles")
                          .format("MM/DD/YYYY, h:mma")}
                      </Typography>
                      {alert.isActive === false && (
                        <Typography variant="caption">
                          <span style={{ fontWeight: "bold" }}>
                            Resolve Date:
                          </span>{" "}
                          {moment(alert.alertResolvedDate)
                            .tz("America/Los_Angeles")
                            .format("MM/DD/YYYY, h:mma")}
                        </Typography>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                      }}
                    >
                      {!alert.userId && alert.isActive === true && (
                        <Button
                          style={{ backgroundColor: "#4276c5", color: "white" }}
                          onClick={() => assign(alert)}
                          variant="outlined"
                        >
                          Assign to me
                        </Button>
                      )}
                      {alert.userId && alert.isActive === true && (
                        <Button
                          style={{ backgroundColor: "#4276c5" }}
                          onClick={() => deAssign(alert)}
                          variant="contained"
                          color="error"
                        >
                          De-assign
                        </Button>
                      )}
                      {alert.userId === profile?._id &&
                        alert.isActive === true && (
                          <Button
                            style={{ backgroundColor: "#7ed957" }}
                            onClick={() => {
                              handleOpen();
                              setSelectedAlert(alert);
                            }}
                            variant="contained"
                          >
                            Resolve
                          </Button>
                        )}
                      {alert.isActive === false && (
                        <Button
                          key={alert._id}
                          style={{ backgroundColor: "#4276c5" }}
                          onClick={() => {
                            handleOpen();
                            setSelectedAlert(alert);
                          }}
                          variant="contained"
                        >
                          View
                        </Button>
                      )}
                    </div>
                  </Box>
                ))}
              </div>
            )}
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <LogTable alert={selectedAlert} close={handleClose} />
              </Box>
            </Modal>
          </div>
          {/* <Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 3 }}>
        See more orders
      </Link> */}
        </div>
      </div>
    </React.Fragment>
  );
}
