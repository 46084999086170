import reqAxios from "./reqAxios";

const newAlertLog = (data: any) => {
  return reqAxios().post("/alertLogs", data);
};

const fetchAlertLogsByAlertId = (_id: string, clientId: string) => {
  return reqAxios().get(`/alertLogs/alert/${_id}?clientId=${clientId}`);
};

export const AlertLogAPI = {
  newAlertLog,
  fetchAlertLogsByAlertId,
};
