import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { PatientAPI } from "../../API/patient-API";
import ToggleSwitch from "../../components/forms/ToggleSwitch";
import InputText from "../../components/forms/InputText";
import {
  Divider,
  TextField,
  FormControl,
  Tooltip,
  IconButton,
  InputLabel,
  Select,
  SelectChangeEvent,
  MenuItem,
  Table,
  TableRow,
  TableCell,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import { UserContext } from "../../context/login/Context";
import {
  faCheckCircle,
  faFolderOpen,
  faQuestionCircle,
} from "@fortawesome/free-regular-svg-icons";
import Title from "./Title";
import { Button, Form, Input, message } from "antd";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import { OrderAPI } from "../../API/order-API";
import moment from "moment";
import "moment-timezone";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import PatientLog from "./PatientLog";
import { PatientLogAPI } from "../../API/patientLog-API";
import { getUserName } from "../../constants/utilities";

function PatientProfile() {
  const param: any = useParams();
  const history = useHistory();
  const { profile, getCurrentClient } = useContext(UserContext);
  const [order, setOrder] = useState([{}] as any);
  const [patient, setPatient] = useState([{}] as any);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mRN, setMRN] = useState("");
  const [sex, setSex] = useState("");
  const [dOB, setDOB] = useState<Date | null>(null);
  const [patientStatus, setPatientStatus] = useState("");
  const [dayOne, setDayOne] = useState<Date | null>(null);
  const [muteAlerts, setMuteAlerts] = useState(false);
  const [active, setActive] = useState(true);
  const [fullName, setFullName] = useState("");
  getUserName().then((res: any) => setFullName(res));
  let currentClient = getCurrentClient();
  const initialState = {
    dayOne:
      patient.day1InAuthEx !== undefined && patient.day1InAuthEx !== null
        ? moment(patient.day1InAuthEx).format("MM/DD/YYYY")
        : null,
  };

  const onMuteAlertsChange = (checked: any) => {
    setMuteAlerts(checked);
  };

  useEffect(() => {
    async function getData() {
      await PatientAPI.getPatient(param.id, currentClient).then((res) => {
        const resPatient = res.data.patient;
        if (!resPatient.muteAlerts) {
          setMuteAlerts(false);
        } else {
          setMuteAlerts(resPatient.muteAlerts);
        }
        setPatient({ ...resPatient });
        setFirstName(resPatient.firstName);
        setLastName(resPatient.lastName);
        setDOB(resPatient.dob);
        setMRN(resPatient.mRN);
        setSex(resPatient.sex);
        setDayOne(resPatient.day1InAuthEx);
        setActive(resPatient.isActive);
        setPatientStatus(resPatient.patientStatus);
      });
    }
    getData();
  }, [param.id]);

  const handlePStatus = (e: any) => {
    setPatientStatus(e.target.value);
  };

  const handleActive = (e: any) => {
    if (e.target.value === "true") {
      setActive(true);
    } else {
      setActive(false);
    }
  };

  const handleSex = (event: SelectChangeEvent) => {
    setSex(event.target.value as string);
  };

  const submitPatientChange = async () => {
    PatientAPI.getPatients(currentClient).then(async (res) => {
      if (
        res.data.patients.length > 0 &&
        res.data.patients.find(
          (resPatient: any) =>
            resPatient.mRN === mRN && resPatient.patientId !== patient.patientId
        )
      ) {
        message.error(
          "The MRN you are trying to enter already exists for another patient"
        );
      } else {
        const body = {
          ...patient,
          dob: dOB,
          firstName: firstName,
          lastName: lastName,
          sex: sex,
          mRN: mRN,
          day1InAuthEx: dayOne,
          patientStatus: patientStatus,
          muteAlerts: muteAlerts,
          isActive: active,
        };
        await PatientAPI.editPatient(
          {
            data: body,
          },
          param.id
        )
          .then(async (res) => {
            if (
              initialState.dayOne !==
              (dayOne !== null ? moment(dayOne).format("MM/DD/YYYY") : null)
            ) {
              const body = {
                logType: "Day 1 in XpediteMD",
                patientId: res.data.patient.patientId,
                userId: profile?._id,
                clientId: currentClient,
                keyDate: null,
                // orderId: order._id,
                // orderNumber: order.orderNumber,
                author: fullName,
                comment: `Day 1 in XpediteMD updated to ${moment(dayOne)
                  .tz("America/Los_Angeles")
                  .format("MM/DD/YYYY h:mma")}.`,
              };
              await PatientLogAPI.newPatientLog({ data: body });
            }
            message.success("Patient updated successfully!");
            await PatientAPI.getPatient(param.id, currentClient).then((res) => {
              const resPatient = res.data.patient;
              setPatient({ ...resPatient });
              setFirstName(resPatient.firstName);
              setLastName(resPatient.lastName);
              setDOB(resPatient.dob);
              setMRN(resPatient.mRN);
              setSex(resPatient.sex);
              setDayOne(resPatient.day1InAuthEx);
              setActive(resPatient.isActive);
              setPatientStatus(resPatient.patientStatus);
            });
            window.location.reload();
          })
          .catch(() => {
            message.error("Update failed!");
          });
      }
    });
  };

  return (
    <div>
      <div
        style={{
          // marginTop: "90px",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "calc(100vw - 240px)",
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "30px",
          }}
        >
          <div
            style={{
              textAlign: "left",
              width: "90%",
              backgroundColor: "#ffffff",
              padding: "10px",
              boxShadow: "-2px 1px 3px 0px #d2d2d2",
            }}
          >
            <div
              style={{
                textAlign: "left",
                width: "100%",
              }}
            >
              <Title>
                <span style={{ fontSize: "28px" }}>Patient Profile</span>
              </Title>
              <Table style={{ width: "100%" }}>
                <TableRow
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <TableCell style={{ fontSize: "16px" }}>
                    <span style={{ fontSize: "16px" }}>
                      Patient created on{" "}
                    </span>
                    {moment(patient.createdAt)
                      .tz("America/Los_Angeles")
                      .format("MM/DD/YYYY, h:mma")}
                  </TableCell>

                  <div
                    style={{
                      marginRight: "60px",
                      alignItems: "center",
                    }}
                  >
                    <label htmlFor="muteAlerts">
                      <span
                        style={{
                          fontSize: "16px",
                          fontFamily: "Roboto, Helvetica, Arial , sans-serif",
                        }}
                      >
                        Mute alerts
                      </span>
                    </label>{" "}
                    <Tooltip
                      title="When selected this feature will cause alerts NOT to be generated for this patient"
                      arrow
                      placement="top"
                    >
                      <IconButton>
                        <FontAwesomeIcon
                          style={{
                            color: "#4276c5",
                            fontSize: "20px",
                            margin: "0px",
                          }}
                          icon={faQuestionCircle}
                        />
                      </IconButton>
                    </Tooltip>{" "}
                    <ToggleSwitch
                      id="muteAlerts"
                      checked={muteAlerts}
                      onChange={onMuteAlertsChange}
                      disabled={false}
                      optionLabels
                    />
                  </div>
                </TableRow>
              </Table>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "20px",
                    width: "100%",
                    paddingLeft: "10%",
                    paddingRight: "10%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ width: "60%" }}>
                    <h1 style={{ marginBottom: "30px" }}>
                      Patient ID: {patient.patientId}
                    </h1>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <div style={{ width: "300px", marginRight: "30px" }}>
                        <TextField
                          className="input age"
                          fullWidth
                          style={{ marginBottom: "30px" }}
                          value={firstName}
                          label="First Name"
                          onChange={(e) => {
                            setFirstName(e.target.value);
                          }}
                        />
                      </div>
                      <div style={{ width: "300px" }}>
                        <TextField
                          fullWidth
                          className="input age"
                          style={{
                            marginBottom: "30px",
                          }}
                          value={lastName}
                          label="Last Name"
                          onChange={(e) => {
                            setLastName(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div style={{ width: "300px" }}>
                      <TextField
                        fullWidth
                        className="input age"
                        style={{
                          marginBottom: "30px",
                        }}
                        value={mRN}
                        label="MRN"
                        onChange={(e) => {
                          setMRN(e.target.value);
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        width: "300px",
                      }}
                    >
                      <DatePicker
                        label="Date of Birth"
                        maxDate={new Date()}
                        value={dOB}
                        onChange={(newValue) => {
                          setDOB(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            className="ant-input"
                            style={{ marginBottom: "30px" }}
                            {...params}
                          />
                        )}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        marginBottom: "30px",
                      }}
                    >
                      <FormControl
                        style={{ width: "300px", marginRight: "30px" }}
                      >
                        <InputLabel id="demo-simple-select-label">
                          Gender
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={sex}
                          label="Gender"
                          onChange={handleSex}
                        >
                          <MenuItem value={"Male"}>Male</MenuItem>
                          <MenuItem value={"Female"}>Female</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div style={{ width: "300px", marginBottom: "30px" }}>
                      {/* <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Patient Status
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={patientStatus}
                          label="Patient Status"
                          onChange={handlePStatus}
                        >
                          <MenuItem value={"Dummy text 1"}>
                            Dummy text 1
                          </MenuItem>
                          <MenuItem value={"Dummy text 2"}>
                            Dummy text 2
                          </MenuItem>
                        </Select>
                      </FormControl> */}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div style={{ width: "300px", marginRight: "30px" }}>
                        <DatePicker
                          label="Day 1 Patient in AuthEx"
                          maxDate={new Date()}
                          value={dayOne}
                          onChange={(newValue) => {
                            setDayOne(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              className="ant-input"
                              style={{ marginBottom: "30px" }}
                              {...params}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {/* <FormControl
                        style={{ width: "300px", marginBottom: "40px" }}
                        component="fieldset"
                      >
                        <FormLabel component="legend">
                          <Typography variant={"h6"}>
                            Is This An Active Patient?
                          </Typography>
                        </FormLabel>
                        <RadioGroup
                          aria-label="gender"
                          name="controlled-radio-buttons-group"
                          value={active}
                          onChange={handleActive}
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl> */}
                    </div>
                  </div>
                </div>
              </LocalizationProvider>
              <Divider />
              <Form.Item className="accept-form">
                <div
                  className="action-form-btn-group"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "30px",
                  }}
                >
                  <Button
                    style={{
                      marginTop: "0px",
                      width: "15%",
                    }}
                    type="primary"
                    htmlType="submit"
                    onClick={submitPatientChange}
                    className="accept-form-button"
                  >
                    Save
                  </Button>
                </div>
              </Form.Item>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PatientProfile;
