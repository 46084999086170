export const REFERRAL_SURGERY = [
  {
    _id: 1,
    question: "Surgery",
    answers: [
      {
        content: "Name 1",
      },
      {
        content: "Name 2",
      },
      {
        content: "Name 3",
      },
      {
        content: "Name 4",
      },
      {
        content: "Name 5",
      },
    ],
    isMultiAnswers: true,
  },
];

export const REFERRAL_MEDONCOLOGY = [
  {
    _id: 1,
    question: "Medical Oncology",
    answers: [
      {
        content: "Name 1",
      },
      {
        content: "Name 2",
      },
      {
        content: "Name 3",
      },
      {
        content: "Name 4",
      },
      {
        content: "Name 5",
      },
    ],
    isMultiAnswers: true,
  },
];

export const REFERRAL_RADONCOLOGY = [
  {
    _id: 1,
    question: "Radiation Oncology",
    answers: [
      {
        content: "Name 1",
      },
      {
        content: "Name 2",
      },
      {
        content: "Name 3",
      },
      {
        content: "Name 4",
      },
      {
        content: "Name 5",
      },
    ],
    isMultiAnswers: true,
  },
];

export const REFERRAL_PATIENT = [
  {
    _id: 1,
    question: "Patient Navigation",
    answers: [
      {
        content: "Name 1",
      },
      {
        content: "Name 2",
      },
      {
        content: "Name 3",
      },
      {
        content: "Name 4",
      },
      {
        content: "Name 5",
      },
    ],
    isMultiAnswers: true,
  },
];

export const REFERRAL_THERAPY = [
  {
    _id: 1,
    question: "Physical /Occupational therapy",
    answers: [
      {
        content: "Name 1",
      },
      {
        content: "Name 2",
      },
      {
        content: "Name 3",
      },
      {
        content: "Name 4",
      },
      {
        content: "Name 5",
      },
    ],
    isMultiAnswers: true,
  },
];

export const REFERRAL_SOCIAL = [
  {
    _id: 1,
    question: "Social Work",
    answers: [
      {
        content: "Name 1",
      },
      {
        content: "Name 2",
      },
      {
        content: "Name 3",
      },
      {
        content: "Name 4",
      },
      {
        content: "Name 5",
      },
    ],
    isMultiAnswers: true,
  },
];

export const REFERRAL_NUTRITION = [
  {
    _id: 1,
    question: "Nutrition",
    answers: [
      {
        content: "Name 1",
      },
      {
        content: "Name 2",
      },
      {
        content: "Name 3",
      },
      {
        content: "Name 4",
      },
      {
        content: "Name 5",
      },
    ],
    isMultiAnswers: true,
  },
];

export const REFERRAL_GENETIC = [
  {
    _id: 1,
    question: "Genetic counseling",
    answers: [
      {
        content: "Name 1",
      },
      {
        content: "Name 2",
      },
      {
        content: "Name 3",
      },
      {
        content: "Name 4",
      },
      {
        content: "Name 5",
      },
    ],
    isMultiAnswers: true,
  },
];

export const RADIATION_ONCOLOGY = [
  {
    _id: 1,
    question: "What type of surgery was performed or is anticipated?",
    instruction: "Choose one of the following options",
    answers: [
      {
        content: "Lumpectomy or Partial Mastectomy",
        nextQuestionId: [2],
      },
      {
        content: "Mastectomy",
        nextQuestionId: [3],
      },
    ],
  },
  {
    _id: 2,
    question: "Is radiation therapy planned?",
    instruction: "Choose one of the following options:",
    answers: [
      {
        content: "No",
        nextQuestionId: [5],
      },
      {
        content: "Yes",
        nextQuestionId: [6],
      },
    ],
    preQuestionId: [1],
  },
  {
    _id: 3,
    question: "Is post-mastectomy radiation planned?",
    instruction: "Choose one of the following options:",
    answers: [
      {
        content: "No",
        nextQuestionId: [7],
      },
      {
        content: "Yes",
        nextQuestionId: [4],
      },
    ],
    preQuestionId: [1],
  },
  {
    _id: 4,
    question: "What type of post-mastectomy radiation is planned?",
    instruction: "Choose one of the following options:",
    answers: [
      {
        content:
          "3DCRT Conformal radiotherapy to chest wall +/- regional nodes",
        instruction: "Any of the following criteria must be met:",
        cpt: "77280 X 2, 77263, 77290 X 2, 77295 X 2 , 77300X 3-5,  77334 X 4-8, 77336 X 7, 77402 X 28, 77387, 77427 X 7",
        criteria: ["Positive nodes", "Primary tumor > 5 cm", "Margins < 1 mm"],
        // isMultiAnswers: true,
        isCriteria: true,
      },
      {
        content:
          "3DCRT Conformal radiotherapy to chest wall +/- regional nodes (up to 28 fractions) with Electronic boost up to 8 fractions",
        instruction: "Any of the following criteria must be met:",
        cpt: "77280 X 2, 77263, 77290 X 2, 77295 X 2 , 77300X 3-5,  77334 X 4-8, 77336 X 7, 77402 X 28, 77387, 77427 X 7",
        criteria: [
          "Positive nodes",
          "Primary tumor > 5 cm",
          "Margins < 1 mm",
          "age </=50",
          "age 51-70 with high tumor grade",
        ],
        // isMultiAnswers: true,
        isCriteria: true,
      },
      {
        content:
          "IMRT Intensity-Modulated Radiation Therapy (up to 28 fractions) with 3DCRT Boost up to 8 fractions",
        instruction: "Must meet any of the following criteria:",
        cpt: "77280 X 2, 77263, 77290 X 2, 77295 X 2 , 77300X 3-5,  77334 X 4-8, 77336 X 7, 77402 X 28, 77387, 77427 X 77402 X 8",
        criteria: [
          "Positive nodes",
          "Primary tumor > 5 cm",
          "Margins < 1 mm",
          "left sided cancer with risk of excessive cardiac exposure",
          "intent to give IMN radiation due to pathologically enlarged or histologically positive IMN or high risk of IMN involvement",
          "4 or more positive nodes",
          "medial quadrant tumors with at least one positive axillary nodes",
          "medial quadrant T3 tumor",

          "Risk of Hot spots",
        ],
        isMultiAnswers: true,
        isCriteria: true,
      },
      {
        content:
          " IMRT Intensity-Modulated Radiation Therapy (up to 28 fractions)",
        instruction: "Must meet any of the following criteria:",
        cpt: "77280 X 2, 77263, 77290 X 2, 77301,  77338, 77300 X 3-5, 77334 X 4-8, 77336 X 7, 77385 X 28, 77427 X 7, 77387 X 36",
        criteria: [
          "Positive nodes",
          "Primary tumor > 5 cm",
          "Margins < 1 mm",
          "left sided cancer with risk of excessive cardiac exposure",
          "intent to give IMN radiation due to pathologically enlarged or histologically positive IMN or high risk of IMN involvement",
          "4 or more positive nodes",
          "BMI > 30",
          "concurrent chemo",
          "medial quadrant tumors with at least one positive axillary nodes",
          "medial quadrant T3 tumor",
          "Risk of Hot spots",
        ],
        isMultiAnswers: true,
        isCriteria: true,
      },
    ],
    preQuestionId: [3],
  },
  {
    _id: 5,
    question: "Why is radiation not planned?",
    instruction: "Choose one of the following options:",
    answers: [
      {
        content: "CALGB 9343 Protocol",
        instruction: "Must meet all of the following criteria:",
        criteria: [
          "age >/= 70",
          "Hormone receptor positive",
          "Plans to take Endocrine therapy",
          "margins >/= 2 mm",
        ],
        isCriteria: true,
        isMultiAnswers: true,
      },
      {
        content: "Patient Refuses Radiation",
        isMultiAnswers: true,
      },
      {
        content: "Minimal clinical benefit",
        isMultiAnswers: true,
        isOtherText: true,
      },
    ],
    preQuestionId: [2],
  },
  {
    _id: 6,
    question: "What type of radiation is planned?",
    instruction: "Choose one of the following options:",
    answers: [
      {
        content: "Partial Breast Radiation is Planned",
        nextQuestionId: [8],
      },
      {
        content: "Whole Breast Radiation Is Planned",
        nextQuestionId: [9],
      },
    ],
    preQuestionId: [2],
  },
  {
    _id: 7,
    question: "Whyis post-mastectomy radiotherapy not planned?",
    instruction: "Choose one of the following options:",
    answers: [
      {
        content: "Not indicated",
      },
      {
        content: "Indicated, but patient refused",
      },
    ],
    preQuestionId: [3],
  },
  {
    _id: 8,
    question: "Which type of partial breast radiation is planned?",
    instruction: "Choose one of the following options:",
    answers: [
      {
        instruction: "All of the following criteria must be met:",
        content:
          "3DCRT Conformal Radiation Therapy (up to 10 fractions twice daily)",
        cpt: "77280 X 2, 77263, 77290 X 2, 77295 X 2, 77300 X 3-5, 77334 X 4-8, 77336 X 2, 77402 X 10, 77387, 77427 X 4",
        criteria: [
          "age 45 or older",
          "unifocal cancer",
          "treatable with BCS",
          "Tumor size <3 cm",
          "negative margins anticipated or confirmed",
          "pN0",
          "no EIC",
          "no LVI",
        ],
        isCriteria: true,
      },
      {
        instruction: "All of the following criteria must be met:",
        content: "3DCRT Conformal Radiation Therapy (15 or 16 fractions daily)",
        cpt: "77280 X 2, 77263, 77290 X 2, 77295 X 2, 77300 X 3-5, 77334 X 4-8, 77336 X 4, 77402 X 16, 77387, 77427 X 4",
        criteria: [
          "age 45 or older",
          "unifocal cancer",
          "treatable with BCS",
          "Tumor size <3 cm",
          "negative margins anticipated or confirmed",
          "pN0",
          "no EIC",
          "no LVI",
        ],
        isCriteria: true,
      },
      {
        instruction: "All of the following criteria must be met:",
        content:
          "IMRT Intensity-Modulated Radiation Therapy (up to 10 fractions twice daily)",
        cpt: "77280 X 2, 77263, 77290 X 2, 77301 X 1, 77338, 77300 X 3-5, 77334 X 4-8, 77336 X 2, 77385 X 10, 77427 X 2, 77387 X 10",
        criteria: [
          "age 45 or older",
          "unifocal cancer",
          "treatable with BCS",
          "Tumor size <3 cm",
          "negative margins anticipated or confirmed",
          "pN0",
          "no EIC",
          "no LVI",
        ],
        isCriteria: true,
      },
      {
        instruction: "All of the following criteria must be met:",
        content:
          "HDR High Dose Rate brachytherapy (up to 10 fractions twice daily)",
        cpt: "19296, 77263, 77280, 77290, 77318, 77336, 77370,  77771, C1717, Q3001",
        criteria: [
          "age 45 or older",
          "unifocal cancer",
          "treatable with BCS",
          "Tumor size <3 cm",
          "negative margins anticipated or confirmed",
          "pN0",
          "no EIC",
          "no LVI",
        ],
        isCriteria: true,
      },
      {
        content: "Intraoperative Radiotherapy",
        instruction: "Select one of the following options:",
        childInstruction: "Must meet all of the following criteria",
        iCriteria: [
          "age 50 or older",
          "Tumor <3 cm",
          "grossly negative margins",
          "cN0",
        ],
        isCriteria: true,
        childrenAnswer: [
          {
            content: "ELIOT",
            cpt: "19294, 77263, 77280, 77290, 77300, 77332, 77334, 77370, 77425, 77469",
          },
          {
            content: "TARGIT",
            cpt: "19294, 77263, 77280, 77290, 77300, 77332, 77334, 77370, 77425, 77469",
          },
          {
            content: "Electronic Brachytherapy",
            cpt: "19297, 77263, 77280, 77290, 77300, 77332, 77334, 77370, 77424, 77469",
          },
        ],
        isMultiAnswers: false,
      },
      {
        instruction: "All of the following criteria must be met:",
        content: "Accuboost (standard prior authorization required)",
        isPA: true,
        cpt: "77470, 77771, 77387, G60002, 77417, C1717, Q3001",
        criteria: [
          "age 45 or older",
          "unifocal cancer",
          "treatable with BCS",
          "Tumor size <3 cm",
          "negative margins anticipated or confirmed",
          "pN0",
          "no EIC",
          "no LVI",
        ],
        isCriteria: true,
      },
    ],
    preQuestionId: [6],
  },
  {
    _id: 9,
    question: "Which type of radiation is planned?",
    instruction: "Choose one of the following options:",
    answers: [
      {
        content: "Hypofractionated Radiation is Planned",
        nextQuestionId: [10],
      },
      {
        content:
          "Standard Fractionation is planned (Patient is ineligible for Hypofractionation)",
        nextQuestionId: [11],
      },
    ],
    preQuestionId: [6],
  },
  {
    _id: 10,
    question: "Which type of hypofractionation is planned?",
    instruction: "Choose one of the following options:",
    answers: [
      {
        content: "3DCRT Conformal Radiotherapy - up to 16 fractions",
        cpt: "77280 X 2, 77263, 77290 X 2, 77301,  77338, 77300 X 3-5, 77334 X 4-8, 77336 X 4, 77385 X 16, 77427 X 4, 77387 X 21",
      },
      {
        content:
          "3DCRT Conformal Radiotherapy - up to 16 fractions with 3DCRT Tumor Bed Boost up to 8 fractions",
        cpt: "77280 X 2, 77263, 77290 X 2, 77295 X 2, 77300 X 3-5, 77334 X 4-8, 77336 X 4, 77402 X 21, 77387, 77427 X 4, 77402 X 5",
        instruction: "Any of the following criteria must be met:",
        criteria: ["age <50", "age 51-70 with high grade", "+ margin"],
        isCriteria: true,
      },
      {
        content:
          "IMRT-Intensity-Modulated Radiation Therapy - up to 16 fractions",
        cpt: "77280 X 2, 77263, 77290 X 2, 77301,  77338, 77300 X 3-5, 77334 X 4-8, 77336 X 4, 77385 X 16, 77427 X 4, 77387 X 21, 77402 X 5",
        instruction: "Any of the following criteria must be met:",
        criteria: [
          "left sided cancer with risk of excessive cardiac exposure",
          "intent to give IMN radiation due to pathologically enlarged or histologically positive IMN or high risk of IMN involvement",
          "4 or more positive nodes",
          "medial quadrant tumors with at least one positive axillary nodes,",
          "medial quadrant T3 tumor",
          "Risk of Hot spots",
        ],
        isCriteria: true,
      },
      {
        content:
          "IMRT-Intensity-Modulated Radiation Therapy - up to 16 fractions with 3DCRT Tumor Bed Boost up to 8 fractions",
        cpt: "77280 X 2, 77263, 77290 X 2, 77301,  77338, 77300 X 3-5, 77334 X 4-8, 77336 X 4, 77385 X 16, 77427 X 4, 77387 X 21, 77402 X 5",
        instruction: "Must meet all of the following criteria:",
        criteria: [
          "left sided cancer with risk of excessive cardiac exposure",
          "intent to give IMN radiation due to pathologically enlarged or histologically positive IMN or high risk of IMN involvement",
          "4 or more positive nodes",
          "medial quadrant tumors with at least one positive axillary nodes,",
          "medial quadrant T3 tumor",
          "Risk of Hot spots",
        ],
        isCriteria: true,
      },
    ],
    preQuestionId: [9],
  },
  {
    _id: 11,
    question: "Which type of standard fractionation is planned?",
    instruction: "Choose one of the following options:",
    answers: [
      {
        content: "3DCRT Whole External Beam Radiation (17-28 fractions)",
        instruction: "Any of the following criteria must be met:",
        criteria: [
          "lymph node involvement requiring treatment of the supraclavicular or internal mammary nodes regions",
          "concurrency chemotherapy excluding trastuzumab and endocrine therapy)",
          "age </= 50",
          "age 51-70 with high grade",
          "+ margin",
        ],
        isCriteria: true,
        cpt: "77280 X 2, 77263, 77290 X 2, 77295 X 2 , 77300X 3-5,  77334 X 4-8, 77336 X 7, 77402 X 36, 77387, 77427 X 7",
      },
      {
        content:
          "3DCRT Whole External Beam Radiation (17-28 Fractions) with 3DCRT Tumor Bed Boost up to 8 fractions",
        instruction: "Any of the following criteria must be met:",
        criteria: [
          "lymph node involvement requiring treatment of the supraclavicular or internal mammary nodes regions",
          "concurrency chemotherapy excluding trastuzumab and endocrine therapy",
          "age </= 50",
          "age 51-70 with high grade",
          "+ margin",
        ],
        isCriteria: true,
        cpt: "77280 X 2, 77263, 77290 X 2, 77295 X 2 , 77300X 3-5,  77334 X 4-8, 77336 X 7, 77402 X 28, 77387, 77427 X 7,  77402 X 8",
      },
      {
        content:
          "IMRT Intensity-Modulated Radiation Therapy (up to 28 fractions)",
        instruction: "Any of the following criteria must be met:",
        cpt: "77280 X 2, 77263, 77290 X 2, 77301, 77338, 77300 X 3-5, 77334 X 4-8, 77336 X 7, 77385 X 28, 77427 X 7, 77387 X 36",
        criteria: [
          "left sided cancer with risk of excessive cardiac exposure",
          "intent to give IMN radiation due to pathologically enlarged or histologically positive IMN or high risk of IMN involvement",
          "4 or more positive nodes",
          "medial quadrant tumors with at least one positive axillary nodes,",
          "medial quadrant T3 tumor",
          "Risk of Hot spots",
        ],
        isCriteria: true,
      },
      {
        content:
          "IMRT Intensity-Modulated Radiation Therapy (up to 28 fractions) with 3DCRT Boost up to 8 fractions",
        instruction: "Any of the following criteria must be met:",
        cpt: "77280 X 2, 77263, 77290 X 2, 77301, 77338, 77300 X 3-5, 77334 X 4-8, 77336 X 7, 77385 X 28, 77427 X 7, 77402 X 8, 77387 X 36",
        criteria: [
          "left sided cancer with risk of excessive cardiac exposure",
          "intent to give IMN radiation due to pathologically enlarged or histologically positive IMN or high risk of IMN involvement",
          "4 or more positive nodes",
          "medial quadrant tumors with at least one positive axillary nodes,",
          "medial quadrant T3 tumor",
          "Risk of Hot spots",
        ],
        isCriteria: true,
      },
    ],
    preQuestionId: [9],
  },
];

export const MEDICAL_ONCOLOGY = [
  {
    _id: 1,
    question: "What is the pathological stage or hightest clinical stage?",
    instruction: "Select only one of the following options:",
    answers: [
      {
        content: "Stage 0 (Ductal carcinoma in situ)",
        nextQuestionId: [2],
      },
      {
        content: "Stage I-III",
        nextQuestionId: [3],
      },
      {
        content: "Stage IV",
        nextQuestionId: [4],
      },
    ],
    isMultiAnswers: false,
  },
  {
    _id: 2,
    question:
      "Select one or more of the following based on clinical indication(s)",
    instruction: "Multiple selections are permitted",
    answers: [
      {
        instruction: "Select only one of the following options:",
        content: "Endocrine therapy",
        childrenAnswer: [{ content: "Tamoxifen" }, { content: "Anastrozole" }],
        isMultiAnswers: false,
      },
      {
        instruction: "Select only one of the following options:",
        content: "Genomic testing",
        childrenAnswer: [
          { content: "OncotypeDx DCIS" },
          { content: "DCISionRT" },
        ],
        isMultiAnswers: false,
      },
    ],
    isMultiAnswers: true,
    preQuestionId: [1],
  },
  {
    _id: 3,
    question: "Select one of the following biomarker profiles",
    answers: [
      {
        content: "HER2/neu-Positive, Hormone Receptor-Negative",
        nextQuestionId: [5],
      },
      {
        content:
          "HER2/neu-Positive, Hormone Receptor-Positive (Triple Positive)",
        nextQuestionId: [6],
      },
      {
        content: "HER2/neu-Negative, Hormone Receptor-Positive",
        nextQuestionId: [7],
      },
      {
        content: "Triple negative",
        nextQuestionId: [8],
      },
    ],
    preQuestionId: [1],
  },
  {
    _id: 4,
    question: "Select one of the following biomarker profiles",
    answers: [
      {
        content: "HER2/neu-Positive, Hormone Receptor-Negative",
        nextQuestionId: [9],
      },
      {
        content:
          "HER2/neu-Positive, Hormone Receptor-Positive (Triple Positive)",
        nextQuestionId: [10],
      },
      {
        content: "HER2/neu-Negative, Hormone Receptor-Positive",
        nextQuestionId: [11],
      },
      {
        content: "Triple negative",
        nextQuestionId: [12],
      },
    ],
    preQuestionId: [1],
  },
  {
    _id: 5,
    question: "HER2/NEU positive, hormone receptor negative",
    instruction: "Select tumor extent",
    answers: [
      {
        content: "TUMOR > 5 mm, N1-N3, or N4",
        nextQuestionId: [29],
        instruction: "Select one of the following treatment regimens:",
        childrenAnswer: [
          {
            content:
              "Paclitaxel X 12 + trastuzumab (TH) (preferred for Stage 1)",
          },
          {
            content:
              "Taxotere or Taxol + carboplatin +  Herceptin (TCH) (preferred for Stage 1)",
          },
          {
            content:
              "Taxotere + carpoplatin +  trastuzumab + pertuzumab (TCHP) (preferred for Stage 2A-3C)",
          },
          {
            content:
              "Dose Dense Adriamycin + Cyclophamide - taxotere + trastuzumab + pertuzumab (AC-THP) ",
            isPA: true,
          },
          {
            content:
              "Dose Dense Adriamycin + Cyclophosphamide - weekly Paclitaxel X 12 + trastuzumab +  pertuzumab",
            isPA: true,
          },
          {
            content: "Other (specify): ",
            isPA: true,
            isOtherText: true,
          },
        ],
      },
      {
        content: "TUMOR </= 5 mm",
        instruction: "Select one of the following treatment regimens:",
        childrenAnswer: [
          {
            content: "None",
          },
          {
            content: "Taxotere + trastuzumab (TH) ",
            isPA: true,
          },
          {
            content: "Other (Specify): ",
            isPA: true,
            isOtherText: true,
          },
        ],
      },
    ],
    preQuestionId: [3],
  },
  {
    _id: 29,
    question: "Select one of the following treatment regimens:",
    answers: [
      {
        content: "Paclitaxel X 12 + trastuzumab (TH) (preferred for Stage 1)",
      },
      {
        content:
          "Taxotere or Taxol + carboplatin +  Herceptin (TCH) (preferred for Stage 1)",
      },
      {
        content: "Taxotere + carpoplatin +  trastuzumab + pertuzumab (TCHP)",
        criteria: ["neoadjuvant", "Stage 2A-3C"],
        isCriteria: true,
      },
      {
        content:
          "Dose Dense Adriamycin + Cyclophamide - taxotere + trastuzumab + pertuzumab (AC-THP)",
      },
      {
        content:
          "Dose Dense Adriamycin + Cyclophosphamide - weekly Paclitaxel X 12 + trastuzumab +  pertuzumab.",
      },
      {
        content: "Other (Specify):",
        isOtherText: true,
      },
    ],
    preQuestionId: [5],
  },
  {
    _id: 30,
    question: "Select one of the following chemotherapy regimens:",
    answers: [
      {
        content: "Paclitaxel  X 12 + Trastuzumab",
      },
      {
        content:
          "Taxotere or Taxol + carboplatin + Herceptin (TCH) (preferred for Stage 1)",
      },
      {
        content: "Adriamycin + Cyclophosphamide, taxol + trastuzumab (ACT+H)",
      },
      {
        content: "Taxotere + carpoplatin +  trastuzumab + pertuzumab",
        criteria: ["neoadjuvant", "Stage 2A-3C"],
        isCriteria: true,
      },
      {
        content: "Other (Specify): ",
        isOtherText: true,
      },
    ],
  },
  {
    _id: 6,
    question: "Select tumor extent:",
    answers: [
      {
        content: "TUMOR > 5 mm, N1-N3, or T4",
        nextQuestionId: [13],
      },
      {
        content: "TUMOR </= 5 mm",
        nextQuestionId: [14],
      },
    ],
    preQuestionId: [3],
  },
  {
    _id: 7,
    question: "What is the status of genomic testing?",
    answers: [
      {
        content: "To be ordered",
        instruction: "Select one of the following genomic assays",
        childrenAnswer: [
          {
            content: "Oncotype DX",
          },
          {
            content: "Prosigna",
          },
          {
            content: "MammaPrint",
          },
          {
            content: "Other (Specify): ",
            isPA: true,
            isOtherText: true,
          },
        ],
      },

      {
        content: "Ordered, results are pending",
      },
      {
        content: "Not Planned",
        nextQuestionId: [15, 16],
      },
      {
        content: "Completed",
        nextQuestionId: [17],
      },
      // {
      //   content: "TRIPLE NEGATIVE",
      //   nextQuestionId: [35],
      // },
    ],
    preQuestionId: [3],
  },
  {
    _id: 35,
    question: "Select tumor extent:",
    answers: [
      {
        content: "TUMOR > 10 mm, N1-N3, or T4",
        nextQuestionId: [36],
      },
      {
        content: "TUMOR </= 10 mm",
        nextQuestionId: [37],
      },
    ],
    preQuestionId: [7],
  },
  {
    _id: 36,
    question: "TUMOR > 10 mm, N1-N3, or T4",
    answers: [
      {
        content: "Chemotherapy regimens",
        instruction:
          "Select the appropriate chemotherapy regimens based on clinical indications",
        childrenAnswer: [
          {
            content: "None",
          },
          {
            content: "Dose Dense Adriamycin + Cyclophosphamide + Taxol ",
          },
          {
            content: "Taxotere + Cyclophosphamide (TC X 6)",
          },
          {
            content: "Other (Specify):",
            isOtherText: true,
          },
        ],
      },
      {
        content: "BRCA-positive.",
        instruction: "Select one of the following chemotherapy regimens:",
        childrenAnswer: [
          {
            content: "Add Olaparib",
          },
          {
            content: "Add Talazoparib",
          },
          {
            content: "Add Carboplatin/gemcitabine",
          },
          {
            content: "Add Cisplatin",
          },
        ],
      },
    ],
    isMultiAnswers: true,
    preQuestionId: [35],
  },
  {
    _id: 37,
    question: "TUMOR </= 10 mm",
    answers: [
      {
        content: "None",
      },
      {
        content: "Dose Dense Adriamycin + Cyclophosphamide + Taxol",
      },

      {
        content: "Taxotere + Cyclophosphamide (TC X 6)",
      },
      {
        content: "Other (Specify):",
        isOtherText: true,
      },
    ],
    preQuestionId: [35],
  },
  {
    _id: 8,
    question: "Select tumor extent",
    answers: [
      {
        content: "TUMOR > 10 mm, N1-N3, or T4",
        nextQuestionId: [31],
      },
      {
        content: "TUMOR </= 10 mm",
        nextQuestionId: [32],
      },
    ],
    preQuestionId: [3],
  },
  {
    _id: 31,
    question:
      "Select the appropriate chemotherapy regimens based on clinical indications",
    instruction: "Multiple selections permitted:",
    answers: [
      {
        content: "Chemotherapy regimens:",
        instruction:
          "Select the appropriate chemotherapy regimen based on clinical indications",
        childrenAnswer: [
          {
            content: "Dose Dense Adriamycin + Cyclophosphamide + Taxol ",
          },
          {
            content: "Dose Dense Adriamycin + Cyclophosphamide + carboplatin",
          },
          {
            content:
              "Dose Dense Adriamycin + Cyclophosphamide + paclitaxel X 12",
          },
          {
            content: "Taxotere + Cyclophosphamide (TC X 6)",
          },
          {
            content: "Other (Specify): ",
            isPA: true,
            isOtherText: true,
          },
        ],
      },
      {
        content: "BRCA-mutation positive",
        instruction:
          "Select the appropriate chemotherapy regimen based on clinical indications",
        childrenAnswer: [
          {
            content: "Add Olaparib",
          },
          {
            content: "Add Talazoparib",
          },
          {
            content: "Add Carboplatin/gemcitabine",
          },
          {
            content: "Add Cisplatin",
          },
        ],
      },
      // {
      //   content: "Gene mutations status",
      //   nextQuestionId: [34],
      // },
    ],
    isMultiAnswers: true,
    preQuestionId: [8],
  },
  {
    _id: 32,
    question:
      "Select the appropriate chemotherapy regimen based on clinical indications",
    answers: [
      {
        content: "None",
      },
      {
        content: "Dose Dense Adriamycin + Cyclophosphamide + Taxol",
      },
      {
        content: "Taxotere + Cyclophosphamide (TC X 6)",
      },
      {
        content: "Other (Specify): ",
        isPA: true,
        isOtherText: true,
      },
    ],
    preQuestionId: [8],
  },
  {
    _id: 33,
    question: "",
    answers: [
      {
        content: "Gene mutations status",
        nextQuestionId: [34],
      },
    ],
    isMultiAnswers: true,
    preQuestionId: [8],
  },
  {
    _id: 34,
    question: "",
    answers: [
      {
        content: "BRCA-mutation positive",
        instruction:
          "Select the appropriate chemotherapy regimen based on clinical indications",
        childrenAnswer: [
          {
            content: "Add Olaparib",
          },
          {
            content: "Add Talazoparib",
          },
          {
            content: "Add Carboplatin/gemcitabine",
          },
          {
            content: "Add Cisplatin",
          },
        ],
      },
      {
        content: "BRCA-mutation negative",
        childrenAnswer: [
          {
            content: "None",
          },
        ],
      },
    ],
    preQuestionId: [32, 33],
  },
  {
    _id: 9,
    question: "Select one of the following regimens:",
    answers: [
      {
        content:
          "Pertuzumab + trastuzumab + paclitaxel followed by trastuzumab + pertuzumab indefinitely",
      },
      {
        content:
          "Pertuzumab + trastuzumab + docetaxel followed by trastuzumab + pertuzumab indefinitely",
      },
      {
        content: "Other (Specify): ",
        isPA: true,
        isOtherText: true,
      },
    ],
    preQuestionId: [4],
  },
  {
    _id: 10,
    question:
      "Select a chemotherapy/anti-HER2/neu regimen, endocrine therapy regimen, and bisphospohonate therapy regimens based on clinical indications:",
    answers: [
      {
        content: "Chemotherapy/anti-HER2/neu regimen",
        instruction:
          "Select one of the following chemotherapy/anti-Her2/neu regimens:",
        childrenAnswer: [
          {
            content: "Pertuzumab + trastuzumab + paclitaxel",
          },
          {
            content: "Pertuzumab + trastuzumab + docetaxel",
          },
          {
            content: "trastuzumab",
          },
          {
            content: "Other (Specify): ",
            isPA: true,
            isOtherText: true,
          },
        ],
      },
      {
        content: "Endocrine therapy regimen based on menopausal status:",
        nextQuestionId: [18],
      },
      {
        content: "Bisphosphonate inhibitor",
        instruction:
          "Select one of the following bisphosphonates based on clinical indications:",
        childrenAnswer: [
          {
            content: "Bisphosphonates (Zoledronic acid)",
          },
          {
            content: "RANK inhibitor (Denosumab)",
          },
        ],
      },
    ],
    preQuestionId: [4],
    isMultiAnswers: true,
  },
  {
    _id: 11,
    question:
      "Select an endocrine therapy regimen, chemotherapy regimen, and bisphospohonate therapy regimen",
    answers: [
      {
        content: "Endocrine therapy regimen based on menopausal status:",
        nextQuestionId: [19],
      },
      {
        content: "Chemotherapy",
        instruction: "Select one of the following chemotherapy regimens:",
        childrenAnswer: [
          // {
          //   content: "Fulvestrant",
          // },
          {
            content: "Capecitabine",
          },
          {
            content: "Paclitaxel",
          },
          {
            content: "Docetaxel",
          },
        ],
      },
      {
        content: "Bisphosphonate inhibitor",
        instruction:
          "Select one of the following bisphosphonates based on clinical indications:",
        childrenAnswer: [
          {
            content: "Bisphosphonates (Zoledronic acid)",
          },
          {
            content: "RANK inhibitor (Denosumab)",
          },
        ],
      },
    ],
    preQuestionId: [4],
    isMultiAnswers: true,
  },
  {
    _id: 12,
    question:
      "Select a chemotherapy regimen based on BRCA-mutation status and checkpoint inhibitor status",
    instruction: "Multiple selections are possible.",

    answers: [
      {
        content: "Checkpoint Inhibitor Status",
        instruction: "Select one of the following:",
        childrenAnswer: [
          {
            content: "PDL1-Positive ",
            instruction: "Select one of the following chemotherapy regimens:",
            grandChildAnswer: [
              "Nab-paclitaxel + atezolizumab",
              "Nab-paclitaxel + pembrolizumab",
              "Paclitaxel + pembrolizumab",
              "Carboplatin/gemcitabine + pembrolizumab",
            ],
          },
          {
            content: "PDL1-Negative",
            instruction: "Select one of the following chemotherapy regimens:",
            grandChildAnswer: [
              "Paclitaxel",
              "Docetaxel",
              "Carboplatin/gemcitabine",
              "Nab-paclitaxel",
              "Capecitabine",
            ],
          },
        ],
        // nextQuestionId: [20],
      },
      {
        content: "BRCA-mutation positive",
        instruction: "Select one of the following chemotherapy regimens:",
        childrenAnswer: [
          {
            content: "Add Olaparib",
          },
          {
            content: "Add Talazoparib",
          },
          {
            content: "Add Carboplatin/gemcitabine",
          },
          {
            content: "Add Cisplatin",
          },
        ],
      },
    ],
    preQuestionId: [4],
    isMultiAnswers: true,
  },
  {
    _id: 13,
    question:
      "Select the appropriate chemotherapy, endocrine therapy, and ovarian suppression regimen based on clinical indications",
    instruction: "Multiple selections are possible.",
    answers: [
      {
        content: "Chemotherapy regimen",
        instruction: "Select one of the following chemotherapy regimens:",
        childrenAnswer: [
          {
            content: "Paclitaxel  X 12 + Trastuzumab",
          },
          {
            content:
              "Taxotere or Taxol + carboplatin + Herceptin (TCH) (preferred for Stage 1)",
          },
          {
            content:
              "Adriamycin + Cyclophosphamide, taxol + trastuzumab (ACT+H)",
          },
          {
            content:
              "Taxotere + carpoplatin +  trastuzumab + pertuzumab (THCP) (preferred for Stage 2A-3C)",
            criteria: ["neoadjuvant", "Stage 2A-3C"],
            isCriteria: true,
          },
          {
            content: "Other (Specify): ",
            isPA: true,
            isOtherText: true,
          },
        ],
      },
      {
        content: "Endocrine therapy regimen",
        instruction: "Select one of the following endocrine therapy regimens:",
        childrenAnswer: [
          {
            content: "Anastrozole",
          },
          {
            content: "Letrozole",
          },
          {
            content: "Exemestane",
          },
          {
            content: "Tamoxifen",
          },
        ],
      },
      {
        content: "Ovarian Suppression regimens",
        instruction:
          "Select one of the following ovarian suppression regimens:",
        childrenAnswer: [
          {
            content: "Lupron",
          },
          {
            content: "Other (Specify): ",
            isPA: true,
            isOtherText: true,
          },
        ],
      },
    ],
    isMultiAnswers: true,
    preQuestionId: [6],
  },
  {
    _id: 14,
    question:
      "Select the appropriate chemotherapy, endocrine therapy, and ovarian suppression regimen based on clinical indications",
    instruction: "Multiple selections are possible.",

    answers: [
      {
        content: "Chemotherapy regimen",
        instruction: "Select one of the following chemotherapy regimens:",
        childrenAnswer: [
          {
            content: "Paclitaxel  X 12 + Trastuzumab",
          },
          {
            content:
              "Taxotere or Taxol + carboplatin + Herceptin (TCH) (preferred for Stage 1)",
          },
          {
            content:
              "Adriamycin + Cyclophosphamide, taxol + trastuzumab (ACT+H)",
          },
          {
            content: "Taxotere + carpoplatin +  trastuzumab + pertuzumab ",
            isPA: true,
          },
          {
            content: "Other (Specify): ",
            isPA: true,
            isOtherText: true,
          },
        ],
      },
      {
        content: "Endocrine therapy regimen",
        instruction: "Select one of the following endocrine therapy regimens:",
        childrenAnswer: [
          {
            content: "Anastrozole",
          },
          {
            content: "Letrozole",
          },
          {
            content: "Exemestane",
          },
          {
            content: "Tamoxifen",
          },
        ],
      },
      {
        content: "Ovarian Suppression",
        instruction: "Select one of the following ovarian suppression regimens",
        childrenAnswer: [
          {
            content: "Lupron",
          },
          {
            content: "Other (Specify): ",
            isPA: true,
            isOtherText: true,
          },
        ],
      },
    ],
    preQuestionId: [6],
    isMultiAnswers: true,
  },
  {
    _id: 15,
    question: "Select rationale for foregoing genomic testing:",
    answers: [
      {
        content: "Patient refused chemotherapy",
      },
      {
        content: "Patient has 4 or more positve lymph nodes",
      },
      {
        content: "Special tumor type: ",
        isOtherText: true,
      },
      {
        content: "Other (Specify): ",
        isPA: true,
        isOtherText: true,
      },
    ],
    preQuestionId: [7],
  },
  {
    _id: 16,
    question:
      "Select the appropriate chemotherapy, endocrine therapy, and ovarian suppression regimen based on clinical indications",
    instruction: "Multiple selections are possible.",

    answers: [
      {
        content: "Chemotherapy regimens",
        instruction: "Select one of the following chemotherapy regimens",
        childrenAnswer: [
          {
            content: "Taxotere + cyclophamide (TC)",
          },
          {
            content: "Adriamycin + Cyclophospamide + Taxol (ACT)",
          },
          {
            content: " cmP (adjuvant only)",
          },
          {
            content:
              "Dose Dense Adriamycin + Cyclophosphamide + Taxol (DD ACT) ",
            isPA: true,
          },
          // {
          //   content: "Other (Specify): ",
          //   isOtherText: true,
          // },
        ],
      },
      {
        content: "Endocrine therapy regimens",
        instruction: "Select one of the following endocrine therapy regimens",
        childrenAnswer: [
          {
            content: "Anastrozole",
          },
          {
            content: "Letrozole",
          },
          {
            content: "Exemestane",
          },
          {
            content: "Tamoxifen",
          },
        ],
      },
      {
        content: "Ovarian Suppression regimens",
        instruction: "Select one of the following ovarian suppression regimens",
        childrenAnswer: [
          {
            content: "Lupron",
          },
          {
            content: "Other (Specify): ",
            isPA: true,
            isOtherText: true,
          },
        ],
      },
    ],
    isMultiAnswers: true,
    preQuestionId: [7],
  },
  {
    _id: 17,
    question: "Select one of the following results:",
    answers: [
      {
        content: "LOW RISK",
        nextQuestionId: [22],
      },
      {
        content: "INTERMEDIATE RISK",
        nextQuestionId: [23],
      },
      {
        content: "HIGH RISK",
        nextQuestionId: [24],
      },
    ],
    preQuestionId: [7],
  },
  {
    _id: 18,
    question: "Select one of the following results:",
    answers: [
      {
        content: "Postmenopausal",
        instruction:
          "Select a CDK4/6 and aromatase inhibitors based on clinical indications More than one selection is permitted.",
        childrenAnswer: [
          {
            content: "CDK4/6-inhibitor",
            instruction: "Select one of the following CDK4/6-inhibitors:",
            grandChildAnswer: ["Palbociclib", "Ribociclib", "Abemaciclib"],
          },
          {
            content: "Aromatase inhibitor",
            instruction: "Select one of the following aromatase inhibitors:",
            grandChildAnswer: ["Anastrozole", "Letrozole", "Exemestane"],
          },
        ],
        isMultiAnswers: true,
      },
      {
        content: "Premenopausal/Perimenopausal",
        instruction:
          "Select from following regimens More than one selection is permitted.",
        childrenAnswer: [
          {
            content: "CDK4/6-inhibitor",
            instruction: "Select one of the following CDK4/6-inhibitors:",
            grandChildAnswer: ["Palbociclib", "Ribociclib", "Abemaciclib"],
          },
          {
            content: "Aromatase inhibitor",
            instruction: "Select one of the following aromatase inhibitors:",
            grandChildAnswer: ["Anastrozole", "Letrozole", "Exemestane"],
          },
          {
            content: "Ovarian Suppression",
            instruction:
              "Select one of the following overian suppression options:",
            middleChildAnswer: [
              {
                content: "Lupron",
              },
              {
                content: "Other (Specify): ",
                isPA: true,
                isOtherText: true,
              },
            ],
          },
          // {
          //   content: "Bisphosphonate inhibitor",
          //   instruction:
          //     "Select one of the following bisphosphonates based on clinical indications:",
          //   grandChildAnswer: [
          //     "Bisphosphonates (Zoledronic acid)",
          //     "RANK inhibitor (Denosumab)",
          //   ],
          // },
        ],
        isMultiAnswers: true,
      },
    ],
    preQuestionId: [10],
  },
  {
    _id: 19,
    question: "Select one of the following results:",
    answers: [
      {
        content: "Postmenopausal",
        instruction:
          "Select a CDK4/6 and aromatase inhibitors based on clinical indications More than one selection is permitted.",
        childrenAnswer: [
          {
            content: "CDK4/6-inhibitor",
            instruction: "Select one of the following CDK4/6-inhibitors:",
            grandChildAnswer: ["Palbociclib", "Ribociclib", "Abemaciclib"],
          },
          {
            content: "Aromatase inhibitor",
            instruction: "Select one of the following aromatase inhibitors:",
            grandChildAnswer: ["Anastrozole", "Letrozole", "Fulvestrant"],
          },
        ],
        isMultiAnswers: true,
      },
      {
        content: "Premenopausal/Perimenopausal",
        instruction:
          "Select from following regimens More than one selection is permitted.",
        childrenAnswer: [
          {
            content: "CDK4/6-inhibitor",
            instruction: "Select one of the following CDK4/6-inhibitors:",
            grandChildAnswer: ["Palbociclib", "Ribociclib", "Abemaciclib"],
          },
          {
            content: "Aromatase inhibitor",
            instruction: "Select one of the following aromatase inhibitors:",
            grandChildAnswer: ["Anastrozole", "Letrozole", "Fulvestran"],
          },
          {
            content: "Ovarian Suppression",
            instruction:
              "Select one of the following ovarian suppression options:",
            middleChildAnswer: [
              {
                content: "Lupron",
              },
              {
                content: "Other (Specify): ",
                isPA: true,
                isOtherText: true,
              },
            ],
          },
        ],
        isMultiAnswers: true,
      },
    ],
    preQuestionId: [11],
  },
  {
    _id: 20,
    question: "Select one of the following results:",
    answers: [
      {
        content: "PDL1-Positive ",
        instruction: "Select one of the following chemotherapy regimens:",
        childrenAnswer: [
          {
            content: "Nab-paclitaxel + atezolizumab",
          },
          {
            content: "Nab-paclitaxel + pembrolizumab",
          },
          {
            content: "Paclitaxel + pembrolizumab",
          },
          {
            content: "Carboplatin/gemcitabine + pembrolizumab",
          },
        ],
      },
      {
        content: "PDL1-Negative",
        instruction: "Select one of the following chemotherapy regimens:",
        childrenAnswer: [
          {
            content: "Paclitaxel",
          },
          {
            content: "Docetaxel",
          },
          {
            content: "Carboplatin/gemcitabine",
          },
          {
            content: "Nab-paclitaxel",
          },
          {
            content: "Capecitabine",
          },
        ],
      },
    ],
    preQuestionId: [12],
  },
  {
    _id: 21,
    question: "Select one of the following results:",
    answers: [
      {
        content: "BRCA-mutation positive",
        instruction: "Select one of the following chemotherapy regimens:",
        childrenAnswer: [
          {
            content: "Add Olaparib",
          },
          {
            content: "Add Talazoparib",
          },
          {
            content: "Add Carboplatin/gemcitabine",
          },
          {
            content: "Add Cisplatin",
          },
        ],
      },
      {
        content: "BRCA-mutation Negative",
        childrenAnswer: [
          {
            content: "None",
          },
        ],
      },
    ],
    preQuestionId: [12],
  },
  {
    _id: 22,
    question:
      "Select the appropriate endocrine therapy and ovarian suppression regimen based on clinical indications",
    instruction: "Multiple selections are possible.",

    answers: [
      {
        content: "Endocrine therapy regimen",
        instruction: "Select one of the following endocrine therapy regimes",
        childrenAnswer: [
          {
            content: "Anastrozole",
          },
          {
            content: "Letrozole",
          },
          {
            content: "Exemestane",
          },
          {
            content: "Tamoxifen",
          },
        ],
      },
      {
        content: "Ovarian Suppression regimens",
        instruction: "Select one of the following overian suppression regimes",
        childrenAnswer: [
          {
            content: "Lupron",
          },
          {
            content: "Other (Specify): ",
            isPA: true,
            isOtherText: true,
          },
        ],
      },
    ],
    isMultiAnswers: true,

    preQuestionId: [17],
  },
  {
    _id: 23,
    question:
      "Select the appropriate chemotherapy, endocrine therapy, and ovarian suppression regimen based on clinical indications",
    instruction: "Multiple selections are possible.",

    answers: [
      {
        content: "Chemotherapy regimens",
        instruction: "Select one of the following chemotherapy regimens:",
        childrenAnswer: [
          {
            content: "Taxotere + cyclophosphamide (TC)",
          },
          {
            content: "Adriamycin + Cyclophosphamide + Taxol ",
            isPA: true,
          },
          {
            content: "Dose Dense Adriamycin + Cyclophosphamide + Taxol ",
            isPA: true,
          },
          {
            content: "None",
          },
          {
            content: "Other (Specify): ",
            isPA: true,
            isOtherText: true,
          },
        ],
      },
      {
        content: "Endocrine therapy regimen",
        instruction: "Select one of the following endocrine therapy regimens:",
        childrenAnswer: [
          {
            content: "Anastrozole",
          },
          {
            content: "Letrozole",
          },
          {
            content: "Exemestane",
          },
          {
            content: "Tamoxifen",
          },
        ],
      },
      {
        content: "Ovarian Suppression",
        instruction:
          "Select one of the following ovarian suppression regimens:",
        childrenAnswer: [
          {
            content: "Lupron",
          },
          {
            content: "Other (Specify): ",
            isPA: true,
            isOtherText: true,
          },
        ],
      },
    ],
    isMultiAnswers: true,
    preQuestionId: [17],
  },
  {
    _id: 24,
    question:
      "Select the appropriate endocrine therapy and ovarian suppression regimen based on clinical indications",
    instruction: "Multiple selections are possible.",

    answers: [
      {
        content: "Chemotherapy regimen",
        instruction: "Select one of the following chemotherapy regimens:",
        childrenAnswer: [
          {
            content: "Taxotere + cyclophosphamide (TC)",
          },
          {
            content: "Adriamycin + Cyclophosphamide + Taxol (ACT) ",
            isPA: true,
          },
          {
            content:
              "Dose Dense Adriamycin + Cyclophosphamide + Taxol (DD ACT) ",
            isPA: true,
          },
          {
            content: "None",
          },
          {
            content: "Other (Specify): ",
            isPA: true,
            isOtherText: true,
          },
        ],
      },
      {
        content: "Endocrine therapy regimen",
        instruction: "Select one of the following chemotherapy regimens:",
        childrenAnswer: [
          {
            content: "Anastrozole",
          },
          {
            content: "Letrozole",
          },
          {
            content: "Exemestane",
          },
          {
            content: "Tamoxifen",
          },
        ],
      },
      {
        content: "Ovarian Suppression",
        instruction: "Select one of the following chemotherapy regimens:",
        childrenAnswer: [
          {
            content: "Lupron",
          },
          {
            content: "Other (Specify):",
            isOtherText: true,
          },
        ],
      },
    ],
    isMultiAnswers: true,
    preQuestionId: [17],
  },
  {
    _id: 25,
    question:
      "Select a CDK4/6 and aromatase inhibitors based on clinical indications",
    instruction: "More than one selection is permitted:",
    answers: [
      {
        content: "CDK4/6-inhibitor",
        instruction: "Select one of the following CDK4/6-inhibitors:",
        childrenAnswer: [
          {
            content: "Palbociclib",
          },
          {
            content: "Ribociclib",
          },
          {
            content: "Abemaciclib",
          },
        ],
      },
      {
        content: "Aromatase inhibitor",
        instruction: "Select one of the following aromatase inhibitors:",
        childrenAnswer: [
          {
            content: "Anastrozole",
          },
          {
            content: "Letrozole",
          },
          {
            content: "Exemestane",
          },
        ],
      },
    ],
    preQuestionId: [18],
    isMultiAnswers: true,
  },
  {
    _id: 26,
    question: "Select from following regimens",
    instruction: "More than one selection is permitted:",
    answers: [
      {
        content: "CDK4/6-inhibitor",
        instruction: "Select one of the following CDK4/6-inhibitors:",
        childrenAnswer: [
          {
            content: "Palbociclib",
          },
          {
            content: "Ribociclib",
          },
          {
            content: "Abemaciclib",
          },
        ],
      },
      {
        content: "Aromatase inhibitor",
        instruction: "Select one of the following aromatase inhibitors:",
        childrenAnswer: [
          {
            content: "Anastrozole",
          },
          {
            content: "Letrozole",
          },
          {
            content: "Exemestane",
          },
        ],
      },
      {
        content: "Ovarian Suppression",
        instruction: "Select one of the following overian suppression options:",
        childrenAnswer: [
          {
            content: "Lupron",
          },
          {
            content: "Other (Specify): ",
            isPA: true,
            isOtherText: true,
          },
        ],
      },
      {
        content: "Bisphosphonate inhibitor",
        instruction:
          "Select one of the following bisphosphonates based on clinical indications:",
        childrenAnswer: [
          {
            content: "Bisphosphonates (Zoledronic acid)",
          },
          {
            content: "RANK inhibitor (Denosumab)",
          },
        ],
      },
    ],
    preQuestionId: [18],
    isMultiAnswers: true,
  },
  {
    _id: 27,
    question:
      "Select a CDK4/6 and aromatase inhibitors based on clinical indications",
    instruction: "More than one selection is permitted:",
    answers: [
      {
        content: "CDK4/6-inhibitor",
        instruction: "Select one of the following CDK4/6-inhibitors:",
        childrenAnswer: [
          {
            content: "Palbociclib",
          },
          {
            content: "Ribociclib",
          },
          {
            content: "Abemaciclib",
          },
        ],
      },
      {
        content: "Aromatase inhibitor",
        instruction: "Select one of the following aromatase inhibitors:",
        childrenAnswer: [
          {
            content: "Anastrozole",
          },
          {
            content: "Letrozole",
          },
          {
            content: "Fulvestrant",
          },
        ],
      },
    ],
    preQuestionId: [19],
    isMultiAnswers: true,
  },
  {
    _id: 28,
    question: "Select from following regimens",
    instruction: "More than one selection is permitted.",
    answers: [
      {
        content: "CDK4/6-inhibitor",
        instruction: "Select one of the following CDK4/6-inhibitors:",
        childrenAnswer: [
          {
            content: "Palbociclib",
          },
          {
            content: "Ribociclib",
          },
          {
            content: "Abemaciclib",
          },
        ],
      },
      {
        content: "Aromatase inhibitor",
        instruction: "Select one of the following aromatase inhibitors:",
        childrenAnswer: [
          {
            content: "Anastrozole",
          },
          {
            content: "Letrozole",
          },
          {
            content: "Fulvestran",
          },
        ],
      },
      {
        content: "Ovarian Suppression",
        instruction: "Select one of the following ovarian suppression options:",
        childrenAnswer: [
          {
            content: "Lupron",
          },
          {
            content: "Other (Specify): ",
            isPA: true,
            isOtherText: true,
          },
        ],
      },
      // {
      //   content: "Bisphosphonate inhibitor",
      //   childrenAnswer: [
      //     {
      //       content: "Bisphosphonates (Zoledronic acid)",
      //     },
      //     {
      //       content: "RANK inhibitor (Denosumab)",
      //     },
      //   ],
      // },
    ],
    isMultiAnswers: true,
    preQuestionId: [19],
  },
];

export const SURGERYLEFT = [
  {
    _id: 1,
    question: "What surgical procedure is planned?",
    instruction: "Complete a separate pathway for each breast.",
    answers: [
      {
        content:
          "Left breast conserving surgery (Lumpectomy or Partial mastectomy)",
        nextQuestionId: [2],
      },
      {
        content: "Left mastectomy",
        nextQuestionId: [3],
      },
    ],
  },
  {
    _id: 2,
    question:
      "Select one or more of the following based on clinical indication(s)",
    answers: [
      {
        content: "Type of Breast Conserving Surgery",
        instruction:
          "Select one or more of the following based on clinical indication(s):",
        childrenAnswer: [
          {
            content: "Lumpectomy/Partial mastectomy",
            cpt: "19301",
          },
          {
            content: "Additional Lumpectomy/Partial mastectomy (Ipsilateral)",
            cpt: "19301",
          },
          {
            content:
              "Lumpectomy/Partial Mastectomy and Axillary node dissection",
            cpt: "19302",
          },
          {
            content: "Excisional biopsy (Ipsilateral)",
            cpt: "19120",
          },
          {
            content: "Excisional biopsy with localization (Ipsilateral)",
            cpt: "19125",
          },
        ],
        isMultiAnswers: true,
      },
      {
        content: "Localization and/or Specimen Imaging is Required",
        instruction:
          "Select one or more of the following based on clinical indication(s):",
        childrenAnswer: [
          {
            content:
              "Placement of localization device under mammographic guidance",
            instruction: "Up to three devices:",
            middleChildAnswer: [
              {
                content: "First Device (19281)",
              },
              {
                content: "Second Device (19282)",
              },
              {
                content: "Third Device (19282)",
              },
              {
                id: 1,
                content: "Specify lesion location(s):",
                isOtherText: true,
              },
            ],
            isMultiAnswers: true,
            cpt: "19281 (first lesion), 19282 (each additional lesion)",
          },
          {
            content:
              "Placement of localization device under stereotactic guidance",
            instruction: "Up to three devices:",

            middleChildAnswer: [
              {
                content: "First Device ",
              },
              {
                content: "Second Device ",
              },
              {
                content: "Third Device ",
              },
              {
                id: 2,
                content: "Specify lesion location(s):",
                isOtherText: true,
              },
            ],
            isMultiAnswers: true,
            cpt: "19283 (first lesion), 19284 (each additional lesion)",
          },
          {
            content:
              "Placement of localization device under ultrasound guidance",
            instruction: "Up to three devices:",

            middleChildAnswer: [
              {
                content: "First Device (19285)",
              },
              {
                content: "Second Device (19286)",
              },
              {
                content: "Third Device (19286)",
              },
              {
                id: 3,
                content: "Specify lesion location(s):",
                isOtherText: true,
              },
            ],
            isMultiAnswers: true,
            cpt: "19285 (first lesion), 19286 (each additional lesion)",
          },
          {
            content: "Placement of localization device under MRI Guidance",
            instruction: "Up to three devices:",

            middleChildAnswer: [
              {
                content: "First Device (19287)",
              },
              {
                content: "Second Device (19288)",
              },
              {
                content: "Third Device (19288)",
              },
              {
                id: 4,
                content: "Specify lesion location(s):",
                isOtherText: true,
              },
            ],
            isMultiAnswers: true,
            cpt: "19287 (first lesion), 19288 (each additional lesion)",
            criteria: [
              "not amenable to mammography or Ultrasound localization",
            ],
            isCreiteria: true,
          },
          {
            content: "Specimen ultrasound",
            cpt: "76998",
          },
          {
            content: "Specimen X-ray",
            cpt: "78098",
          },
          {
            content: "Other (specify): ",
            isPA: true,
            isOtherText: true,
          },
        ],
        isMultiAnswers: true,
      },
      {
        content: "Lymph node Surgery Is Planned",
        instruction:
          "Select one or more of the following based on clinical indication(s):",
        childrenAnswer: [
          {
            content: "Sentinel node biopsy",
            cpt: "38740",
          },
          {
            content: "Possible Axillary node dissection",
            cpt: "38745",
          },
          {
            content: "Axillary node dissection",
            cpt: "38745",
          },
          {
            content: "Blue dye injection for sentinel node mapping",
            cpt: "38900",
          },
          {
            content: "Lymphoscintigraphy for sentinel node mapping",
            cpt: "38792",
          },
          {
            content: "Other (specify): ",
            isPA: true,
            isOtherText: true,
          },
        ],
        isMultiAnswers: true,
      },
      {
        content: "Oncoplastic Surgery In Planned",
        instruction:
          "Select one or more of the following based on clinical indication(s):",
        childrenAnswer: [
          {
            content: "Adjacent tissue transfer up to <30 cm2",
            cpt: "14001",
          },
          {
            content: "Adjacent tissue transfer up to 30-60 cm2",
            cpt: "14301",
          },
          {
            content: "Adjacent tissue transfer up to >60 cm2",
            cpt: "14302",
          },
          {
            content:
              "Reduction mammaplasty (requires plastic surgery referral) ",
          },
          {
            content: "Mastopexy (requires plastic surgery referral) ",
          },
          {
            content: "Other (specify): ",
            isPA: true,
            isOtherText: true,
          },
        ],
        isMultiAnswers: true,
      },
      {
        content: "Other (specify): ",
        isOtherText: true,
      },
    ],
    isMultiAnswers: true,
    preQuestionId: [1],
  },
  // {
  //   _id: 4,
  //   question: "Localization and/or Specimen Imaging is Required",
  //   instruction:
  //     "Select one or more of the following based on clinical indication(s):",
  //   answers: [
  //     {
  //       content:
  //         "Placement of localization device under mammographic guidance",
  //       instruction: "Up to three devices:",
  //       childrenAnswer: [
  //         {
  //           content: "First Device",
  //         },
  //         {
  //           content: "Second Device",
  //         },
  //         {
  //           content: "Third Device",
  //         },
  //         {
  //           content: "Specify lesion location(s):",
  //           isOtherText: true,
  //         },
  //       ],
  //       isMultiAnswers: true,
  //       cpt: "19281 (first lesion), 19282 (each additional lesion)",
  //     },
  //     {
  //       content:
  //         "Placement of localization device under stereotactic guidance",
  //       instruction: "Up to three devices:",

  //       childrenAnswer: [
  //         {
  //           content: "First Device ",
  //         },
  //         {
  //           content: "Second Device ",
  //         },
  //         {
  //           content: "Third Device ",
  //         },
  //         {
  //           content: "Specify lesion location(s):",
  //           isOtherText: true,
  //         },
  //       ],
  //       isMultiAnswers: true,
  //       cpt: "19283 (first lesion), 19284 (each additional lesion)",
  //     },
  //     {
  //       content: "Placement of localization device under ultrasound guidance",
  //       instruction: "Up to three devices:",

  //       childrenAnswer: [
  //         {
  //           content: "First Device",
  //         },
  //         {
  //           content: "Second Device",
  //         },
  //         {
  //           content: "Third Device",
  //         },
  //         {
  //           content: "Specify lesion location(s):",
  //           isOtherText: true,
  //         },
  //       ],
  //       isMultiAnswers: true,
  //       cpt: "19285 (first lesion), 19286 (each additional lesion)",
  //     },
  //     {
  //       content: "Placement of localization device under MRI Guidance",
  //       instruction: "Up to three devices:",

  //       childrenAnswer: [
  //         {
  //           content: "First Device",
  //         },
  //         {
  //           content: "Second Device",
  //         },
  //         {
  //           content: "Third Device",
  //         },
  //         {
  //           content: "Specify lesion location(s):",
  //           isOtherText: true,
  //         },
  //       ],
  //       isMultiAnswers: true,
  //       cpt: "19287 (first lesion), 19288 (each additional lesion)",
  //       criteria: ["not amenable to mammography or Ultrasound localization"],
  //       isCreiteria: true,
  //     },
  //     {
  //       content: "Specimen ultrasound",
  //       cpt: "76998",
  //     },
  //     {
  //       content: "Specimen X-ray",
  //       cpt: "78098",
  //     },
  //     {
  //       content: "Other (specify): ",
  //       isPA: true,
  //       isOtherText: true,
  //     },
  //   ],
  //   isMultiAnswers: true,
  //   preQuestionId: [2],
  // },
  {
    _id: 3,
    question: "Select appropriate option(s) based in clinical scenario.",

    instruction:
      "Select one or more of the following based on clinical indication(s):",
    answers: [
      {
        content: "Type of Mastectomy Planned",
        instruction:
          "Select one or more of the following based on clinical indication(s):",
        childrenAnswer: [
          {
            content: "Total mastectomy",
            cpt: "19303",
          },
          {
            content: "Skin sparing mastectomy",
            cpt: "19303",
          },
          {
            content: "Nipple sparing mastectomy",
            cpt: "19303",
          },
          {
            content: "Modified Radical Mastectomy",
            cpt: "19307",
          },
        ],
      },
      {
        content: "Indication",
        instruction: "Select one of the following options:",
        childrenAnswer: [
          {
            content: "Therapeutic",
          },
          {
            content: "Prophylactic",
          },
        ],
      },

      {
        content: "Breast Reconstruction is Planned",
        instruction: "Select one of the following options:",
        childrenAnswer: [
          {
            content: "Yes (Refer to plastic surgery)",
          },
          {
            content: "No (patient declines breast reconstruction)",
          },
          {
            content: "No (breast reconstruction contraindicated) (Specify):",
            isOtherText: true,
          },
        ],
      },
      {
        content: "Lymph node Surgery Is Planned",
        instruction:
          "Select one more of the following based on clinical indication(s):",
        childrenAnswer: [
          {
            content: "Sentinel node biopsy",
            cpt: "38740",
          },
          {
            content: "Possible Axillary node dissection",
            cpt: "38745",
          },
          {
            content: "Axillary node dissection",
            cpt: "38745",
          },
          {
            content: "Blue dye injection for sentinel node mapping",
            cpt: "38900",
          },
          {
            content: "Lymphoscintigraphy for sentinel node mapping",
            cpt: "38792",
          },
          {
            content: "Other (specify): ",
            isPA: true,
            isOtherText: true,
          },
        ],
        isMultiAnswers: true,
      },
    ],
    isMultiAnswers: true,
    preQuestionId: [1],
  },
];

export const SURGERYRIGHT = [
  {
    _id: 1,
    question: "What surgical procedure is planned?",
    instruction: "Complete a separate pathway for each breast.",
    answers: [
      {
        content:
          "Right breast conserving surgery (Lumpectomy or Partial mastectomy)",
        nextQuestionId: [2],
      },
      {
        content: "Right mastectomy",
        nextQuestionId: [3],
      },
    ],
  },
  {
    _id: 2,
    question:
      "Select one or more of the following based on clinical indication(s)",
    answers: [
      {
        content: "Type of Breast Conserving Surgery",
        instruction:
          "Select one or more of the following based on clinical indication(s):",
        childrenAnswer: [
          {
            content: "Lumpectomy/Partial mastectomy",
            cpt: "19301",
          },
          {
            content: "Additional Lumpectomy (Ipsilateral),",
            cpt: "19301",
          },
          {
            content: "Partial Mastectomy and Axillary node dissection",
            cpt: "19302",
          },
          {
            content: "Excisional biopsy (Ipsilateral),",
            cpt: "19120",
          },
          {
            content: "Excisional biopsy with localization (Ipsilateral)",
            cpt: "19125",
          },
        ],
        isMultiAnswers: true,
      },
      {
        content: "Localization and/or Specimen Imaging is Required",
        instruction:
          "Select one or more of the following based on clinical indication(s):",
        childrenAnswer: [
          {
            content:
              "Placement of localization device under mammographic guidance",
            instruction: "Up to three devices:",

            middleChildAnswer: [
              {
                content: "First Device (19181)",
              },
              {
                content: "Second Device (19282)",
              },
              {
                content: "Third Device (19282)",
              },
              {
                id: 5,
                content: "Specify lesion location(s):",
                isOtherText: true,
              },
            ],
            isMultiAnswers: true,
            cpt: "19281 (first lesion), 19282 (each additional lesion)",
          },
          {
            content:
              "Placement of localization device under stereotactic guidance",
            instruction: "Up to three devices:",

            middleChildAnswer: [
              {
                content: "First Device (19283)",
              },
              {
                content: "Second Device (19284)",
              },
              {
                content: "Third Device (19284)",
              },
              {
                id: 6,
                content: "Specify lesion location(s):",
                isOtherText: true,
              },
            ],
            isMultiAnswers: true,
            cpt: "19283 (first lesion), 19284 (each additional lesion)",
          },
          {
            content:
              "Placement of localization device under ultrasound guidance",
            instruction: "Up to three devices:",

            middleChildAnswer: [
              {
                content: "First Device (19285)",
              },
              {
                content: "Second Device (19286)",
              },
              {
                content: "Third Device (19286)",
              },
              {
                id: 7,
                content: "Specify lesion location(s):",
                isOtherText: true,
              },
            ],
            isMultiAnswers: true,
            cpt: "19285 (first lesion), 19286 (each additional lesion)",
          },
          {
            content: "Placement of localization device under MRI Guidance",
            instruction: "Up to three devices:",

            middleChildAnswer: [
              {
                content: "First Device (19287)",
              },
              {
                content: "Second Device (19288)",
              },
              {
                content: "Third Device (19288)",
              },
              {
                id: 8,
                content: "Specify lesion location(s):",
                isOtherText: true,
              },
            ],
            isMultiAnswers: true,
            cpt: "19287 (first lesion), 19288 (each additional lesion)",
            criteria: [
              "not amenable to mammography or Ultrasound localization",
            ],
            isCriteria: true,
          },
          {
            content: "Specimen ultrasound",
            cpt: "76998",
          },
          {
            content: "Specimen X-ray",
            cpt: "78098",
          },
          {
            content: "Other (specify):",
            isPA: true,
            isOtherText: true,
          },
        ],
        isMultiAnswers: true,
      },
      {
        instruction:
          "Select one or more of the following based on clinical indication(s):",
        content: "Lymph node Surgery Is Planned",
        childrenAnswer: [
          {
            content: "Sentinel node biopsy",
            cpt: "38740",
          },
          {
            content: "Possible Axillary node dissection",
            cpt: "38745",
          },
          {
            content: "Axillary node dissection",
            cpt: "38745",
          },
          {
            content: "Blue dye injection for sentinel node mapping",
            cpt: "38900",
          },
          {
            content: "Lymphoscintigraphy for sentinel node mapping",
            cpt: "38792",
          },
          {
            content: "Other (specify): ",
            isPA: true,
            isOtherText: true,
          },
        ],
        isMultiAnswers: true,
        preQuestionId: [1],
      },
      {
        instruction:
          "Select one or more of the following based on clinical indication(s):",
        content: "Oncoplastic Surgery In Planned",
        childrenAnswer: [
          {
            content: "Adjacent tissue transfer up to <30 cm2",
            cpt: "14001",
          },
          {
            content: "Adjacent tissue transfer up to 30-60 cm2",
            cpt: "14301",
          },
          {
            content: "Adjacent tissue transfer up to >60 cm2",
            cpt: "14302",
          },
          {
            content:
              "Reduction mammaplasty (requires plastic surgery referral)",
          },
          {
            content: "Mastopexy (requires plastic surgery referral)",
          },
          {
            content: "Other (specify): ",
            isPA: true,
            isOtherText: true,
          },
        ],
        isMultiAnswers: true,
      },
      {
        content: "Other (specify): ",
        isOtherText: true,
      },
    ],
    isMultiAnswers: true,
    preQuestionId: [1],
  },
  {
    _id: 4,
    question: "Localization and/or Specimen Imaging is Required",
    instruction:
      "Select one or more of the following based on clinical indication(s):",
    answers: [
      {
        content: "Placement of localization device under mammographic guidance",
        instruction: "Up to three devices:",

        childrenAnswer: [
          {
            content: "First Device (19181)",
          },
          {
            content: "Second Device (19282)",
          },
          {
            content: "Third Device (19282)",
          },
          {
            content: "Specify Lesion location(s):",
            isOtherText: true,
          },
        ],
        isMultiAnswers: true,
        cpt: "19281 (first lesion), 19282 (each additional lesion)",
      },
      {
        content: "Placement of localization device under stereotactic guidance",
        instruction: "Up to three devices:",

        childrenAnswer: [
          {
            content: "First Device (19283)",
          },
          {
            content: "Second Device (19284)",
          },
          {
            content: "Third Device (19284)",
          },
          {
            content: "Specify Lesion location(s):",
            isOtherText: true,
          },
        ],
        isMultiAnswers: true,
        cpt: "19283 (first lesion), 19284 (each additional lesion)",
      },
      {
        content: "Placement of localization device under ultrasound guidance",
        instruction: "Up to three devices:",

        childrenAnswer: [
          {
            content: "First Device (19285)",
          },
          {
            content: "Second Device (19286)",
          },
          {
            content: "Third Device (19286)",
          },
          {
            content: "Specify Lesion location(s):",
            isOtherText: true,
          },
        ],
        isMultiAnswers: true,
        cpt: "19285 (first lesion), 19286 (each additional lesion)",
      },
      {
        content: "Placement of localization device under MRI Guidance",
        instruction: "Up to three devices:",

        childrenAnswer: [
          {
            content: "First Device (19287)",
          },
          {
            content: "Second Device (19288)",
          },
          {
            content: "Third Device (19288)",
          },
          {
            content: "Specify Lesion location(s):",
            isOtherText: true,
          },
        ],
        isMultiAnswers: true,
        cpt: "19287 (first lesion), 19288 (each additional lesion)",
        criteria: ["not amenable to mammography or Ultrasound localization"],
        isCriteria: true,
      },
      {
        content: "Specimen ultrasound",
        cpt: "76998",
      },
      {
        content: "Specimen X-ray",
        cpt: "78098",
      },
      {
        content: "Other (specify):",
        isPA: true,
        isOtherText: true,
      },
    ],
    isMultiAnswers: true,
    preQuestionId: [2],
  },
  {
    _id: 3,
    question: "Select appropriate option(s) based in clinical scenario.",
    instruction: "Multiple selections are possible.",

    answers: [
      {
        content: "Type of Mastectomy Planned",
        instruction:
          "Select one or more of the following based on clinical indication(s):",
        childrenAnswer: [
          {
            content: "Total mastectomy",
            cpt: "19303",
          },
          {
            content: "Skin sparing mastectomy",
            cpt: "19303",
          },
          {
            content: "Nipple sparing mastectomy",
            cpt: "19303",
          },
          {
            content: "Modified Radical Mastectomy",
            cpt: "19307",
          },
        ],
      },
      {
        content: "Indication",
        instruction: "Select one of the following options:",
        childrenAnswer: [
          {
            content: "Therapeutic",
          },
          {
            content: "Prophylactic",
          },
        ],
      },

      {
        content: "Breast Reconstruction is Planned",
        instruction: "Select one of the following options:",
        childrenAnswer: [
          {
            content: "Yes (Refer to plastic surgery)",
          },
          {
            content: "No (patient declines breast reconstruction)",
          },
          {
            content: "No (breast reconstruction contraindicated) (Specify):",
            isOtherText: true,
          },
        ],
      },
      {
        content: "Lymph node Surgery Is Planned",
        instruction:
          "Select one or more of the following based on clinical indications(:)",
        childrenAnswer: [
          {
            content: "Sentinel node biopsy",
            cpt: "38740",
          },
          {
            content: "Possible Axillary node dissection",
            cpt: "38745",
          },
          {
            content: "Axillary node dissection",
            cpt: "38745",
          },
          {
            content: "Blue dye injection for sentinel node mapping",
            cpt: "38900",
          },
          {
            content: "Lymphoscintigraphy for sentinel node mapping",
            cpt: "38792",
          },
          {
            content: "Other (specify): ",
            isPA: true,
            isOtherText: true,
          },
        ],
        isMultiAnswers: true,
      },
    ],
    isMultiAnswers: true,
    preQuestionId: [1],
  },
];

export const DIANOSTICIMAGING = [
  {
    _id: 1,
    question: "What is the indication?",
    instruction: "Select one of the following:",
    answers: [
      {
        content: "BIRADS 0 Work-up",
        nextQuestionId: [2],
      },
      {
        content: "BIRADS 1, 2, or 3 Follow-up",
        nextQuestionId: [3],
      },
      {
        content: "BIRADS 4 or 5 Work-up",
        nextQuestionId: [4],
      },
      {
        content: "BIRADS 6 (Newly diagnosed  Cancer) Work-up",
        nextQuestionId: [5],
      },
      {
        content:
          "Signs/Symptoms: lump, pain, nipple discharge, retraction, rash, edema, erythema",
        nextQuestionId: [6],
      },
    ],
  },
  {
    _id: 2,
    question:
      "Select the appropriate imaging study and/or procedure based on radiologist's recommendations and/or clinical indications",
    instruction: "(Multiple selections permitted)",
    answers: [
      {
        content: "Mammography (Diagnostic)",
        instruction: "Select one of the following:",
        childrenAnswer: [
          {
            content: "RIGHT BREAST",
            cpt: "77065",
          },
          {
            content: "LEFT BREAST",
            cpt: "77065",
          },
          {
            content: "BILATERAL BREASTS",
            cpt: "77065",
          },
        ],
      },
      {
        content: "Ultrasound (Diagnostic)",
        instruction: "Select one of the following:",
        childrenAnswer: [
          {
            content: "Limited (Targeted area)",
            instruction: "Select one of the following:",
            grandChildAnswer: [
              "RIGHT BREAST, to area(s) of interest, including axilla, if performed",
              "LEFT BREAST, to area(s) of interest, including axilla, if performed",
              "BILATERAL BREASTS, to area(s) of interest, including axilla, if performed",
            ],
            cpt: "76641",
          },
          {
            content: "Complete (Whole Breast)",
            instruction: "Select one of the following:",
            grandChildAnswer: [
              "RIGHT BREAST, including axilla, if performed (CPT code: 76642)",
              "LEFT BREAST, including axilla, if performed (CPT code: 76642)",
              "BILATERAL BREAST, including axilla, if performed (CPT code: 76641)",
            ],
            cpt: "76641 , 76642",
          },
        ],
      },
      {
        content: "Contrast-enhanced MRI-BILATERAL BREAST ",
        instruction: "Both of the following criteria must be met:",
        criteria: ["inconclusive mammography", "inconclusive ultrasound"],
        isPA: true,
        isCriteria: true,
        cpt: "77049",
      },
      {
        content: "Other (specify): ",
        isPA: true,
        isOtherText: true,
      },
    ],
    isMultiAnswers: true,
    preQuestionId: [1],
  },
  {
    _id: 3,
    question: "What is the birads score?",
    answers: [
      {
        content: "BIRADS1",
        nextQuestionId: [7],
      },
      {
        content: "BIRADS 2",
        nextQuestionId: [8],
      },
      {
        content: "BIRADS 3",
        nextQuestionId: [9],
      },
    ],
    preQuestionId: [1],
  },
  {
    _id: 4,
    question:
      "Select the appropriate imaging study and/or procedure based on radiologist's recommendations and/or clinical indications",
    instruction: "(Multiple selections permitted)",

    answers: [
      {
        content: "Mammography (Diagnostic)",
        instruction: "Select one of the following:",
        childrenAnswer: [
          {
            content: "RIGHT BREAST",

            cpt: "77065",
          },
          {
            content: "LEFT BREAST",
            cpt: "77065",
          },
          {
            content: "BILATERAL BREASTS",
            cpt: "77065",
          },
        ],
      },
      {
        content: "Ultrasound (Diagnostic)",
        instruction: "Select one of the following:",
        childrenAnswer: [
          {
            content: "Limited (Targeted area)",
            instruction: "Select one of the following:",
            grandChildAnswer: [
              "RIGHT BREAST, to area(s) of interest, including axilla, if performed",
              "LEFT BREAST, to area(s) of interest, including axilla, if performed",
              "BILATERAL BREAST, to area(s) of interest, including axilla, if performed",
            ],
            isMultiAnswers: false,
            cpt: "76641",
          },
          {
            content: "Complete (Whole Breast)",
            instruction: "Select one of the following:",
            grandChildAnswer: [
              "RIGHT BREAST, including axilla, if performed",
              "LEFT BREAST, including axilla, if performed",
              "BILATERAL BREAST, including axilla, if performed",
            ],
            isMultiAnswers: false,
            cpt: "76642 , 76641",
          },
        ],
      },
      {
        content: "Contrast-enhanced MRI",
        instruction:
          "Once per 6 month period if any of the following criteria are be met:",
        criteria: [
          "calculated lifetime risk of breast cancer >/= 20%;",
          "pathogenic breast cancer mutation;",
          "h/o mantle radiation for Hodgkin disease;",
          "personal history of breast cancer and dense breasts;",
          "personal history of breast cancer under age 50;",
          "recent h/o bloody nipple discharge;",
          "follow-up of abnormal breast MRI performed > 3 month ago.",
        ],
        isCriteria: true,
        cpt: "77049",
      },
      {
        content: "Contrast-enhanced MRI-BILATERAL BREAST ",
        instruction: "Both of the following criteria must be met:",
        criteria: ["inconclusive mammography", "inconclusive ultrasound"],
        isPA: true,
        isCriteria: true,
        cpt: "77049",
      },
      {
        content: "Other (specify): ",
        isPA: true,
        isOtherText: true,
      },
      {
        content: "Procedure(s)",
        instruction:
          "Select one or more of the following based on radiologist's recommendations and/or clinical indication(s):",
        childrenAnswer: [
          {
            content:
              "Stereotactic core biopsy RIGHT BREAST (up to three sites), specimen imaging, with marker placement, when performed, and post-biopsy mammogram",
            instruction: "Specify number of sites:",
            middleChildAnswer: [
              {
                content: "First Site",
                // isOtherText: true,
              },
              {
                content: "Second Site",
                // isOtherText: true,
              },
              {
                content: "Third Site",
                // isOtherText: true,
              },
            ],
            cpt: "19081 (first lesion), 19082 (second lesion), 19082 (third lesion), 77065; pathology  assessment per specimen: 88305 plus, if malignant: 88361 or 88342 +/- 88360",
            // isCriteria: true,
          },
          {
            content:
              "Stereotactic core biopsy LEFT BREAST (up to three sites), specimen imaging, with marker placement, when performed, and post-biopsy mammogram",
            instruction: "Specify number of sites:",
            middleChildAnswer: [
              {
                content: "First Site",
                // isOtherText: true,
              },
              {
                content: "Second Site",
                // isOtherText: true,
              },
              {
                content: "Third Site",
                // isOtherText: true,
              },
            ],
            cpt: "19081 (first lesion), 19082 (second lesion), 19082 (third lesion), 77065; pathology  assessment per specimen: 88305 plus, if malignant: 88361 or 88342 +/- 88360",
            // isCriteria: true,
          },
          {
            content:
              "Ultrasound guided core biopsy RIGHT BREAST (up to three sites) +/- axilla, specimen imaging, with marker placement and post-biopsy mammogram",
            instruction: "Specify number of sites:",
            middleChildAnswer: [
              {
                content: "First Site",
                // isOtherText: true,
              },
              {
                content: "Second Site",
                // isOtherText: true,
              },
              {
                content: "Third Site",
                // isOtherText: true,
              },
            ],
            cpt: "19083 (first lesion), 19084 (second lesion), 19084 (third lesion), 77065; pathology  assessment per specimen: 88305 plus, if malignant: 88361 or 88342 +/- 88360",
          },
          {
            content:
              "Ultrasound guided core biopsy LEFT BREAST (up to three sites) +/- axilla, specimen imaging, with marker placement and post-biopsy mammogram",
            instruction: "Specify number of sites:",
            middleChildAnswer: [
              {
                content: "First Site",
                // isOtherText: true,
              },
              {
                content: "Second Site",
                // isOtherText: true,
              },
              {
                content: "Third Site",
                // isOtherText: true,
              },
            ],
            cpt: "19083 (first lesion), 19084 (second lesion), 19084 (third lesion), 77065; pathology  assessment per specimen: 88305 plus, if malignant: 88361 or 88342 +/- 88360",
          },
          {
            content:
              "Ultrasound guided core biopsy RIGHT AXILLA, specimen imaging, with marker placement and post-biopsy mammogram",
            cpt: "19083 (first lesion), 19084 (second lesion), 19084 (third lesion), 77065; pathology  assessment per specimen: 88305 plus, if malignant: 88361 or 88342 +/- 88360",
          },
          {
            content:
              "Ultrasound guided core biopsy LEFT AXILLA, specimen imaging, with marker placement and post-biopsy mammogram",
            cpt: "19083 (first lesion), 19084 (second lesion), 19084 (third lesion), 77065; pathology  assessment per specimen: 88305 plus, if malignant: 88361 or 88342 +/- 88360",
          },

          {
            content:
              "MRI-guided core biopsy RIGHT BREAST (up to 3 sites), specimen imaging, with marker placement, when performed, and post-biopsy mammogram ",
            instruction: "Specify number of sites:",
            middleChildAnswer: [
              {
                content: "First Site",
                // isOtherText: true,
              },
              {
                content: "Second Site",
                // isOtherText: true,
              },
              {
                content: "Third Site",
                // isOtherText: true,
              },
            ],
            isPA: true,
            cpt: "19085 (first lesion), 19086 (second lesion), 19086 (third lesion), 77065; pathology assessment per specimen: 88305 plus, if malignant: 88361 or 88342 +/- 88360",
          },
          {
            content:
              "MRI-guided core biopsy LEFT BREAST (up to 3 sites), specimen imaging, with marker placement, when performed, and post-biopsy mammogram ",
            instruction: "Specify number of sites:",
            middleChildAnswer: [
              {
                content: "First Site",
                // isOtherText: true,
              },
              {
                content: "Second Site",
                // isOtherText: true,
              },
              {
                content: "Third Site",
                // isOtherText: true,
              },
            ],
            isPA: true,
            cpt: "19085 (first lesion), 19086 (second lesion), 19086 (third lesion), 77065; pathology assessment per specimen: 88305 plus, if malignant: 88361 or 88342 +/- 88360",
          },
          {
            content:
              "Ultrasound Guided Aspiration of cyst/mass RIGHT BREAST (up to 3 sites)",
            instruction: "Specify number of sites:",
            middleChildAnswer: [
              {
                content: "First Site",
                // isOtherText: true,
              },
              {
                content: "Second Site",
                // isOtherText: true,
              },
              {
                content: "Third Site",
                // isOtherText: true,
              },
            ],
            cpt: "10005 (first lesion), 10006 (second lesion), 10006 (third lesion); pathology assessment per specimen; 88173 +/- 88172",
          },
          {
            content:
              "Ultrasound Guided Aspiration of cyst/mass LEFT BREAST (up to 3 sites)",
            instruction: "Specify number of sites:",
            middleChildAnswer: [
              {
                content: "First Site",
                // isOtherText: true,
              },
              {
                content: "Second Site",
                // isOtherText: true,
              },
              {
                content: "Third Site",
                // isOtherText: true,
              },
            ],
            cpt: "10005 (first lesion), 10006 (second lesion), 10006 (third lesion); pathology assessment per specimen; 88173 +/- 88172",
          },
          {
            content: "Other (specify): ",
            isPA: true,
            isOtherText: true,
          },
        ],
        isMultiAnswers: true,
      },
    ],
    isMultiAnswers: true,
    preQuestionId: [1],
  },
  {
    _id: 5,
    question:
      "Select the appropriate imaging study and/or procedure based on radiologist's recommendations and/or clinical indications",
    instruction: "(Multiple selections permitted)",
    answers: [
      {
        content: "Mammography (Diagnostic)",
        instruction: "Select one of the following:",
        childrenAnswer: [
          {
            content: "RIGHT BREAST",
            cpt: "77065",
          },
          {
            content: "LEFT BREAST",
            cpt: "77065",
          },
          {
            content: "BILATERAL BREAST",
            cpt: "77065",
          },
        ],
      },
      {
        content: "Ultrasound (Diagnostic)",
        instruction: "Select one of the following:",
        childrenAnswer: [
          {
            content: "Limited (Targeted area)",
            instruction: "Select one of the following:",
            grandChildAnswer: [
              "RIGHT BREAST, to area(s) of interest, including axilla, if performed",
              "LEFT BREAST, to area(s) of interest, including axilla, if performed",
              "BILATERAL BREAST, to area(s) of interest, including axilla, if performed",
            ],
            isMultiAnswers: false,
            cpt: "76641",
          },
          {
            content: "Complete (Whole Breast)",
            instruction: "Select one of the following:",
            grandChildAnswer: [
              "RIGHT BREAST, including axilla, if performed",
              "LEFT BREAST, including axilla, if performed",
              "BILATERAL BREAST, including axilla, if performed",
            ],
            isMultiAnswers: false,
            cpt: "76642 , 76641",
          },
        ],
      },
      {
        content: "Contrast-enhanced MRI-BILATERAL BREASTS",
        instruction: "Specify indication:",
        criteria: [
          "evaluation of disease extent.",
          "unknown primary;",
          "assessment of post-lumpectomy margins;",
          "neoadjuvant chemotherapy;",
        ],
        isCriteria: true,
        cpt: "77049",
      },
      {
        content: "Other (specify): ",
        isPA: true,
        isOtherText: true,
      },
      {
        content: "Procedure(s)",
        instruction:
          "Select one or more of the following based on radiologist's recommendations and/or clinical indication(s):",
        childrenAnswer: [
          {
            content:
              "Stereotactic core biopsy RIGHT BREAST (up to three sites), specimen imaging, with marker placement, when performed, and post-biopsy mammogram",
            instruction: "Specify number of sites:",
            middleChildAnswer: [
              {
                content: "First Site",
                // isOtherText: true,
              },
              {
                content: "Second Site",
                // isOtherText: true,
              },
              {
                content: "Third Site",
                // isOtherText: true,
              },
            ],
            isCriteria: true,
            cpt: "19081 (first lesion), 19082 (second lesion), 19082 (third lesion), 77065; pathology  assessment per specimen: 88305 plus, if malignant: 88361 or 88342 +/- 88360",
          },
          {
            content:
              "Stereotactic core biopsy LEFT BREAST (up to three sites), specimen imaging, with marker placement, when performed, and post-biopsy mammogram",
            instruction: "Specify number of sites:",
            middleChildAnswer: [
              {
                content: "First Site",
                // isOtherText: true,
              },
              {
                content: "Second Site",
                // isOtherText: true,
              },
              {
                content: "Third Site",
                // isOtherText: true,
              },
            ],
            isCriteria: true,
            cpt: "19081 (first lesion), 19082 (second lesion), 19082 (third lesion), 77065; pathology  assessment per specimen: 88305 plus, if malignant: 88361 or 88342 +/- 88360",
          },
          {
            content:
              "Ultrasound guided core biopsy RIGHT BREAST (up to three sites) +/- axilla, specimen imaging, with marker placement and post-biopsy mammogram",
            instruction: "Specify number of sites:",
            middleChildAnswer: [
              {
                content: "First Site",
                // isOtherText: true,
              },
              {
                content: "Second Site",
                // isOtherText: true,
              },
              {
                content: "Third Site",
                // isOtherText: true,
              },
            ],
            cpt: "19083 (first lesion), 19084 (second lesion), 19084 (third lesion), 77065; pathology  assessment per specimen: 88305 plus, if malignant: 88361 or 88342 +/- 88360",
          },
          {
            content:
              "Ultrasound guided core biopsy LEFT BREAST (up to three sites) +/- axilla, specimen imaging, with marker placement and post-biopsy mammogram",
            instruction: "Specify number of sites:",
            middleChildAnswer: [
              {
                content: "First Site",
                // isOtherText: true,
              },
              {
                content: "Second Site",
                // isOtherText: true,
              },
              {
                content: "Third Site",
                // isOtherText: true,
              },
            ],
            cpt: "19083 (first lesion), 19084 (second lesion), 19084 (third lesion), 77065; pathology  assessment per specimen: 88305 plus, if malignant: 88361 or 88342 +/- 88360",
          },
          {
            content:
              "Ultrasound guided core biopsy RIGHT AXILLA, specimen imaging, with marker placement and post-biopsy mammogram",
            cpt: "19083 (first lesion), 19084 (second lesion), 19084 (third lesion), 77065; pathology  assessment per specimen: 88305 plus, if malignant: 88361 or 88342 +/- 88360",
          },
          {
            content:
              "Ultrasound guided core biopsy LEFT AXILLA, specimen imaging, with marker placement and post-biopsy mammogram",
            cpt: "19083 (first lesion), 19084 (second lesion), 19084 (third lesion), 77065; pathology  assessment per specimen: 88305 plus, if malignant: 88361 or 88342 +/- 88360",
          },
          {
            content:
              "MRI-guided core biopsy RIGHT BREAST (up to 3 sites), specimen imaging, with marker placement, when performed, and post-biopsy mammogram ",
            instruction: "Specify number of sites:",
            middleChildAnswer: [
              {
                content: "First Site",
                // isOtherText: true,
              },
              {
                content: "Second Site",
                // isOtherText: true,
              },
              {
                content: "Third Site",
                // isOtherText: true,
              },
            ],
            cpt: "19085 (first lesion), 19086 (second lesion), 19086 (third lesion), 77065; pathology assessment per specimen: 88305 plus, if malignant: 88361 or 88342 +/- 88360",
            // isPA: true,
          },
          {
            content:
              "MRI-guided core biopsy LEFT BREAST (up to 3 sites), specimen imaging, with marker placement, when performed, and post-biopsy mammogram ",
            instruction: "Specify number of sites:",
            middleChildAnswer: [
              {
                content: "First Site",
                // isOtherText: true,
              },
              {
                content: "Second Site",
                // isOtherText: true,
              },
              {
                content: "Third Site",
                // isOtherText: true,
              },
            ],
            cpt: "19085 (first lesion), 19086 (second lesion), 19086 (third lesion), 77065; pathology assessment per specimen: 88305 plus, if malignant: 88361 or 88342 +/- 88360",
            // isPA: true,
          },
          {
            content:
              "Ultrasound Guided Aspiration of cyst/mass RIGHT BREAST (up to 3 sites)",
            instruction: "Specify number of sites:",
            middleChildAnswer: [
              {
                content: "First Site",
                // isOtherText: true,
              },
              {
                content: "Second Site",
                // isOtherText: true,
              },
              {
                content: "Third Site",
                // isOtherText: true,
              },
            ],
            cpt: "10005 (first lesion), 10006 (second lesion), 10006 (third lesion); pathology assessment per specimen; 88173 +/- 88172",
          },
          {
            content:
              "Ultrasound Guided Aspiration of cyst/mass LEFT BREAST (up to 3 sites)",
            instruction: "Specify number of sites:",
            middleChildAnswer: [
              {
                content: "First Site",
                // isOtherText: true,
              },
              {
                content: "Second Site",
                // isOtherText: true,
              },
              {
                content: "Third Site",
                // isOtherText: true,
              },
            ],
            cpt: "10005 (first lesion), 10006 (second lesion), 10006 (third lesion); pathology assessment per specimen; 88173 +/- 88172",
          },
          {
            content: "Other (specify): ",
            isPA: true,
            isOtherText: true,
          },
        ],
        isMultiAnswers: true,
      },
    ],
    isMultiAnswers: true,
    preQuestionId: [1],
  },
  {
    _id: 6,
    question: "Is patient age 30 or older?",
    answers: [
      {
        content: "Yes, patient is 30 years of age or older",
        nextQuestionId: [10],
      },
      {
        content: "No, patient is younger than 30 years of age",
        nextQuestionId: [11],
      },
    ],
    preQuestionId: [1],
  },

  {
    _id: 7,
    question:
      "Select the appropriate imaging study and/or procedure based on radiologist's recommendations and/or clinical indications",
    instruction: "(Multiple selections permitted)",
    answers: [
      {
        content: "Mammography (Diagnostic)",
        instruction: "Select one of the following:",
        childrenAnswer: [
          {
            content: "RIGHT BREAST in 1 year",
            cpt: "77065",
          },
          {
            content: "LEFT BREAST in 1 year",
            cpt: "77065",
          },
          {
            content: "BILATERAL BREASTS in 1 year",
            cpt: "77065",
          },
        ],
      },
      {
        content: "Ultrasound (Diagnostic)",
        instruction: "Select one of the following:",
        childrenAnswer: [
          {
            content: "Limited (Targeted area)",
            instruction: "Select one of the follow:",
            grandChildAnswer: [
              "RIGHT BREAST, to area(s) of interest, including axilla, if performed, in 1 year",
              "LEFT BREAST, to area(s) of interest, including axilla, if performed, in 1 year",
              "BILATERAL BREAST, to area(s) of interest, including axilla, if performed, in 1 year",
            ],
            isMultiAnswers: false,
            cpt: "76641",
          },
          {
            content: "Complete (Whole Breast)",
            instruction: "Select one of the follow:",
            grandChildAnswer: [
              "RIGHT BREAST, including axilla, if performed, in 1 year",
              "LEFT BREAST, including axilla, if performed, in 1 year",
              "BILATERAL BREAST, including axilla, if performed, in 1 year",
            ],
            isMultiAnswers: false,
            cpt: "76642",
          },
        ],
      },
      {
        content: "Contrast-enhanced MRI",
        instruction: "Once per year if any of the following criterias are met:",
        criteria: [
          "calculated lifetime risk of breast cancer >/= 20%;",
          "pathogenic breast cancer mutation;",
          "h/o mantle radiation for Hodgkin disease;",
          "personal history of breast cancer and dense breasts;",
          "personal history of breast cancer under age 50;",
          "recent h/o bloody nipple discharge;",
          "follow-up of abnormal breast MRI performed > 3 month ago.",
        ],
        isCriteria: true,
        cpt: "77049",
      },
      {
        content: "Other (specify): ",
        isPA: true,
        isOtherText: true,
      },
    ],
    isMultiAnswers: true,
    preQuestionId: [3],
  },

  {
    _id: 8,
    question:
      "Select the appropriate screening study based on radiologist's recommendations and/or screening guidlines",
    instruction: "(Multiple selections permitted)",
    answers: [
      {
        content: "Bilateral Screening mammogram in 1 year",
        cpt: "77063 or 77067",
      },
      {
        content: "Bilateral Screening ultrasound in 1 year",
        instruction: "Any of the following criteria must be met:",
        criteria: [
          "ultrasound-visible finding is present",
          "mammographically dense breast",
          "elevated cancer risk",
        ],
        isCriteria: true,
        cpt: "76641",
      },
      {
        content: "Contrast-enhanced MRI",
        instruction: "Once per year if any of the following criterias are met:",
        criteria: [
          "calculated lifetime risk of breast cancer >/= 20%;",
          "pathogenic breast cancer mutation;",
          "h/o mantle radiation for Hodgkin disease;",
          "personal history of breast cancer and dense breasts;",
          "personal history of breast cancer under age 50;",
          "recent h/o bloody nipple discharge;",
          "ollow-up of abnormal breast MRI performed > 3 month ago.",
        ],
        isCriteria: true,
        cpt: "77049",
      },
      {
        content: "Other (specify): ",
        isPA: true,
        isOtherText: true,
      },
    ],
    isMultiAnswers: true,
    preQuestionId: [3],
  },
  {
    _id: 9,
    question:
      "Select the appropriate imaging study and/or procedure based on radiologist's recommendations and/or clinical indications",
    instruction: "(Multiple selections permitted)",
    answers: [
      {
        content: "Mammography (Diagnostic)",
        instruction: "Select one of the following:",

        childrenAnswer: [
          {
            content: "RIGHT BREAST in 3-6 months",
            cpt: "77065",
          },
          {
            content: "LEFT BREAST in 3-6 months",
            cpt: "77065",
          },
          {
            content: "BILATERAL BREAST in 3-6 months",
            cpt: "77065",
          },
        ],
      },
      {
        content: "Ultrasound (Diagnostic)",
        instruction: "Select one of the following:",
        childrenAnswer: [
          {
            content: "Limited (Targeted area)",
            instruction: "Select one of the following:",
            grandChildAnswer: [
              "RIGHT BREAST, to area(s) of interest, including axilla, if performed, in 3-6 months",
              "LEFT BREAST, to area(s) of interest, including axilla, if performed, in 3-6 months",
              "BILATERAL BREAST, to area(s) of interest, including axilla, if performed, in 3-6 months",
            ],
            isMultiAnswers: false,
            cpt: "76641",
          },
          {
            content: "Complete (Whole Breast)",
            instruction: "Select one of the following:",
            grandChildAnswer: [
              "RIGHT BREAST, including axilla, if performed, in 3-6 months",
              "LEFT BREAST, including axilla, if performed, in 3-6 months",
              "BILATERAL BREAST, including axilla, if performed, in 3-6 months",
            ],
            isMultiAnswers: false,
            cpt: "76642",
          },
        ],
      },
      {
        content: "Contrast-enhanced MRI",
        instruction: "Once per year if any of the following criterias are met:",
        criteria: [
          "calculated lifetime risk of breast cancer >/= 20%;",
          "pathogenic breast cancer mutation;",
          "h/o mantle radiation for Hodgkin disease;",
          "personal history of breast cancer and dense breasts;",
          "personal history of breast cancer under age 50;",
          "recent h/o bloody nipple discharge;",
          "ollow-up of abnormal breast MRI performed > 3 month ago.",
        ],
        isCriteria: true,
        cpt: "77049",
      },
      {
        content:
          "Non-contrast breast MRI for evaluation of breast implant rupture",
        cpt: "77046 Unilateral or 77048 Bilateral",
      },
      {
        content: "Contrast-enhanced MRI-BILATERAL BREASTS (in 3-6 months)",
        instruction: "One of the following criteria must be met:",
        criteria: ["inconclusive mammoraph and ultrasound imaging"],
        isPA: true,
        isCriteria: true,
        cpt: "77049",
      },
      {
        content: "Other (specify) :",
        isPA: true,
        isOtherText: true,
      },
      {
        content: "Procedure(s)",
        instruction:
          "Select one or more of the following based on radiologist's recommendations and/or clinical indication(s):",
        childrenAnswer: [
          {
            content:
              "Ultrasound Guided Core biopsy RIGHT BREAST and/or axilla (up to 2 sites), specimen imaging, with marker placement, when performed,  and post-biopsy Diagnostic Mammogram",
            instruction: "Specify number of sites:",
            middleChildAnswer: [
              {
                content: "First Site",
                // isOtherText: true,
              },
              {
                content: "Second Site",
                // isOtherText: true,
              },
              {
                content: "Third Site",
                // isOtherText: true,
              },
            ],
            cpt: "19083 (first lesion), 19084 (second lesion), 77065; pathology  assessment per specimen: 88305 plus, if malignant: 88361 or 88342 +/- 88360",
          },
          {
            content:
              "Ultrasound Guided Core biopsy LEFT BREAST and/or axilla (up to 2 sites), specimen imaging, with marker placement, when performed, and post-biopsy Diagnostic Mammogram",
            instruction: "Specify number of sites:",
            middleChildAnswer: [
              {
                content: "First Site",
                // isOtherText: true,
              },
              {
                content: "Second Site",
                // isOtherText: true,
              },
              {
                content: "Third Site",
                // isOtherText: true,
              },
            ],
            cpt: "19083 (first lesion), 19084 (second lesion), 77065; pathology  assessment per specimen: 88305 plus, if malignant: 88361 or 88342 +/- 88360",
          },
          {
            content: "Ultrasound Guided Aspiration of cyst/mass RIGHT BREAST",
            instruction: "Specify number of sites:",
            middleChildAnswer: [
              {
                content: "First Site",
                // isOtherText: true,
              },
              {
                content: "Second Site",
                // isOtherText: true,
              },
              {
                content: "Third Site",
                // isOtherText: true,
              },
            ],
            cpt: "10005 (first lesion), 10006 (second lesion), 10006 (third lesion); pathology assessment per specimen; 88173 +/- 88172",
          },
          {
            content: "Ultrasound Guided Aspiration of cyst/mass LEFT BREAST",
            instruction: "Specify number of sites:",
            middleChildAnswer: [
              {
                content: "First Site",
                // isOtherText: true,
              },
              {
                content: "Second Site",
                // isOtherText: true,
              },
              {
                content: "Third Site",
                // isOtherText: true,
              },
            ],
            cpt: "10005 (first lesion), 10006 (second lesion), 10006 (third lesion); pathology assessment per specimen; 88173 +/- 88172",
          },
          {
            content: "Other (specify): ",
            isPA: true,
            isOtherText: true,
          },
        ],
        isMultiAnswers: true,
      },
    ],
    isMultiAnswers: true,
    preQuestionId: [3],
  },
  {
    _id: 10,
    question:
      "Select the appropriate imaging study and/or procedure based on radiologist's recommendations and/or clinical indications",
    instruction: "(Multiple selections permitted)",
    answers: [
      {
        content: "Mammography (Diagnostic)",
        instruction: "Select one of the following:",
        childrenAnswer: [
          {
            content: "RIGHT BREAST",
            cpt: "77065",
          },
          {
            content: "LEFT BREAST",
            cpt: "77065",
          },
          {
            content: "BILATERAL BREAST",
            cpt: "77065",
          },
        ],
      },
      {
        content: "Ultrasound (Diagnostic)",
        instruction: "Select one of the following:",
        childrenAnswer: [
          {
            content: "Limited (Targeted area)",
            instruction: "Select one of the following:",
            grandChildAnswer: [
              "RIGHT BREAST, to area(s) of interest, including axilla, if performed",
              "LEFT BREAST, to area(s) of interest, including axilla, if performed",
              "BILATERAL BREAST, to area(s) of interest, including axilla, if performed",
            ],
            isMultiAnswers: false,
            cpt: "76641",
          },
          {
            content: "Complete (Whole Breast)",
            instruction: "Select one of the following:",
            grandChildAnswer: [
              "RIGHT BREAST, including axilla, if performed",
              "LEFT BREAST, including axilla, if performed",
              "BILATERAL BREAST, including axilla, if performed",
            ],
            isMultiAnswers: false,
            cpt: "76642",
          },
        ],
      },
      {
        content: "Contrast-enhanced MRI",
        instruction:
          "Once per 6 month period if any of the following criterias are met:",
        criteria: [
          "calculated lifetime risk of breast cancer >/= 20%;",
          "pathogenic breast cancer mutation;",
          "h/o mantle radiation for Hodgkin disease;",
          "personal history of breast cancer and dense breasts;",
          "personal history of breast cancer under age 50;",
          "follow-up of abnormal breast MRI performed > 3 month ago;",
          "inconclusive mammography and ultrasound",
        ],
        isCriteria: true,
        cpt: "77049",
      },
      {
        content: "Other (specify): ",
        isPA: true,
        isOtherText: true,
      },
    ],
    isMultiAnswers: true,
    preQuestionId: [6],
  },
  {
    _id: 11,
    question:
      "Select the appropriate imaging study and/or procedure based on radiologist's recommendations and/or clinical indications",
    instruction: "(Multiple selections permitted)",
    answers: [
      {
        content: "Mammography (Diagnostic)",
        instruction: "Select one of the following:",
        childrenAnswer: [
          {
            content: "RIGHT BREAST",
            cpt: "77065",
          },
          {
            content: "LEFT BREAST",
            cpt: "77065",
          },
          {
            content: "BILATERAL BREAST",
            cpt: "77065",
          },
        ],
      },
      {
        content: "Ultrasound (Diagnostic)",
        instruction: "Select one of the following:",
        childrenAnswer: [
          {
            content: "Limited (Targeted area)",
            instruction: "Select one of the following:",
            grandChildAnswer: [
              "RIGHT BREAST, to area(s) of interest, including axilla, if performed",
              "LEFT BREAST, to area(s) of interest, including axilla, if performed",
              "BILATERAL BREAST, to area(s) of interest, including axilla, if performed",
            ],
            isMultiAnswers: false,
            cpt: "76641",
          },
          {
            content: "Complete (Whole Breast)",
            instruction: "Select one of the following:",
            grandChildAnswer: [
              "RIGHT BREAST, including axilla, if performed",
              "LEFT BREAST, including axilla, if performed",
              "BILATERAL BREAST, including axilla, if performed",
            ],
            isMultiAnswers: false,
            cpt: "76642",
          },
        ],
      },
      {
        content: "Contrast-enhanced MRI",
        instruction:
          "Once per 6 month period if any of the following criterias are met:",
        criteria: [
          "calculated lifetime risk of breast cancer >/= 20%;",
          "pathogenic breast cancer mutation;",
          "h/o mantle radiation for Hodgkin disease;",
          "personal history of breast cancer and dense breasts;",
          "personal history of breast cancer under age 50;",
          "follow-up of abnormal breast MRI performed > 3 month ago;",
          "inconclusive mammography and ultrasound",
        ],
        isCriteria: true,
        cpt: "77049",
      },
      {
        content: "Other (specify): ",
        isPA: true,
        isOtherText: true,
      },
    ],
    isMultiAnswers: true,
    preQuestionId: [6],
  },
];
