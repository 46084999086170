import React, { useContext } from "react";
import { Spin } from "antd";
import { Parser } from "json2csv";
import { useQuery } from "react-query";
import { Button, Link } from "@mui/material";
import { LoadingOutlined } from "@ant-design/icons";

import Title from "./Title";
import { OrderAPI } from "../../API/order-API";
import Header from "../../components/layouts/Header";
import { UserContext } from "../../context/login/Context";
import ReactTable from "../../components/forms/ReactTable";

const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

const columns = [
  {
    Header: "Patient ID",
    accessor: "patientId",
    Cell: (props: any) => (
      <Link
        href={`/patient-board/orders/${props.row.original.patientRecordId}`}
      >
        {props.value}
      </Link>
    ),
  },
  {
    Header: "Order #",
    accessor: "orderNumber",
    Cell: (props: any) => (
      <Link
        href={`/patient-board/orderDetail/${props.row.original.orderRecordId}`}
      >
        {props.value}
      </Link>
    ),
  },
  {
    Header: "Order Type",
    accessor: "orderType",
  },
  {
    Header: "Order Status",
    accessor: "orderStatus",
  },
  {
    Header: "Order Created",
    accessor: "createdAt",
  },
  {
    Header: "Order Open (Days)",
    accessor: "daysOrderOpen",
  },
  {
    Header: "Date Order Sent",
    accessor: "orderSentDate",
  },
  {
    Header: "1st Day In AuthEx",
    accessor: "day1InAuthEx",
  },
  {
    Header: "Indication",
    accessor: "indication",
  },
  {
    Header: "MRN",
    accessor: "mrn",
  },
  {
    Header: "Patient Created",
    accessor: "patientCreatedDate",
  },
  {
    Header: "Appointment Requested",
    accessor: "appointmentRequestedDate",
  },
  {
    Header: "Request Confirmed",
    accessor: "requestConfirmed",
  },
  {
    Header: "Appointment Date",
    accessor: "appointmentDate",
  },
  {
    Header: "Appointment Completed",
    accessor: "appointmentCompletedDate",
  },
  {
    Header: "Results To Provider",
    accessor: "resultsToProvider",
  },
  {
    Header: "Appointment Result",
    accessor: "appointmentResult",
  },
  {
    Header: "Results To Patient",
    accessor: "resultsToPatient",
  },
];

const WeeklyReport = () => {
  const { getCurrentClient } = useContext(UserContext);
  const currentClient = getCurrentClient();

  const { data: reports = [], isLoading } = useQuery(
    ["weekly-report"],
    () => OrderAPI.getWeeklyReport(currentClient),
    {
      enabled: !!currentClient,
      select: (resp) => resp.data,
    }
  );

  const downloadCSV = () => {
    // Remove patientRecordId and orderRecordId which are only used for navigation purposes
    const ignoredColumns = ["patientRecordId", "orderRecordId"];
    const fields = Object.keys(reports?.[0]).filter(
      (column) => !ignoredColumns.includes(column)
    );
    const parser = new Parser({ fields });
    const csvData = parser.parse(reports);

    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "weekly-report");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <Header />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "30px",
          marginBottom: "30px",
        }}
      >
        <div
          style={{
            maxWidth: "95%",
            overflow: "scroll",
            backgroundColor: "#ffffff",
            padding: "10px",
            boxShadow: "-2px 1px 3px 0px #d2d2d2",
          }}
        >
          <Title>Weekly Report</Title>
          <i>All dates shown are in pacific timezone</i>
          <div style={{ marginTop: "1rem" }}>
            <Button
              variant="contained"
              disabled={!reports.length}
              onClick={downloadCSV}
            >
              Download CSV
            </Button>
          </div>
          <ReactTable columns={columns} data={reports} />
          {isLoading && (
            <Spin
              size="large"
              indicator={antIcon}
              className={"loading-spin-btn active"}
              style={{ display: "flex", justifyContent: "center" }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default WeeklyReport;
