import React from "react";

interface IProps {
  answer: any;
}

const QuizSideBar: React.FC<IProps> = (props) => {
  return (
    <div className={"quiz-view"}>
      <div color="black" style={{ opacity: 1, fontSize: "14px" }}>
        {props.answer}
      </div>
    </div>
  );
};

export default QuizSideBar;
