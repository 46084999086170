import { Form, Input } from "antd";
import React from "react";
import { PanelContext } from "../../context/panel-context/PanelContext";

const InputTextQuiz = (props: any) => {
  const { setFieldSelected, getFieldByName } = React.useContext(PanelContext);

  const onClick = (e: any) => {
    setFieldSelected({
      name: props.name,
      field: getFieldByName(props.name),
    });
  };

  return (
    <Form.Item
      name="name"
      label
      rules={[
        {
          required: props.rq,
          message: `Please don't leave it blank !`,
        },
      ]}
      onClick={onClick}
      {...props}
      className="inputquiz"
    >
      <Input
        style={{ float: "right" }}
        className={props.className ? props.className : ""}
      />
    </Form.Item>
  );
};

export default InputTextQuiz;
