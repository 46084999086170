import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tab,
  Tabs,
} from "@mui/material";
import moment from "moment";
import "moment-timezone";
import Title from "./Title";
import { Spin, Form, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { AlertAPI } from "../../API/alert-API";
import { AlertLogAPI } from "../../API/alertLog-API";
import { UserContext } from "../../context/login/Context";

interface Props {
  alert: any;
  close(): void;
}

const LogTable: React.FC<Props> = ({ alert, close }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [alertLogs, setAlertLogs] = useState([{}] as any);
  const [newAlert, setNewAlert] = useState(alert);
  const [detail, setDetail] = useState(true);
  const [log, setLogs] = useState(false);
  const [value, setValue] = useState(0);
  const [resolveNote, setResolveNote] = useState("");
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const { getCurrentClient } = useContext(UserContext);

  let currentClient = getCurrentClient();
  const [form] = Form.useForm();

  useEffect(() => {
    async function getData() {
      await AlertLogAPI.fetchAlertLogsByAlertId(alert._id, currentClient).then(
        (res) => {
          setAlertLogs(res.data.alertLogs);
        }
      );
      setIsLoading(false);
    }
    getData();
  }, []);

  const handleResolve = async () => {
    const body = {
      ...alert,
      isActive: false,
      alertResolvedDate: Date.now(),
      alertResolvedNotes: resolveNote,
    };
    setNewAlert(body);
    await AlertAPI.updateAlert(
      {
        data: body,
      },
      alert._id
    )
      .then(async (res) => {
        const body = {
          logText: resolveNote,
          clientId: currentClient,
          patientId: alert.patientId,
          eventType: "Resolution",
          alertId: alert._id,
        };
        await AlertLogAPI.newAlertLog({ data: body }).then((res) => {});
        await AlertLogAPI.fetchAlertLogsByAlertId(
          newAlert._id,
          currentClient
        ).then((res) => {
          setAlertLogs(res.data.alertLogs);
        });
        message.success("Alert has been resolved!");
      })
      .catch(() => {
        message.error("Alert not resolved!");
      });
  };

  const sortedAlertLogs = alertLogs
    .sort((a: any, b: any) => {
      return a.createdAt - b.createdAt;
    })
    .reverse();

  return (
    <div>
      <Typography variant={"h5"}>{newAlert.alertType}</Typography>
      <Box sx={{ width: "100%" }}>
        <Tabs
          style={{ marginBottom: "30px" }}
          onChange={handleChange}
          value={value}
          selectionFollowsFocus
        >
          <Tab
            label="Alert Detail"
            onClick={() => {
              setDetail(true);
              setLogs(false);
            }}
          />
          <Tab
            label="Log"
            onClick={() => {
              setDetail(false);
              setLogs(true);
            }}
          />
        </Tabs>
      </Box>
      {detail && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {newAlert.orderId && (
                <Typography variant="body1">
                  <span style={{ fontWeight: "bold", fontSize: "inherit" }}>
                    Order ID:
                  </span>{" "}
                  {!window.location.pathname.includes("/orderDetail") ? (
                    <a
                      href={`/patient-board/orderDetail/${newAlert.orderId}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {newAlert.orderNumber}
                    </a>
                  ) : (
                    newAlert.orderNumber
                  )}
                </Typography>
              )}
              <Typography variant="body1">
                <span style={{ fontWeight: "bold", fontSize: "inherit" }}>
                  Date Created:
                </span>{" "}
                {moment(newAlert.createdAt)
                  .tz("America/Los_Angeles")
                  .format("MM/DD/YYYY, h:mma")}
              </Typography>
              {newAlert.isActive === false && (
                <Typography variant="body1">
                  <span style={{ fontWeight: "bold", fontSize: "inherit" }}>
                    Resolved Date:
                  </span>{" "}
                  {moment(newAlert.alertResolvedDate)
                    .tz("America/Los_Angeles")
                    .format("MM/DD/YYYY, h:mma")}
                </Typography>
              )}
              <br />

              <Typography variant="body1">
                {/* <span style={{ fontWeight: "bold", fontSize: "inherit" }}>
                  Summary:
                </span>{" "} */}
                {newAlert.alertSummary}
              </Typography>
              <Typography variant="body1">
                {/* <span style={{ fontWeight: "bold", fontSize: "inherit" }}>
                  Alert Text:
                </span>{" "} */}
                {/* <br /> */}
                {newAlert.alertText}
              </Typography>
            </div>
          </div>

          <div style={{ marginTop: "30px" }}>
            {newAlert.isActive === true && (
              <Form
                form={form}
                onFinish={handleResolve}
                style={{ width: "100%" }}
              >
                <FormControl
                  style={{
                    width: "70%",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Form.Item
                    name="thisField"
                    style={{ width: "80%", marginBottom: "0px" }}
                  >
                    <TextField
                      className="ant-input"
                      required
                      label="Enter resolution notes"
                      autoComplete="off"
                      onChange={(e: any) => setResolveNote(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      width: "20%",
                      margin: "0px",
                    }}
                  >
                    <Button
                      style={{
                        width: "100%",
                        marginTop: "0px",
                        marginLeft: "20px",
                        backgroundColor: "#7ed957",
                      }}
                      // type="primary"
                      className="accept-form-button"
                      type="submit"
                      variant="contained"
                    >
                      Resolve
                    </Button>
                  </Form.Item>
                </FormControl>
              </Form>
            )}
          </div>
        </div>
      )}
      {log && (
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell>
                <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                  Patient ID
                </span>
              </TableCell>
              <TableCell align="right">
                <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                  Type
                </span>
              </TableCell>
              <TableCell align="right">
                <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                  Date Created
                </span>
              </TableCell>
              <TableCell align="right">
                <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                  Text
                </span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedAlertLogs.map((alertLog: any) => (
              <TableRow key={alertLog._id}>
                <TableCell>{alertLog.patientId}</TableCell>
                <TableCell align="right">{alertLog.eventType}</TableCell>
                <TableCell align="right">
                  {moment(alertLog.createdAt)
                    .tz("America/Los_Angeles")
                    .format("MM/DD/YYYY, h:mma")}
                </TableCell>
                <TableCell align="right">{alertLog.logText}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  );
};

export default LogTable;
