import React, { useMemo, useState, useEffect, useContext } from "react";
import OauthPopup from "react-oauth-popup";
import useDrivePicker from "react-google-drive-picker";
import ReactTable from "../../components/forms/ReactTable";
import { UserContext } from "../../context/login/Context";
import moment from "moment";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { Link, Typography, FormControl, Popover } from "@mui/material";
import { LoadingOutlined } from "@ant-design/icons";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { faGoogleDrive } from "@fortawesome/free-brands-svg-icons";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FileAPI } from "../../API/file-API";
import { PatientAPI } from "../../API/patient-API";
import { Button, Form, Spin, message } from "antd";
import { getUserName } from "../../constants/utilities";
import Title from "./Title";

interface Props {}

const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

const fetchPatientFiles = (patientId: string) => {
  return FileAPI.fetchFilesByPatient(patientId).then((res: any) => res?.data);
};

const {
  REACT_APP_GOOGLE_KEY: developerKey,
  REACT_APP_GOOGLE_OAUTH: clientId,
  REACT_APP_GOOGLE_SCOPE: scope,
}: any = process.env;

const DISCOVERY_DOCS = [
  "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
];

const Documents: React.FC<Props> = ({}) => {
  const [openPicker, authResponse] = useDrivePicker();
  const [googleAuthLink, setGoogleAuthLink] = useState("");
  const { id: patientId }: { id: string } = useParams();
  const [form] = Form.useForm();
  const { profile, getCurrentClient } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFile, setSelectedFile] = useState({} as any);
  const [fileName, setFileName] = useState("Select Document:");
  const [patient, setPatient] = useState([{}] as any);
  const [newAuthor, setNewAuthor] = useState(profile?.fullName);

  let currentClient = getCurrentClient();

  const onCode = (code: any, params: any) => {
    FileAPI.getAccessToken({ code: code }).then((res: any) =>
      handleOpenPicker(res.data.data.access_token, code)
    );
  };

  const onClose = () => console.log("closed!");

  const handleOpenPicker = (token: string, code: string) => {
    openPicker({
      clientId: clientId,
      developerKey: developerKey,
      viewId: "DOCS",
      token: token, // pass oauth token in case you already have one
      showUploadView: false,
      showUploadFolders: false,
      supportDrives: true,
      multiselect: true,
      viewMimeTypes:
        "application/vnd.google-apps.document, application/vnd.google-apps.photo",
      callbackFunction: async (data) => {
        if (data.action === "cancel") {
          console.log("User clicked cancel/close button");
        } else if (data.action === "picked") {
          setFileName(data.docs[0].name);

          setSelectedFile({
            name: data.docs[0].name,
            google: {
              token,
              id: data.docs[0].id,
              mimeType: data.docs[0].mimeType,
            },
          });
        }
      },
    });
  };

  const { isLoading, data, refetch } = useQuery(
    ["fetch-patient-files", patientId],
    () => fetchPatientFiles(patientId),
    { enabled: false, refetchOnWindowFocus: false }
  );

  useEffect(() => {
    FileAPI.getTokenUrl().then((res: any) => {
      setGoogleAuthLink(res.data.data);
    });
  }, []);

  useEffect(() => {
    PatientAPI.getPatient(patientId, currentClient).then((res) => {
      const resPatient = res.data.patient;
      setPatient({ ...resPatient });
    });
    refetch();
  }, [patientId, refetch]);

  useEffect(() => {
    if (!newAuthor && profile?.fullName) {
      setNewAuthor(profile?.fullName);
    }
    if (!newAuthor && !profile?.fullName) {
      getUserName().then((res: any) => setNewAuthor(res));
    }
  }, [profile?.fullName]);

  const handleClose = () => setAnchorEl(null);
  const handleClick = (e: any) => setAnchorEl(e.currentTarget);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const documents = data?.files.map((data: any, index: number) => {
    return {
      name: data.fileName,
      createdAt: new Date(data.createdAt ?? ""),
      user: data.author,
      type: data.fileType,
      key: index,
      view: <Link onClick={() => getFileLink(data.s3DocumentURL)}>View</Link>,
      delete: data.userId === profile?._id && (
        <div>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Typography
              style={{ width: "200px", textAlign: "center" }}
              sx={{ p: 2 }}
            >
              Are you sure you want to delete this document?
            </Typography>
            <Button
              className="cancel"
              style={{ width: "50%" }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              className="delete"
              style={{ width: "50%" }}
              onClick={() => deleteDocument(data)}
            >
              Delete
            </Button>
          </Popover>
          <Button
            aria-describedby={id}
            onClick={handleClick}
            // onClick={() => deleteNote(note)}
            style={{
              backgroundColor: "transparent",
              border: "none",
              margin: "0px",
              padding: "0px",
            }}
          >
            <FontAwesomeIcon
              icon={faTimesCircle}
              style={{
                marginLeft: "20px",
                fontSize: "24px",
                fontWeight: 700,
                color: "red",
              }}
            />
          </Button>
        </div>
      ),
    };
  });

  const getFileLink = async (link: any) => {
    FileAPI.getFileLink({
      data: {
        link: link,
      },
    })
      .then((res) => {
        window.open(res.data.link, "_blank");
      })
      .catch(() => {
        message.error("Something went wrong trying to view this document.");
      });
  };

  const uploadDocument = async () => {
    if (selectedFile.length !== 0) {
      const body = {
        google: selectedFile.google,
        name: fileName,
        author: newAuthor,
        patientId: patient.patientId,
        userId: profile?._id,
        type: selectedFile.type,
        base64: selectedFile.base64,
      };
      FileAPI.newFile({ data: body })
        .then((res) => {
          message.success("Document uploaded successfully!");
          form.resetFields();
          setSelectedFile([]);
          refetch();
        })
        .catch((error) => {
          message.error("Document upload failed!", error);
        });
    } else {
      message.error("Please choose a file to upload");
    }
  };

  const deleteDocument = async (document: any) => {
    await FileAPI.deleteFile(document._id)
      .then((res) => {
        message.success("Document deleted successfully!");
        refetch();
      })
      .catch(() => {
        message.error("Deleting document failed!");
      });
    handleClose();
  };

  const changeFileName = (e: any) => setFileName(e.target.value);

  const handleFileCapture = ({ target }: any) => {
    refetch();
    getBase64(target.files[0]).then((base64: any) => {
      setSelectedFile({
        name: target.files[0].name,
        base64,
        type: target.files[0].type,
      });
      setFileName(target.files[0].name);
    });
  };

  const handleUpload = () => {
    document?.getElementById("upload")?.click();
  };

  const columns = useMemo(
    () => [
      {
        Header: "Document Name",
        columnId: 0,
        accessor: "name",
        width: 40,
      },
      {
        Header: "Uploaded Date",
        columnId: 1,
        accessor: "createdAt",
        Cell: (props: any) => (
          <span>{moment(props.value).format("MM/DD/YYYY")}</span>
        ),
        sortType: "datetime",
        width: 40,
      },
      {
        Header: "Document Type",
        columnId: 2,
        accessor: "type",
        width: 40,
      },
      {
        Header: "Uploaded By",
        columnId: 3,
        accessor: "user",
        width: 40,
      },
      {
        columnId: 4,
        accessor: "view",
        width: 40,
      },
      {
        columnId: 5,
        accessor: "delete",
        width: 40,
      },
    ],
    []
  );

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "30px",
      }}
    >
      <div
        style={{
          textAlign: "left",
          width: "90%",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "30px",
          }}
        >
          <Title>
            <span style={{ fontSize: "30px" }}>Patient Documents</span>
          </Title>
          <Link href={`/new-patientOrder`}></Link>
        </div>
        <div>
          <Form form={form} onFinish={uploadDocument} style={{ width: "65%" }}>
            <FormControl
              style={{
                width: "55%",
                display: "flex",
                flexDirection: "column",
                marginBottom: "30px",
              }}
            >
              <legend style={{ color: "#141414" }}>Upload Document</legend>
              <Form.Item
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div className="label" style={{ fontSize: "15px" }}>
                    {fileName}
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p className="subtitle">
                      Supported documents (PDF & PNG/JPG images)
                    </p>
                    <Button
                      style={{
                        fontSize: "14px",
                        borderRadius: "10px",
                        borderColor: "#4276c5",
                        color: "#4276c5",
                      }}
                      onClick={handleUpload}
                    >
                      <FontAwesomeIcon
                        style={{ marginRight: "5px" }}
                        icon={faCloudUploadAlt}
                      />
                      Upload
                      <input
                        accept=".pdf, .png, .jpeg, .jpg"
                        id="upload"
                        type="file"
                        hidden
                        onChange={handleFileCapture}
                      />
                    </Button>
                    {googleAuthLink && (
                      <Button
                        style={{
                          fontSize: "14px",
                          borderRadius: "10px",
                          borderColor: "#4276c5",
                          color: "#4276c5",
                          marginTop: "20px",
                        }}
                      >
                        <OauthPopup
                          url={googleAuthLink}
                          onCode={onCode}
                          title="Google Drive"
                          width={500}
                          height={500}
                          onClose={onClose}
                        >
                          <FontAwesomeIcon
                            style={{ marginRight: "5px" }}
                            icon={faGoogleDrive}
                          />
                          Drive Upload
                        </OauthPopup>
                      </Button>
                    )}
                  </div>
                </div>
              </Form.Item>
              <Form.Item
                name="thisField"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  marginBottom: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <label
                    className="label"
                    style={{ marginRight: "auto", fontSize: "15px" }}
                  >
                    Document Name:
                  </label>
                  <input
                    className="search"
                    required
                    style={{
                      width: "100%",
                      maxWidth: "265px",
                    }}
                    onChange={changeFileName}
                  />
                </div>
              </Form.Item>
              <Form.Item
                style={{
                  width: "100%",
                  margin: "0px",
                }}
              >
                <Button
                  style={{
                    width: "100%",
                    marginTop: "0px",
                  }}
                  type="primary"
                  className="accept-form-button"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </FormControl>
          </Form>
        </div>
        <div
          style={{
            textAlign: "left",
            backgroundColor: "#ffffff",
            padding: "10px",
            boxShadow: "-2px 1px 3px 0px #d2d2d2",
          }}
        >
          <div style={{ width: "100%" }}>
            {isLoading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "80vw",
                  height: "20vh",
                }}
              >
                <Spin
                  size="large"
                  className={`loading-spin-btn ${"active"}`}
                  indicator={antIcon}
                />
              </div>
            )}
            {data?.files && <ReactTable columns={columns} data={documents} />}

            {!isLoading && !data?.files?.length && (
              <div
                style={{
                  textAlign: "center",
                  fontWeight: 500,
                }}
              >
                <Typography variant={"h5"}>
                  There are currently no document for this patient
                </Typography>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

function getBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

function driveBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default Documents;
