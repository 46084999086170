import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";

interface Props {
  columns: any;
  data: Array<object>;
}

interface filterProps {
  preGlobalFilteredRows: any;
  globalFilter: any;
  setGlobalFilter: any;
}

const GlobalFilter: React.FC<filterProps> = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) => {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);

  return (
    <span>
      <input
        className="search"
        value={globalFilter || ""}
        onChange={(e) => setGlobalFilter(e.currentTarget.value || undefined)}
        placeholder={`Search`}
      />
    </span>
  );
};

const ReactTable: React.FC<Props> = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    state: { pageIndex, pageSize, globalFilter },
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    setPageSize,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 100 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // We don't want to render all 2000 rows for this example, so cap
  // it at 20 for this use case
  //   const firstPageRows = rows.slice(0, 100);
  const activePage = pageIndex;
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          className="dashboard-table"
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "20px",
            fontSize: "16px",
          }}
        >
          Viewing {page.length} of {data.length} records.
        </div>
        <div>
          <span>Rows per page:</span>{" "}
          <select
            style={{ border: "none", marginBottom: "20px" }}
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[50, 100, 150, 200].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>
        <div
          className="dashboard-table"
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "20px",
          }}
        >
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </div>
      </div>
      <Table {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{
                    fontSize: "15px",
                    alignContent: "center",
                    width: column.width,
                  }}
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          style={{
                            marginLeft: "10px",
                            fontSize: "10px",
                            fontWeight: 700,
                            color: "black",
                          }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faChevronUp}
                          style={{
                            marginLeft: "10px",
                            fontSize: "10px",
                            fontWeight: 700,
                            color: "black",
                          }}
                        />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {page.map((row: any, i) => {
            prepareRow(row);
            return (
              <TableRow
                {...row.getRowProps()}
                style={{
                  borderColor: "primary.light",
                  "& .MuiDataGrid-cell:hover": {
                    color: "blue",
                  },
                }}
              >
                {row.cells.map((cell: any) => {
                  return (
                    <TableCell {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <br />
      {data.length !== 0 && (
        <div className="Table__pagination">
          <div className="Table__prevPageWrapper">
            <button
              className="Table__pageButton"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              {"Previous"}
            </button>{" "}
          </div>
          <div
            className="Table__visiblePagesWrapper"
            style={{ display: "flex" }}
          >
            {pageOptions.map((pageIndex, index, array) => {
              return (
                <div
                  key={pageIndex}
                  style={{ marginLeft: "2px", marginRight: "2px" }}
                  className={
                    activePage === pageIndex
                      ? "Table__pageButton Table__pageButton--active"
                      : "Table__pageButton"
                  }
                  onClick={() => {
                    gotoPage(pageIndex);
                  }}
                >
                  {pageIndex + 1}
                </div>
              );
            })}
          </div>
          <div className="Table__prevPageWrapper">
            <button
              className="Table__pageButton"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              {"Next"}
            </button>{" "}
          </div>
        </div>
      )}
    </>
  );
};

export default ReactTable;
