import reqAxios from "./reqAxios";

const createPatient = (data: any) => {
  return reqAxios().post("/patients", data);
};

const getPatients = (clientId: string) => {
  return reqAxios().get(`/patients?clientId=${clientId}`);
};

const getActivePatients = (clientId: string) => {
  return reqAxios().get(`/patients/active?clientId=${clientId}`);
};

const editPatient = (data: any, _id: string) => {
  return reqAxios().put(`/patients/${_id}`, data);
};

const getPatient = (_id: string, clientId: string) => {
  return reqAxios().get(`./patients/${_id}?clientId=${clientId}`);
};

const getNextPatientId = (clientId: string) => {
  return reqAxios().get(`./patients/getNextId?clientId=${clientId}`);
};

export const PatientAPI = {
  createPatient,
  getPatient,
  getPatients,
  editPatient,
  getActivePatients,
  getNextPatientId,
};
