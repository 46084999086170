import reqAxios from "./reqAxios";

const fetchList = () => {
  return reqAxios().get("/orders/list");
};

const fetchOrder = (_id: string, clientId: string) => {
  return reqAxios().get(`/orders/${_id}?clientId=${clientId}`);
};

const fetchOrdersByPatient = (_id: string, clientId: string) => {
  return reqAxios().get(`/orders/patient/${_id}?clientId=${clientId}`);
};

const fetchPatientByOrder = (_id: string, clientId: string) => {
  return reqAxios().get(`/orders/patientId/${_id}?clientId=${clientId}`);
};

const fetchOrdersByStatus = (status: string, clientId: string) => {
  return reqAxios().get(`/orders/status/${status}?clientId=${clientId}`);
};

const fetchOrdersByBiopsyResult = (result: string, clientId: string) => {
  return reqAxios().get(
    `/orders/appointmentResult/${result}?clientId=${clientId}`
  );
};

const fetchOrdersByRecommendation = (result: string, clientId: string) => {
  return reqAxios().get(
    `/orders/appointmentRecommendation/${result}?clientId=${clientId}`
  );
};

const newOrder = (data: any) => {
  return reqAxios().post("/orders", data);
};

const editOrder = (data: any, _id: string) => {
  return reqAxios().put(`/orders/${_id}`, data);
};

const getStats = (clientId: string) => {
  return reqAxios().get(`/orders/getStats?clientId=${clientId}`);
};

const updateStatus = (data: any, _id: string) => {
  return reqAxios().put(`/orders/status/${_id}`, data);
};

const cancelOrder = (data: any, _id: string) => {
  return reqAxios().put(`/orders/cancel/${_id}`, data);
};

const getWeeklyReport = (clientId: string) => {
  return reqAxios().get(`/orders/weeklyReport`, { headers: { clientId } });
};

const getFollowUps = (clientId: string) => {
  return reqAxios().get(`/orders/follow-up`, { headers: { clientId } });
};

export const OrderAPI = {
  fetchList,
  newOrder,
  editOrder,
  fetchOrder,
  fetchOrdersByPatient,
  getStats,
  updateStatus,
  cancelOrder,
  fetchOrdersByStatus,
  fetchOrdersByBiopsyResult,
  fetchOrdersByRecommendation,
  fetchPatientByOrder,
  getWeeklyReport,
  getFollowUps,
};
