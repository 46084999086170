import { CheckOutlined, RightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router";

import { PanelContext } from "../../../context/panel-context/PanelContext";

interface IProps {}

const MainTreatment: React.FC<IProps> = (props) => {
  const { setIsDisable, isQuizFinish } = React.useContext(PanelContext);
  const history = useHistory();

  return (
    <>
      <div className="pre-op-orders-referrals-container">
        <div className="pre-op-orders-inner" style={{ marginTop: "60px" }}>
          <Button
            className={
              isQuizFinish[`surgeryleft`] || isQuizFinish[`surgeryright`]
                ? "new-order-btn middle done"
                : "new-order-btn middle"
            }
            onClick={() => (
              setIsDisable(true), history.push(`therapeutic?item=surgery`)
            )}
          >
            <p>
              Surgery
              {isQuizFinish[`surgeryleft`] || isQuizFinish[`surgeryright`] ? (
                <CheckOutlined className="dashboard-item-arrow green" />
              ) : (
                <RightOutlined className="dashboard-item-arrow" />
              )}
            </p>
          </Button>
        </div>
        <div className="pre-op-orders-inner">
          <Button
            className={
              isQuizFinish[`medical`]
                ? "new-order-btn middle done"
                : "new-order-btn middle"
            }
            onClick={() => (
              setIsDisable(true), history.push(`therapeutic?item=medical`)
            )}
          >
            <p>
              Medical Oncology
              {isQuizFinish[`medical`] ? (
                <CheckOutlined className="dashboard-item-arrow green" />
              ) : (
                <RightOutlined className="dashboard-item-arrow" />
              )}
            </p>
          </Button>
        </div>

        <div className="pre-op-orders-inner">
          <Button
            className={
              isQuizFinish[`radiationleft`] || isQuizFinish[`radiationright`]
                ? "new-order-btn middle done"
                : "new-order-btn middle"
            }
            onClick={() => (
              setIsDisable(true), history.push(`therapeutic?item=radiation`)
            )}
          >
            <p>
              Radiation Oncology
              {isQuizFinish[`radiationleft`] ||
              isQuizFinish[`radiationright`] ? (
                <CheckOutlined className="dashboard-item-arrow green" />
              ) : (
                <RightOutlined className="dashboard-item-arrow" />
              )}
            </p>
          </Button>
        </div>
      </div>
    </>
  );
};

export default MainTreatment;
