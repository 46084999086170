import React, { useContext } from "react";
import moment from "moment";
import { Link } from "@mui/material";
import { Spin, Collapse } from "antd";
import { useQuery } from "react-query";
import { LoadingOutlined } from "@ant-design/icons";

import Title from "./Title";
import { OrderAPI } from "../../API/order-API";
import Header from "../../components/layouts/Header";
import { UserContext } from "../../context/login/Context";
import ReactTable from "../../components/forms/ReactTable";

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

const columns = [
  {
    Header: "Patient ID",
    accessor: "_id",
    Cell: (props: any) => (
      <Link
        target="_blank"
        rel="noopener"
        href={`/patient-board/orders/${props.row.original.patient[0]._id}`}
      >
        {props.value}
      </Link>
    ),
  },
  {
    Header: "Order Number",
    accessor: "orderNumber",
    Cell: (props: any) => (
      <Link
        target="_blank"
        rel="noopener"
        href={`/patient-board/orderDetail/${props.row.original.orderId}`}
      >
        {props.value}
      </Link>
    ),
  },
  {
    Header: "First Name",
    accessor: "patient[0].firstName",
  },
  {
    Header: "Last Name",
    accessor: "patient[0].lastName",
  },
  {
    Header: "Appointment Results",
    accessor: "appointmentResults",
  },
  {
    Header: "Further Recommendation",
    accessor: "recommendation",
  },
  {
    Header: "Results Recieved Date",
    accessor: "orderDetail.resultsReceivedDate",
    Cell: ({ value }: any) => moment(value).format("MM/DD/YYYY"),
  },
  {
    Header: "Last Date For Follow up",
    accessor: "finalFollowUpDate",
    Cell: ({ value }: any) => moment(value).format("MM/DD/YYYY"),
  },
];

const FollowUpReport = () => {
  const { getCurrentClient } = useContext(UserContext);
  const currentClient = getCurrentClient();

  const { data: followUps = [], isLoading } = useQuery(
    ["follow-ups"],
    () => OrderAPI.getFollowUps(currentClient),
    {
      enabled: !!currentClient,
      select: (resp) => resp.data,
    }
  );

  return (
    <div>
      <Header />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "30px",
          marginBottom: "30px",
        }}
      >
        <div
          style={{
            minWidth: "95%",
            overflow: "scroll",
            backgroundColor: "#ffffff",
            padding: "10px",
            boxShadow: "-2px 1px 3px 0px #d2d2d2",
          }}
        >
          <Title>Follow Ups</Title>
          <Collapse accordion>
            {Object.entries(followUps).map(([category, followUpOrders]) => (
              <Panel
                key={category}
                header={`${category.toUpperCase()} (${
                  (followUpOrders as object[]).length
                })`}
              >
                <ReactTable
                  columns={columns}
                  data={followUpOrders as object[]}
                />
              </Panel>
            ))}
          </Collapse>
          {isLoading && (
            <Spin
              size="large"
              indicator={antIcon}
              className={"loading-spin-btn active"}
              style={{ display: "flex", justifyContent: "center" }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default FollowUpReport;
