import { Form, Select } from "antd";
import React from "react";
import { PanelContext } from "../../context/panel-context/PanelContext";
const { Option, OptGroup } = Select;

const MultiSelect = (props: any) => {
  const { setFieldSelected, getFieldByName } = React.useContext(PanelContext);

  const onClick = (e: any) => {
    setFieldSelected({
      name: props.name,
      field: getFieldByName(props.name),
    });
  };

  const children: any = [];
  props.options.map((o: any, i: number) =>
    children.push(
      <Option key={i} value={o.value}>
        {o.value}
      </Option>
    )
  );

  return (
    <Form.Item
      name="sex"
      className="input status"
      label="Sex"
      rules={[
        {
          required: props.rq,
          message: "Please select a item",
        },
      ]}
      onClick={onClick}
      {...props}
    >
      <Select
        // onDeselect={() => {
        //   props.deselect();
        // }}
        placeholder="Please select a item"
        mode="tags"
        allowClear
      >
        <OptGroup label="Select all that apply">{children}</OptGroup>
      </Select>
    </Form.Item>
  );
};

export default MultiSelect;
