import React from "react";
import { Button } from "antd";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";

const PA = () => {
  return (
    <>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip">Standard Prior Authorization Required</Tooltip>}
      >
        <Button
          style={{
            backgroundColor: "transparent",
            border: "none",
            margin: "0px",
            marginLeft: "10px",
            marginRight: "10px",
            padding: "0px",
            color: "green",
            textDecoration: "none",
          }}
        >
          <span
            style={{
              textDecorationLine: "underline",
              marginRight: "5px",
              fontWeight: 800,
              //   color: "green",
              //   textDecoration: "none",
            }}
          >
            PA
          </span>
          <FontAwesomeIcon icon={faQuestionCircle} />
        </Button>
      </OverlayTrigger>
    </>
  );
};

export default PA;
