import React, { useEffect } from "react";
import { useParams } from "react-router";
import MainTreatment from "./MainTreatment";
import TreatmentQuiz from "./TreatmentQuiz";
import {
  MEDICAL_ONCOLOGY,
  RADIATION_ONCOLOGY,
  SURGERYLEFT,
  SURGERYRIGHT,
} from "../../../constants/treatment/SelectQuestions";
import { useContext } from "react";
import { PanelContext } from "../../../context/panel-context/PanelContext";
import { useHistory } from "react-router-dom";
import BreastChoose from "./BreastChoose";

interface IProps {}
function DisplayFormItem(props: {
  name: string;
  quiz: any;
  show: boolean;
  Component: any;
  query: string;
}) {
  const { show, Component, name, quiz, query } = props;

  return (
    <div
      style={{
        display: show ? "block" : "none",
      }}
    >
      <Component name={name} quiz={quiz} query={query} />
    </div>
  );
}

function DisplayChooseCard(props: {
  Component: any;
  show: boolean;
  query: string;
}) {
  const { Component, show, query } = props;

  return (
    <div
      style={{
        display: show ? "block" : "none",
      }}
    >
      <Component query={query} />
    </div>
  );
}

const TreatmentPanel: React.FC<IProps> = (props) => {
  const history = useHistory();
  const { setIsDisable } = useContext(PanelContext);
  const query = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (!history.location.search) setIsDisable(false);
    else setIsDisable(true);
  }, [history.location.search]);
  const params: any = useParams();
  return (
    <>
      <div className="treatment-container">
        {/* choosecard render */}
        <DisplayChooseCard
          show={params.isTags === "therapeutic" && !window.location.search}
          query=""
          Component={MainTreatment}
        />
        <DisplayChooseCard
          show={
            params.isTags === "therapeutic" &&
            !query.get("breast") &&
            (query.get("item") === "surgery" ||
              query.get("item") === "radiation")
          }
          query={`item=${query.get("item")}`}
          Component={BreastChoose}
        />

        {/* formitem render */}
        <DisplayFormItem
          show={
            params.isTags === "therapeutic" &&
            query.get("item") === "surgery" &&
            query.get("breast") === "left"
          }
          name="surgeryleft"
          quiz={SURGERYLEFT}
          query={`item=${query.get("item")}`}
          Component={TreatmentQuiz}
        />
        <DisplayFormItem
          show={
            params.isTags === "therapeutic" &&
            query.get("item") === "surgery" &&
            query.get("breast") === "right"
          }
          name="surgeryright"
          quiz={SURGERYRIGHT}
          query={`item=${query.get("item")}`}
          Component={TreatmentQuiz}
        />

        <DisplayFormItem
          show={
            params.isTags === "therapeutic" && query.get("item") === "medical"
          }
          name="medical"
          quiz={MEDICAL_ONCOLOGY}
          query={`item=${query.get("item")}`}
          Component={TreatmentQuiz}
        />

        <DisplayFormItem
          query={`item=${query.get("item")}`}
          show={
            params.isTags === "therapeutic" &&
            query.get("item") === "radiation" &&
            query.get("breast") === "left"
          }
          name="radiationleft"
          quiz={RADIATION_ONCOLOGY}
          Component={TreatmentQuiz}
        />
        <DisplayFormItem
          query={`item=${query.get("item")}`}
          show={
            params.isTags === "therapeutic" &&
            query.get("item") === "radiation" &&
            query.get("breast") === "right"
          }
          name="radiationright"
          quiz={RADIATION_ONCOLOGY}
          Component={TreatmentQuiz}
        />
      </div>
    </>
  );
};

export default TreatmentPanel;
