import React, { useState, useEffect, useContext } from "react";
// import { Helmet } from "react-helmet";
import "moment-timezone";
import moment from "moment";
import Thresholds from "./AlertThreshold";
import { Button, Form, Spin, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useParams } from "react-router";
import { getUserName } from "../../constants/utilities";
import { Link, useHistory } from "react-router-dom";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  Box,
  Tab,
  Tabs,
  Typography,
  Grid,
  Divider,
  TextField,
  MenuItem,
  FormControl,
  List,
  ListItem,
  ListItemText,
  Card,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Popover,
  Tooltip,
  IconButton,
  Toolbar,
  Stepper,
  Switch,
  Step,
  StepLabel,
  StepContent,
} from "@mui/material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import {
  faCheckCircle,
  faQuestionCircle,
  faFolderOpen,
  faTimesCircle,
} from "@fortawesome/free-regular-svg-icons";
import OrderAlerts from "./OrderAlerts";
import Title from "./Title";
import { OrderAPI } from "../../API/order-API";
import { OrderNoteAPI } from "../../API/orderNote-API";
import { UserNameAPI } from "../../API/user-API";
import { UserContext } from "../../context/login/Context";
import { PatientLogAPI } from "../../API/patientLog-API";

export default function OrderDetail() {
  const [activeStep, setActiveStep] = useState(0);
  const isStepFailed = (step: number) => {
    return step === 7;
  };
  const { profile, getCurrentClient } = useContext(UserContext);
  const history = useHistory();
  const param: any = useParams();
  const [dates, setDates] = useState(true);
  const [notes, setNotes] = useState(false);
  const [alerts, setAlerts] = useState(false);
  const [flag, setFlag] = useState(false);
  const [threshold, setThreshold] = useState(false);
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [userName, setUserName] = useState("");
  const [old, setOld] = useState("");
  const [orderDate, setOrderDate] = useState<Date | null>(null);
  const [apptRequestedDate, setApptRequestedDate] = useState<Date | null>(null);
  const [apptReceivedDate, setApptReceivedDate] = useState<Date | null>(null);
  const [patientApptDate, setPatientApptDate] = useState<Date | null>(null);
  const [apptCompletedDate, setApptCompletedDate] = useState<Date | null>(null);
  const [resultsReceivedDate, setResultsReceivedDate] = useState<Date | null>(
    null
  );
  const [patient, setPatient] = useState([{}] as any);
  const [appointmentResults, setAppointmentResults] = useState("");
  const [resultsSentToPatient, setResultsSentToPatient] = useState<Date | null>(
    null
  );
  const [biopsyDate, setBiopsyDate] = useState<Date | null>(null);
  const [surgicalRequestDate, setSurgicalRequestDate] = useState<Date | null>(
    null
  );
  const [officeVisitDate, setOfficeVisitDate] = useState<Date | null>(null);
  const [surgeryDate, setSurgeryDate] = useState<Date | null>(null);
  const [onCologyReferral, setOncologyReferral] = useState();

  const [apptRecommendation, setApptRecommendation] = useState<string[]>([]);
  const [stuckStatus, setStuckStatus] = useState("");
  const [order, setOrder] = useState([{}] as any);
  const [orderNotes, setOrderNotes] = useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;
  const [fullName, setFullName] = useState("");
  let currentClient = getCurrentClient();

  const dateCap = new Date("09/01/2021");

  useEffect(() => {
    if (!fullName && profile?.fullName) {
      setFullName(profile?.fullName);
    }
    if (!fullName && !profile?.fullName) {
      getUserName().then((res: any) => setFullName(res));
    }
  }, [profile?.fullName, fullName]);

  const handleMulti = (event: SelectChangeEvent<typeof apptRecommendation>) => {
    const {
      target: { value },
    } = event;
    setApptRecommendation(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const initialState = {
    orderDate:
      order.orderDetail?.orderSentDate !== undefined &&
      order.orderDetail?.orderSentDate !== null
        ? moment(order.orderDetail?.orderSentDate).format("MM/DD/YYYY")
        : null,
    apptRequestedDate:
      order.orderDetail?.appointmentRequestedDate !== undefined &&
      order.orderDetail?.appointmentRequestedDate !== null
        ? moment(order.orderDetail?.appointmentRequestedDate).format(
            "MM/DD/YYYY"
          )
        : null,
    apptReceivedDate:
      order.orderDetail?.appointmentConfirmationReceivedDate !== undefined &&
      order.orderDetail?.appointmentConfirmationReceivedDate !== null
        ? moment(order.orderDetail?.appointmentConfirmationReceivedDate).format(
            "MM/DD/YYYY"
          )
        : null,
    patientApptDate:
      order.orderDetail?.patientAppointmentDate !== undefined &&
      order.orderDetail?.patientAppointmentDate !== null
        ? moment(order.orderDetail?.patientAppointmentDate).format("MM/DD/YYYY")
        : null,
    apptCompletedDate:
      order.orderDetail?.appointmentCompletedDate !== undefined &&
      order.orderDetail?.appointmentCompletedDate !== null
        ? moment(order.orderDetail?.appointmentCompletedDate).format(
            "MM/DD/YYYY"
          )
        : null,
    resultsReceivedDate:
      order.orderDetail?.resultsReceivedDate !== undefined &&
      order.orderDetail?.resultsReceivedDate !== null
        ? moment(order.orderDetail?.resultsReceivedDate).format("MM/DD/YYYY")
        : null,
    resultsSentToPatient:
      order.orderDetail?.resultsSentToPatient !== undefined &&
      order.orderDetail?.resultsSentToPatient !== null
        ? moment(order.orderDetail?.resultsSentToPatient).format("MM/DD/YYYY")
        : null,
    orderStatus: order.status,
    appointmentResults:
      order.appointmentResults !== undefined &&
      order.appointmentResults !== null
        ? order.appointmentResults
        : "",
    appointmentRecommendation:
      order.appointmentRecommendation !== undefined &&
      order.appointmentRecommendation !== null
        ? order.appointmentRecommendation
        : [],
  };
  let steps = [];
  if (order.type === "Therapeutic") {
    steps = [
      {
        label: "Date of Imaging",
      },
      {
        label: "Biopsy Date",
      },
      {
        label: "Surgical Request Date",
      },
      {
        label: "Office Visit Date",
      },
      {
        label: "Surgery Date",
      },
      {
        label: "Oncology Referral",
      },
      {
        label: "Order Completed",
      },
    ];
  } else {
    steps = [
      {
        label: "Order Sent",
      },
      {
        label: "Appointment Requested",
      },
      {
        label: "Appointment Confirmed",
      },
      {
        label: "Appointment Completed",
      },
      {
        label: "Results Received by PCP",
      },
      {
        label: "Results Received by Patient",
      },
      {
        label: "Order Completed",
      },
    ];
  }

  useEffect(() => {
    OrderAPI.fetchPatientByOrder(param.id, currentClient).then((res) => {
      setPatient(res.data.patient);
      let start = moment().format("YYYY");
      let end = moment(res.data.patient.dob).format("YYYY");
      setOld(Math.abs(parseInt(end) - parseInt(start)).toString());
    });

    OrderAPI.fetchOrder(param.id, currentClient).then((res) => {
      const resOrder = res.data.order;
      setOrder({
        ...resOrder,
      });
      if (
        resOrder.appointmentRecommendation !== undefined &&
        resOrder.appointmentRecommendation !== null
      ) {
        setApptRecommendation([...resOrder.appointmentRecommendation]);
      }
      if (typeof resOrder.appointmentRecommendation === "string") {
        setApptRecommendation([resOrder.appointmentRecommendation]);
      }
      if (
        resOrder.appointmentResults !== undefined &&
        resOrder.appointmentResults !== null
      ) {
        setAppointmentResults(resOrder.appointmentResults);
      }
      setStuckStatus(resOrder.stuckReason);
      UserNameAPI.fetchUserNameById(res.data.order.userId).then((res) => {
        setUserName(res.data.user);
      });

      if (resOrder.orderDetail !== undefined) {
        let {
          orderSentDate,
          biopsyDate,
          surgicalRequestDate,
          officeVisitDate,
          surgeryDate,
          onCologyReferral,
          appointmentRequestedDate,
          appointmentConfirmationReceivedDate,
          patientAppointmentDate,
          appointmentCompletedDate,
          resultsReceivedDate,
          resultsSentToPatient,
        } = resOrder.orderDetail;
        if (orderSentDate !== undefined) {
          setOrderDate(orderSentDate);
          if (orderSentDate !== null) {
            setActiveStep(1);
          }
        }
        if (resOrder.type == "Therapeutic") {
          if (biopsyDate !== undefined) {
            setBiopsyDate(biopsyDate);
            if (biopsyDate !== null) {
              setActiveStep(2);
            }
          }
          if (surgicalRequestDate !== undefined) {
            setSurgicalRequestDate(surgicalRequestDate);
            if (surgicalRequestDate !== null) {
              setActiveStep(3);
            }
          }
          if (officeVisitDate !== undefined) {
            setOfficeVisitDate(officeVisitDate);
            if (officeVisitDate !== null) {
              setActiveStep(4);
            }
          }
          if (surgeryDate !== undefined) {
            setSurgeryDate(surgeryDate);
            if (surgeryDate !== null) {
              setActiveStep(5);
            }
          }

          if (onCologyReferral !== undefined) {
            setOncologyReferral(onCologyReferral);
            if (onCologyReferral !== null) {
              setActiveStep(6);
            }
          }
        } else {
          if (appointmentRequestedDate !== undefined) {
            setApptRequestedDate(appointmentRequestedDate);
            if (appointmentRequestedDate !== null) {
              setActiveStep(2);
            }
          }
          if (appointmentConfirmationReceivedDate !== undefined) {
            setApptReceivedDate(appointmentConfirmationReceivedDate);
          }
          if (patientAppointmentDate !== undefined) {
            setPatientApptDate(patientAppointmentDate);
            if (patientAppointmentDate !== null) {
              setActiveStep(3);
            }
          }
          if (appointmentCompletedDate !== undefined) {
            setApptCompletedDate(appointmentCompletedDate);
            if (appointmentCompletedDate !== null) {
              setActiveStep(4);
            }
          }
          if (resultsReceivedDate !== undefined) {
            setResultsReceivedDate(resultsReceivedDate);
            if (resultsReceivedDate !== null) {
              setActiveStep(5);
            }
          }

          if (resultsSentToPatient !== undefined) {
            setResultsSentToPatient(resultsSentToPatient);
            if (resultsSentToPatient !== null) {
              setActiveStep(7);
            }
          }
        }
      }
    });
    OrderNoteAPI.fetchOrderNotesByOrder(param.id, currentClient).then((res) => {
      setOrderNotes(res.data.orderNotes);
      // setSearch(resPatient.patientId);
    });
    setIsLoading(false);
  }, [param.id]);

  const [newNote, setNewNote] = useState("");
  const [noteType, setNoteType] = useState("");
  const [form] = Form.useForm();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const addNewNote = async () => {
    const body = {
      note: newNote,
      author: fullName,
      patientId: order.patientId,
      clientId: currentClient,
      orderId: order._id,
      userId: profile?._id,
      noteType: noteType,
    };
    await OrderNoteAPI.newOrderNote({
      data: body,
    })
      .then((res) => {
        OrderNoteAPI.fetchOrderNotesByOrder(param.id, currentClient).then(
          (res) => {
            setOrderNotes(res.data.orderNotes);
          }
        );
        setNoteType("");
        setNewNote("");
        message.success("Order log added successfully!");
      })
      .catch(() => {
        message.error("Adding order log failed!");
        // setLoading(false);
      });
    form.resetFields();
  };

  const deleteOrderNote = async (note: any) => {
    await OrderNoteAPI.deleteOrderNote(note._id)
      .then((res) => {
        OrderNoteAPI.fetchOrderNotesByOrder(param.id, currentClient).then(
          (res) => {
            setOrderNotes(res.data.orderNotes);
          }
        );
        message.success("Order log deleted successfully!");
      })
      .catch(() => {
        message.error("Deleting Order log failed!");
        // setLoading(false);
      });
    handleClose();
  };

  const change = (e: any) => {
    setNewNote(e.target.value);
  };

  const submitOrderChange = async () => {
    var body = {
      ...order,
      appointmentRecommendation: apptRecommendation,
      appointmentResults: appointmentResults,
      orderDetail: {
        orderSentDate: orderDate,
        appointmentRequestedDate: apptRequestedDate,
        appointmentConfirmationReceivedDate: apptReceivedDate,
        patientAppointmentDate: patientApptDate,
        appointmentCompletedDate: apptCompletedDate,
        resultsReceivedDate: resultsReceivedDate,
        resultsSentToPatient: resultsSentToPatient,
      },
    };
    if (order.type == "Therapeutic") {
      body.orderDetail = {
        orderSentDate: orderDate,
        biopsyDate: biopsyDate,
        surgicalRequestDate: surgicalRequestDate,
        officeVisitDate: officeVisitDate,
        surgeryDate: surgeryDate,
        onCologyReferral: onCologyReferral == "" ? null : onCologyReferral,
      };
    }

    await OrderAPI.editOrder(
      {
        data: body,
      },
      param.id
    )
      .then(async (res) => {
        if (
          initialState.orderDate !==
          (orderDate !== null ? moment(orderDate).format("MM/DD/YYYY") : null)
        ) {
          const body = {
            note: `Order sent date updated to ${moment(orderDate).format(
              "MM/DD/YYYY"
            )}`,
            author: fullName,
            patientId: order.patientId,
            orderId: order._id,
            clientId: currentClient,
            userId: profile?._id,
            noteType: "Date Updated",
          };
          await OrderNoteAPI.newOrderNote({
            data: body,
          });
        }
        if (
          initialState.apptRequestedDate !==
          (apptRequestedDate !== null
            ? moment(apptRequestedDate).format("MM/DD/YYYY")
            : null)
        ) {
          const body = {
            note: `Appointment request date updated to ${moment(
              apptRequestedDate
            ).format("MM/DD/YYYY")}`,
            author: fullName,
            patientId: order.patientId,
            clientId: currentClient,
            orderId: order._id,
            userId: profile?._id,
            noteType: "Date Updated",
          };
          await OrderNoteAPI.newOrderNote({
            data: body,
          });
        }
        if (
          initialState.apptReceivedDate !==
          (apptReceivedDate !== null
            ? moment(apptReceivedDate).format("MM/DD/YYYY")
            : null)
        ) {
          const body = {
            note: `Appointment received date updated to ${moment(
              apptReceivedDate
            ).format("MM/DD/YYYY")}`,
            author: fullName,
            patientId: order.patientId,
            clientId: currentClient,
            orderId: order._id,
            userId: profile?._id,
            noteType: "Date Updated",
          };
          await OrderNoteAPI.newOrderNote({
            data: body,
          });
        }
        if (
          initialState.patientApptDate !==
          (patientApptDate !== null
            ? moment(patientApptDate).format("MM/DD/YYYY")
            : null)
        ) {
          const body = {
            note: `Patient appointment date updated to ${moment(
              patientApptDate
            ).format("MM/DD/YYYY")}`,
            author: fullName,
            patientId: order.patientId,
            clientId: currentClient,
            orderId: order._id,
            userId: profile?._id,
            noteType: "Date Updated",
          };
          await OrderNoteAPI.newOrderNote({
            data: body,
          });
        }
        if (
          initialState.apptCompletedDate !==
          (apptCompletedDate !== null
            ? moment(apptCompletedDate).format("MM/DD/YYYY")
            : null)
        ) {
          const body = {
            note: `Appointment completed date updated to ${moment(
              apptCompletedDate
            ).format("MM/DD/YYYY")}`,
            author: fullName,
            patientId: order.patientId,
            clientId: currentClient,
            orderId: order._id,
            userId: profile?._id,
            noteType: "Date Updated",
          };
          await OrderNoteAPI.newOrderNote({
            data: body,
          });
        }
        if (
          initialState.resultsReceivedDate !==
          (resultsReceivedDate !== null
            ? moment(resultsReceivedDate).format("MM/DD/YYYY")
            : null)
        ) {
          const body = {
            note: `Results received date updated to ${moment(
              resultsReceivedDate
            ).format("MM/DD/YYYY")}`,
            author: fullName,
            patientId: order.patientId,
            clientId: currentClient,
            orderId: order._id,
            userId: profile?._id,
            noteType: "Date Updated",
          };
          await OrderNoteAPI.newOrderNote({
            data: body,
          });
        }
        if (order.type !== "Therapeutic") {
          if (
            initialState.resultsReceivedDate !==
              (resultsReceivedDate !== null
                ? moment(resultsReceivedDate).format("MM/DD/YYYY")
                : null) ||
            initialState.appointmentResults !== appointmentResults ||
            initialState.appointmentRecommendation.join(", ") !==
              apptRecommendation.join(", ")
          ) {
            const body = {
              logType: "Order results received",
              patientId: order.patientId,
              userId: profile?._id,
              keyDate: resultsReceivedDate,
              clientId: currentClient,
              orderId: order._id,
              orderNumber: order.orderNumber,
              author: fullName,
              comment: `Order results received: ${
                !appointmentResults ? "not yet recorded" : appointmentResults
              }. Results received on ${moment(resultsReceivedDate).format(
                "MM/DD/YYYY"
              )}. The recommendation was: ${
                apptRecommendation.length === 0
                  ? "not yet recorded"
                  : apptRecommendation.join(", ")
              }`,
            };
            await PatientLogAPI.newPatientLog({
              data: body,
            });
          } else {
            if (initialState.appointmentResults !== appointmentResults) {
              const log = {
                logType: "Order results received",
                patientId: order.patientId,
                userId: profile?._id,
                keyDate: resultsReceivedDate,
                clientId: currentClient,
                orderId: order._id,
                orderNumber: order.orderNumber,
                author: fullName,
                comment: `Order results received: ${
                  !appointmentResults ? "not yet recorded" : appointmentResults
                }. Results received on ${moment(resultsReceivedDate).format(
                  "MM/DD/YYYY"
                )}.`,
              };
              await PatientLogAPI.newPatientLog({
                data: log,
              });
            }
          }
        } else {
          if (
            initialState.apptCompletedDate !==
            (apptCompletedDate !== null
              ? moment(apptCompletedDate).format("MM/DD/YYYY")
              : null)
          ) {
            const body = {
              logType: "Therapeutic completed",
              patientId: order.patientId,
              userId: profile?._id,
              keyDate: apptCompletedDate,
              clientId: currentClient,
              orderId: order._id,
              orderNumber: order.orderNumber,
              author: fullName,
              comment: `${moment(apptCompletedDate).format(
                "MM/DD/YYYY"
              )} is the date patient saw Surgeon or Oncologist`,
            };
            await PatientLogAPI.newPatientLog({
              data: body,
            });
          }
        }

        if (
          initialState.resultsSentToPatient !==
          (resultsSentToPatient !== null
            ? moment(resultsSentToPatient).format("MM/DD/YYYY")
            : null)
        ) {
          const body = {
            note: `Results sent to patient date updated to ${moment(
              resultsSentToPatient
            ).format("MM/DD/YYYY")}`,
            author: fullName,
            patientId: order.patientId,
            clientId: currentClient,
            orderId: order._id,
            userId: profile?._id,
            noteType: "Date Updated",
          };
          await OrderNoteAPI.newOrderNote({
            data: body,
          });
        }
        await OrderNoteAPI.fetchOrderNotesByOrder(param.id, currentClient).then(
          (res) => {
            setOrderNotes(res.data.orderNotes);
          }
        );
        message.success("Update successful!");
      })
      .catch(() => {
        message.error("Update failed!");
      });
    await OrderAPI.fetchOrder(param.id, currentClient).then((res) => {
      window.scrollTo({
        top: 0,
      });
      const resOrder = res.data.order;
      setOrder({
        ...resOrder,
      });
      setApptRecommendation([...resOrder.appointmentRecommendation]);
      setStuckStatus("");
      setAppointmentResults(resOrder.appointmentResults);

      if (resOrder.orderDetail !== undefined && resOrder.orderDetail !== null) {
        let {
          orderSentDate,
          biopsyDate,
          surgicalRequestDate,
          officeVisitDate,
          surgeryDate,
          onCologyReferral,
          appointmentRequestedDate,
          appointmentConfirmationReceivedDate,
          patientAppointmentDate,
          appointmentCompletedDate,
        } = resOrder.orderDetail;
        if (orderSentDate !== undefined) {
          setOrderDate(orderSentDate);
          if (orderSentDate !== null) {
            setActiveStep(1);
          }
        }

        if (resOrder.type == "Therapeutic") {
          if (biopsyDate !== undefined) {
            if (biopsyDate !== null) {
              setActiveStep(2);
            }
          }
          if (surgicalRequestDate !== undefined) {
            if (surgicalRequestDate !== null) {
              setActiveStep(3);
            }
          }
          if (officeVisitDate !== undefined) {
            if (officeVisitDate !== null) {
              setActiveStep(4);
            }
          }
          if (surgeryDate !== undefined) {
            if (surgeryDate !== null) {
              setActiveStep(5);
            }
          }
          if (onCologyReferral !== undefined) {
            if (onCologyReferral !== null) {
              setActiveStep(6);
            }
          }
        } else {
          if (appointmentRequestedDate !== undefined) {
            if (appointmentRequestedDate !== null) {
              setActiveStep(2);
            }
          }
          if (patientAppointmentDate !== undefined) {
            if (patientAppointmentDate !== null) {
              setActiveStep(3);
            }
          }
          if (appointmentCompletedDate !== undefined) {
            if (appointmentCompletedDate !== null) {
              setActiveStep(4);
            }
          }
          if (resultsReceivedDate !== undefined) {
            if (resultsReceivedDate !== null) {
              setActiveStep(5);
            }
          }

          if (resultsSentToPatient !== undefined) {
            if (resultsSentToPatient !== null) {
              setActiveStep(7);
            }
          }
        }
      }
    });
  };

  const cancelOrderStatus = async () => {
    const body = {
      // ...order,
      canceledBy: fullName,
      canceledDate: new Date(),
      status: "Canceled",
    };
    await OrderAPI.cancelOrder(
      {
        data: body,
      },
      param.id
    )
      .then(async (res) => {
        const body = {
          note: `Canceled date: ${moment(res.data.canceledDate).format(
            "MM/DD/YYYY"
          )}`,
          author: fullName,
          patientId: order.patientId,
          clientId: currentClient,
          orderId: order?._id,
          userId: profile?._id,
          noteType: "Canceled",
        };
        await OrderNoteAPI.newOrderNote({
          data: body,
        }).then(async () => {
          await OrderNoteAPI.fetchOrderNotesByOrder(
            param.id,
            currentClient
          ).then((res) => {
            setOrderNotes(res.data.orderNotes);
          });
        });
        message.success("Update successful!");
      })
      .catch(() => {
        message.error("Update failed!");
      });
    await OrderAPI.fetchOrder(param.id, currentClient).then((res) => {
      const resOrder = res.data.order;
      setOrder({
        ...resOrder,
      });
    });
  };

  const submitOrderStatusChange = async () => {
    const body = {
      // ...order,
      stuckReason: stuckStatus,
      status: "Obstructed",
    };
    await OrderAPI.updateStatus(
      {
        data: body,
      },
      param.id
    )
      .then(async (res) => {
        if (initialState.orderStatus !== "Obstructed") {
          const body = {
            note: `Obstructed reason: ${stuckStatus}`,
            author: fullName,
            patientId: order.patientId,
            clientId: currentClient,
            orderId: order._id,
            userId: profile?._id,
            noteType: "Obstructed",
          };
          await OrderNoteAPI.newOrderNote({
            data: body,
          }).then(async () => {
            await OrderNoteAPI.fetchOrderNotesByOrder(
              param.id,
              currentClient
            ).then((res) => {
              setOrderNotes(res.data.orderNotes);
            });
          });
        }
        message.success("Update successful!");
      })
      .catch(() => {
        message.error("Update failed!");
      });
    await OrderAPI.fetchOrder(param.id, currentClient).then((res) => {
      const resOrder = res.data.order;
      setOrder({
        ...resOrder,
      });
    });
  };

  const clearStuckStatus = async () => {
    const body = {
      // ...order,
      stuckReason: "",
      status: "In Progress",
    };
    await OrderAPI.updateStatus(
      {
        data: body,
      },
      param.id
    )
      .then(async (res) => {
        if (initialState.orderStatus !== res.data.order.status) {
          const body = {
            note: "Order set to in progress.",
            author: fullName,
            patientId: order.patientId,
            clientId: currentClient,
            orderId: order._id,
            userId: profile?._id,
            noteType: "Status Change",
          };
          await OrderNoteAPI.newOrderNote({
            data: body,
          }).then(async () => {
            await OrderNoteAPI.fetchOrderNotesByOrder(
              param.id,
              currentClient
            ).then((res) => {
              setOrderNotes(res.data.orderNotes);
            });
          });
        }
        message.success("Obstructed status cleared!");
      })
      .catch(() => {
        message.error("Update failed!");
      });
    await OrderAPI.fetchOrder(param.id, currentClient).then((res) => {
      const resOrder = res.data.order;
      setOrder({
        ...resOrder,
      });
      setStuckStatus("");
    });
  };

  const sortedNotes = orderNotes
    .sort((a: any, b: any) => {
      return a.createdAt - b.createdAt;
    })
    .reverse();

  return (
    <>
      {/* <Helmet>
            <title>{`Authex | ${patient.patientId}`}</title>
          </Helmet> */}
      <div className="office-container" style={{ marginBottom: "30px" }}>
        <div
          className="side-nav"
          style={{
            height: "100%",
            width: "240px",
            position: "fixed",
            top: "90px",
            left: "0",
            backgroundColor: "#569aff",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              height: "10%",
              // alignItems: "center",
              justifyContent: "center",
              color: "white",
              fontWeight: 900,
              fontSize: "20px",
              marginLeft: "10px",
            }}
          >
            <Typography variant={"h6"} style={{ color: "white" }}>
              <span style={{ fontWeight: "bold", fontSize: "inherit" }}>
                Patient ID:
              </span>{" "}
              {patient.patientId}
            </Typography>
            <Typography variant={"body2"} style={{ color: "white" }}>
              <span style={{ fontWeight: "bold", fontSize: "inherit" }}>
                MRN:
              </span>{" "}
              {patient.mRN}
            </Typography>
            <Typography variant={"body2"} style={{ color: "white" }}>
              <span style={{ fontWeight: "bold" }}>DOB:</span>{" "}
              {moment(patient.dob).format("MM/DD/YYYY")}{" "}
              <span style={{ fontWeight: "bold" }}>Age:</span>{" "}
              {old !== "NaN" && old !== "0" && old !== undefined && old}
            </Typography>
          </div>
          {/* <Toolbar /> */}
          <Box>
            <List style={{ textAlign: "center" }}>
              <a href={`/patient-board/orders/${patient._id}`}>
                <ListItem
                  button
                  onClick={() => history.goBack()}
                  style={{
                    fontSize: "24px",
                    color: "#ffffff",
                    textAlign: "center",
                  }}
                >
                  <FontAwesomeIcon icon={faFolderOpen} />
                  <ListItemText primary="Back" />
                </ListItem>
              </a>
              <Divider />
            </List>
          </Box>
          <Toolbar />
          <Box
            style={
              order.type == "Therapeutic"
                ? { marginLeft: "20px", marginTop: "-50px" }
                : { marginLeft: "20px" }
            }
          >
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel
                  // optional={
                  //   index === 2 ? (
                  //     <Typography variant="caption">Last step</Typography>
                  //   ) : null
                  // }
                  >
                    {step.label}
                  </StepLabel>
                  {/* <Typography>{step.description}</Typography> */}
                  <StepContent>
                    {/* <Box sx={{ mb: 2 }}>
                          <div>
                            <Button
                              variant="contained"
                              onClick={handleNext}
                              sx={{ mt: 1, mr: 1 }}
                            >
                              {index === steps.length - 1 ? "Finish" : "Continue"}
                            </Button>
                            <Button
                              disabled={index === 0}
                              onClick={handleBack}
                              sx={{ mt: 1, mr: 1 }}
                            >
                              Back
                            </Button>
                          </div>
                        </Box> */}
                  </StepContent>
                </Step>
              ))}
              {order.status === "Canceled" ? (
                <Step>
                  <StepLabel error>
                    <span style={{ color: "red" }}>CANCELED</span>
                  </StepLabel>
                </Step>
              ) : (
                order.status === "Obstructed" && (
                  <Step>
                    <StepLabel error>
                      <span style={{ color: "red" }}>OBSTRUCTED</span>
                    </StepLabel>
                  </Step>
                )
              )}
            </Stepper>
          </Box>
        </div>

        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "calc(100vw - 240px)",
            position: "relative",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "30px",
              //   height: "100vh",
            }}
          >
            <div
              style={{
                textAlign: "left",
                width: "90%",
                backgroundColor: "#ffffff",
                padding: "10px",
                boxShadow: "-2px 1px 3px 0px #d2d2d2",
              }}
            >
              <div style={{ textAlign: "left", width: "100%" }}>
                <Title>
                  <span style={{ fontSize: "28px" }}>Order Detail</span>
                </Title>
                <Table style={{ width: "100%" }}>
                  <TableRow style={{ width: "100%" }}>
                    <TableCell style={{ fontSize: "18px", color: "grey" }}>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "20px",
                          color: "black",
                        }}
                      >
                        ID:
                      </span>{" "}
                      {order.orderNumber !== undefined
                        ? order.orderNumber
                        : order._id}
                    </TableCell>
                    <TableCell style={{ fontSize: "18px", color: "grey" }}>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "20px",
                          color: "black",
                        }}
                      >
                        Type:
                      </span>{" "}
                      {order.type}
                    </TableCell>
                    <TableCell style={{ fontSize: "18px", color: "grey" }}>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "20px",
                          color: "black",
                        }}
                      >
                        Status:
                      </span>{" "}
                      {order.status === "Obstructed" ||
                      order.status === "Canceled" ? (
                        <span style={{ color: "red", fontSize: "20px" }}>
                          {order.status}
                        </span>
                      ) : (
                        order.status
                      )}
                    </TableCell>
                    <TableCell style={{ fontSize: "18px", color: "grey" }}>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "20px",
                          color: "black",
                        }}
                      >
                        Created By:
                      </span>{" "}
                      {userName}
                    </TableCell>
                    <TableCell style={{ fontSize: "18px", color: "grey" }}>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "20px",
                          color: "black",
                        }}
                      >
                        Date Created:
                      </span>{" "}
                      {moment(order.createdAt)
                        .tz("America/Los_Angeles")
                        .format("MM/DD/YYYY h:mma")}
                    </TableCell>
                  </TableRow>
                </Table>
                <Box
                  style={{
                    textAlign: "left",
                    display: "flex",
                    // justifyContent: "center",
                    paddingLeft: "10%",
                    paddingRight: "60px",
                  }}
                >
                  {order.detailedSummary && (
                    <Grid style={{ width: "80%" }}>
                      <Title>
                        <span
                          style={{
                            fontSize: "20px",
                            color: "black",
                            fontWeight: "bold",
                          }}
                        >
                          Order Summary
                        </span>
                      </Title>
                      <Typography variant="body1">
                        {order.detailedSummary}
                      </Typography>
                    </Grid>
                  )}
                </Box>
                <Divider style={{ marginTop: "10px", marginBottom: "40px" }} />
                <Box sx={{ width: "100%" }}>
                  <Tabs
                    onChange={handleChange}
                    value={value}
                    selectionFollowsFocus
                  >
                    <Tab
                      label="Dates"
                      onClick={() => {
                        setDates(true);
                        setNotes(false);
                        setAlerts(false);
                        setFlag(false);
                        setThreshold(false);
                      }}
                    />
                    <Tab
                      label="Order Log"
                      onClick={() => {
                        setDates(false);
                        setNotes(true);
                        setAlerts(false);
                        setFlag(false);
                        setThreshold(false);
                      }}
                    />
                    {order.status !== "Obstructed" && (
                      <Tab
                        label="FLAG ORDER AS Obstructed"
                        onClick={() => {
                          setDates(false);
                          setNotes(false);
                          setAlerts(false);
                          setFlag(true);
                          setThreshold(false);
                        }}
                      />
                    )}
                    {order.status === "Obstructed" && (
                      <Tab
                        label="Clear Obstructed status"
                        onClick={() => {
                          setDates(false);
                          setNotes(false);
                          setAlerts(false);
                          setFlag(true);
                          setThreshold(false);
                        }}
                      />
                    )}
                    <Tab
                      label="Alerts"
                      onClick={() => {
                        setDates(false);
                        setNotes(false);
                        setFlag(false);
                        setAlerts(true);
                        setThreshold(false);
                      }}
                    />
                    <Tab
                      label="Alert Thresholds"
                      onClick={() => {
                        setDates(false);
                        setNotes(false);
                        setAlerts(false);
                        setFlag(false);
                        setThreshold(true);
                      }}
                    />
                  </Tabs>
                </Box>
                <Divider style={{ marginBottom: "20px" }} />
                {alerts && <OrderAlerts />}
                {threshold && <Thresholds order={order} />}
                {dates && (
                  <div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingLeft: "10%",
                          paddingRight: "10%",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <div style={{ width: "100%", marginRight: "5px" }}>
                            <Card
                              style={{
                                padding: "5px",
                                marginBottom: "15px",
                                backgroundColor: "#EFEFEF",
                                minHeight: "220px",
                              }}
                            >
                              <Typography variant={"body1"} gutterBottom>
                                {order && order.type == "Therapeutic"
                                  ? "Date of Imaging"
                                  : "Order Sent Date"}{" "}
                                <Tooltip
                                  title={
                                    order && order.type == "Therapeutic"
                                      ? "Order that resulted in Birad 4 or Birad 5 Appointment Date of Diagnostic order"
                                      : "Date the order was sent to AuthEx"
                                  }
                                  arrow
                                  placement="top"
                                >
                                  <IconButton>
                                    <FontAwesomeIcon
                                      style={{
                                        color: "green",
                                        fontSize: "20px",
                                        margin: "0px",
                                      }}
                                      icon={faQuestionCircle}
                                    />
                                  </IconButton>
                                </Tooltip>{" "}
                                {orderDate !== null && (
                                  <IconButton>
                                    <FontAwesomeIcon
                                      style={{
                                        color: "green",
                                        fontSize: "20px",
                                        margin: "0px",
                                      }}
                                      icon={faCheckCircle}
                                    />
                                  </IconButton>
                                )}
                              </Typography>
                              <DatePicker
                                label="Date"
                                value={orderDate}
                                maxDate={new Date()}
                                minDate={dateCap}
                                onChange={(newValue) => {
                                  setOrderDate(newValue);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    fullWidth
                                    className="ant-input"
                                    style={{ marginBottom: "10px" }}
                                    {...params}
                                  />
                                )}
                              />
                            </Card>
                          </div>
                          {order.type == "Therapeutic" ? (
                            <div style={{ width: "100%", marginRight: "5px" }}>
                              <Card
                                style={{
                                  padding: "5px",
                                  marginBottom: "15px",
                                  backgroundColor: "#EFEFEF",
                                  minHeight: "220px",
                                }}
                              >
                                <Typography variant={"body1"} gutterBottom>
                                  Biopsy Date{" "}
                                  <Tooltip
                                    title="This is the Birad 4 or 5 order that resulted in +ve/-ve Appointment Date of Diagnostic order"
                                    arrow
                                    placement="top"
                                  >
                                    <IconButton>
                                      <FontAwesomeIcon
                                        style={{
                                          color: "green",
                                          fontSize: "20px",
                                          margin: "0px",
                                        }}
                                        icon={faQuestionCircle}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                  {biopsyDate !== null && (
                                    <IconButton>
                                      <FontAwesomeIcon
                                        style={{
                                          color: "green",
                                          fontSize: "20px",
                                          margin: "0px",
                                        }}
                                        icon={faCheckCircle}
                                      />
                                    </IconButton>
                                  )}
                                </Typography>
                                <DatePicker
                                  label="Date"
                                  value={biopsyDate}
                                  maxDate={new Date()}
                                  minDate={dateCap}
                                  // inputFormat="mm/dd/yyyy"
                                  onChange={(newValue) => {
                                    setBiopsyDate(newValue);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      fullWidth
                                      className="ant-input"
                                      style={{ marginBottom: "10px" }}
                                      {...params}
                                    />
                                  )}
                                />
                              </Card>
                            </div>
                          ) : (
                            <div style={{ width: "100%", marginRight: "5px" }}>
                              <Card
                                style={{
                                  padding: "5px",
                                  marginBottom: "15px",
                                  backgroundColor: "#EFEFEF",
                                  minHeight: "220px",
                                }}
                              >
                                <Typography variant={"body1"} gutterBottom>
                                  Appointment/Order Requested Date{" "}
                                  <Tooltip
                                    title="Date an appointment/order is requested"
                                    arrow
                                    placement="top"
                                  >
                                    <IconButton>
                                      <FontAwesomeIcon
                                        style={{
                                          color: "green",
                                          fontSize: "20px",
                                          margin: "0px",
                                        }}
                                        icon={faQuestionCircle}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </Typography>
                                <DatePicker
                                  label="Date"
                                  disabled={checkDisabled([orderDate])}
                                  value={apptRequestedDate}
                                  // inputFormat="mm/dd/yyyy"
                                  maxDate={new Date()}
                                  minDate={dateCap}
                                  onChange={(newValue) => {
                                    setApptRequestedDate(newValue);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      fullWidth
                                      className="ant-input"
                                      style={{ marginBottom: "10px" }}
                                      {...params}
                                    />
                                  )}
                                />
                              </Card>
                            </div>
                          )}
                        </div>
                        {order && order.type === "Therapeutic" ? (
                          <>
                            <div style={{ display: "flex" }}>
                              <div
                                style={{ width: "100%", marginRight: "5px" }}
                              >
                                <Card
                                  style={{
                                    padding: "5px",
                                    marginBottom: "15px",
                                    backgroundColor: "#EFEFEF",
                                    minHeight: "220px",
                                  }}
                                >
                                  <Typography variant={"body1"} gutterBottom>
                                    Surgical Request Date{" "}
                                    <Tooltip
                                      title="Date Surgical Request is received"
                                      arrow
                                      placement="top"
                                    >
                                      <IconButton>
                                        <FontAwesomeIcon
                                          style={{
                                            color: "green",
                                            fontSize: "20px",
                                            margin: "0px",
                                          }}
                                          icon={faQuestionCircle}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Typography>
                                  <DatePicker
                                    label="Date"
                                    value={surgicalRequestDate}
                                    minDate={dateCap}
                                    disabled={checkDisabled([biopsyDate])}
                                    onChange={(newValue) => {
                                      setSurgicalRequestDate(newValue);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        fullWidth
                                        className="ant-input"
                                        style={{ marginBottom: "10px" }}
                                        {...params}
                                      />
                                    )}
                                  />
                                </Card>
                              </div>

                              <div
                                style={{ width: "100%", marginRight: "5px" }}
                              >
                                <Card
                                  style={{
                                    padding: "5px",
                                    marginBottom: "15px",
                                    backgroundColor: "#EFEFEF",
                                    minHeight: "220px",
                                  }}
                                >
                                  <Typography variant={"body1"} gutterBottom>
                                    Office Visit Date{" "}
                                    <Tooltip
                                      title="Data the patient visited the office"
                                      arrow
                                      placement="top"
                                    >
                                      <IconButton>
                                        <FontAwesomeIcon
                                          style={{
                                            color: "green",
                                            fontSize: "20px",
                                            margin: "0px",
                                          }}
                                          icon={faQuestionCircle}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Typography>
                                  <DatePicker
                                    label="Date"
                                    value={officeVisitDate}
                                    minDate={dateCap}
                                    disabled={checkDisabled([
                                      biopsyDate,
                                      surgicalRequestDate,
                                    ])}
                                    onChange={(newValue) => {
                                      setOfficeVisitDate(newValue);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        fullWidth
                                        className="ant-input"
                                        style={{ marginBottom: "10px" }}
                                        {...params}
                                      />
                                    )}
                                  />
                                </Card>
                              </div>
                            </div>

                            <div style={{ display: "flex" }}>
                              <div
                                style={{ width: "100%", marginRight: "5px" }}
                              >
                                <Card
                                  style={{
                                    padding: "5px",
                                    marginBottom: "15px",
                                    backgroundColor: "#EFEFEF",
                                    minHeight: "220px",
                                  }}
                                >
                                  <Typography variant={"body1"} gutterBottom>
                                    Surgery Date{" "}
                                    <Tooltip
                                      title="Date of Surgery"
                                      arrow
                                      placement="top"
                                    >
                                      <IconButton>
                                        <FontAwesomeIcon
                                          style={{
                                            color: "green",
                                            fontSize: "20px",
                                            margin: "0px",
                                          }}
                                          icon={faQuestionCircle}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Typography>
                                  <DatePicker
                                    label="Date"
                                    value={surgeryDate}
                                    minDate={dateCap}
                                    disabled={checkDisabled([
                                      biopsyDate,
                                      surgicalRequestDate,
                                      officeVisitDate,
                                    ])}
                                    onChange={(newValue) => {
                                      setSurgeryDate(newValue);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        fullWidth
                                        className="ant-input"
                                        style={{ marginBottom: "10px" }}
                                        {...params}
                                      />
                                    )}
                                  />
                                </Card>
                              </div>
                              <div
                                style={{ width: "100%", marginRight: "5px" }}
                              >
                                <Card
                                  style={{
                                    padding: "5px",
                                    marginBottom: "15px",
                                    backgroundColor: "#EFEFEF",
                                    minHeight: "220px",
                                  }}
                                >
                                  <Typography variant={"body1"} gutterBottom>
                                    Oncology Referral{" "}
                                    <Tooltip
                                      title="Check if an oncologist referred you."
                                      arrow
                                      placement="top"
                                    >
                                      <IconButton>
                                        <FontAwesomeIcon
                                          style={{
                                            color: "green",
                                            fontSize: "20px",
                                            margin: "0px",
                                          }}
                                          icon={faQuestionCircle}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Typography>
                                  <Select
                                    disabled={checkDisabled([
                                      biopsyDate,
                                      surgicalRequestDate,
                                      officeVisitDate,
                                      surgeryDate,
                                    ])}
                                    fullWidth
                                    value={
                                      onCologyReferral != undefined &&
                                      onCologyReferral != null
                                        ? onCologyReferral
                                        : ""
                                    }
                                    name="Oncology Referral"
                                    label="Oncology Result"
                                    onChange={(e: any) =>
                                      setOncologyReferral(e.target.value)
                                    }
                                  >
                                    <MenuItem value={""}>
                                      Select a value
                                    </MenuItem>
                                    <MenuItem value={"Yes"}>Yes</MenuItem>
                                    <MenuItem value={"No"}>No</MenuItem>
                                  </Select>
                                </Card>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div style={{ display: "flex" }}>
                              <div
                                style={{ width: "100%", marginRight: "5px" }}
                              >
                                <Card
                                  style={{
                                    padding: "5px",
                                    marginBottom: "15px",
                                    backgroundColor: "#EFEFEF",
                                    minHeight: "220px",
                                  }}
                                >
                                  <Typography variant={"body1"} gutterBottom>
                                    Appointment/Order Confirmation Received Date{" "}
                                    <Tooltip
                                      title="Date appointment/order confirmation is received"
                                      arrow
                                      placement="top"
                                    >
                                      <IconButton>
                                        <FontAwesomeIcon
                                          style={{
                                            color: "green",
                                            fontSize: "20px",
                                            margin: "0px",
                                          }}
                                          icon={faQuestionCircle}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                    {apptReceivedDate !== null && (
                                      <IconButton>
                                        <FontAwesomeIcon
                                          style={{
                                            color: "green",
                                            fontSize: "20px",
                                            margin: "0px",
                                          }}
                                          icon={faCheckCircle}
                                        />
                                      </IconButton>
                                    )}
                                  </Typography>
                                  <DatePicker
                                    label="Date"
                                    value={apptReceivedDate}
                                    maxDate={new Date()}
                                    minDate={dateCap}
                                    disabled={
                                      order.type === "Therapeutic"
                                        ? checkDisabled([
                                            biopsyDate,
                                            surgicalRequestDate,
                                            officeVisitDate,
                                            surgeryDate,
                                            onCologyReferral,
                                          ])
                                        : checkDisabled([orderDate])
                                    }
                                    onChange={(newValue) => {
                                      setApptReceivedDate(newValue);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        fullWidth
                                        className="ant-input"
                                        style={{ marginBottom: "10px" }}
                                        {...params}
                                      />
                                    )}
                                  />
                                </Card>
                              </div>
                              <div
                                style={{ width: "100%", marginRight: "5px" }}
                              >
                                <Card
                                  style={{
                                    padding: "5px",
                                    marginBottom: "15px",
                                    backgroundColor: "#EFEFEF",
                                    minHeight: "220px",
                                  }}
                                >
                                  <Typography variant={"body1"} gutterBottom>
                                    Patient Appointment Date{" "}
                                    <Tooltip
                                      title="Data the patient will have the appointment"
                                      arrow
                                      placement="top"
                                    >
                                      <IconButton>
                                        <FontAwesomeIcon
                                          style={{
                                            color: "green",
                                            fontSize: "20px",
                                            margin: "0px",
                                          }}
                                          icon={faQuestionCircle}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Typography>
                                  <DatePicker
                                    label="Date"
                                    value={patientApptDate}
                                    minDate={dateCap}
                                    disabled={
                                      order.type === "Therapeutic"
                                        ? checkDisabled([
                                            biopsyDate,
                                            surgicalRequestDate,
                                            officeVisitDate,
                                            surgeryDate,
                                            onCologyReferral,
                                            apptReceivedDate,
                                          ])
                                        : checkDisabled([
                                            orderDate,
                                            apptReceivedDate,
                                          ])
                                    }
                                    onChange={(newValue) => {
                                      setPatientApptDate(newValue);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        fullWidth
                                        className="ant-input"
                                        style={{ marginBottom: "10px" }}
                                        {...params}
                                      />
                                    )}
                                  />
                                </Card>
                              </div>
                            </div>
                            <div style={{ display: "flex" }}>
                              <div
                                style={{ width: "100%", marginRight: "5px" }}
                              >
                                <Card
                                  style={{
                                    padding: "5px",
                                    marginBottom: "15px",
                                    backgroundColor: "#EFEFEF",
                                    minHeight:
                                      order.type === "Diagnostic" ||
                                      (order.workUpPreOrderRadiologySummary !==
                                        undefined &&
                                        order.workUpPreOrderRadiologySummary
                                          .length > 0)
                                        ? "290px"
                                        : "220px",
                                  }}
                                >
                                  <Typography variant={"body1"} gutterBottom>
                                    Appointment/Order Completed Date{" "}
                                    <Tooltip
                                      title="Date the patient completed the appointment or date the order was completed"
                                      arrow
                                      placement="top"
                                    >
                                      <IconButton>
                                        <FontAwesomeIcon
                                          style={{
                                            color: "green",
                                            fontSize: "20px",
                                            margin: "0px",
                                          }}
                                          icon={faQuestionCircle}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                    {apptCompletedDate !== null && (
                                      <IconButton>
                                        <FontAwesomeIcon
                                          style={{
                                            color: "green",
                                            fontSize: "20px",
                                            margin: "0px",
                                          }}
                                          icon={faCheckCircle}
                                        />
                                      </IconButton>
                                    )}
                                  </Typography>
                                  <DatePicker
                                    label="Date"
                                    value={apptCompletedDate}
                                    maxDate={new Date()}
                                    minDate={dateCap}
                                    disabled={
                                      order.type == "Therapeutic"
                                        ? checkDisabled([
                                            biopsyDate,
                                            surgicalRequestDate,
                                            officeVisitDate,
                                            surgeryDate,
                                            onCologyReferral,
                                            apptReceivedDate,
                                            patientApptDate,
                                          ])
                                        : checkDisabled([
                                            orderDate,
                                            apptReceivedDate,
                                            patientApptDate,
                                          ])
                                    }
                                    onChange={(newValue) => {
                                      setApptCompletedDate(newValue);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        fullWidth
                                        className="ant-input"
                                        style={{ marginBottom: "10px" }}
                                        {...params}
                                      />
                                    )}
                                  />
                                </Card>
                              </div>
                              <div
                                style={{ width: "100%", marginRight: "5px" }}
                              >
                                <Card
                                  style={{
                                    padding: "5px",
                                    marginBottom: "15px",
                                    backgroundColor: "#EFEFEF",
                                    minHeight: "220px",
                                  }}
                                >
                                  <Typography variant={"body1"} gutterBottom>
                                    Results Received by Ordering Provider{" "}
                                    <Tooltip
                                      title="Date the appointment/order results were received by the ordering provider"
                                      arrow
                                      placement="top"
                                    >
                                      <IconButton>
                                        <FontAwesomeIcon
                                          style={{
                                            color: "green",
                                            fontSize: "20px",
                                            margin: "0px",
                                          }}
                                          icon={faQuestionCircle}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Typography>
                                  <DatePicker
                                    label="Date"
                                    value={resultsReceivedDate}
                                    maxDate={new Date()}
                                    minDate={dateCap}
                                    disabled={
                                      order.type === "Therapeutic"
                                        ? checkDisabled([
                                            biopsyDate,
                                            surgicalRequestDate,
                                            officeVisitDate,
                                            surgeryDate,
                                            onCologyReferral,
                                            apptReceivedDate,
                                            patientApptDate,
                                            apptCompletedDate,
                                          ])
                                        : checkDisabled([
                                            orderDate,
                                            apptReceivedDate,
                                            patientApptDate,
                                            apptCompletedDate,
                                          ])
                                    }
                                    onChange={(newValue) => {
                                      setResultsReceivedDate(newValue);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        fullWidth
                                        className="ant-input"
                                        style={{ marginBottom: "10px" }}
                                        {...params}
                                      />
                                    )}
                                  />

                                  <Form.Item>
                                    <Typography variant={"body1"}>
                                      Appointment/Order Results
                                    </Typography>
                                    <Select
                                      name="thoseField"
                                      style={{
                                        width: "390px",
                                      }}
                                      fullWidth
                                      disabled={
                                        order.type === "Therapeutic"
                                          ? checkDisabled([
                                              biopsyDate,
                                              surgicalRequestDate,
                                              officeVisitDate,
                                              surgeryDate,
                                              onCologyReferral,
                                              apptReceivedDate,
                                              patientApptDate,
                                              apptCompletedDate,
                                              resultsReceivedDate,
                                            ])
                                          : checkDisabled([
                                              orderDate,
                                              apptReceivedDate,
                                              patientApptDate,
                                              apptCompletedDate,
                                              resultsReceivedDate,
                                            ])
                                      }
                                      // labelId="demo-simple-select-label"
                                      // id="demo-simple-select"
                                      value={appointmentResults}
                                      label="Result"
                                      onChange={(e: any) =>
                                        setAppointmentResults(e.target.value)
                                      }
                                    >
                                      <MenuItem value={"Birad 0"}>
                                        Birad 0
                                      </MenuItem>
                                      <MenuItem value={"Birad 1"}>
                                        Birad 1
                                      </MenuItem>
                                      <MenuItem value={"Birad 2"}>
                                        Birad 2
                                      </MenuItem>
                                      <MenuItem value={"Birad 3"}>
                                        Birad 3
                                      </MenuItem>
                                      <MenuItem value={"Birad 4"}>
                                        Birad 4
                                      </MenuItem>
                                      <MenuItem value={"Birad 5"}>
                                        Birad 5
                                      </MenuItem>
                                      <MenuItem value={"Biopsy Positive"}>
                                        Biopsy Positive
                                      </MenuItem>
                                      <MenuItem value={"Biopsy Negative"}>
                                        Biopsy Negative
                                      </MenuItem>
                                      <MenuItem value={"Biopsy High Risk"}>
                                        Biopsy High Risk
                                      </MenuItem>
                                    </Select>
                                  </Form.Item>
                                  {order &&
                                    order.workUpPreOrderRadiologySummary &&
                                    order.workUpPreOrderRadiologySummary
                                      .length > 0 && (
                                      <Form.Item
                                        // name="thisField"
                                        style={{ width: "100%" }}
                                      >
                                        <Typography variant={"body1"}>
                                          Further Recommendation
                                        </Typography>
                                        <Select
                                          style={{
                                            width: "390px",
                                          }}
                                          fullWidth
                                          multiple
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          disabled={
                                            order.type === "Therapeutic"
                                              ? checkDisabled([
                                                  biopsyDate,
                                                  surgicalRequestDate,
                                                  officeVisitDate,
                                                  surgeryDate,
                                                  onCologyReferral,
                                                  apptReceivedDate,
                                                  patientApptDate,
                                                  apptCompletedDate,
                                                ])
                                              : checkDisabled([
                                                  orderDate,
                                                  apptReceivedDate,
                                                  patientApptDate,
                                                  apptCompletedDate,
                                                  resultsReceivedDate,
                                                ])
                                          }
                                          value={apptRecommendation}
                                          label="Further Recommendation"
                                          onChange={handleMulti}
                                        >
                                          <MenuItem
                                            value={
                                              "Follow-up CT CAP scan requested immediately"
                                            }
                                          >
                                            Follow-up CT CAP scan requested
                                            immediately
                                          </MenuItem>
                                          <MenuItem
                                            value={
                                              "Follow-up CT CAP scan requested in 3 months"
                                            }
                                          >
                                            Follow-up CT CAP scan requested in 3
                                            months
                                          </MenuItem>
                                          <MenuItem
                                            value={
                                              "Follow-up CT CAP scan requested in 6 months"
                                            }
                                          >
                                            Follow-up CT CAP scan requested in 6
                                            months
                                          </MenuItem>
                                          <MenuItem
                                            value={
                                              "Follow-up CT CAP scan requested in 12 months"
                                            }
                                          >
                                            Follow-up CT CAP scan requested in
                                            12 months
                                          </MenuItem>
                                          <MenuItem
                                            value={
                                              "Follow-up PET/CT requested immediately"
                                            }
                                          >
                                            Follow-up PET/CT requested
                                            immediately
                                          </MenuItem>
                                          <MenuItem
                                            value={
                                              "Follow-up PET/CT requested in 3 months"
                                            }
                                          >
                                            Follow-up PET/CT requested in 3
                                            months
                                          </MenuItem>
                                          <MenuItem
                                            value={
                                              "Follow-up PET/CT requested in 6 months"
                                            }
                                          >
                                            Follow-up PET/CT requested in 6
                                            months
                                          </MenuItem>
                                          <MenuItem
                                            value={
                                              "Follow-up PET/CT requested in 12 months"
                                            }
                                          >
                                            Follow-up PET/CT requested in 12
                                            months
                                          </MenuItem>
                                          <MenuItem
                                            value={
                                              "Follow-up Bone scan requested immediately"
                                            }
                                          >
                                            Follow-up Bone scan requested
                                            immediately
                                          </MenuItem>
                                          <MenuItem
                                            value={
                                              "Follow-up Bone scan requested in 3 months"
                                            }
                                          >
                                            Follow-up Bone scan requested in 3
                                            months
                                          </MenuItem>
                                          <MenuItem
                                            value={
                                              "Follow-up Bone scan requested in 6 months"
                                            }
                                          >
                                            Follow-up Bone scan requested in 6
                                            months
                                          </MenuItem>
                                          <MenuItem
                                            value={
                                              "Follow-up Bone scan requested in 12 months"
                                            }
                                          >
                                            Follow-up Bone scan requested in 12
                                            months
                                          </MenuItem>
                                          <MenuItem
                                            value={
                                              "Follow-up ECHO requested immediately"
                                            }
                                          >
                                            Follow-up ECHO requested immediately
                                          </MenuItem>
                                          <MenuItem
                                            value={
                                              "Follow-up ECHO requested in 3 months"
                                            }
                                          >
                                            Follow-up ECHO requested in 3 months
                                          </MenuItem>
                                          <MenuItem
                                            value={
                                              "Follow-up ECHO requested in 6 months"
                                            }
                                          >
                                            Follow-up ECHO requested in 6 months
                                          </MenuItem>
                                          <MenuItem
                                            value={
                                              "Follow-up ECHO scan requested in 12 months"
                                            }
                                          >
                                            Follow-up ECHO scan requested in 12
                                            months
                                          </MenuItem>
                                          <MenuItem
                                            value={
                                              "Follow-up DEXA scan in 6 months"
                                            }
                                          >
                                            Follow-up DEXA scan in 6 months
                                          </MenuItem>
                                          <MenuItem
                                            value={
                                              "Follow-up DEXA scan in 12 months"
                                            }
                                          >
                                            Follow-up DEXA scan in 12 months
                                          </MenuItem>
                                        </Select>
                                      </Form.Item>
                                    )}
                                  {order.type === "Diagnostic" && (
                                    <Form.Item
                                      // name="thisField"
                                      style={{ width: "100%" }}
                                    >
                                      <Typography variant={"body1"}>
                                        Further Recommendation
                                      </Typography>
                                      <Select
                                        style={{
                                          width: "390px",
                                        }}
                                        fullWidth
                                        multiple
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        disabled={
                                          !orderDate ||
                                          !apptRequestedDate ||
                                          !apptReceivedDate ||
                                          !patientApptDate ||
                                          !apptCompletedDate ||
                                          !resultsReceivedDate
                                            ? true
                                            : false
                                        }
                                        value={apptRecommendation}
                                        label="Further Recommendation"
                                        onChange={handleMulti}
                                      >
                                        <MenuItem
                                          value={
                                            "Follow-up Mammogram requested immediately"
                                          }
                                        >
                                          Follow-up Mammogram requested
                                          immediately
                                        </MenuItem>
                                        <MenuItem
                                          value={
                                            "Follow-up Mammogram requested in 3 months"
                                          }
                                        >
                                          Follow-up Mammogram requested in 3
                                          months
                                        </MenuItem>
                                        <MenuItem
                                          value={
                                            "Follow-up Mammogram requested in 6 months"
                                          }
                                        >
                                          Follow-up Mammogram requested in 6
                                          months
                                        </MenuItem>
                                        <MenuItem
                                          value={
                                            "Follow-up Mammogram requested in 12 months"
                                          }
                                        >
                                          Follow-up Mammogram requested in 12
                                          months
                                        </MenuItem>
                                        <MenuItem
                                          value={
                                            "Follow-up Ultrasound requested immediately"
                                          }
                                        >
                                          Follow-up Ultrasound requested
                                          immediately
                                        </MenuItem>
                                        <MenuItem
                                          value={
                                            "Follow-up Ultrasound requested in 3 months"
                                          }
                                        >
                                          Follow-up Ultrasound requested in 3
                                          months
                                        </MenuItem>
                                        <MenuItem
                                          value={
                                            "Follow-up Ultrasound requested in 6 months"
                                          }
                                        >
                                          Follow-up Ultrasound requested in 6
                                          months
                                        </MenuItem>
                                        <MenuItem
                                          value={
                                            "Follow-up Ultrasound requested in 12 months"
                                          }
                                        >
                                          Follow-up Ultrasound requested in 12
                                          months
                                        </MenuItem>
                                        <MenuItem
                                          value={
                                            "Follow-up MRI requested immediately"
                                          }
                                        >
                                          Follow-up MRI requested immediately
                                        </MenuItem>
                                        <MenuItem
                                          value={
                                            "Follow-up MRI requested in 3 months"
                                          }
                                        >
                                          Follow-up MRI requested in 3 months
                                        </MenuItem>
                                        <MenuItem
                                          value={
                                            "Follow-up MRI requested in 6 months"
                                          }
                                        >
                                          Follow-up MRI requested in 6 months
                                        </MenuItem>
                                        <MenuItem
                                          value={"USG biopsy requested"}
                                        >
                                          USG biopsy requested
                                        </MenuItem>
                                        <MenuItem
                                          value={
                                            "Stereotactic core biopsy requested"
                                          }
                                        >
                                          Stereotactic core biopsy requested
                                        </MenuItem>
                                        <MenuItem
                                          value={"Referral to Surgery First"}
                                        >
                                          Referral to Surgery First
                                        </MenuItem>
                                        <MenuItem
                                          value={
                                            "Referral to Medical Oncology First"
                                          }
                                        >
                                          Referral to Medical Oncology First
                                        </MenuItem>
                                        <MenuItem
                                          value={
                                            "Referral to Surgeon/Medical Oncology"
                                          }
                                        >
                                          Referral to Surgeon/Medical Oncology
                                        </MenuItem>
                                        <MenuItem
                                          value={
                                            "MRI guided core biopsy requested"
                                          }
                                        >
                                          MRI guided core biopsy requested
                                        </MenuItem>
                                        <MenuItem
                                          value={
                                            "Patient declined further management"
                                          }
                                        >
                                          Patient declined further management
                                        </MenuItem>
                                        <MenuItem
                                          value={
                                            "Patient Moved Out of Surface Area"
                                          }
                                        >
                                          Patient Moved Out of Surface Area
                                        </MenuItem>
                                        <MenuItem value={"Patient deceased"}>
                                          Patient deceased
                                        </MenuItem>
                                      </Select>
                                    </Form.Item>
                                  )}
                                </Card>
                              </div>
                            </div>
                            <div style={{ display: "flex" }}>
                              <div
                                style={{ width: "100%", marginRight: "5px" }}
                              >
                                <Card
                                  style={{
                                    padding: "5px",
                                    marginBottom: "15px",
                                    backgroundColor: "#EFEFEF",
                                    minHeight: "220px",
                                  }}
                                >
                                  <Typography variant={"body1"} gutterBottom>
                                    Results Received by Patient{" "}
                                    <Tooltip
                                      title="Date the appointment/order results were communicated to the patient"
                                      arrow
                                      placement="top"
                                    >
                                      <IconButton>
                                        <FontAwesomeIcon
                                          style={{
                                            color: "green",
                                            fontSize: "20px",
                                            margin: "0px",
                                          }}
                                          icon={faQuestionCircle}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Typography>
                                  <DatePicker
                                    label="Date"
                                    value={resultsSentToPatient}
                                    maxDate={new Date()}
                                    minDate={dateCap}
                                    disabled={
                                      order.type === "Therapeutic"
                                        ? checkDisabled([
                                            biopsyDate,
                                            surgicalRequestDate,
                                            officeVisitDate,
                                            surgeryDate,
                                            onCologyReferral,
                                            apptReceivedDate,
                                            patientApptDate,
                                            apptCompletedDate,
                                            resultsReceivedDate,
                                            appointmentResults,
                                            resultsReceivedDate,
                                          ])
                                        : checkDisabled([
                                            orderDate,
                                            apptReceivedDate,
                                            patientApptDate,
                                            apptCompletedDate,
                                            resultsReceivedDate,
                                            appointmentResults,
                                            resultsReceivedDate,
                                          ])
                                    }
                                    onChange={(newValue) => {
                                      setResultsSentToPatient(newValue);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        fullWidth
                                        className="ant-input"
                                        style={{ marginBottom: "10px" }}
                                        {...params}
                                      />
                                    )}
                                  />
                                </Card>
                              </div>
                              <div
                                style={{ width: "100%", marginRight: "5px" }}
                              ></div>
                            </div>
                          </>
                        )}
                      </div>
                    </LocalizationProvider>
                    <Form.Item className="accept-form">
                      <div
                        className="action-form-btn-group"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "30px",
                        }}
                      >
                        {/* <Link
                              to={`/patientBoard/${order._id}`}
                              className="accept-form-button-link"
                            >
                              Exit
                            </Link> */}
                        <Button
                          style={{
                            marginTop: "0px",
                            // marginLeft: "50px",
                            width: "15%",
                          }}
                          type="primary"
                          htmlType="submit"
                          onClick={submitOrderChange}
                          className="accept-form-button"
                        >
                          Save
                        </Button>
                      </div>
                    </Form.Item>
                  </div>
                )}
                {flag && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "30px",
                    }}
                  >
                    <div
                      style={{
                        width: "90%",
                        textAlign: "left",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        {order.status !== "Obstructed" && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              marginBottom: "30px",
                            }}
                          >
                            <Typography variant={"body1"}>
                              Please indicate why the order is Obstructed
                            </Typography>
                            <FormControl
                              style={{
                                marginBottom: "10px",
                                width: "50%",
                              }}
                            >
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={stuckStatus}
                                label="Note Type"
                                onChange={(e: any) =>
                                  setStuckStatus(e.target.value)
                                }
                              >
                                <MenuItem
                                  value={"Patient has denied earlier date"}
                                >
                                  Patient has denied earlier date
                                </MenuItem>
                                <MenuItem value={"Patient is hospitalized"}>
                                  Patient is hospitalized
                                </MenuItem>
                                <MenuItem value={"Patient is traveling"}>
                                  Patient is traveling
                                </MenuItem>
                                <MenuItem
                                  value={"Patient wants to consult with family"}
                                >
                                  Patient wants to consult with family
                                </MenuItem>
                                <MenuItem
                                  value={"Patient wants a second opinion"}
                                >
                                  Patient wants a second opinion
                                </MenuItem>
                                <MenuItem value={"Patient in on blood thinner"}>
                                  Patient in on blood thinner
                                </MenuItem>
                                <MenuItem value={"Patient is not reachable"}>
                                  Patient is not reachable
                                </MenuItem>
                                <MenuItem value={"Patient was a no-show"}>
                                  Patient was a no-show
                                </MenuItem>
                                <MenuItem
                                  value={"Patient declined further treatment"}
                                >
                                  Patient declined further treatment
                                </MenuItem>
                                <MenuItem
                                  value={"Patient called to cancel appointment"}
                                >
                                  Patient called to cancel appointment
                                </MenuItem>
                                <MenuItem value={"Patient is UTCx3"}>
                                  Patient is UTCx3
                                </MenuItem>
                                <MenuItem value={"Order needs to be corrected"}>
                                  Order needs to be corrected
                                </MenuItem>
                                <MenuItem value={"Order requested is denied"}>
                                  Order requested is denied
                                </MenuItem>
                                <MenuItem
                                  value={"Previous records are awaited"}
                                >
                                  Previous records are awaited
                                </MenuItem>
                              </Select>
                              <Form.Item className="accept-form">
                                <div
                                  className="action-form-btn-group"
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginTop: "30px",
                                  }}
                                >
                                  {stuckStatus === "" && (
                                    <Button
                                      style={{
                                        marginTop: "0px",
                                        // marginLeft: "50px",
                                        // width: "50%",
                                      }}
                                      type="primary"
                                      disabled
                                      htmlType="submit"
                                      onClick={submitOrderStatusChange}
                                      className="accept-form-button"
                                    >
                                      Save
                                    </Button>
                                  )}
                                  {stuckStatus !== "" && (
                                    <Button
                                      style={{
                                        marginTop: "0px",
                                        // width: "50%",
                                      }}
                                      type="primary"
                                      htmlType="submit"
                                      onClick={submitOrderStatusChange}
                                      className="accept-form-button"
                                    >
                                      Save
                                    </Button>
                                  )}
                                </div>
                              </Form.Item>
                            </FormControl>
                          </div>
                        )}
                        {order.status === "Obstructed" && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              marginBottom: "30px",
                            }}
                          >
                            <Typography variant={"body1"}>
                              Reason order is Obstructed: {order.stuckReason}
                            </Typography>

                            <Form.Item className="accept-form">
                              <div
                                className="action-form-btn-group"
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginTop: "30px",
                                }}
                              >
                                <Popover
                                  id={id}
                                  open={open}
                                  anchorEl={anchorEl}
                                  onClose={handleClose}
                                  anchorOrigin={{
                                    vertical: -120,
                                    horizontal: 75,
                                  }}
                                >
                                  <Typography
                                    style={{
                                      width: "200px",
                                      textAlign: "center",
                                    }}
                                    sx={{ p: 2 }}
                                  >
                                    Are you sure you want to cancel this order?
                                  </Typography>
                                  <Button
                                    className="cancel"
                                    style={{ width: "50%" }}
                                    onClick={handleClose}
                                  >
                                    No
                                  </Button>
                                  <Button
                                    className="confirm-cancel"
                                    style={{ width: "50%" }}
                                    onClick={cancelOrderStatus}
                                  >
                                    Yes
                                  </Button>
                                </Popover>
                                <Button
                                  style={{
                                    marginTop: "0px",
                                    // width: "50%",
                                    marginRight: "5px",
                                    backgroundColor: "transparent",
                                  }}
                                  aria-describedby={id}
                                  onClick={handleClick}
                                  type="primary"
                                  htmlType="submit"
                                  className="cancel-form-button"
                                >
                                  Cancel Order
                                </Button>
                                <Button
                                  style={{
                                    marginTop: "0px",
                                    // width: "50%",
                                    marginLeft: "5px",
                                    backgroundColor: "green",
                                  }}
                                  type="primary"
                                  htmlType="submit"
                                  onClick={clearStuckStatus}
                                  className="accept-form-button"
                                >
                                  Clear Obstructed Status
                                </Button>
                              </div>
                            </Form.Item>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {notes && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "30px",
                      //   height: "100vh",
                    }}
                  >
                    <div
                      style={{
                        width: "90%",
                        textAlign: "left",
                        // backgroundColor: "#ffffff",
                        // padding: "10px",
                        // boxShadow: "-2px 1px 3px 0px #d2d2d2",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            marginBottom: "30px",
                          }}
                        >
                          <Title>
                            <span style={{ fontSize: "24px" }}>
                              Order Notes
                            </span>
                          </Title>
                          {/* {alert && (
                <Alert severity="warning">Your note was empty</Alert>
              )} */}
                          <Form
                            form={form}
                            onFinish={addNewNote}
                            style={{
                              width: "100%",
                            }}
                          >
                            <FormControl
                              fullWidth
                              style={{
                                width: "100%",
                                alignItems: "left",
                                display: "flex",
                              }}
                            >
                              <Form.Item style={{ width: "35%" }}>
                                <Typography variant={"body1"}>
                                  Log Type
                                </Typography>
                                <Select
                                  name="thatField"
                                  fullWidth
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={noteType}
                                  label="Note Type"
                                  onChange={(e: any) =>
                                    setNoteType(e.target.value)
                                  }
                                >
                                  <MenuItem value={"General"}>General</MenuItem>
                                  <MenuItem value={"Missed Appointment Reason"}>
                                    Missed Appointment Reason
                                  </MenuItem>
                                  <MenuItem value={"Order Sent"}>
                                    Order Sent
                                  </MenuItem>
                                  <MenuItem value={"Appointment Requested"}>
                                    Appointment Requested
                                  </MenuItem>
                                  <MenuItem value={"Appointment Updated"}>
                                    Appointment Updated
                                  </MenuItem>
                                  <MenuItem value={"Appointment Date"}>
                                    Appointment Date
                                  </MenuItem>
                                  <MenuItem value={"Appointment Completed"}>
                                    Appointment Completed
                                  </MenuItem>
                                </Select>
                              </Form.Item>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <Form.Item
                                  name="thatField"
                                  style={{
                                    width: "50%",
                                    height: "100%",
                                    marginBottom: "0px",
                                  }}
                                >
                                  <Form.Item>
                                    <TextField
                                      className="ant-input"
                                      required
                                      label="Add Order Note"
                                      multiline
                                      rows={2}
                                      onChange={change}
                                    />
                                  </Form.Item>
                                </Form.Item>
                                <Form.Item
                                  style={{
                                    width: "18%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    margin: "0px",
                                    marginLeft: "20px",
                                  }}
                                >
                                  {noteType === "" || newNote === "" ? (
                                    <Button
                                      style={{
                                        width: "100%",
                                        marginTop: "0px",
                                      }}
                                      disabled
                                      type="primary"
                                      className="accept-form-button"
                                      htmlType="submit"
                                    >
                                      Save
                                    </Button>
                                  ) : (
                                    <Button
                                      style={{
                                        width: "100%",
                                        marginTop: "0px",
                                      }}
                                      type="primary"
                                      className="accept-form-button"
                                      htmlType="submit"
                                    >
                                      Save
                                    </Button>
                                  )}
                                </Form.Item>
                              </div>
                            </FormControl>
                          </Form>
                        </div>
                        {isLoading ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "80vw",
                              height: "20vh",
                            }}
                          >
                            <Spin
                              size="large"
                              className={`loading-spin-btn ${"active"}`}
                              indicator={antIcon}
                            />
                          </div>
                        ) : sortedNotes.length === 0 ? (
                          <div
                            style={{
                              textAlign: "center",
                              fontWeight: 500,
                              marginTop: "100px",
                              marginBottom: "100px",
                            }}
                          >
                            <Typography variant={"h5"}>
                              There are currently no notes for this order
                            </Typography>
                          </div>
                        ) : (
                          <div>
                            <Table
                              sx={{ minWidth: 700 }}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>
                                    <span
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Patient ID
                                    </span>
                                  </TableCell>
                                  <TableCell align="right">
                                    <span
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Type
                                    </span>
                                  </TableCell>
                                  <TableCell align="right">
                                    <span
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Date Created
                                    </span>
                                  </TableCell>
                                  <TableCell align="right">
                                    <span
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Author
                                    </span>
                                  </TableCell>
                                  <TableCell align="right">
                                    <span
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Text
                                    </span>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              {sortedNotes.map((note: any) => (
                                <TableBody>
                                  <TableRow key={note._id}>
                                    <TableCell>{note.patientId}</TableCell>
                                    <TableCell align="right">
                                      {note.noteType}
                                    </TableCell>
                                    <TableCell align="right">
                                      {moment(note.createdAt)
                                        .tz("America/Los_Angeles")
                                        .format("MM/DD/YYYY, h:mma")}
                                    </TableCell>
                                    <TableCell align="right">
                                      {note.author}
                                    </TableCell>
                                    <TableCell align="right">
                                      {note.note}
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              ))}
                            </Table>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <Divider />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function checkDisabled(arr: any) {
  let valid = false;
  arr.map((e: any) => {
    if (e == undefined || e == null) {
      valid = true;
    }
  });
  return valid;
}
