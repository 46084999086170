import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from 'react-query';

import App from './App';
import './scss/index.scss';
import reportWebVitals from './reportWebVitals';
import LoginProvider from './context/login/Context';
import Auth0Provider from './context/auth0/Auth0Provider';
import PanelProvider from './context/panel-context/PanelContext';

const queryClient = new QueryClient();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Router>
      <Auth0Provider>
        <LoginProvider>
          {/* <SignUpProvider> */}
          <PanelProvider>
            <App/>
          </PanelProvider>
          {/* </SignUpProvider> */}
        </LoginProvider>
      </Auth0Provider>
    </Router>
  </QueryClientProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
