import { Form, Radio, Space, Typography, Checkbox } from "antd";
import React from "react";
import { PanelContext } from "../../context/panel-context/PanelContext";
import InputTextQuiz from "./InputTextQuiz";
import PA from "./PA";

const RadioCustom = (props: any) => {
  const { setFieldSelected, getFieldByName, form } =
    React.useContext(PanelContext);
  const quizData = form.getFieldValue(props.name[0]);
  const onClick = (e: any) => {
    setFieldSelected({
      name: props.name,
      field: getFieldByName(props.name),
    });
  };

  return (
    <>
      <Typography.Title level={3}>{props.question}</Typography.Title>
      <Typography.Text className="instruction">
        {props.instruction}
      </Typography.Text>
      <Form.Item
        name="name"
        className="input age"
        rules={[
          {
            required: props.rq,
            message: `Please don't leave it blank !`,
          },
        ]}
        onClick={onClick}
        {...props}
      >
        <Radio.Group style={{ width: "100%" }}>
          <Space direction="vertical">
            {props.answers.map((fatherdata: any, fatherindex: number) => {
              return (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {/* // Diagnostic-BIRAD0 */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Radio value={fatherindex}>{fatherdata.content}</Radio>

                      {fatherdata.isPA && <PA />}
                    </div>
                    {fatherdata.isOtherText && fatherdata && (
                      <div
                        style={{
                          display: quizData
                            ? quizData[props.questionId]
                              ? quizData[props.questionId].father.answer ===
                                  fatherindex ||
                                (quizData[props.questionId].father.answer
                                  .length > 0 &&
                                  quizData[
                                    props.questionId
                                  ].father.answer.includes(fatherindex))
                                ? "flex"
                                : "none"
                              : "none"
                            : "none",
                        }}
                      >
                        <InputTextQuiz
                          label=""
                          name={[
                            props.name[0],
                            props.name[1],
                            props.name[2],
                            "other",
                          ]}
                        />
                      </div>
                    )}
                  </div>

                  {fatherdata.childrenAnswer &&
                    fatherdata &&
                    (fatherdata.isMultiAnswers ? (
                      <Form.Item
                        name={[...fatherdata.formname, "checkbox"]}
                        className="input age formchild"
                        rules={[
                          {
                            required: props.rq,
                            message: `Please don't leave it blank !`,
                          },
                        ]}
                        style={{
                          display: quizData
                            ? quizData[props.questionId]
                              ? quizData[props.questionId].father.answer ===
                                  fatherindex ||
                                (quizData[props.questionId].father.answer
                                  .length > 0 &&
                                  quizData[
                                    props.questionId
                                  ].father.answer.includes(fatherindex))
                                ? "block"
                                : "none"
                              : "none"
                            : "none",
                        }}
                      >
                        <Checkbox.Group
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography.Text className="instruction">
                            {fatherdata.instruction}
                          </Typography.Text>
                          <Space direction="vertical">
                            {fatherdata.childrenAnswer.map(
                              (childdata: any, childindex: number) => {
                                return (
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Checkbox value={childindex}>
                                          {childdata.content}
                                        </Checkbox>
                                        {childdata.isPA && <PA />}
                                      </div>
                                      {childdata.isOtherText && (
                                        <div
                                          style={{
                                            display: quizData
                                              ? quizData[props.questionId]
                                                ? quizData[props.questionId]
                                                    .child
                                                  ? quizData[props.questionId]
                                                      .child.answer[fatherindex]
                                                      ?.radio === childindex ||
                                                    (quizData[props.questionId]
                                                      .child.answer[fatherindex]
                                                      ?.checkbox?.length > 0 &&
                                                      quizData[
                                                        props.questionId
                                                      ].child.answer[
                                                        fatherindex
                                                      ]?.checkbox?.includes(
                                                        childindex
                                                      ))
                                                    ? "flex"
                                                    : "none"
                                                  : "none"
                                                : "none"
                                              : "none",
                                          }}
                                        >
                                          <InputTextQuiz
                                            label=""
                                            name={fatherdata.formname.map(
                                              (name: any, index: number) =>
                                                index === 3 ? "other" : name
                                            )}
                                            className="inputquiz"
                                          />
                                        </div>
                                      )}
                                    </div>
                                    {childdata.criteria && (
                                      <div
                                        style={{
                                          display: quizData
                                            ? quizData[props.questionId]
                                              ? quizData[props.questionId].child
                                                ? quizData[props.questionId]
                                                    .child.answer[fatherindex]
                                                    ?.radio === childindex ||
                                                  (quizData[props.questionId]
                                                    .child.answer[fatherindex]
                                                    ?.checkbox?.length > 0 &&
                                                    quizData[
                                                      props.questionId
                                                    ].child.answer[
                                                      fatherindex
                                                    ]?.checkbox?.includes(
                                                      childindex
                                                    ))
                                                  ? "block"
                                                  : "none"
                                                : "none"
                                              : "none"
                                            : "none",
                                        }}
                                      >
                                        <Form.Item
                                          name={[
                                            props.criterianame[0],
                                            props.criterianame[1],
                                            "child",
                                            "criteria",
                                            fatherdata.formname[4],
                                            childindex,
                                          ]}
                                          className="input age formciteria"
                                          rules={[
                                            {
                                              required: props.rq,
                                              message: `Please don't leave it blank !`,
                                            },
                                          ]}
                                        >
                                          <Checkbox.Group
                                            style={{ width: "100%" }}
                                          >
                                            <Space direction="vertical">
                                              {childdata.criteria.map(
                                                (
                                                  childdata: any,
                                                  childindex: number
                                                ) => {
                                                  return (
                                                    <>
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          flexDirection: "row",
                                                          alignItems: "center",
                                                        }}
                                                      >
                                                        <Checkbox
                                                          style={{
                                                            color: "red",
                                                          }}
                                                          value={childindex}
                                                        >
                                                          {childdata}
                                                        </Checkbox>
                                                        {/* {fatherdata.isPA && (
                                                          <PA />
                                                        )} */}
                                                      </div>
                                                    </>
                                                  );
                                                }
                                              )}
                                            </Space>
                                          </Checkbox.Group>
                                        </Form.Item>
                                      </div>
                                    )}
                                    {childdata.middleChildAnswer && (
                                      <div
                                        style={{
                                          display: quizData
                                            ? quizData[props.questionId]
                                              ? quizData[props.questionId].child
                                                ? quizData[props.questionId]
                                                    .child.answer[fatherindex]
                                                    ?.radio === childindex ||
                                                  (quizData[props.questionId]
                                                    .child.answer[fatherindex]
                                                    ?.checkbox?.length > 0 &&
                                                    quizData[
                                                      props.questionId
                                                    ].child.answer[
                                                      fatherindex
                                                    ]?.checkbox?.includes(
                                                      childindex
                                                    ))
                                                  ? "block"
                                                  : "none"
                                                : "none"
                                              : "none"
                                            : "none",
                                        }}
                                      >
                                        <Form.Item
                                          name={[
                                            props.criterianame[0],
                                            props.criterianame[1],
                                            "child",
                                            "criteria",
                                            fatherdata.formname[4],
                                            childindex,
                                          ]}
                                          className="input age formciteria"
                                          rules={[
                                            {
                                              required: props.rq,
                                              message: `Please don't leave it blank !`,
                                            },
                                          ]}
                                        >
                                          <Typography.Text className="instruction">
                                            {childdata.instruction}
                                          </Typography.Text>
                                          <Radio.Group
                                            style={{ width: "100%" }}
                                          >
                                            <Space direction="vertical">
                                              {childdata.middleChildAnswer.map(
                                                (
                                                  childdata: any,
                                                  childindex: number
                                                ) => {
                                                  return (
                                                    <>
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          flexDirection: "row",
                                                          alignItems: "center",
                                                        }}
                                                      >
                                                        <Radio
                                                          value={childindex}
                                                        >
                                                          {childdata.content}
                                                        </Radio>
                                                        {childdata.isPA && (
                                                          <PA />
                                                        )}
                                                        {childdata.isOtherText && (
                                                          <div
                                                          // style={{
                                                          //   display: quizData
                                                          //     ? quizData[
                                                          //         props
                                                          //           .questionId
                                                          //       ]
                                                          //       ? quizData[
                                                          //           props
                                                          //             .questionId
                                                          //         ].child
                                                          //         ? quizData[
                                                          //             props
                                                          //               .questionId
                                                          //           ].child
                                                          //             .answer[
                                                          //             fatherindex
                                                          //           ]
                                                          //             ?.radio ===
                                                          //             childindex ||
                                                          //           (quizData[
                                                          //             props
                                                          //               .questionId
                                                          //           ].child
                                                          //             .answer[
                                                          //             fatherindex
                                                          //           ]
                                                          //             ?.checkbox
                                                          //             ?.length >
                                                          //             0 &&
                                                          //             quizData[
                                                          //               props
                                                          //                 .questionId
                                                          //             ].child.answer[
                                                          //               fatherindex
                                                          //             ]?.checkbox?.includes(
                                                          //               childindex
                                                          //             ))
                                                          //           ? "flex"
                                                          //           : "none"
                                                          //         : "none"
                                                          //       : "none"
                                                          //     : "none",
                                                          // }}
                                                          >
                                                            <InputTextQuiz
                                                              label=""
                                                              name={fatherdata.formname.map(
                                                                (
                                                                  name: any,
                                                                  index: number
                                                                ) =>
                                                                  index === 3
                                                                    ? "other"
                                                                    : name
                                                              )}
                                                              className="inputquiz"
                                                            />
                                                          </div>
                                                        )}
                                                      </div>
                                                    </>
                                                  );
                                                }
                                              )}
                                            </Space>
                                          </Radio.Group>
                                        </Form.Item>
                                      </div>
                                    )}
                                    {childdata.grandChildAnswer && (
                                      <div
                                        style={{
                                          display: quizData
                                            ? quizData[props.questionId]
                                              ? quizData[props.questionId].child
                                                ? quizData[props.questionId]
                                                    .child.answer[fatherindex]
                                                    ?.radio === childindex ||
                                                  (quizData[props.questionId]
                                                    .child.answer[fatherindex]
                                                    ?.checkbox?.length > 0 &&
                                                    quizData[
                                                      props.questionId
                                                    ].child.answer[
                                                      fatherindex
                                                    ]?.checkbox?.includes(
                                                      childindex
                                                    ))
                                                  ? "block"
                                                  : "none"
                                                : "none"
                                              : "none"
                                            : "none",
                                        }}
                                      >
                                        <Form.Item
                                          name={[
                                            props.criterianame[0],
                                            props.criterianame[1],
                                            "grandChild",
                                            "answer",
                                            fatherdata.formname[4],
                                            childindex,
                                          ]}
                                          label={childdata.instruction}
                                          className="input age formciteria"
                                          rules={[
                                            {
                                              required: props.rq,
                                              message: `Please don't leave it blank !`,
                                            },
                                          ]}
                                        >
                                          <Radio.Group
                                            style={{ width: "100%" }}
                                          >
                                            <Space direction="vertical">
                                              {childdata.grandChildAnswer.map(
                                                (
                                                  childdata: any,
                                                  childindex: number
                                                ) => {
                                                  return (
                                                    <>
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          flexDirection: "row",
                                                          alignItems: "center",
                                                        }}
                                                      >
                                                        <Radio
                                                          value={childindex}
                                                        >
                                                          {childdata}
                                                        </Radio>
                                                        {fatherdata.isPA && (
                                                          <PA />
                                                        )}
                                                      </div>
                                                    </>
                                                  );
                                                }
                                              )}
                                            </Space>
                                          </Radio.Group>
                                        </Form.Item>
                                      </div>
                                    )}
                                  </>
                                );
                              }
                            )}
                          </Space>
                        </Checkbox.Group>
                      </Form.Item>
                    ) : (
                      <Form.Item
                        name={[...fatherdata.formname, "radio"]}
                        className="input age formchild"
                        rules={[
                          {
                            required: props.rq,
                            message: `Please don't leave it blank !`,
                          },
                        ]}
                        style={{
                          display: quizData
                            ? quizData[props.questionId]
                              ? quizData[props.questionId].father.answer ===
                                  fatherindex ||
                                (quizData[props.questionId].father.answer
                                  .length > 0 &&
                                  quizData[
                                    props.questionId
                                  ].father.answer.includes(fatherindex))
                                ? "block"
                                : "none"
                              : "none"
                            : "none",
                        }}
                      >
                        <span className="instruction">
                          {fatherdata.instruction}
                        </span>
                        <Radio.Group
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Space direction="vertical">
                            {fatherdata.childrenAnswer.map(
                              (childdata: any, childindex: number) => {
                                return (
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Radio value={childindex}>
                                          {childdata.content}
                                        </Radio>
                                        {childdata.isPA && <PA />}
                                      </div>
                                      {childdata.isOtherText && (
                                        <div
                                        // style={{
                                        //   display: quizData
                                        //     ? quizData[props.questionId]
                                        //       ? quizData[props.questionId]
                                        //           .child
                                        //         ? quizData[props.questionId]
                                        //             .child.answer[fatherindex]
                                        //             ?.radio === childindex ||
                                        //           (quizData[props.questionId]
                                        //             .child.answer[fatherindex]
                                        //             ?.checkbox?.length > 0 &&
                                        //             quizData[
                                        //               props.questionId
                                        //             ].child.answer[
                                        //               fatherindex
                                        //             ]?.checkbox?.includes(
                                        //               childindex
                                        //             ))
                                        //           ? "flex"
                                        //           : "none"
                                        //         : "none"
                                        //       : "none"
                                        //     : "none",
                                        // }}
                                        >
                                          <InputTextQuiz
                                            label=""
                                            name={fatherdata.formname.map(
                                              (name: any, index: number) =>
                                                index === 3 ? "other" : name
                                            )}
                                            className="inputquiz"
                                          />
                                        </div>
                                      )}
                                    </div>

                                    {/* <Typography.Text>{fatherdata.instruction}</Typography.Text> */}
                                    {childdata.grandChildAnswer && (
                                      <div
                                        style={{
                                          display: quizData
                                            ? quizData[props.questionId]
                                              ? quizData[props.questionId].child
                                                ? quizData[props.questionId]
                                                    .child.answer[fatherindex]
                                                    ?.radio === childindex ||
                                                  (quizData[props.questionId]
                                                    .child.answer[fatherindex]
                                                    ?.checkbox?.length > 0 &&
                                                    quizData[
                                                      props.questionId
                                                    ].child.answer[
                                                      fatherindex
                                                    ]?.checkbox?.includes(
                                                      childindex
                                                    ))
                                                  ? "block"
                                                  : "none"
                                                : "none"
                                              : "none"
                                            : "none",
                                        }}
                                      >
                                        <Form.Item
                                          name={[
                                            props.criterianame[0],
                                            props.criterianame[1],
                                            "child",
                                            "grand",
                                            fatherdata.formname[4],
                                            childindex,
                                          ]}
                                          className="input age formciteria"
                                          rules={[
                                            {
                                              required: props.rq,
                                              message: `Please don't leave it blank !`,
                                            },
                                          ]}
                                        >
                                          <Radio.Group
                                            style={{ width: "100%" }}
                                          >
                                            {/* <Typography.Text
                                              style={{ fontStyle: "italic" }}
                                            >
                                              {fatherdata.instruction}
                                            </Typography.Text> */}

                                            <Space direction="vertical">
                                              {childdata.grandChildAnswer.map(
                                                (
                                                  childdata: any,
                                                  childindex: number
                                                ) => {
                                                  return (
                                                    <>
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          flexDirection: "row",
                                                          alignItems: "center",
                                                        }}
                                                      >
                                                        <Radio
                                                          value={childindex}
                                                        >
                                                          {childdata}
                                                        </Radio>
                                                        {/* {fatherdata.isPA && (
                                                          <PA />
                                                        )} */}
                                                      </div>
                                                    </>
                                                  );
                                                }
                                              )}
                                            </Space>
                                          </Radio.Group>
                                        </Form.Item>
                                      </div>
                                    )}
                                  </>
                                );
                              }
                            )}
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                    ))}
                  {fatherdata.iCriteria && (
                    <div
                    // style={{
                    //   display: quizData
                    //     ? quizData[props.questionId]
                    //       ? quizData[props.questionId].child
                    //         ? quizData[props.questionId]
                    //             .child.answer[fatherindex]
                    //             ?.radio === childindex ||
                    //           (quizData[props.questionId]
                    //             .child.answer[fatherindex]
                    //             ?.checkbox?.length > 0 &&
                    //             quizData[
                    //               props.questionId
                    //             ].child.answer[
                    //               fatherindex
                    //             ]?.checkbox?.includes(
                    //               childindex
                    //             ))
                    //           ? "block"
                    //           : "none"
                    //         : "none"
                    //       : "none"
                    //     : "none",
                    // }}
                    >
                      <Form.Item
                        name={[...props.criterianame, fatherindex]}
                        className="input age formciteria"
                        rules={[
                          {
                            required: props.rq,
                            message: `Please don't leave it blank !`,
                          },
                        ]}
                        style={{
                          display: quizData
                            ? quizData[props.questionId]
                              ? quizData[props.questionId].father.answer ===
                                  fatherindex ||
                                (quizData[props.questionId].father.answer
                                  .length > 0 &&
                                  quizData[
                                    props.questionId
                                  ].father.answer.includes(fatherindex))
                                ? "block"
                                : "none"
                              : "none"
                            : "none",
                        }}
                      >
                        <Typography.Text className="instruction">
                          {fatherdata.childInstruction}
                        </Typography.Text>
                        <Checkbox.Group style={{ width: "100%" }}>
                          <Space direction="vertical">
                            {fatherdata.iCriteria.map(
                              (childdata: any, childindex: number) => {
                                return (
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Checkbox
                                        style={{
                                          color: "red",
                                          fontSize: "24px",
                                        }}
                                        value={childindex}
                                      >
                                        {childdata}
                                      </Checkbox>
                                      {/* {childdata.isPA && <PA />} */}
                                    </div>
                                  </>
                                );
                              }
                            )}
                          </Space>
                        </Checkbox.Group>
                      </Form.Item>
                    </div>
                  )}
                  {fatherdata.criteria && (
                    <Form.Item
                      name={[...props.criterianame]}
                      label={fatherdata.instruction}
                      className="input age formciteria"
                      rules={[
                        {
                          required: props.rq,
                          message: `Please don't leave it blank !`,
                        },
                      ]}
                      style={{
                        display: quizData
                          ? quizData[props.questionId]
                            ? quizData[props.questionId].father.answer ===
                                fatherindex ||
                              (quizData[props.questionId].father.answer.length >
                                0 &&
                                quizData[
                                  props.questionId
                                ].father.answer.includes(fatherindex))
                              ? "block"
                              : "none"
                            : "none"
                          : "none",
                      }}
                    >
                      <Checkbox.Group style={{ width: "100%" }}>
                        <Space direction="vertical">
                          {fatherdata.criteria.map(
                            (childdata: any, childindex: number) => {
                              return (
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Checkbox
                                      style={{
                                        color: "red",
                                        fontSize: "24px",
                                      }}
                                      value={childindex}
                                    >
                                      {childdata}
                                    </Checkbox>
                                    {/* {fatherdata.isPA && <PA />} */}
                                  </div>
                                </>
                              );
                            }
                          )}
                        </Space>
                      </Checkbox.Group>
                    </Form.Item>
                  )}
                </>
              );
            })}
          </Space>
        </Radio.Group>
      </Form.Item>
    </>
  );
};

export default RadioCustom;
