import React, { useState, useContext, useEffect, useCallback } from "react";
import { useQuery } from "react-query";
import { Card, Statistic, Spin } from "antd";
import { Grid, Divider, Modal, Box, Button } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { LoadingOutlined, LogoutOutlined } from "@ant-design/icons";
import { UserContext } from "../../context/login/Context";

import { OrderAPI } from "../../API/order-API";
import Layout from "../../components/layouts/LayoutContent";
import ClientPopup from "../frontOffice/ClientPopup";

const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

interface IGridItemsProps {
  [key: string]: any;
  isViewOrderStatus?: boolean;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "30%",
  maxWidth: "50%",
  maxHeight: "60%",
  minHeight: "40%",
  overflow: "scroll",
  backgroundColor: "rgb(35, 48, 68)",
  borderRadius: "12px",
  boxShadow: 24,
  p: 4,
};

const GridItems = ({ value, isViewOrderStatus = true }: IGridItemsProps) => {
  let items = [
    {
      link: "/orderView/New",
      status: "New",
      title: "New Orders",
      value: value?.New,
    },
    {
      link: "/orderView/Obstructed",
      status: "Stuck",
      title: "Obstructed Orders",
      value: value?.Obstructed,
    },
    {
      link: "/orderView/In Progress",
      status: "InProgress",
      title: "In Progress Orders",
      value: value?.["In Progress"],
    },
    {
      link: "/orderView/Scheduled",
      status: "Scheduled",
      title: "Scheduled Orders",
      value: value?.Scheduled,
    },
    {
      link: "/orderView/Results Received",
      status: "patients",
      title: "Results Received Orders",
      value: value?.["Results Received"],
    },
    {
      link: "/orderView/Completed",
      status: "Completed",
      title: "Completed Orders",
      value: value?.Completed,
    },
    {
      link: "/orderView/Biopsy Positive",
      status: "BiopsyPositive",
      title: "Biopsy Positive Results",
      value: value?.["Biopsy Positive"],
    },
    {
      link: "/orderView/Biopsy High Risk",
      status: "BiopsyPositive",
      title: "Biopsy High Risk Results",
      value: value?.["Biopsy High Risk"],
    },
    {
      link: "/orderView/Canceled",
      status: "Stuck",
      title: "Canceled",
      value: value?.Canceled,
    },
    {
      link: "/orderView/Biopsy Negative",
      status: "Stuck",
      title: "Benign, Non-High Risk Biopsy Results",
      value: value?.["Biopsy Negative"],
    },
    {
      link: "/orderView/Referral to Surgery First",
      status: "Stuck",
      title: "Referral to Surgery First",
      value: value?.["Referral to Surgery First"],
    },
    {
      link: "/orderView/Referral to Medical Oncology First",
      status: "Stuck",
      title: "Referral to Medical Oncology First",
      value: value?.["Referral to Medical Oncology First"],
    },
  ];

  if (!isViewOrderStatus) {
    items = [
      {
        link: "/adminView",
        status: "patients",
        title: "Number of Patients in system",
        value: value?.patients,
      },
      {
        link: "/alerts",
        status: "activeAlerts",
        title: "Total Active Alerts",
        value: value?.alerts,
      },
    ];
  }

  return (
    <>
      {items.map(({ link, status, title, value }, key) => (
        <Grid item xs={4} key={key}>
          <a href={link}>
            <Card>
              <Statistic title={title} value={value} className={status} />
            </Card>
          </a>
        </Grid>
      ))}
    </>
  );
};

const Dashboard = () => {
  const { isAuthenticated, logout } = useAuth0();
  const { clients, getCurrentClient, setCurrentClient } =
    useContext(UserContext);
  const [data, setData] = useState({} as any);
  const [isLoading, setIsLoading] = useState(true);
  const [openModal, setOpen] = useState(false);

  let currentClient = getCurrentClient();

  useEffect(() => {
    if (clients) {
      if (clients?.length === 0 && isAuthenticated) {
        logout();
      } else if (currentClient == undefined || currentClient == "") {
        if (clients?.length === 1) {
          setCurrentClient(clients[0]._id);
        } else {
          setOpen(true);
        }
      } else {
        setOpen(false);
        loadOrders();
      }
    } else if (clients == null && isAuthenticated) {
      // logout();
    }
  }, [currentClient, isAuthenticated, clients]);

  function loadOrders() {
    setIsLoading(true);
    OrderAPI.getStats(currentClient).then(({ data }) => {
      setData(data);
      setIsLoading(false);
    });
  }

  const handleClose = () => {
    setOpen(false);
    loadOrders();
  };

  if (!isAuthenticated) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "20vh",
        }}
      >
        Loading...{" "}
        <Spin
          size="large"
          indicator={antIcon}
          style={{ marginLeft: "10px" }}
          className={`loading-spin-btn ${"active"}`}
        />
      </div>
    );
  }

  return (
    <Layout>
      <div className="dashboard-container">
        <div
          className="wrapper-dash"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <h1>Executive Summary</h1>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "20vh",
              }}
            >
              Loading...{" "}
              <Spin
                size="large"
                indicator={antIcon}
                style={{ marginLeft: "10px" }}
                className={`loading-spin-btn ${"active"}`}
              />
            </div>
          ) : data ? (
            <div
              className="dashboard-items-container"
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Grid
                container
                rowSpacing={3}
                columnSpacing={{ xs: 6, sm: 7, md: 8 }}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <GridItems value={data?.status} />
              </Grid>
              <Divider style={{ marginTop: "40px", marginBottom: "40px" }} />
              <Grid
                container
                rowSpacing={3}
                columnSpacing={{ xs: 2, sm: 3, md: 4 }}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <GridItems
                  isViewOrderStatus={false}
                  value={{ patients: data?.patients, alerts: data?.alerts }}
                />
              </Grid>
            </div>
          ) : null}
        </div>
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box style={{ boxSizing: "border-box" }} sx={style}>
            <ClientPopup handleClose={handleClose} />
          </Box>
        </Modal>
      </div>
    </Layout>
  );
};

export default Dashboard;
