import React, { useState, useEffect, useContext } from "react";
import Header from "../../components/layouts/Header";
import { Link } from "react-router-dom";
import { PatientAPI } from "../../API/patient-API";
import {
  isNullOrEmpty,
  getPatientId,
  getUserName,
} from "../../constants/utilities";
import {
  Typography,
  Divider,
  TextField,
  FormControl,
  Toolbar,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import Title from "./Title";
import { Button, Form, message } from "antd";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import "moment-timezone";
import moment from "moment";
import { UserContext } from "../../context/login/Context";
import { useHistory } from "react-router-dom";
import { PatientLogAPI } from "../../API/patientLog-API";
// import { ClientAPI } from "../../API/client-API";

function NewPatient() {
  const history = useHistory();
  const { profile, getCurrentClient } = useContext(UserContext);
  const [patients, setPatients] = useState([]);
  const [mRN, setMRN] = useState("");
  const [id, setID] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [sex, setSex] = useState("");
  const [dOB, setDOB] = useState<Date | null>(null);
  const [dayOne, setDayOne] = useState<Date | null>(null);
  const [active] = useState(true);
  const [fullName, setFullName] = useState("");
  getUserName().then((res: any) => setFullName(res));

  let currentClient = getCurrentClient();

  async function submitNewPatient() {
    if (
      isNullOrEmpty(firstName) ||
      isNullOrEmpty(lastName) ||
      isNullOrEmpty(mRN) ||
      isNullOrEmpty(sex) ||
      dOB === null ||
      dayOne == null
    ) {
      message.error("Please enter all fields");
    } else {
      PatientAPI.getPatients(currentClient).then(async (res) => {
        if (
          res.data.patients.length > 0 &&
          res.data.patients.find((patient: any) => patient.mRN === mRN)
        ) {
          message.error(
            "Patient could not be created as this MRN already exists for another patient"
          );
        } else {
          getPatientId(currentClient).then((res: any) => {
            PatientAPI.createPatient({
              patientId: res,
              clientId: currentClient,
              firstName: firstName,
              lastName: lastName,
              dob: dOB,
              sex: sex,
              mRN: mRN,
              day1InAuthEx: dayOne,
              muteAlerts: false,
              isActive: active,
            })
              .then(async (res) => {
                const body = {
                  logType: "Patient Created",
                  patientId: res.data.patient.patientId,
                  clientId: currentClient,
                  userId: profile?._id,
                  keyDate: res.data.patient.createdAt,
                  // orderId: order._id,
                  // orderNumber: order.orderNumber,
                  author: fullName,
                  comment: `Patient created  on the portal on ${moment(
                    res.data.patient.createdAt
                  )
                    .tz("America/Los_Angeles")
                    .format("MM/DD/YYYY h:mma")}.`,
                };
                await PatientLogAPI.newPatientLog({ data: body });
                const body2 = {
                  logType: "Day 1 in XpediteMD",
                  patientId: res.data.patient.patientId,
                  clientId: currentClient,
                  userId: profile?._id,
                  keyDate: null,
                  // orderId: order._id,
                  // orderNumber: order.orderNumber,
                  author: fullName,
                  comment: `Day 1 in XpediteMD set to ${moment(dayOne)
                    .tz("America/Los_Angeles")
                    .format("MM/DD/YYYY h:mma")}.`,
                };
                await PatientLogAPI.newPatientLog({ data: body2 });
                message.success("Patient created successfully!");
                history.push(`/patient-board/profile/${res.data.patient._id}`);
              })
              .catch(() => {
                message.error("Update failed!");
              });
          });
        }
      });
    }
  }

  let start = moment().format("YYYY");
  let end = moment(dOB).format("YYYY");

  let old = Math.abs(parseInt(end) - parseInt(start)).toString();

  return (
    <div>
      <Header />
      <div
        className="side-nav"
        style={{
          height: "100%",
          width: "240px",
          position: "fixed",
          top: "90px",
          left: "0",
          backgroundColor: "#569aff",
        }}
      >
        <Toolbar />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
            // height: "10%",
            // alignItems: "center",
            justifyContent: "center",
            color: "white",
            fontWeight: 900,
            fontSize: "20px",
            marginLeft: "20px",
          }}
        >
          <Typography variant={"h6"} style={{ color: "white" }}>
            <span style={{ fontWeight: "bold", fontSize: "inherit" }}>
              Patient ID:
            </span>{" "}
            {id}
          </Typography>

          <Typography variant={"body2"} style={{ color: "white" }}>
            <span style={{ fontWeight: "bold", fontSize: "inherit" }}>
              Name:
            </span>{" "}
            {firstName} {lastName}
          </Typography>
          <Typography variant={"body2"} style={{ color: "white" }}>
            <span style={{ fontWeight: "bold", fontSize: "inherit" }}>
              MRN:
            </span>{" "}
            {mRN}
          </Typography>
          <Typography variant={"body2"} style={{ color: "white" }}>
            <span style={{ fontWeight: "bold", fontSize: "inherit" }}>
              DOB:
            </span>{" "}
            {dOB !== null && moment(dOB).format("MM/DD/YYYY")}
          </Typography>
          <Typography variant={"body2"} style={{ color: "white" }}>
            <span style={{ fontWeight: "bold", fontSize: "inherit" }}>
              Age:
            </span>{" "}
            {old !== "NaN" && old}
          </Typography>
          <Typography variant={"body2"} style={{ color: "white" }}>
            <span style={{ fontWeight: "bold", fontSize: "inherit" }}>
              Gender:
            </span>{" "}
            {sex}
          </Typography>
          {/* <Typography variant={"body2"}>Status: {patientStatus}</Typography> */}
          <Typography variant={"body2"} style={{ color: "white" }}>
            <span style={{ fontWeight: "bold", fontSize: "inherit" }}>
              Date in XpediteMD:
            </span>{" "}
            {dayOne !== null && moment(dayOne).format("MM/DD/YYYY")}
          </Typography>
        </div>
        {/* <Box>
          <List style={{ textAlign: "center" }}>
            <Link to={`/patientBoard/${order._id}`}>
              <ListItem
                button
                style={{
                  fontSize: "24px",
                  color: "#ffffff",
                  textAlign: "center",
                }}
              >
                <FontAwesomeIcon icon={faFolderOpen} />
                <ListItemText primary="Back" />
              </ListItem>
            </Link>
            <Divider /> */}
        {/* <Link to="/patientBoard/IMV001">
                <ListItem
                  button
                  style={{
                    fontSize: "24px",
                    color: "#ffffff",
                    textAlign: "center",
                  }}
                >
                  <FontAwesomeIcon icon={faClipboard} />
                  <ListItemText primary="Notes" />
                </ListItem>
              </Link>
              <Divider />
              <Link to="/patientBoard/IMV001">
                <ListItem
                  button
                  style={{
                    fontSize: "24px",
                    color: "#ffffff",
                    textAlign: "center",
                  }}
                >
                  <FontAwesomeIcon icon={faExclamationCircle} />
                  <ListItemText primary="Alerts" />
                </ListItem>
              </Link>
              <Divider />
              <Link to="/patientBoard/IMV001">
                <ListItem
                  button
                  style={{
                    fontSize: "24px",
                    color: "#ffffff",
                    textAlign: "center",
                  }}
                >
                  <FontAwesomeIcon icon={faStickyNote} />
                  <ListItemText primary="Patient Log" />
                </ListItem>
              </Link> */}
        {/* </List> */}
        {/* </Box> */}
      </div>
      <div
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginLeft: "240px",
          width: "calc(100% - 240px)",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "30px",
            //   height: "100vh",
          }}
        >
          <div
            style={{
              textAlign: "left",
              width: "90%",
              backgroundColor: "#ffffff",
              padding: "10px",
              boxShadow: "-2px 1px 3px 0px #d2d2d2",
            }}
          >
            <div style={{ textAlign: "left", width: "100%" }}>
              <Title>
                <span style={{ fontSize: "28px" }}>New Patient</span>
              </Title>
              <Divider style={{ marginTop: "40px", marginBottom: "40px" }} />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div style={{ width: "60%" }}>
                    {/* <div style={{ width: "300px", marginRight: "30px" }}>
                      <TextField
                        className="input age"
                        fullWidth
                        style={{ marginBottom: "30px" }}
                        value={id}
                        label="Patient ID"
                        onChange={() => setID(getNextPatientId())}
                      />
                    </div> */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <div style={{ width: "300px", marginRight: "30px" }}>
                        <TextField
                          className="input age"
                          fullWidth
                          style={{ marginBottom: "30px" }}
                          value={firstName}
                          label="First Name"
                          onChange={(e) => {
                            setFirstName(e.target.value);
                          }}
                        />
                      </div>
                      <div style={{ width: "300px" }}>
                        <TextField
                          fullWidth
                          className="input age"
                          style={{
                            marginBottom: "30px",
                          }}
                          value={lastName}
                          label="Last Name"
                          onChange={(e) => {
                            setLastName(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div style={{ width: "300px" }}>
                      <TextField
                        fullWidth
                        className="input age"
                        style={{
                          marginBottom: "30px",
                        }}
                        value={mRN}
                        label="MRN"
                        onChange={(e) => {
                          setMRN(e.target.value);
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        // marginBottom: "30px",
                        width: "300px",
                        // justifyContent: "center",
                      }}
                    >
                      {/* <Typography variant={"h6"} gutterBottom>
                          Date of Birth:{" "}
                          {dOB !== null && (
                            <FontAwesomeIcon
                              style={{ color: "green" }}
                              icon={faCheckCircle}
                            />
                          )}
                        </Typography> */}
                      <DatePicker
                        label="Date of Birth"
                        maxDate={new Date()}
                        value={dOB}
                        onChange={(newValue) => {
                          setDOB(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            className="ant-input"
                            style={{ marginBottom: "30px" }}
                            {...params}
                          />
                        )}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        marginBottom: "30px",
                      }}
                    >
                      <FormControl
                        style={{ width: "300px", marginRight: "30px" }}
                      >
                        <InputLabel id="demo-simple-select-label">
                          Gender
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={sex}
                          label="Gender"
                          onChange={(e: any) => setSex(e.target.value)}
                        >
                          <MenuItem value={"Male"}>Male</MenuItem>
                          <MenuItem value={"Female"}>Female</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div style={{ width: "300px", marginRight: "30px" }}>
                        {/* <Typography variant={"h6"} gutterBottom>
                          Day 1 Patient in XpediteMD{" "}
                          {dayOne !== null && (
                            <FontAwesomeIcon
                              style={{ color: "green" }}
                              icon={faCheckCircle}
                            />
                          )}
                        </Typography> */}
                        <DatePicker
                          label="Day 1 in XpediteMD"
                          maxDate={new Date()}
                          value={dayOne}
                          onChange={(newValue) => {
                            setDayOne(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              className="ant-input"
                              style={{ marginBottom: "30px" }}
                              {...params}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </LocalizationProvider>
              <Divider />
              <Form.Item className="accept-form">
                <div
                  className="action-form-btn-group"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "30px",
                  }}
                >
                  <Link to={`/adminView`} className="accept-form-button-link">
                    Exit
                  </Link>
                  <Button
                    style={{
                      marginTop: "0px",
                      marginLeft: "50px",
                      width: "15%",
                    }}
                    type="primary"
                    htmlType="submit"
                    onClick={() => {
                      submitNewPatient();
                    }}
                    className="accept-form-button"
                  >
                    Save
                  </Button>
                </div>
              </Form.Item>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewPatient;
