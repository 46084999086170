import React from "react"

import Header from "./Header"

const Layout: React.FC = (props) => (
  <div className='container'>
    <Header />
    <div className='content'>
      {props.children}
    </div>
  </div>
);

export default Layout;
