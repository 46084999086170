import { CheckOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Form, message } from "antd";
import { useHistory, useParams } from "react-router-dom";
import SkinSelect from "../new-order/specs/SkinSelect";
import React, { useEffect, useState } from "react";
import SelectCustom from "../../components/forms/SelectCustom";
import {
  TUMOR,
  ICD10CODES,
  ESTROGEN,
  LYMPH,
  METASTASISCLINICAL,
  MENOPAUSAL,
  HER2,
  KI67,
  PREOPORDER_ITEMS,
  PREOPRADIO_ITEMS,
  PATHOLOGY,
} from "../../constants/inputValues";
import MultiSelect from "../../components/forms/MultiSelect";
import {
  REFERRAL_GENETIC,
  REFERRAL_MEDONCOLOGY,
  REFERRAL_NUTRITION,
  REFERRAL_PATIENT,
  REFERRAL_RADONCOLOGY,
  REFERRAL_SOCIAL,
  REFERRAL_SURGERY,
  REFERRAL_THERAPY,
} from "../../constants/treatment/SelectQuestions";
import { PanelContext } from "../../context/panel-context/PanelContext";
import TreatmentQuiz from "./treatment/TreatmentQuiz";

interface IProps {}

function DisplayFormItem(props: {
  name: string;
  quiz: any;
  show: boolean;
  Component: any;
  query: string;
}) {
  const { show, Component, name, quiz, query } = props;
  return (
    <div
      style={{
        display: show ? "block" : "none",
      }}
    >
      <Component name={name} quiz={quiz} query={query} />
    </div>
  );
}

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update the state to force render
}

const MainWorkup: React.FC<IProps> = (props) => {
  const {
    setIsDisable,
    isQuizFinish,
    setNewOrderValue,
    editOrderFormData,
    form,
    onFormChangeListen,
    listActive,
    setlistActive,
    icd10,
  } = React.useContext(PanelContext);

  const history = useHistory();
  const query = new URLSearchParams(window.location.search);
  const params: any = useParams();

  // const [isTags, setIsTags] = useState("Main");
  const [icdView, setIcdView] = useState<any>([]);
  const forceUpdate = useForceUpdate();
  const breastItem = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
  ];

  const breastLabel = ["12:00", "9:00", "6:00", "3:00"];

  const clears = () => {
    form.setFieldsValue({
      panelICD10: [],
    });
    setNewOrderValue("file", []);
    message.success("cleared!");
  };

  const deselect = () => {
    setIcdView([]);
  };

  const active = (e: any) => {
    // console.log(icd10, icd10?.includes(ICD10CODES[e.target.id - 1]), [
    //   ICD10CODES[e.target.id - 1],
    // ]);
    if (
      form
        .getFieldValue("panelICD10")
        ?.includes(ICD10CODES[e.target.id - 1]?.value)
    ) {
      form.setFieldsValue({
        panelICD10: form
          .getFieldValue("panelICD10")
          .filter((ele: any) => ele !== ICD10CODES[e.target.id - 1]?.value),
      });
    } else {
      form.setFieldsValue({
        panelICD10: form.getFieldValue("panelICD10")
          ? [
              ...form.getFieldValue("panelICD10"),
              ICD10CODES[e.target.id - 1]?.value,
            ]
          : [ICD10CODES[e.target.id - 1]?.value],
      });
    }
    forceUpdate();
  };

  return (
    <>
      {query.get("item") !== "surgeryWorkup" &&
        query.get("item") !== "rad-oncology" &&
        query.get("item") !== "therapy" &&
        query.get("item") !== "patient" &&
        query.get("item") !== "nutrition" &&
        query.get("item") !== "social" &&
        query.get("item") !== "med-oncology" &&
        query.get("item") !== "genetic" && (
          <div className="info">
            <SelectCustom
              name="pathology"
              label="Diagnostic Pathology Results"
              options={PATHOLOGY}
              rq={false}
            />
            <div className="age-status">
              <div className="receptorStatus">
                <SelectCustom
                  name="tumorSize"
                  label="Tumor Size"
                  options={TUMOR}
                  rq={false}
                />
                <SelectCustom
                  name="lymph"
                  label="Lymph Node Status"
                  options={LYMPH}
                  initialValue="No evidence of lymph node involvement"
                  rq={false}
                />
                <SelectCustom
                  name="metastasisClinical"
                  label="Evidence of distant metastasis"
                  options={METASTASISCLINICAL}
                  rq={false}
                />
                <SkinSelect />
                <SelectCustom
                  name="menopausal"
                  label="Menopausal Status"
                  options={MENOPAUSAL}
                  rq={false}
                />
              </div>
              <div className="receptorStatus">
                <SelectCustom
                  name="estrogen"
                  label="Estrogen Receptor Status"
                  options={ESTROGEN}
                  rq={false}
                />
                <SelectCustom
                  name="progesterone"
                  label="Progesterone Receptor"
                  options={ESTROGEN}
                  rq={false}
                />
                <SelectCustom
                  name="HER2"
                  label="HER2/neu status"
                  options={HER2}
                  rq={false}
                />
                <SelectCustom
                  name="ki67"
                  label="Ki-67"
                  options={KI67}
                  rq={false}
                />
              </div>
            </div>
            <div
              className="select-lesion-location"
              style={{ fontWeight: 800, color: "black", fontSize: "larger" }}
            >
              Use mouse to select lesion location(s) on the following site map.
              Multiple selection are possible
              <div className="img-detail">
                <Form.Item
                  name="file"
                  className="file"
                  rules={[
                    {
                      required: false,
                      message: `Please don't leave it blank !`,
                    },
                  ]}
                >
                  {breastItem.map((item) => {
                    return (
                      <div
                        id={item}
                        className={`breast-${item} ${
                          form
                            .getFieldValue("panelICD10")
                            ?.includes(ICD10CODES[Number(item) - 1]?.value)
                            ? "active"
                            : ""
                        }`}
                        onClick={active}
                      ></div>
                    );
                  })}
                  {breastLabel.map((item) => {
                    return (
                      <span id={item} className={`left-${item.slice(0, -3)}`}>
                        {item}
                      </span>
                    );
                  })}
                  {breastLabel.map((item) => {
                    return (
                      <span id={item} className={`right-${item.slice(0, -3)}`}>
                        {item}
                      </span>
                    );
                  })}
                  {/* <SignatureCanvas
                    penColor="blue"
                    canvasProps={{ width: 400, height: 200, className: "canvas-img" }}
                    ref={(ref) => {
                        sigPads.current = ref;

                        if (imagesURL() && sigPads.current && sigPads.current.fromDataURL) {
                            sigPads.current.fromDataURL(imagesURL(), {
                                width: 390,
                                height: 200
                            });ß
                        };
                    }}
                /> */}
                  {/* <img src={a} /> */}
                  {/* <img  style={{ width: '100%' }} src={fmImg} alt="" /> */}
                  {/* <img  style={{ width: '100%' }} src={fmImg} alt="" /> */}
                  {/* <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        beforeUpload={file => {
                            return false;
                        }}
                        onChange={handleChange}
                        maxCount={2}
                    >
                        {getFieldByName("file") ? <img className="imguploads" 
                            src={`${'http://localhost:3001/api/uploads'}/${getFieldByName("file").value}`}
                            alt=""
                        /> : uploadButton}
                    </Upload> */}
                  <div className="img-draw">
                    <div
                      className="clear-draw"
                      style={{
                        display: `${
                          form.getFieldValue("panelICD10")?.length < 1
                            ? "none"
                            : ""
                        }`,
                      }}
                      onClick={clears}
                    >
                      Clear
                    </div>
                  </div>
                </Form.Item>
              </div>
            </div>

            <MultiSelect
              className="summary"
              deselect={deselect}
              name="panelICD10"
              label="Cancer Panel ICD-10 Codes (Females)"
              options={ICD10CODES}
              rq={false}
            />
            <MultiSelect
              name="preopOrders"
              label="Pre-op Orders Labs"
              options={PREOPORDER_ITEMS}
              rq={false}
            />
            <MultiSelect
              name="preopRadiology"
              label="Pre-op Radiology Studies"
              options={PREOPRADIO_ITEMS}
              rq={false}
            />
            <div className="pre-op-orders-referrals-container-workup">
              <div className="pre-op-orders-inner">
                <Button
                  className={
                    isQuizFinish[`surgeryWorkup`]
                      ? "new-order-btn middle done"
                      : "new-order-btn middle"
                  }
                  onClick={() => (
                    setIsDisable(true),
                    history.push(`work-up?item=surgeryWorkup`)
                  )}
                >
                  <p>
                    Surgery
                    {isQuizFinish[`surgeryWorkup`] ? (
                      <CheckOutlined className="dashboard-item-arrow green" />
                    ) : (
                      <RightOutlined className="dashboard-item-arrow" />
                    )}
                  </p>
                </Button>
                <Button
                  className={
                    isQuizFinish[`social`]
                      ? "new-order-btn middle done"
                      : "new-order-btn middle"
                  }
                  onClick={() => (
                    setIsDisable(true), history.push(`work-up?item=social`)
                  )}
                >
                  <p>
                    Social Work
                    {isQuizFinish[`social`] ? (
                      <CheckOutlined className="dashboard-item-arrow green" />
                    ) : (
                      <RightOutlined className="dashboard-item-arrow" />
                    )}
                  </p>
                </Button>
                <Button
                  className={
                    isQuizFinish[`medOncology`]
                      ? "new-order-btn middle done"
                      : "new-order-btn middle"
                  }
                  onClick={() => (
                    setIsDisable(true),
                    history.push(`work-up?item=med-oncology`)
                  )}
                >
                  <p>
                    Medical Oncology
                    {isQuizFinish[`medOncology`] ? (
                      <CheckOutlined className="dashboard-item-arrow green" />
                    ) : (
                      <RightOutlined className="dashboard-item-arrow" />
                    )}
                  </p>
                </Button>
              </div>
              <div className="pre-op-orders-inner">
                <Button
                  className={
                    isQuizFinish[`radOncology`]
                      ? "new-order-btn middle done"
                      : "new-order-btn middle"
                  }
                  onClick={() => (
                    setIsDisable(true),
                    history.push(`work-up?item=rad-oncology`)
                  )}
                >
                  <p>
                    Radiation Oncology
                    {isQuizFinish[`radOncology`] ? (
                      <CheckOutlined className="dashboard-item-arrow green" />
                    ) : (
                      <RightOutlined className="dashboard-item-arrow" />
                    )}
                  </p>
                </Button>
                <Button
                  className={
                    isQuizFinish[`patientNav`]
                      ? "new-order-btn middle done"
                      : "new-order-btn middle"
                  }
                  onClick={() => (
                    setIsDisable(true), history.push(`work-up?item=patient`)
                  )}
                >
                  <p>
                    Patient Navigation
                    {isQuizFinish[`patientNav`] ? (
                      <CheckOutlined className="dashboard-item-arrow green" />
                    ) : (
                      <RightOutlined className="dashboard-item-arrow" />
                    )}
                  </p>
                </Button>
                <Button
                  className={
                    isQuizFinish[`therapy`]
                      ? "new-order-btn middle done"
                      : "new-order-btn middle"
                  }
                  onClick={() => (
                    setIsDisable(true), history.push(`work-up?item=therapy`)
                  )}
                >
                  <p>
                    Physical <br />
                    /Occupational therapy
                    {isQuizFinish[`therapy`] ? (
                      <CheckOutlined className="dashboard-item-arrow green" />
                    ) : (
                      <RightOutlined className="dashboard-item-arrow" />
                    )}
                  </p>
                </Button>
              </div>
              <div className="pre-op-orders-inner">
                <Button
                  className={
                    isQuizFinish[`nutrition`]
                      ? "new-order-btn middle done"
                      : "new-order-btn middle"
                  }
                  onClick={() => (
                    setIsDisable(true), history.push(`work-up?item=nutrition`)
                  )}
                >
                  <p>
                    Nutrition
                    {isQuizFinish[`nutrition`] ? (
                      <CheckOutlined className="dashboard-item-arrow green" />
                    ) : (
                      <RightOutlined className="dashboard-item-arrow" />
                    )}
                  </p>
                </Button>
                <Button
                  className={
                    isQuizFinish[`genetic`]
                      ? "new-order-btn middle done"
                      : "new-order-btn middle"
                  }
                  onClick={() => (
                    setIsDisable(true), history.push(`work-up?item=genetic`)
                  )}
                >
                  <p>
                    Genetic counseling
                    {isQuizFinish[`genetic`] ? (
                      <CheckOutlined className="dashboard-item-arrow green" />
                    ) : (
                      <RightOutlined className="dashboard-item-arrow" />
                    )}
                  </p>
                </Button>
                <div
                  style={{ opacity: 0 }}
                  className={
                    isQuizFinish[`genetic`]
                      ? "new-order-btn middle done"
                      : "new-order-btn middle"
                  }
                  onClick={() => (
                    setIsDisable(true), history.push(`work-up?item=genetic`)
                  )}
                >
                  <p>
                    Genetic counseling
                    {isQuizFinish[`genetic`] ? (
                      <CheckOutlined className="dashboard-item-arrow green" />
                    ) : (
                      <RightOutlined className="dashboard-item-arrow" />
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      <DisplayFormItem
        show={
          params.isTags === "work-up" && query.get("item") === "surgeryWorkup"
        }
        name="surgeryWorkup"
        quiz={REFERRAL_SURGERY}
        query={`item=${query.get("item")}`}
        Component={TreatmentQuiz}
      />
      <DisplayFormItem
        show={params.isTags === "work-up" && query.get("item") === "social"}
        name="social"
        quiz={REFERRAL_SOCIAL}
        query={`item=${query.get("item")}`}
        Component={TreatmentQuiz}
      />
      <DisplayFormItem
        show={
          params.isTags === "work-up" && query.get("item") === "med-oncology"
        }
        name="medOncology"
        quiz={REFERRAL_MEDONCOLOGY}
        query={`item=${query.get("item")}`}
        Component={TreatmentQuiz}
      />
      <DisplayFormItem
        show={
          params.isTags === "work-up" && query.get("item") === "rad-oncology"
        }
        name="radOncology"
        quiz={REFERRAL_RADONCOLOGY}
        query={`item=${query.get("item")}`}
        Component={TreatmentQuiz}
      />
      <DisplayFormItem
        show={params.isTags === "work-up" && query.get("item") === "nutrition"}
        name="nutrition"
        quiz={REFERRAL_NUTRITION}
        query={`item=${query.get("item")}`}
        Component={TreatmentQuiz}
      />
      <DisplayFormItem
        show={params.isTags === "work-up" && query.get("item") === "therapy"}
        name="therapy"
        quiz={REFERRAL_THERAPY}
        query={`item=${query.get("item")}`}
        Component={TreatmentQuiz}
      />
      <DisplayFormItem
        show={params.isTags === "work-up" && query.get("item") === "patient"}
        name="patientNav"
        quiz={REFERRAL_PATIENT}
        query={`item=${query.get("item")}`}
        Component={TreatmentQuiz}
      />
      <DisplayFormItem
        show={params.isTags === "work-up" && query.get("item") === "genetic"}
        name="genetic"
        quiz={REFERRAL_GENETIC}
        query={`item=${query.get("item")}`}
        Component={TreatmentQuiz}
      />
    </>
  );
};

export default MainWorkup;
