import reqAxios from "./reqAxios";

const fetchActiveAlerts = (clientId: string) => {
  return reqAxios().get(`/alerts?clientId=${clientId}`);
};

const fetchAlertsByPatient = (_id: string, clientId: string) => {
  return reqAxios().get(`/alerts/patient/${_id}?clientId=${clientId}`);
};

const fetchAlertsByOrder = (_id: string, clientId: string) => {
  return reqAxios().get(`/alerts/order/${_id}?clientId=${clientId}`);
};

const newAlert = (data: any) => {
  return reqAxios().post("/alerts", data);
};

const updateAlert = (data: any, _id: string) => {
  return reqAxios().put(`/alerts/${_id}`, data);
};

export const AlertAPI = {
  newAlert,
  updateAlert,
  fetchActiveAlerts,
  fetchAlertsByPatient,
  fetchAlertsByOrder,
};
