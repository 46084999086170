import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import { Button, Popover, Typography } from "antd";

import QuizSideBar from "./QuizSideBar";
import RadioCustom from "../../../components/forms/RadioCustom";
import CheckboxCustom from "../../../components/forms/CheckboxQuiz";
import { PanelContext } from "../../../context/panel-context/PanelContext";

interface IProps {
  name: string;
  quiz: any;
  query: string;
}

const DisplayQuestion = (props: { show: boolean; Component: any }) => {
  const { show, Component } = props;
  return <div style={{ display: show ? "block" : "none" }}>{Component}</div>;
};

const TreatmentQuiz: React.FC<IProps> = (props) => {
  const { Title } = Typography;
  const history = useHistory();
  const {
    form,
    isQuizFinish,
    setIsQuizFinish,
    setNewOrderValue,
    setEditOrderValue,
    preopLabs,
    preopStudies,
  } = useContext(PanelContext);

  let labs = [];
  if (preopLabs !== undefined) {
    labs = preopLabs;
  } else {
    labs = [];
  }

  let studies = [];
  if (preopStudies !== undefined) {
    studies = preopStudies;
  } else {
    studies = [];
  }

  const quizName = props.name.includes("left")
    ? props.name.replace("left", " Left")
    : props.name.replace("right", " Right");
  const query: any = new URLSearchParams(window.location.search);

  const [currentQuestionId, setCurrentQuestionId] = useState([1]);

  const currentQuestion = currentQuestionId.map((id: any) =>
    props.quiz.find((ele: any) => ele._id === id)
  );
  const quizData = form.getFieldValue(props.name);

  const changeRenderItem = () => history.goBack();

  const extractSummary = () => {
    let summary: any;
    const entireSummary = document.getElementById("summary-details");
    if (entireSummary?.textContent === "") {
      const partialSummaries = document.querySelectorAll("#summary-detail");
      partialSummaries.forEach(({ textContent }) => (summary += textContent));
    } else {
      summary = entireSummary?.textContent;
    }
    const matches = summary.match(
      /CPT:\s\d{1,5}(\s)?(or\s)?(\d{1,5})?(\(.+\),\s.+)?([X\s\d{1,}(\-)?,\s]+)?/gm
    );
    const cpts = matches?.join(" || ").replace(/CPT:\s(\n)?/g, "") || "";
    summary = summary.replace(/undefined/g, "");

    // TODO: We need to clean this up so that only one context state contains the updated values.
    setNewOrderValue("shortSummary", cpts);
    setNewOrderValue("detailedSummary", summary);
    setEditOrderValue("shortSummary", cpts);
    setEditOrderValue("detailedSummary", summary);
  };

  const handlePreviousQuestion = () => {
    if (currentQuestion[0]?.preQuestionId)
      setCurrentQuestionId(currentQuestion[0]?.preQuestionId);
  };

  const handleNextQuestion = (_id: any) => {
    setCurrentQuestionId(_id);
  };

  const handleStartOver = () => {
    form.setFieldsValue({ [props.name]: undefined });
    setIsQuizFinish({ ...isQuizFinish, [props.name]: false });
    setCurrentQuestionId([1]);
  };

  return (
    <div className="pre-op-orders-referrals-container">
      <div className={"pre-op-orders-container"}>
        <div
          className="back-btn"
          onClick={() =>
            currentQuestionId.includes(1)
              ? changeRenderItem()
              : handlePreviousQuestion()
          }
        >
          <LeftOutlined className="dashboard-item-arrow" />
          <span>Back</span>
        </div>
      </div>
      <div className="quiz-container">
        <div className="quiz-left">
          {currentQuestion?.map((question: any, indexFather) => {
            return (
              <>
                <div className="quizs">
                  {props.quiz.map((data: any) => (
                    <DisplayQuestion
                      show={data._id === question._id}
                      Component={
                        !question.isMultiAnswers ? (
                          <RadioCustom
                            name={[
                              props.name,
                              String(question._id),
                              "father",
                              "answer",
                            ]}
                            grandChildData={data.answers.map(
                              (fatherAnswer: any, fatherindex: number) => ({
                                ...fatherAnswer,
                                formname: [
                                  props.name,
                                  String(question._id),
                                  "grandChild",
                                  "answer",
                                  String(fatherindex),
                                ],
                              })
                            )}
                            question={data.question}
                            instruction={data.instruction}
                            answers={data.answers.map(
                              (fatheranswer: any, fatherindex: number) => ({
                                ...fatheranswer,
                                formname: [
                                  props.name,
                                  String(question._id),
                                  "child",
                                  "answer",
                                  String(fatherindex),
                                ],
                              })
                            )}
                            questionId={data._id}
                            criterianame={[
                              props.name,
                              String(question._id),
                              "father",
                              "criteria",
                            ]}
                          />
                        ) : (
                          <CheckboxCustom
                            name={[
                              props.name,
                              String(question._id),
                              "father",
                              "answer",
                            ]}
                            grandChildData={data.answers.map(
                              (fatherAnswer: any, fatherindex: number) => ({
                                ...fatherAnswer,
                                formname: [
                                  props.name,
                                  String(question._id),
                                  "grandChild",
                                  "answer",
                                  String(fatherindex),
                                ],
                              })
                            )}
                            question={data.question}
                            instruction={data.instruction}
                            answers={data.answers.map(
                              (fatheranswer: any, fatherindex: number) => ({
                                ...fatheranswer,
                                formname: [
                                  props.name,
                                  String(question._id),
                                  "child",
                                  "answer",
                                  String(fatherindex),
                                ],
                              })
                            )}
                            questionId={data._id}
                            criterianame={[
                              props.name,
                              String(question._id),
                              "father",
                              "criteria",
                            ]}
                          />
                        )
                      }
                    />
                  ))}
                </div>
                {indexFather === currentQuestion.length - 1 && (
                  <div className="action-radio">
                    {quizData &&
                      quizData[question._id] &&
                      (question.isMultiAnswers ? (
                        quizData[question._id].father?.answer.length > 0 &&
                        quizData[question._id].father?.answer.some(
                          (ans: any) => {
                            return (
                              question.answers[ans]?.isCriteria ||
                              (question.answers[ans]?.childrenAnswer &&
                                question.answers[ans]?.childrenAnswer[
                                  quizData[question._id].child?.answer[ans]
                                    ?.radio
                                ]?.isCriteria) ||
                              (quizData[question._id].child?.answer[ans]
                                ?.checkbox?.length > 0 &&
                                quizData[question._id].child?.answer[
                                  ans
                                ]?.checkbox.some(
                                  (childans: any) =>
                                    question.answers[ans]?.childrenAnswer[
                                      childans
                                    ]?.isCriteria
                                ))
                            );
                          }
                        ) ? (
                          <Popover
                            placement="bottom"
                            content={
                              <div>
                                <p>Select all of those criterias!</p>
                              </div>
                            }
                            title="To next or finish"
                          >
                            {quizData[question._id].father?.answer
                              .map((quizans: any) => question.answers[quizans])
                              .some((ans: any) => ans.nextQuestionId) ? (
                              <Button
                                onClick={
                                  (quizData[question._id].father &&
                                    quizData[question._id].father?.criteria &&
                                    (quizData[question._id].father?.answer
                                      ?.length > 0
                                      ? quizData[
                                          question._id
                                        ].father?.answer.some((ans: any) => {
                                          return (
                                            quizData[question._id].father
                                              ?.criteria[ans]?.length ===
                                              question.answers[
                                                quizData[question._id].father
                                                  ?.answer
                                              ]?.criteria?.length ||
                                            (quizData[question._id].child
                                              ?.criteria &&
                                              quizData[question._id].child
                                                ?.answer &&
                                              (quizData[question._id].child
                                                ?.answer[ans]?.radio
                                                ? quizData[question._id].child
                                                    ?.criteria[
                                                    quizData[question._id]
                                                      .father?.answer
                                                  ][
                                                    quizData[question._id].child
                                                      ?.answer[ans]?.radio
                                                  ]?.length ===
                                                  (question.answers[
                                                    quizData[question._id]
                                                      .father?.answer
                                                  ].childrenAnswer &&
                                                    question.answers[
                                                      quizData[question._id]
                                                        .father?.answer
                                                    ].childrenAnswer[
                                                      quizData[question._id]
                                                        .child?.answer[ans]
                                                        ?.radio
                                                    ]?.criteria?.length)
                                                : quizData[
                                                    question._id
                                                  ].child?.answer[
                                                    ans
                                                  ]?.checkbox.some(
                                                    (childans: any) => {
                                                      return (
                                                        quizData[question._id]
                                                          .child?.criteria[
                                                          childans
                                                        ]?.length ===
                                                        (question.answers[
                                                          quizData[question._id]
                                                            .father?.answer
                                                        ].childrenAnswer &&
                                                          question.answers[
                                                            quizData[
                                                              question._id
                                                            ].father?.answer
                                                          ].childrenAnswer[
                                                            childans
                                                          ]?.criteria?.length)
                                                      );
                                                    }
                                                  )))
                                          );
                                        })
                                      : quizData[question._id].father?.criteria[
                                          quizData[question._id].father?.answer
                                        ]?.length ===
                                        question.answers[
                                          quizData[question._id].father?.answer
                                        ]?.criteria?.length)) ||
                                  (quizData[question._id].child?.criteria &&
                                    quizData[question._id].child?.answer &&
                                    (quizData[question._id].child?.answer[
                                      quizData[question._id].father?.answer
                                    ]?.radio
                                      ? quizData[question._id].child?.criteria[
                                          quizData[question._id].father?.answer
                                        ][
                                          quizData[question._id].child?.answer[
                                            quizData[question._id].father
                                              ?.answer
                                          ]?.radio
                                        ]?.length ===
                                        (question.answers[
                                          quizData[question._id].father?.answer
                                        ].childrenAnswer &&
                                          question.answers[
                                            quizData[question._id].father
                                              ?.answer
                                          ].childrenAnswer[
                                            quizData[question._id].child
                                              ?.answer[
                                              quizData[question._id].father
                                                ?.answer
                                            ]?.radio
                                          ]?.criteria?.length)
                                      : quizData[question._id].child?.answer[
                                          quizData[question._id].father?.answer
                                        ]?.checkbox.some((childans: any) => {
                                          return (
                                            quizData[question._id].child
                                              ?.criteria[childans]?.length ===
                                            (question.answers[
                                              quizData[question._id].father
                                                ?.answer
                                            ].childrenAnswer &&
                                              question.answers[
                                                quizData[question._id].father
                                                  ?.answer
                                              ].childrenAnswer[childans]
                                                ?.criteria?.length)
                                          );
                                        })))
                                    ? () => {
                                        let nextViewQues: any = [];
                                        quizData[
                                          question._id
                                        ].father?.answer.forEach(
                                          (multians: any) =>
                                            question.answers[
                                              multians
                                            ].nextQuestionId?.forEach(
                                              (mulnextques: any) =>
                                                nextViewQues.push(mulnextques)
                                            )
                                        );
                                        handleNextQuestion(nextViewQues);
                                      }
                                    : () => {}
                                }
                                type="primary"
                                className="accept-form-button"
                              >
                                Next
                              </Button>
                            ) : (
                              <Button
                                onClick={
                                  (quizData[question._id].father &&
                                    quizData[question._id].father?.criteria &&
                                    (quizData[question._id].father?.answer
                                      ?.length > 0
                                      ? quizData[
                                          question._id
                                        ].father?.answer.some(
                                          (ans: any) =>
                                            quizData[question._id].father
                                              ?.criteria[ans]?.length ===
                                              question.answers[
                                                quizData[question._id].father
                                                  ?.answer
                                              ]?.criteria?.length ||
                                            (quizData[question._id].child
                                              ?.criteria &&
                                              quizData[question._id].child
                                                ?.answer &&
                                              (quizData[question._id].child
                                                ?.answer[ans]?.radio
                                                ? quizData[question._id].child
                                                    ?.criteria[
                                                    quizData[question._id]
                                                      .father?.answer
                                                  ][
                                                    quizData[question._id].child
                                                      ?.answer[ans]?.radio
                                                  ]?.length ===
                                                  (question.answers[
                                                    quizData[question._id]
                                                      .father?.answer
                                                  ].childrenAnswer &&
                                                    question.answers[
                                                      quizData[question._id]
                                                        .father?.answer
                                                    ].childrenAnswer[
                                                      quizData[question._id]
                                                        .child?.answer[ans]
                                                        ?.radio
                                                    ]?.criteria?.length)
                                                : quizData[
                                                    question._id
                                                  ].child?.answer[
                                                    ans
                                                  ]?.checkbox.some(
                                                    (childans: any) => {
                                                      return (
                                                        quizData[question._id]
                                                          .child?.criteria[
                                                          quizData[question._id]
                                                            .father?.answer
                                                        ][childans]?.length ===
                                                        (question.answers[
                                                          quizData[question._id]
                                                            .father?.answer
                                                        ].childrenAnswer &&
                                                          question.answers[
                                                            quizData[
                                                              question._id
                                                            ].father?.answer
                                                          ].childrenAnswer[
                                                            childans
                                                          ]?.criteria?.length)
                                                      );
                                                    }
                                                  )))
                                        )
                                      : quizData[question._id].father?.criteria[
                                          quizData[question._id].father?.answer
                                        ]?.length ===
                                        question.answers[
                                          quizData[question._id].father?.answer
                                        ]?.criteria?.length)) ||
                                  (quizData[question._id].child?.criteria &&
                                    quizData[question._id].child?.answer &&
                                    (quizData[question._id].child?.answer[
                                      quizData[question._id].father?.answer
                                    ]?.radio
                                      ? quizData[question._id].child?.criteria[
                                          quizData[question._id].father?.answer
                                        ][
                                          quizData[question._id].child?.answer[
                                            quizData[question._id].father
                                              ?.answer
                                          ]?.radio
                                        ]?.length ===
                                        (question.answers[
                                          quizData[question._id].father?.answer
                                        ].childrenAnswer &&
                                          question.answers[
                                            quizData[question._id].father
                                              ?.answer
                                          ].childrenAnswer[
                                            quizData[question._id].child
                                              ?.answer[
                                              quizData[question._id].father
                                                ?.answer
                                            ]?.radio
                                          ]?.criteria?.length)
                                      : quizData[question._id].child?.answer[
                                          quizData[question._id].father?.answer
                                        ]?.checkbox.some((childans: any) => {
                                          return (
                                            quizData[question._id].child
                                              ?.criteria[
                                              quizData[question._id].father
                                                ?.answer
                                            ][childans]?.length ===
                                            (question.answers[
                                              quizData[question._id].father
                                                ?.answer
                                            ].childrenAnswer &&
                                              question.answers[
                                                quizData[question._id].father
                                                  ?.answer
                                              ].childrenAnswer[childans]
                                                ?.criteria?.length)
                                          );
                                        })))
                                    ? () => {
                                        setIsQuizFinish({
                                          ...isQuizFinish,
                                          [props.name]: true,
                                        });
                                        extractSummary();
                                        changeRenderItem();
                                      }
                                    : () => {}
                                }
                                type="primary"
                                className="accept-form-button"
                              >
                                Finish
                              </Button>
                            )}
                          </Popover>
                        ) : quizData[question._id].father?.answer
                            .map((quizans: any) => question.answers[quizans])
                            .some((ans: any) => ans.nextQuestionId) ? (
                          <Button
                            onClick={() => {
                              let nextViewQues: any = [];
                              quizData[question._id].father?.answer.forEach(
                                (multians: any) =>
                                  question.answers[
                                    multians
                                  ].nextQuestionId?.forEach(
                                    (mulnextques: any) =>
                                      nextViewQues.push(mulnextques)
                                  )
                              );
                              handleNextQuestion(nextViewQues);
                            }}
                            type="primary"
                            className="accept-form-button"
                          >
                            Next
                          </Button>
                        ) : (
                          <Button
                            onClick={() => {
                              setIsQuizFinish({
                                ...isQuizFinish,
                                [props.name]: true,
                              });
                              changeRenderItem();
                              extractSummary();
                            }}
                            type="primary"
                            className="accept-form-button"
                          >
                            Finish
                          </Button>
                        )
                      ) : question.answers[
                          quizData[question._id].father?.answer
                        ]?.isCriteria ||
                        (question.answers[quizData[question._id].father?.answer]
                          ?.childrenAnswer &&
                          question.answers[quizData[question._id].father.answer]
                            ?.childrenAnswer[
                            quizData[question._id].child?.answer[
                              quizData[question._id].father?.answer
                            ]?.radio
                          ]?.isCriteria) ||
                        (quizData[question._id].child?.answer[
                          quizData[question._id].father?.answer
                        ]?.checkbox?.length > 0 &&
                          quizData[question._id].child?.answer[
                            quizData[question._id].father?.answer
                          ]?.checkbox.some(
                            (childans: any) =>
                              question.answers[
                                quizData[question._id].father?.answer
                              ]?.childrenAnswer[childans]?.isCriteria
                          )) ? (
                        <Popover
                          placement="bottom"
                          content={
                            <div>
                              <p>Select all of those criterias!</p>
                            </div>
                          }
                          title="To next or finish"
                        >
                          {question.answers[
                            quizData[question._id].father?.answer
                          ].nextQuestionId ? (
                            <Button
                              onClick={
                                (quizData[question._id].father &&
                                  quizData[question._id].father?.criteria &&
                                  (quizData[question._id].father?.answer
                                    ?.length > 0
                                    ? quizData[
                                        question._id
                                      ].father?.answer.some((ans: any) => {
                                        return (
                                          quizData[question._id].father
                                            ?.criteria[ans]?.length ===
                                            question.answers[
                                              quizData[question._id].father
                                                ?.answer
                                            ]?.criteria?.length ||
                                          (quizData[question._id].child
                                            ?.criteria &&
                                            quizData[question._id].child
                                              ?.answer &&
                                            (quizData[question._id].child
                                              ?.answer[ans]?.radio
                                              ? quizData[question._id].child
                                                  ?.criteria[
                                                  quizData[question._id].father
                                                    ?.answer
                                                ][
                                                  quizData[question._id].child
                                                    ?.answer[ans]?.radio
                                                ]?.length ===
                                                (question.answers[
                                                  quizData[question._id].father
                                                    ?.answer
                                                ].childrenAnswer &&
                                                  question.answers[
                                                    quizData[question._id]
                                                      .father?.answer
                                                  ].childrenAnswer[
                                                    quizData[question._id].child
                                                      ?.answer[ans]?.radio
                                                  ]?.criteria?.length)
                                              : quizData[
                                                  question._id
                                                ].child?.answer[
                                                  ans
                                                ]?.checkbox.some(
                                                  (childans: any) => {
                                                    return (
                                                      quizData[question._id]
                                                        .child?.criteria[
                                                        quizData[question._id]
                                                          .father?.answer
                                                      ][childans]?.length ===
                                                      (question.answers[
                                                        quizData[question._id]
                                                          .father?.answer
                                                      ].childrenAnswer &&
                                                        question.answers[
                                                          quizData[question._id]
                                                            .father?.answer
                                                        ].childrenAnswer[
                                                          childans
                                                        ]?.criteria?.length)
                                                    );
                                                  }
                                                )))
                                        );
                                      })
                                    : quizData[question._id].father?.criteria[
                                        quizData[question._id].father?.answer
                                      ]?.length ===
                                      question.answers[
                                        quizData[question._id].father?.answer
                                      ]?.criteria?.length)) ||
                                (quizData[question._id].child?.criteria &&
                                  quizData[question._id].child?.answer &&
                                  (quizData[question._id].child?.answer[
                                    quizData[question._id].father?.answer
                                  ]?.radio
                                    ? quizData[question._id].child?.criteria[
                                        quizData[question._id].father?.answer
                                      ][
                                        quizData[question._id].child?.answer[
                                          quizData[question._id].father?.answer
                                        ]?.radio
                                      ]?.length ===
                                      (question.answers[
                                        quizData[question._id].father?.answer
                                      ].childrenAnswer &&
                                        question.answers[
                                          quizData[question._id].father?.answer
                                        ].childrenAnswer[
                                          quizData[question._id].child?.answer[
                                            quizData[question._id].father
                                              ?.answer
                                          ]?.radio
                                        ]?.criteria?.length)
                                    : quizData[question._id].child?.answer[
                                        quizData[question._id].father?.answer
                                      ]?.checkbox.some((childans: any) => {
                                        return (
                                          quizData[question._id].child
                                            ?.criteria[childans]?.length ===
                                          (question.answers[
                                            quizData[question._id].father
                                              ?.answer
                                          ].childrenAnswer &&
                                            question.answers[
                                              quizData[question._id].father
                                                ?.answer
                                            ].childrenAnswer[childans]?.criteria
                                              ?.length)
                                        );
                                      })))
                                  ? () =>
                                      handleNextQuestion(
                                        question.answers[
                                          quizData[question._id].father?.answer
                                        ].nextQuestionId
                                      )
                                  : () => {}
                              }
                              type="primary"
                              className="accept-form-button"
                            >
                              Next
                            </Button>
                          ) : (
                            <Button
                              onClick={
                                (quizData[question._id].father &&
                                  quizData[question._id].father?.criteria &&
                                  (quizData[question._id].father?.answer
                                    ?.length > 0
                                    ? quizData[
                                        question._id
                                      ].father?.answer.some(
                                        (ans: any) =>
                                          quizData[question._id].father
                                            ?.criteria[ans]?.length ===
                                            question.answers[
                                              quizData[question._id].father
                                                ?.answer
                                            ]?.criteria?.length ||
                                          (quizData[question._id].child
                                            ?.criteria &&
                                            quizData[question._id].child
                                              ?.answer &&
                                            (quizData[question._id].child
                                              ?.answer[ans]?.radio
                                              ? quizData[question._id].child
                                                  ?.criteria[
                                                  quizData[question._id].father
                                                    ?.answer
                                                ][
                                                  quizData[question._id].child
                                                    ?.answer[ans]?.radio
                                                ]?.length ===
                                                (question.answers[
                                                  quizData[question._id].father
                                                    ?.answer
                                                ].childrenAnswer &&
                                                  question.answers[
                                                    quizData[question._id]
                                                      .father?.answer
                                                  ].childrenAnswer[
                                                    quizData[question._id].child
                                                      ?.answer[ans]?.radio
                                                  ]?.criteria?.length)
                                              : quizData[
                                                  question._id
                                                ].child?.answer[
                                                  ans
                                                ]?.checkbox.some(
                                                  (childans: any) =>
                                                    quizData[question._id].child
                                                      ?.criteria[
                                                      quizData[question._id]
                                                        .father?.answer
                                                    ][childans]?.length ===
                                                    (question.answers[
                                                      quizData[question._id]
                                                        .father?.answer
                                                    ].childrenAnswer &&
                                                      question.answers[
                                                        quizData[question._id]
                                                          .father?.answer
                                                      ].childrenAnswer[childans]
                                                        ?.criteria?.length)
                                                )))
                                      )
                                    : quizData[question._id].father?.criteria[
                                        quizData[question._id].father?.answer
                                      ]?.length ===
                                      question.answers[
                                        quizData[question._id].father?.answer
                                      ]?.criteria?.length)) ||
                                (quizData[question._id].child?.criteria &&
                                  quizData[question._id].child?.answer &&
                                  (quizData[question._id].child?.answer[
                                    quizData[question._id].father?.answer
                                  ]?.radio
                                    ? quizData[question._id].child?.criteria[
                                        quizData[question._id].father?.answer
                                      ][
                                        quizData[question._id].child?.answer[
                                          quizData[question._id].father?.answer
                                        ]?.radio
                                      ]?.length ===
                                      (question.answers[
                                        quizData[question._id].father?.answer
                                      ].childrenAnswer &&
                                        question.answers[
                                          quizData[question._id].father?.answer
                                        ].childrenAnswer[
                                          quizData[question._id].child?.answer[
                                            quizData[question._id].father
                                              ?.answer
                                          ]?.radio
                                        ]?.criteria?.length)
                                    : quizData[question._id].child?.answer[
                                        quizData[question._id].father?.answer
                                      ]?.checkbox.some(
                                        (childans: any) =>
                                          quizData[question._id].child
                                            ?.criteria[
                                            quizData[question._id].father
                                              ?.answer
                                          ][childans]?.length ===
                                          (question.answers[
                                            quizData[question._id].father
                                              ?.answer
                                          ].childrenAnswer &&
                                            question.answers[
                                              quizData[question._id].father
                                                ?.answer
                                            ].childrenAnswer[childans]?.criteria
                                              ?.length)
                                      )))
                                  ? () => {
                                      setIsQuizFinish({
                                        ...isQuizFinish,
                                        [props.name]: true,
                                      });
                                      extractSummary();
                                      changeRenderItem();
                                    }
                                  : () => {}
                              }
                              type="primary"
                              className="accept-form-button"
                            >
                              Finish
                            </Button>
                          )}
                        </Popover>
                      ) : question.answers[
                          quizData[question._id].father?.answer
                        ].nextQuestionId ? (
                        <Button
                          onClick={() =>
                            handleNextQuestion(
                              question.answers[
                                quizData[question._id].father?.answer
                              ].nextQuestionId
                            )
                          }
                          type="primary"
                          className="accept-form-button"
                        >
                          Next
                        </Button>
                      ) : (
                        <Button
                          onClick={() => {
                            setIsQuizFinish({
                              ...isQuizFinish,
                              [props.name]: true,
                            });
                            extractSummary();
                            changeRenderItem();
                          }}
                          type="primary"
                          className="accept-form-button"
                        >
                          Finish
                        </Button>
                      ))}
                  </div>
                )}
              </>
            );
          })}
        </div>
        <div className="quiz-right">
          <div className="quiz-data">
            <div className="quiz-right-wrapper">
              <Title level={3}>Order Summary</Title>
              <div id="summary-details">
                {preopLabs && (
                  <div>
                    <p>Pre-op Orders Labs</p>
                    <ul>
                      {labs.map((lab: any) => (
                        <li>{lab}</li>
                      ))}
                    </ul>
                  </div>
                )}
                {preopStudies && (
                  <div>
                    <p>Pre-op Radiology Studies</p>
                    <ul>
                      {studies.map((study: any) => (
                        <li>{study}</li>
                      ))}
                    </ul>
                  </div>
                )}
                {query.get("item") &&
                  quizData &&
                  Object.keys(quizData).map((key: any, index: number) => (
                    <div id="summary-detail">
                      <QuizSideBar
                        answer={
                          quizData[key] &&
                          quizData[key].father?.answer.length > 0 ? (
                            <>
                              {quizData[key].father?.answer !== "undefined" &&
                                quizData[key].father?.answer.map(
                                  (answer: any) => (
                                    <>
                                      <div>
                                        {`${
                                          props.quiz.find(
                                            (question: any) =>
                                              question._id === Number(key)
                                          ).answers[answer]?.content
                                        } ${
                                          props.quiz.find(
                                            (question: any) =>
                                              question._id === Number(key)
                                          ).answers[answer]?.cpt
                                            ? `CPT: ${
                                                props.quiz.find(
                                                  (question: any) =>
                                                    question._id === Number(key)
                                                ).answers[answer]?.cpt
                                              }`
                                            : ""
                                        }`}
                                      </div>

                                      {quizData[key].child?.answer[answer] && (
                                        <div
                                          style={{
                                            marginLeft: "10px",
                                          }}
                                        >
                                          {quizData[key].child?.answer[answer]
                                            .checkbox
                                            ? quizData[key].child?.answer[
                                                answer
                                              ].checkbox.map(
                                                (childans: any) => (
                                                  <div>
                                                    {`${
                                                      props.quiz.find(
                                                        (question: any) =>
                                                          question._id ===
                                                          Number(key)
                                                      ).answers[answer]
                                                        ?.childrenAnswer[
                                                        childans
                                                      ]?.content
                                                    } ${
                                                      props.quiz.find(
                                                        (question: any) =>
                                                          question._id ===
                                                          Number(key)
                                                      ).answers[answer]
                                                        ?.childrenAnswer[
                                                        childans
                                                      ]?.cpt
                                                        ? `CPT: ${
                                                            props.quiz.find(
                                                              (question: any) =>
                                                                question._id ===
                                                                Number(key)
                                                            ).answers[answer]
                                                              ?.childrenAnswer[
                                                              childans
                                                            ]?.cpt
                                                          }`
                                                        : ""
                                                    }`}
                                                  </div>
                                                )
                                              )
                                            : `${
                                                props.quiz.find(
                                                  (question: any) =>
                                                    question._id === Number(key)
                                                ).answers[answer]
                                                  ?.childrenAnswer[
                                                  quizData[key].child?.answer[
                                                    answer
                                                  ].radio
                                                ]?.content
                                              }  ${
                                                props.quiz.find(
                                                  (question: any) =>
                                                    question._id === Number(key)
                                                ).answers[answer]
                                                  ?.childrenAnswer[
                                                  quizData[key].child?.answer[
                                                    answer
                                                  ].radio
                                                ]?.cpt
                                                  ? `CPT: ${
                                                      props.quiz.find(
                                                        (question: any) =>
                                                          question._id ===
                                                          Number(key)
                                                      ).answers[answer]
                                                        ?.childrenAnswer[
                                                        quizData[key].child
                                                          ?.answer[answer].radio
                                                      ]?.cpt
                                                    }`
                                                  : ""
                                              }

                                                `}
                                        </div>
                                      )}
                                      {quizData[key]?.grandChild?.answer?.[
                                        answer
                                      ]?.map?.((gc: any) => (
                                        <div>
                                          {props.quiz.find(
                                            (question: any) =>
                                              question._id === Number(key)
                                          ).answers[answer]?.childrenAnswer[
                                            answer
                                          ]?.grandChildAnswer?.[gc] || ""}
                                          {props.quiz.find(
                                            (question: any) =>
                                              question._id === Number(key)
                                          ).answers[answer]?.childrenAnswer[
                                            answer
                                          ]?.middleChildAnswer?.[gc]?.content ||
                                            ""}
                                        </div>
                                      ))}
                                    </>
                                  )
                                )}
                            </>
                          ) : (
                            <>
                              {
                                <div>
                                  {`${
                                    props.quiz.find(
                                      (question: any) =>
                                        question._id === Number(key)
                                    ).answers[quizData[key].father?.answer]
                                      ?.content
                                  }
                                   ${
                                     props.quiz.find(
                                       (question: any) =>
                                         question._id === Number(key)
                                     ).answers[quizData[key].father?.answer]
                                       ?.cpt
                                       ? `CPT: ${
                                           props.quiz.find(
                                             (question: any) =>
                                               question._id === Number(key)
                                           ).answers[
                                             quizData[key].father?.answer
                                           ]?.cpt
                                         }`
                                       : ""
                                   }
                                `}
                                </div>
                              }
                              {quizData[key].child &&
                                quizData[key].child?.answer[
                                  quizData[key].father?.answer
                                ] && (
                                  <div
                                    style={{
                                      marginLeft: "10px",
                                    }}
                                  >
                                    {quizData[key].child?.answer[
                                      quizData[key].father?.answer
                                    ].checkbox
                                      ? quizData[key].child?.answer[
                                          quizData[key].father?.answer
                                        ].checkbox.map((childans: any) => (
                                          <div>
                                            {`${
                                              props.quiz.find(
                                                (question: any) =>
                                                  question._id === Number(key)
                                              ).answers[
                                                quizData[key].father?.answer
                                              ]?.childrenAnswer[childans]
                                                ?.content
                                            } ${
                                              props.quiz.find(
                                                (question: any) =>
                                                  question._id === Number(key)
                                              ).answers[
                                                quizData[key].father?.answer
                                              ]?.childrenAnswer[childans]?.cpt
                                                ? `CPT: ${
                                                    props.quiz.find(
                                                      (question: any) =>
                                                        question._id ===
                                                        Number(key)
                                                    ).answers[
                                                      quizData[key].father
                                                        ?.answer
                                                    ]?.childrenAnswer[childans]
                                                      ?.cpt
                                                  }`
                                                : ""
                                            }`}
                                          </div>
                                        ))
                                      : `${
                                          props.quiz.find(
                                            (question: any) =>
                                              question._id === Number(key)
                                          ).answers[
                                            quizData[key].father?.answer
                                          ]?.childrenAnswer[
                                            quizData[key].child?.answer[
                                              quizData[key].father?.answer
                                            ].radio
                                          ]?.content
                                        } CPT: ${
                                          props.quiz.find(
                                            (question: any) =>
                                              question._id === Number(key)
                                          ).answers[
                                            quizData[key].father?.answer
                                          ]?.childrenAnswer[
                                            quizData[key].child?.answer[
                                              quizData[key].father?.answer
                                            ].radio
                                          ]?.cpt
                                            ? `CPT: ${
                                                props.quiz.find(
                                                  (question: any) =>
                                                    question._id === Number(key)
                                                ).answers[
                                                  quizData[key].father?.answer
                                                ]?.childrenAnswer[
                                                  quizData[key].child?.answer[
                                                    quizData[key].father?.answer
                                                  ].radio
                                                ]?.cpt
                                              }`
                                            : ""
                                        }`}
                                  </div>
                                )}
                              {quizData[key].father.criteria?.map((gc: any) => (
                                <div>
                                  {props.quiz.find(
                                    (question: any) =>
                                      question._id === Number(key)
                                  ).answers[quizData[key].father.answer]
                                    ?.criteria[gc] || ""}
                                </div>
                              ))}
                              {quizData[key].grandChild?.answer?.[
                                quizData[key].father?.answer
                              ]?.map((gc: any, gcKey: any) =>
                                quizData[key].child?.answer?.[
                                  quizData[key].father?.answer
                                ].checkbox?.map((child: any, childKey: any) => {
                                  if (gcKey === childKey) {
                                    return (
                                      <div>
                                        {props.quiz.find(
                                          (question: any) =>
                                            question._id === Number(key)
                                        ).answers[quizData[key].father.answer]
                                          ?.childrenAnswer[childKey]
                                          .grandChildAnswer[gc] || ""}
                                      </div>
                                    );
                                  }
                                })
                              )}
                            </>
                          )
                        }
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="start-over">
            <Button
              style={{ marginBottom: "20px" }}
              onClick={handleStartOver}
              type="primary"
              className="accept-form-button"
            >
              Start Over
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TreatmentQuiz;
