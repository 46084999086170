import axios from "axios";

const instanceAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "http://localhost:3001/api",
});

const reqAxios = () => {
  const token = localStorage.getItem("auth");

  instanceAxios.defaults.headers.common = {
    Authorization: `Bearer ${token}`,
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: 0
  };
  instanceAxios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

  return instanceAxios;
};

export default reqAxios;
