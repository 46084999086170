import reqAxios from "./reqAxios";

const fetchClientById = (_id: string) => {
  return reqAxios().get(`/clients/${_id}`);
};

const getLogoLink = (data: any) => {
  return reqAxios().post(`/clients/link`, data);
};

export const ClientAPI = {
  fetchClientById,
  getLogoLink,
};
