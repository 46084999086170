import React, { useContext } from "react";
import { UserContext } from "../../context/login/Context";
import moment from "moment";
import { message } from "antd";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Typography,
  List,
  Divider,
  ListItem,
  ListItemText,
} from "@mui/material";
import {
  faClipboard,
  faFolderOpen,
  faStickyNote,
  faAddressCard,
} from "@fortawesome/free-regular-svg-icons";
import {
  faExclamationCircle,
  faCloudUploadAlt,
} from "@fortawesome/free-solid-svg-icons";
import { PatientAPI } from "../../API/patient-API";
import Header from "../../components/layouts/Header";
import Notes from "./Notes";
import Orders from "./Orders";
import Alerts from "./Alerts";
import PatientLog from "./PatientLog";
import OrderDetail from "./OrderDetail";
import PatientProfile from "./PatientProfile";
import Documents from "./Documents";

interface ITagMap {
  [key: string]: any;
}

const tagMap: ITagMap = {
  profile: {
    sidebar: true,
    icon: faAddressCard,
    text: "Patient Profile",
    component: PatientProfile,
  },
  orders: {
    sidebar: true,
    icon: faFolderOpen,
    text: "Orders",
    component: Orders,
  },
  notes: {
    sidebar: true,
    icon: faClipboard,
    text: "Notes",
    component: Notes,
  },
  alerts: {
    sidebar: true,
    icon: faExclamationCircle,
    text: "Alerts",
    component: Alerts,
  },
  logs: {
    sidebar: true,
    icon: faStickyNote,
    text: "Patient Journey",
    component: PatientLog,
  },
  documents: {
    sidebar: true,
    icon: faCloudUploadAlt,
    text: "Documents",
    component: Documents,
  },
  orderDetail: {
    sidebar: false,
    component: OrderDetail,
  },
};

const displayTagComponent = (tag: string, patient: any) => {
  const Component = tagMap[tag]?.component;
  if (tag === "alerts") {
    return <Component patient={patient} />;
  }
  return <Component />;
};

const FrontOffice = () => {
  const history = useHistory();
  const param: any = useParams();
  const { getCurrentClient } = useContext(UserContext);
  let currentClient = getCurrentClient();
  const { data: patient } = useQuery(
    ["patient"],
    () => {
      return PatientAPI.getPatient(param.id, currentClient).then(
        ({ data }) => data?.patient
      );
    },
    {
      onError: (error) =>
        message.error(`Failed to fetch patient data: ${error}`),
    }
  );

  let start = moment().format("YYYY");
  let end = moment(patient?.dob).format("YYYY");
  let old = Math.abs(parseInt(end) - parseInt(start)).toString();

  return (
    <>
      <div className="office-container">
        <div style={{ height: "calc(100vh)", overflowY: "scroll" }}>
          <div
            style={{ position: "fixed", zIndex: +2, top: 0, width: "100vw" }}
          >
            <Header />
          </div>
          <div
            className="side-nav"
            style={{
              height: "100%",
              width: "240px",
              position: "fixed",
              top: "90px",
              left: "0",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "10%",
                marginLeft: "10px",
                alignItems: "left",
                justifyContent: "center",
                color: "white",
                fontWeight: 900,
                fontSize: "20px",
              }}
            >
              <Typography variant={"h6"} style={{ color: "white" }}>
                <span style={{ fontWeight: "bold", fontSize: "inherit" }}>
                  Patient ID:
                </span>{" "}
                {patient?.patientId}
              </Typography>
              <Typography variant={"body2"} style={{ color: "white" }}>
                <span style={{ fontWeight: "bold", fontSize: "inherit" }}>
                  MRN:
                </span>{" "}
                {patient?.mRN}
              </Typography>
              <Typography variant={"body2"} style={{ color: "white" }}>
                <span style={{ fontWeight: "bold" }}>DOB:</span>{" "}
                {moment(patient?.dob).format("MM/DD/YYYY")}{" "}
                <span style={{ fontWeight: "bold" }}>Age:</span>{" "}
                {old !== "NaN" && old !== "0" && old !== undefined && old}
              </Typography>
            </div>
            <Box>
              <List style={{ textAlign: "left" }}>
                {Object.entries(tagMap).map(([tag, tagValues]) => {
                  if (tagValues.sidebar) {
                    return (
                      <>
                        <ListItem
                          button
                          onClick={() =>
                            history.push(`/patient-board/${tag}/${param.id}`)
                          }
                          style={{
                            fontSize: "24px",
                            color: "#ffffff",
                            textAlign: "left",
                            backgroundColor:
                              param.isTags === tag ? "#4276c5" : undefined,
                            borderTop:
                              param.isTags === tag
                                ? "2px solid white"
                                : undefined,
                            borderBottom:
                              param.isTags === tag
                                ? "2px solid white"
                                : undefined,
                          }}
                        >
                          <FontAwesomeIcon
                            icon={tagValues.icon}
                            style={{ marginRight: "10px", width: "10%" }}
                          />
                          <ListItemText primary={tagValues.text} />
                        </ListItem>
                        <Divider />
                      </>
                    );
                  }
                  return null;
                })}
              </List>
            </Box>
          </div>
          <div
            style={{
              marginTop: "90px",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "calc(100vw - 240px)",
              marginLeft: "240px",
              position: "relative",
            }}
          >
            <div>
              {param.isTags === "orders" && <Orders />}
              {param.isTags === "notes" && <Notes />}
              {param.isTags === "alerts" && (
                <Alerts patient={patient} client={currentClient} />
              )}
              {param.isTags === "logs" && <PatientLog />}
              {param.isTags === "profile" && <PatientProfile />}
              {param.isTags === "orderDetail" && <OrderDetail />}
              {param.isTags === "documents" && <Documents />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FrontOffice;
