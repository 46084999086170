import React, { useState, useEffect, useMemo, useContext } from "react";
import { Spin } from "antd";
import moment from "moment";
import { Link } from "@mui/material";
import { useParams } from "react-router";
import { LoadingOutlined } from "@ant-design/icons";

import Title from "./Title";
import { OrderAPI } from "../../API/order-API";
import Header from "../../components/layouts/Header";
import ReactTable from "../../components/forms/ReactTable";
import { UserContext } from "../../context/login/Context";

const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

export default function OrderView(props: any) {
  const param: any = useParams();
  const { getCurrentClient } = useContext(UserContext);
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  let currentClient = getCurrentClient();
  useEffect(() => {
    if (
      param.isTags !== "Biopsy Positive" &&
      param.isTags !== "Biopsy High Risk" &&
      param.isTags !== "Biopsy Negative" &&
      param.isTags !== "Referral to Surgery First" &&
      param.isTags !== "Referral to Medical Oncology First"
    ) {
      OrderAPI.fetchOrdersByStatus(param.isTags, currentClient).then((res) => {
        const orderList = res.data.data.map((data: any, index: number) => {
          return {
            name: data.orderNumber ?? data._id,
            patientId: data.patientId,
            createdDate: new Date(data.createdAt ?? ""),
            key: index,
            type: data.type,
            status: data.status,
            canceledDate: new Date(data.canceledDate ?? ""),
            diagnostic: data.diagnosticPhase?.indications?.value?.join(", "),
            summary: data.shortSummary,
            stuckReason: data.stuckReason,
            stage:
              (data.orderDetail?.resultsSentToPatient !== undefined &&
                data.orderDetail?.resultsSentToPatient !== null &&
                "Results Received by Patient") ||
              (data.orderDetail?.resultsReceivedDate !== undefined &&
                data.orderDetail?.resultsReceivedDate !== null &&
                "Results Received by PCP") ||
              (data.orderDetail?.appointmentCompletedDate !== undefined &&
                data.orderDetail?.appointmentCompletedDate !== null &&
                "Appointment Completed") ||
              (data.orderDetail?.patientAppointmentDate !== undefined &&
                data.orderDetail?.patientAppointmentDate !== null &&
                "Appointment Confirmed") ||
              (data.orderDetail?.appointmentRequestedDate !== undefined &&
                data.orderDetail?.appointmentRequestedDate !== null &&
                "Appointment Requested") ||
              (data.orderDetail?.orderSentDate !== undefined &&
                data.orderDetail?.orderSentDate !== null &&
                "Order Sent"),
            appointmentDate: new Date(
              data.orderDetail?.patientAppointmentDate ?? ""
            ),
            orderSentDate:
              (data.orderDetail !== undefined &&
                data.orderDetail?.orderSentDate !== null &&
                moment(data.orderDetail?.orderSentDate).format("MM/DD/YYYY")) ||
              "",
            edit: (
              <Link
                href={`/order-panel/${data._id}/${data.type.toLowerCase()}`}
              >
                Edit
              </Link>
            ),
            _id: data._id,
          };
        });
        setOrders(orderList);
        setIsLoading(false);
      });
    } else if (
      param.isTags === "Referral to Surgery First" ||
      param.isTags === "Referral to Medical Oncology First"
    ) {
      OrderAPI.fetchOrdersByRecommendation(param.isTags, currentClient).then(
        (res) => {
          const orderList = res.data.data.map((data: any, index: number) => {
            return {
              name: data.orderNumber ?? data._id,
              patientId: data.patientId,
              createdDate: new Date(data.createdAt),
              key: index,
              type: data.type,
              status: data.status,
              diagnostic: data.diagnosticPhase?.indications?.value?.join(", "),
              summary: data.shortSummary,
              appointmentDate: new Date(
                data.orderDetail?.patientAppointmentDate ?? ""
              ),
              resultsReceived: new Date(
                data.orderDetail?.resultsReceivedDate ?? ""
              ),
              orderSentDate:
                data.orderDetail !== undefined ||
                data.orderDetail?.orderSentDate !== null ||
                moment(data.orderDetail?.orderSentDate).format("MM/DD/YYYY") ||
                "",
              edit: (
                <Link
                  href={`/order-panel/${data._id}/${data.type.toLowerCase()}`}
                >
                  Edit
                </Link>
              ),
              _id: data._id,
            };
          });
          setOrders(orderList);
          setIsLoading(false);
        }
      );
    } else {
      OrderAPI.fetchOrdersByBiopsyResult(param.isTags, currentClient).then(
        (res) => {
          const orderList = res.data.data.map((data: any, index: number) => {
            return {
              name: data.orderNumber ?? data._id,
              patientId: data.patientId,
              createdDate: new Date(data.createdAt),
              key: index,
              type: data.type,
              status: data.status,
              diagnostic: data.diagnosticPhase?.indications?.value?.join(", "),
              summary: data.shortSummary,
              appointmentDate: new Date(
                data.orderDetail?.patientAppointmentDate ?? ""
              ),
              resultsReceived: new Date(
                data.orderDetail?.resultsReceivedDate ?? ""
              ),
              orderSentDate:
                data.orderDetail !== undefined ||
                data.orderDetail?.orderSentDate !== null ||
                moment(data.orderDetail?.orderSentDate).format("MM/DD/YYYY") ||
                "",
              edit: (
                <Link
                  href={`/order-panel/${data._id}/${data.type.toLowerCase()}`}
                >
                  Edit
                </Link>
              ),
              _id: data._id,
            };
          });
          setOrders(orderList);
          setIsLoading(false);
        }
      );
    }
  }, []);

  const obstructedColumns = useMemo(
    () => [
      {
        Header: "Order ID",
        columnId: 0,
        accessor: "name",
        Cell: (props: any) => (
          <Link href={`/patient-board/orderDetail/${props.row.original._id}`}>
            {props.value}
          </Link>
        ),
        width: 40,
      },
      {
        Header: "Patient ID",
        columnId: 1,
        accessor: "patientId",
        width: 40,
      },
      {
        Header: () => {
          return param.isTags === "Canceled" ? "Canceled Date" : "Created Date";
        },
        columnId: 2,
        accessor: "createdDate",
        Cell: (props: any) => (
          <span>
            {(param.isTags === "Canceled" &&
              (props.cell.row.original.canceledDate.toString() !==
              "Invalid Date"
                ? moment(props.cell.row.original.canceledDate).format(
                    "MM/DD/YYYY"
                  )
                : "")) ||
              moment(props.value).format("MM/DD/YYYY")}
          </span>
        ),
        width: 40,
      },
      {
        Header: "Indication",
        columnId: 3,
        accessor: "diagnostic",
        width: 40,
      },
      {
        Header: "Status",
        columnId: 4,
        accessor: "status",
        width: 40,
      },
      {
        Header: "Reason",
        columnId: 5,
        accessor: "stuckReason",
        width: 40,
      },
      {
        Header: "CPT Codes",
        columnId: 6,
        accessor: "summary",
        width: 40,
      },
      {
        columnId: 7,
        accessor: "edit",
        width: 40,
      },
    ],
    []
  );

  const scheduledColumns = useMemo(
    () => [
      {
        Header: "Order ID",
        columnId: 0,
        accessor: "name",
        Cell: (props: any) => (
          <Link href={`/patient-board/orderDetail/${props.row.original._id}`}>
            {props.value}
          </Link>
        ),
        width: 40,
      },
      {
        Header: "Patient ID",
        columnId: 1,
        accessor: "patientId",
        width: 40,
      },
      {
        Header: "Created Date",
        columnId: 2,
        accessor: "createdDate",
        Cell: (props: any) => (
          <span>{moment(props.value).format("MM/DD/YYYY")}</span>
        ),
        sortType: "datetime",
        width: 40,
      },
      {
        Header: "Indication",
        columnId: 3,
        accessor: "diagnostic",
        width: 40,
      },
      {
        Header: "Status",
        columnId: 4,
        accessor: "status",
        width: 40,
      },
      {
        Header: "Stage",
        columnId: 5,
        accessor: "stage",
        width: 40,
      },
      {
        Header: () => {
          return (
            (param.isTags === "Scheduled" && "Appointment Date") || "CPT Codes"
          );
        },
        columnId: 6,
        accessor: "appointmentDate",
        Cell: (props: any) => (
          <span>
            {(param.isTags === "Results Received" && (
              <span>{props.cell.row.original.summary}</span>
            )) ||
              (param.isTags === "Scheduled" &&
                (props.cell.row.original.appointmentDate.toString() !==
                "Invalid Date"
                  ? moment(props.cell.row.original.appointmentDate).format(
                      "MM/DD/YYYY"
                    )
                  : ""))}
          </span>
        ),
        sortType: "datetime",
        width: 40,
      },
      {
        columnId: 7,
        accessor: "edit",
        width: 40,
      },
    ],
    []
  );

  const columns = useMemo(
    () => [
      {
        Header: "Order ID",
        columnId: 0,
        accessor: "name",
        Cell: (props: any) => (
          <Link href={`/patient-board/orderDetail/${props.row.original._id}`}>
            {props.value}
          </Link>
        ),
        width: 40,
      },
      {
        Header: "Patient ID",
        columnId: 1,
        accessor: "patientId",
        width: 40,
      },
      {
        Header: "Created Date",
        columnId: 2,
        accessor: "createdDate",
        Cell: (props: any) => (
          <span>{moment(props.value).format("MM/DD/YYYY")}</span>
        ),
        sortType: "datetime",
        width: 40,
      },
      {
        Header: "Indication",
        columnId: 3,
        accessor: "diagnostic",
        width: 40,
      },
      {
        Header: "Status",
        columnId: 4,
        accessor: "status",
        width: 40,
        isVisible: false,
      },
      {
        Header: () => {
          return (
            (param.isTags === "In Progress" && "Stage") ||
            (param.isTags === "Biopsy Positive" && "Confirmed on") ||
            "CPT Codes"
          );
        },
        columnId: 5,
        accessor: "appointmentDate",
        Cell: (props: any) =>
          (param.isTags === "Biopsy Positive" && (
            <span>
              {props.cell.row.original.resultsReceived.toString() !==
              "Invalid Date"
                ? moment(props.cell.row.original.resultsReceived).format(
                    "MM/DD/YYYY"
                  )
                : ""}
            </span>
          )) ||
          (param.isTags === "In Progress" && (
            <span>{props.cell.row.original.stage}</span>
          )) || <span>{props.cell.row.original.summary}</span>,
        sortType: "datetime",
        width: 40,
      },
      {
        columnId: 6,
        accessor: "edit",
        width: 40,
      },
    ],
    []
  );

  return (
    <>
      <Header />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "30px",
          marginBottom: "30px",
        }}
      >
        <div
          style={{
            width: "95%",
            backgroundColor: "#ffffff",
            padding: "10px",
            boxShadow: "-2px 1px 3px 0px #d2d2d2",
          }}
        >
          <div style={{ width: "100%" }}>
            <Title>{param.isTags} Orders</Title>

            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "20vh",
                }}
              >
                <Spin
                  size="large"
                  className={`loading-spin-btn ${"active"}`}
                  indicator={antIcon}
                />
              </div>
            ) : param.isTags === "Scheduled" ? (
              <ReactTable columns={scheduledColumns} data={orders} />
            ) : param.isTags === "Obstructed" || param.isTags === "Canceled" ? (
              <ReactTable columns={obstructedColumns} data={orders} />
            ) : param.isTags === "Results Received" ? (
              <ReactTable columns={scheduledColumns} data={orders} />
            ) : (
              <ReactTable columns={columns} data={orders} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
