import { Form, Input } from "antd";
import React from "react";
import { PanelContext } from "../../context/panel-context/PanelContext";

const OtherInputText = (props: any) => {
  const { setFieldSelected, getFieldByName } = React.useContext(PanelContext);

  const onClick = (e: any) => {
    setFieldSelected({
      name: props.name,
      field: getFieldByName(props.name),
    });
  };

  const { TextArea } = Input;

  return (
    <Form.Item
      name="name"
      className="input age"
      label="Name"
      rules={[
        {
          required: props.rq,
          message: `Please don't leave it blank !`,
        },
      ]}
      onClick={onClick}
      {...props}
    >
      <TextArea className={props.className ? props.className : ""} rows={4} />
    </Form.Item>
  );
};

export default OtherInputText;
