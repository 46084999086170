import { Form, Typography } from "antd";
import DatePicker from "react-datepicker";
import React, { useContext, useState } from "react";
import { PanelContext } from "../../context/panel-context/PanelContext";
import { useBetween } from "use-between";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

//const dateFormatList = ["D/M/YYYY"];

const useShareableState = () => {
  const [date, setDate] = useState<Date | null>(null);
  return {
    date,
    setDate,
  };
};

export const Age = (props: any) => {
  const { date } = useBetween(useShareableState);
  const { getFieldByName } = useContext(PanelContext);

  // console.log(getFieldByName("dob").value);
  let dob;
  if (getFieldByName("dob") !== undefined) {
    dob = new Date(getFieldByName("dob").value);
  } else {
    dob = date;
  }

  let start = moment().format("YYYY");
  let end = moment(dob).format("YYYY");

  let old = Math.abs(parseInt(end) - parseInt(start));

  // moment(date).format("llll") === moment(new Date()).format("llll")

  //setAge(old);
  // const onClick = (e: any) => {
  //   setFieldSelected({
  //     name: props.name,
  //     field: getFieldByName(props.name),
  //   });
  // };
  return (
    <Form.Item
      className="hide"
      label="Age"
      name="age"
      {...props}
      //onChange={onClick}
    >
      {old !== 0 && <Typography> {old}</Typography>}
    </Form.Item>
  );
};

export const DatePickerCustom = (props: any) => {
  const { setFieldSelected, getFieldByName } = useContext(PanelContext);
  const { date, setDate } = useBetween(useShareableState);
  //const [age, setAge] = useState('')

  //const x = document.getElementById('date');
  //console.log(x.value);

  // useEffect(() => {
  //   setDate(new Date("10/21/2000"));
  //   // Run! Like go get some data from an API.
  // }, []);

  const onClick = (e: any) => {
    setFieldSelected({
      name: props.name,
      field: getFieldByName(props.name),
    });
  };

  // const changeHandler = (e: any) => {
  //  //setDate(e.target.value);

  //  console.log()
  // };

  const handleDateChange = (date: Date) => {
    setDate(date);
    // console.log(date);
  };

  // const clearData = () => {
  //   setDate(null);
  // };

  //const d = document.getElementById('date');
  //console.log(year); format={dateFormatList}

  return (
    <Form.Item
      name="name"
      className="input age"
      label="Name"
      rules={[
        {
          required: props.rq,
          message: `Please don't leave it blank !`,
        },
      ]}
      {...props}
      onClick={onClick}
      // onfocus={clearData}
    >
      <DatePicker
        className="ant-picker"
        // value="10/21/2000"
        placeholderText="M / D / YYYY"
        selected={date}
        dateFormat="MM/dd/yyyy"
        onChange={handleDateChange}
      />
    </Form.Item>
  );
};
