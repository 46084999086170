import React, { useState, useMemo, useContext } from "react";
import { Spin } from "antd";
import moment from "moment";
import { Link } from "@mui/material";
import { useQuery } from "react-query";
import { LoadingOutlined } from "@ant-design/icons";
import { UserContext } from "../../context/login/Context";

import Title from "./Title";
import { PatientAPI } from "../../API/patient-API";
import Header from "../../components/layouts/Header";
import ReactTable from "../../components/forms/ReactTable";

const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

const FrontOfficePortal = () => {
  const [search, setSearch] = useState("");
  const [patients, setPatients] = useState([] as any);
  const { getCurrentClient } = useContext(UserContext);

  let currentClient = getCurrentClient();

  const { isLoading } = useQuery(
    ["active-patients"],
    () => {
      return PatientAPI.getActivePatients(currentClient).then(
        ({ data }) => data
      );
    },
    {
      onSuccess: (data) => {
        const patientList = data.patients.map(
          (patient: any, index: number) => ({
            _id: patient._id,
            key: index,
            mRN: patient.mrn,
            name: patient.patientId,
            lastName: patient.lastName,
            firstName: patient.firstName,
            alerts: patient.activeAlerts,
            orders: patient.activeOrders,
            dob: new Date(patient.dob ?? ""),
          })
        );
        setPatients(patientList);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const columns = useMemo(
    () => [
      {
        Header: "Patient ID",
        columnId: 0,
        accessor: "name",
        Cell: (props: any) => (
          <Link href={`/patient-board/orders/${props.row.original._id}`}>
            {props.value}
          </Link>
        ),
      },
      {
        Header: "MRN",
        columnId: 1,
        accessor: "mRN",
      },
      {
        Header: "First Name",
        columnId: 2,
        accessor: "firstName",
      },
      {
        Header: "Last Name",
        columnId: 3,
        accessor: "lastName",
      },
      {
        Header: "Date of Birth",
        columnId: 4,
        accessor: "dob",
        Cell: (props: any) => (
          <span>
            {props.value.toString() !== "Invalid Date"
              ? moment(props.value).format("MM/DD/YYYY")
              : ""}
          </span>
        ),
        sortType: "datetime",
      },
      {
        Header: "# of Active Orders",
        columnId: 5,
        accessor: "orders",
      },
      {
        Header: "# of Active Alerts",
        columnId: 6,
        accessor: "alerts",
      },
    ],
    []
  );

  return (
    <>
      <Header />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "30px",
          marginBottom: "30px",
        }}
      >
        <div
          style={{
            width: "95%",
            backgroundColor: "#ffffff",
            padding: "10px",
            boxShadow: "-2px 1px 3px 0px #d2d2d2",
          }}
        >
          <div style={{ width: "100%" }}>
            <Title>Manage Patients & Orders</Title>
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "20vh",
                }}
              >
                <Spin
                  size="large"
                  indicator={antIcon}
                  className={`loading-spin-btn ${"active"}`}
                />
              </div>
            ) : (
              <ReactTable columns={columns} data={patients} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FrontOfficePortal;
