import reqAxios from "./reqAxios";

const login = (data: any) => {
  return reqAxios().post("/login", data);
};

const me = () => {
  return reqAxios().get("/login/me");
};

const signUp = (data: any) => {
  return reqAxios().post("/users", data);
};

export const AuthAPI = {
  login: login,
  me,
  signUp,
};
