import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router";
import { Button, Form, message, Spin, Modal } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderOpen } from "@fortawesome/free-regular-svg-icons";
import { ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";

import { OrderAPI } from "../../API/order-API";
import MainWorkup from "../new-order/MainWorkup";
import Header from "../../components/layouts/Header";
import FormSelectDiagnostic from "../new-order/DiagnosticPanel";
import TreatmentPanel from "../new-order/treatment/TreatmentPanel";
import DiagnosticModal from "../new-order/treatment/DiagnosticModal";
import { PanelContext } from "../../context/panel-context/PanelContext";
import { UserContext } from "../../context/login/Context";

interface IProps {
  isLoading: boolean;
}
interface IQuizSubtypes {
  [key: string]: boolean;
}
interface ITagsMap {
  [key: string]: string;
}

const getSubtype = (quizSubtypes: IQuizSubtypes, subtypeLabelMap: ITagsMap) => {
  const finishedSubtype = Object.keys(quizSubtypes).find(
    (subtype) => quizSubtypes[subtype]
  );
  if (finishedSubtype) {
    return subtypeLabelMap[finishedSubtype];
  }
  return "";
};

const tagsLabelMap: ITagsMap = {
  diagnostic: "Diagnostic",
  "work-up": "Work-up",
  therapeutic: "Therapeutic",
};

function DisplayForm(props: { show: boolean; Component: any }) {
  const { show, Component } = props;

  return (
    <div style={{ display: show ? "block" : "none" }}>
      <Component />
    </div>
  );
}

const covertQuizData = (value: any) => {
  if (value.length > 0) {
    let editData = value.reduce(
      (acc: any, cur: any) => ({ ...acc, [cur.questionId]: cur.answerId }),
      {}
    );
    editData.childrenAnswer = value.reduce(
      (acc: any, cur: any) => ({
        ...acc,
        [cur.questionId]: cur.childrenAnswerId,
      }),
      {}
    );
    editData.grandChildAnswer = value.reduce(
      (acc: any, cur: any) => ({
        ...acc,
        [cur.questionId]: cur.grandChildAnswerId,
      }),
      {}
    );
    editData.other = value.reduce(
      (acc: any, cur: any) => ({ ...acc, [cur.questionId]: cur.other }),
      {}
    );
    return editData;
  } else return null;
};

export const convertOrderToFormData = (newOrder: any) => {
  try {
    return Object.keys(newOrder).reduce((acc, key) => {
      return {
        ...acc,
        [key]: newOrder[key] ? newOrder[key].value : "",
        dob: moment(newOrder.dob.value).format("MM/DD/YYYY"),
        radiationleft: newOrder.radiationleft.value,
        radiationright: newOrder.radiationright.value,
        medical: newOrder.medical.value,
        surgeryleft: newOrder.surgeryleft.value,
        surgeryright: newOrder.surgeryright.value,
        id: newOrder.id,
      };
    }, {});
  } catch (e) {
    return {
      name: "",
      sex: "",
      dob: "",
      pathology: "",
      ICD: "",
      indications: "",
      menopausal: "",
      lymph: "",
      metastasis: "",
      involvement: "",
      other: "",
      file: "",
      tumorSize: "",
      receptorStatus: "",
      panelICD10: "",
      preopOrders: "",
      preopRadiology: "",
      lumpectomy: "",
      advancement: "",
      lymphsurgery: "",
      mastectomy: "",
      intraoperative: "",
      plastics: "",
      radiology: "",
      specialties: "",
      radiation: {},
      medicalleft: {},
      medicalright: {},
      surgeryleft: {},
      surgeryright: {},
    };
  }
};

const onFinishFailed = (e: any) => {
  message.error("Please enter the red boxes and do not leave them blank !");
};

const OrderPanelSelect: React.FC<IProps> = (props) => {
  const params: any = useParams();
  const history = useHistory();
  const query: any = new URLSearchParams(window.location.search);
  const [patient, setPatient] = useState([{}] as any);
  const { getCurrentClient } = useContext(UserContext);
  let currentClient = getCurrentClient();
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const { confirm } = Modal;
  const {
    clickTags,
    submitEditOrder,
    editOrderFormData,
    isLoading,
    setIsDisable,
    form,
    onChangeForm,
    setNewOrderValue,
    listActive,
    isQuizFinish,
    subtypeLabelMap,
  } = useContext(PanelContext);

  const onFinish = (values: any) => {
    const realvalue = {
      ...values,
      radiationleft: form.getFieldValue("radiationleft"),
      radiationright: form.getFieldValue("radiationright"),
      surgeryleft: form.getFieldValue("surgeryleft"),
      surgeryright: form.getFieldValue("surgeryright"),
      medical: form.getFieldValue("medical"),
      imaging: form.getFieldValue("imaging"),
      surgeryWorkup: form.getFieldValue("surgeryWorkup"),
      social: form.getFieldValue("social"),
      radOncology: form.getFieldValue("radOncology"),
      medOncology: form.getFieldValue("medOncology"),
      genetic: form.getFieldValue("genetic"),
      nutrition: form.getFieldValue("nutrition"),
      patientNav: form.getFieldValue("patientNav"),
      therapy: form.getFieldValue("therapy"),
    };
    showConfirm(realvalue);
  };

  const [old, setOld] = useState("");
  const [newOrder, setNewOrder] = useState([{}] as any);

  useEffect(() => {
    async function getData() {
      await OrderAPI.fetchOrder(params.id, currentClient).then((res) => {
        setNewOrder(res.data.order);
      });
      await OrderAPI.fetchPatientByOrder(params.id, currentClient).then(
        (res) => {
          setPatient(res.data.patient);
          let start = moment().format("YYYY");
          let end = moment(res.data.patient.dob).format("YYYY");

          setOld(Math.abs(parseInt(end) - parseInt(start)).toString());
        }
      );
    }
    getData();
  }, []);

  const showConfirm = (values: any) => {
    const order = Object.keys(values).reduce((prev, key) => {
      return {
        // id: { value: newOrder.patientId },
        ...prev,
        ...newOrder,
        [key]: {
          value: values[key],
          comments: editOrderFormData[key]
            ? editOrderFormData[key].comments
            : "",
        },
        subtype: getSubtype(isQuizFinish, subtypeLabelMap),
      };
    }, {});
    confirm({
      title: "Save confirmation",
      content:
        '"By clicking "accept", you are verifying that all data has been entered to the best of your knowledge.',
      onOk() {
        submitEditOrder(params.id, order);
      },
      onCancel() {
        // console.log("Cancel");
      },
    });
  };

  const onExit = () => {
    confirm({
      title: "Do you want to quit order?",
      icon: <ExclamationCircleOutlined />,
      content: "When clicked the OK button, unsaved data will be lost.",
      onOk() {
        history.push("/");
      },
      onCancel() {},
    });
  };

  useEffect(() => {
    return () => {
      setIsDisable(false);
      clickTags(params.isTags);
    };
  }, []);

  useEffect(() => {
    const formNewVale: any = convertOrderToFormData(editOrderFormData);
    Object.keys(formNewVale).forEach((key) => {
      form.setFieldsValue({ [key]: formNewVale[key] });
    });
  }, []);

  return (
    <div style={{ height: "100%", width: "calc(100% - 240px)" }}>
      <div
        style={{
          position: "fixed",
          zIndex: +2,
          top: 0,
          left: 0,
          width: "100vw",
        }}
      >
        <Header />
      </div>
      <div
        className="side-nav"
        style={{
          height: "100%",
          width: "240px",
          position: "fixed",
          top: "90px",
          left: "0",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
            height: "10%",
            alignItems: "left",
            marginLeft: "10px",
            justifyContent: "center",
            color: "white",
            fontWeight: 900,
            fontSize: "20px",
          }}
        >
          <Typography variant={"h6"} style={{ color: "white" }}>
            <span style={{ fontWeight: "bold", fontSize: "inherit" }}>
              Patient ID:
            </span>{" "}
            {patient.patientId}
          </Typography>
          <Typography
            variant={"body2"}
            style={{ fontSize: "16px", color: "white" }}
          >
            <span style={{ fontWeight: "bold", fontSize: "16px" }}>DOB:</span>{" "}
            {moment(patient.dob).format("MM/DD/YYYY")}{" "}
            <span style={{ fontWeight: "bold", fontSize: "16px" }}>Age:</span>{" "}
            {old !== "NaN" && old}
          </Typography>
          <Typography
            variant={"body2"}
            style={{ fontSize: "16px", color: "white" }}
          >
            <span style={{ fontWeight: "bold", fontSize: "16px" }}>Sex:</span>{" "}
            {patient.sex}
          </Typography>
        </div>
        {/* <Toolbar /> */}
        <Box>
          <List style={{ textAlign: "center" }}>
            {/* <Button> */}
            <a href={`/patient-board/orders/${patient._id}`}>
              <ListItem
                button
                style={{
                  fontSize: "24px",
                  color: "#ffffff",
                  textAlign: "center",
                }}
              >
                <FontAwesomeIcon icon={faFolderOpen} />
                <ListItemText primary="Back" />
              </ListItem>
            </a>
            {/* </Button> */}
            <Divider />
            {/* <Link to="/patientBoard/IMV001">
                <ListItem
                  button
                  style={{
                    fontSize: "24px",
                    color: "#ffffff",
                    textAlign: "center",
                  }}
                >
                  <FontAwesomeIcon icon={faClipboard} />
                  <ListItemText primary="Notes" />
                </ListItem>
              </Link>
              <Divider />
              <Link to="/patientBoard/IMV001">
                <ListItem
                  button
                  style={{
                    fontSize: "24px",
                    color: "#ffffff",
                    textAlign: "center",
                  }}
                >
                  <FontAwesomeIcon icon={faExclamationCircle} />
                  <ListItemText primary="Alerts" />
                </ListItem>
              </Link>
              <Divider />
              <Link to="/patientBoard/IMV001">
                <ListItem
                  button
                  style={{
                    fontSize: "24px",
                    color: "#ffffff",
                    textAlign: "center",
                  }}
                >
                  <FontAwesomeIcon icon={faStickyNote} />
                  <ListItemText primary="Patient Log" />
                </ListItem>
              </Link> */}
          </List>
        </Box>
      </div>
      <div
        className="orderpanel-container"
        style={{
          left: 260,
          top: 90,
          // width: "80%",
          marginLeft: "18%",
          // marginRight: "1260px !important",
          position: "fixed",
        }}
      >
        <div className="order-lerf-item">
          <h1>{tagsLabelMap[params.isTags] || ""}</h1>
          <div className="form-select-qualify" style={{ minHeight: "auto" }}>
            {props.isLoading ? (
              <div className="loading-form">
                <Spin indicator={antIcon} />{" "}
              </div>
            ) : (
              <Form
                form={form}
                name="qualify-data"
                className="qualify-form"
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                initialValues={convertOrderToFormData(editOrderFormData)}
                onFieldsChange={onChangeForm}
              >
                {/* <Form.Item shouldUpdate>
              {() => (
                <div className="select-item">
                  <div
                    onClick={() => (
                      setIsDisable(false),
                      clickTags("diagnostic"),
                      history.push(`diagnostic`)
                    )}
                    className={`select ${
                      params.isTags === "diagnostic" ? "active" : ""
                    } `}
                  >
                    Diagnostic
                  </div>
                  <div
                    onClick={() => (
                      setIsDisable(false),
                      clickTags("workup"),
                      history.push(`workup`)
                    )}
                    className={`select ${
                      params.isTags === "workup" ? "active" : ""
                    } `}
                  >
                    Work-up
                  </div>
                  <div
                    onClick={() => (
                      setIsDisable(false),
                      clickTags("treatment"),
                      history.push(`treatment`)
                    )}
                    className={`select ${
                      params.isTags === "treatment" ? "active" : ""
                    } referrals`}
                  >
                    Therapeutic
                  </div>
                </div>
              )}
            </Form.Item> */}
                <div
                  style={{
                    position: "fixed",
                    top: "100px",
                    left: 250,
                    width: "18%",
                    height: "100%",
                  }}
                >
                  <DiagnosticModal />
                </div>
                <DisplayForm
                  show={params.isTags === "diagnostic"}
                  Component={FormSelectDiagnostic}
                />
                <DisplayForm
                  show={params.isTags.toLowerCase() === "work-up"}
                  Component={MainWorkup}
                />
                <div className="mainTreatment">
                  <DisplayForm
                    show={params.isTags === "therapeutic"}
                    Component={TreatmentPanel}
                  />
                </div>
                <div className="submit-diagnostic">
                  <Form.Item className="accept-form">
                    <div
                      className="action-form-btn-group"
                      style={
                        params.isTags && !query.get("item")
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <Button
                        type="link"
                        className="accept-form-button-link"
                        onClick={onExit}
                      >
                        Exit
                      </Button>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="accept-form-button"
                        onClick={() => setNewOrderValue("file", listActive)}
                      >
                        Save
                      </Button>
                      <div
                        className={`loading-spin-btn ${
                          !isLoading ? "" : "active"
                        }`}
                      >
                        <Spin indicator={antIcon} />
                      </div>
                    </div>
                  </Form.Item>
                </div>
              </Form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderPanelSelect;
