import React from "react";
import { Switch, Route } from "react-router-dom";

import Login from "./pages/login/Login";
import ByType from "./pages/frontOffice/ByType";
import Dashboard from "./pages/dashboard/Dashboard";
import OrderView from "./pages/frontOffice/OrderView";
import NewPatient from "./pages/frontOffice/NewPatient";
import OrderPanel from "./pages/order-panel/OrderPanel";
import FrontOffice from "./pages/frontOffice/PatientBoard";
import ActiveAlerts from "./pages/frontOffice/ActiveAlerts";
import WeeklyReport from "./pages/frontOffice/WeeklyReport";
import FollowUpReport from "./pages/frontOffice/FollowUpReport";
import PatientNewOrder from "./pages/frontOffice/PatientNewOrder";
import FrontOfficePortal from "./pages/frontOffice/FrontOfficePortal";

export default function App() {
  return (
    <div className="App">
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/adminView" component={FrontOfficePortal} />
        <Route path="/orderView/:isTags" component={OrderView} />
        <Route path="/alerts" component={ActiveAlerts} />
        <Route path="/patient-board/:isTags/:id" component={FrontOffice} />
        <Route path="/patient/new" component={NewPatient} />
        <Route path="/weekly-report" component={WeeklyReport} />
        <Route path="/follow-up" component={FollowUpReport} />
        <Route path="/order-panel/:id/:isTags" component={OrderPanel} />
        <Route exact path="/new-patientOrder/:id" component={ByType} />
        <Route
          exact
          path="/new-patientOrder/:id/:isTags"
          component={PatientNewOrder}
        />
        <Route exact path="/" component={Dashboard} />
      </Switch>
    </div>
  );
}
