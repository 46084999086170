import React from "react";
import { useParams } from "react-router";
import SkinSelect from "../specs/SkinSelect";
import { Divider, List, Typography } from "antd";
import InputText from "../../../components/forms/InputText";
import {
  DatePickerCustom,
  Age,
} from "../../../components/forms/DatePickerCustom";
import SelectCustom from "../../../components/forms/SelectCustom";
import MultiSelect from "../../../components/forms/MultiSelect";

import {
  SEX,
  MENOPAUSAL,
  ICDSIGNS,
  ICD10CODES,
  PATHOLOGY,
  LYMPH,
  PREOPORDER_ITEMS,
  TUMOR,
  METASTASISCLINICAL,
  HER2,
  ESTROGEN,
  TUMORPATHOLOGICAL,
  NODALPATHOLOGICAL,
  METASTASISPATHOLOGICAL,
  KI67,
  INDICATIONS,
} from "../../../constants/inputValues";
import { useContext } from "react";
import { PanelContext } from "../../../context/panel-context/PanelContext";
import {
  DIANOSTICIMAGING,
  RADIATION_ONCOLOGY,
  SURGERYLEFT,
  SURGERYRIGHT,
} from "../../../constants/treatment/SelectQuestions";

const DiagnosticModal = () => {
  const { Title } = Typography;
  const params: any = useParams();
  const { form } = useContext(PanelContext);

  const quizDataRadiationLeft = form.getFieldValue("radiationleft");
  const quizDataRadiationRight = form.getFieldValue("radiationright");
  const quizDataSurgeryLeft = form.getFieldValue("surgeryleft");
  const quizDataSurgeryRight = form.getFieldValue("surgeryright");
  const quizDataImaging = form.getFieldValue("imaging");

  const quizRadiationLeft = RADIATION_ONCOLOGY;
  const quizRadiationRight = RADIATION_ONCOLOGY;
  const quizSurgeryLeft = SURGERYLEFT;
  const quizSurgeryRight = SURGERYRIGHT;
  const quizImaging = DIANOSTICIMAGING;

  const createCPTCodes = (quiz: any, quizData: any) => {
    let CPT: string = "";
    if (quizData) {
      Object.keys(quizData)?.forEach((questionId) => {
        if (quizData[questionId]?.father?.answer?.length > 0) {
          quizData[questionId]?.father?.answer?.forEach((fartherans: any) => {
            if (
              quiz.find((quiz: any) => quiz._id === Number(questionId))
                ?.answers[fartherans]?.cpt
            ) {
              CPT += `${String(
                quiz.find((quiz: any) => quiz._id === Number(questionId))
                  ?.answers[fartherans]?.cpt
              )} `;
            }
            if (quizData[questionId].child) {
              if (quizData[questionId]?.child?.answer[fartherans]?.radio) {
                if (
                  quiz.find((quiz: any) => quiz._id === Number(questionId))
                    ?.answers[fartherans]?.childrenAnswer[
                    quizData[questionId]?.child?.answer[fartherans]?.radio
                  ]?.cpt
                ) {
                  CPT += `${
                    quiz.find((quiz: any) => quiz._id === Number(questionId))
                      ?.answers[fartherans]?.childrenAnswer[
                      quizData[questionId]?.child?.answer[fartherans]?.radio
                    ]?.cpt
                  } `;
                }
              } else {
                quizData[questionId]?.child?.answer[
                  fartherans
                ]?.checkbox?.forEach((childAns: any) => {
                  if (
                    quiz.find((quiz: any) => quiz._id === Number(questionId))
                      ?.answers[fartherans]?.childrenAnswer[childAns]?.cpt
                  ) {
                    CPT += `${
                      quiz.find((quiz: any) => quiz._id === Number(questionId))
                        ?.answers[fartherans]?.childrenAnswer[childAns]?.cpt
                    } `;
                  }
                });
              }
            }
          });
        } else {
          if (
            quiz.find((quiz: any) => quiz._id === Number(questionId))?.answers[
              quizData[questionId]?.father?.answer
            ]?.cpt
          ) {
            CPT += `${
              quiz.find((quiz: any) => quiz._id === Number(questionId))
                ?.answers[quizData[questionId]?.father?.answer]?.cpt
            } `;
          }
          if (quizData[questionId]?.child) {
            if (
              quizData[questionId]?.child?.answer[
                quizData[questionId]?.father?.answer
              ]?.radio
            ) {
              if (
                quiz.find((quiz: any) => quiz._id === Number(questionId))
                  ?.answers[quizData[questionId]?.father?.answer]
                  ?.childrenAnswer[
                  quizData[questionId]?.child?.answer[
                    quizData[questionId]?.father?.answer
                  ]?.radio
                ]?.cpt
              ) {
                CPT += `${
                  quiz.find((quiz: any) => quiz._id === Number(questionId))
                    ?.answers[quizData[questionId]?.father?.answer]
                    .childrenAnswer[
                    quizData[questionId]?.child?.answer[
                      quizData[questionId]?.father?.answer
                    ]?.radio
                  ]?.cpt
                } `;
              }
            } else {
              quizData[questionId]?.child?.answer[
                quizData[questionId]?.father?.answer
              ]?.checkbox?.forEach((childAns: any) => {
                if (
                  quiz.find((quiz: any) => quiz._id === Number(questionId))
                    ?.answers[quizData[questionId]?.father?.answer]
                    ?.childrenAnswer[childAns]?.cpt
                ) {
                  CPT += `${
                    quiz.find((quiz: any) => quiz._id === Number(questionId))
                      ?.answers[quizData[questionId]?.father?.answer]
                      ?.childrenAnswer[childAns]?.cpt
                  } `;
                }
              });
            }
          }
        }
      });
    }
    return CPT;
  };

  const data: any = [
    createCPTCodes(quizRadiationLeft, quizDataRadiationLeft),
    createCPTCodes(quizRadiationRight, quizDataRadiationRight),
    createCPTCodes(quizSurgeryLeft, quizDataSurgeryLeft),
    createCPTCodes(quizSurgeryRight, quizDataSurgeryRight),
    createCPTCodes(quizImaging, quizDataImaging),
  ];
  const index = [
    "Radiation Left",
    "Radiation Right",
    "Surgery Left",
    "Surgery Right",
    "Diagnostic Imaging",
  ];

  return (
    <div className="quiz-diagnostic">
      <div className="quiz-diagnostic-wrapper">
        <Title level={3}>Clinical Summary</Title>
        <div className="age-status name-age-sex">
          {/* <DatePickerCustom name="dob" label="Date of birth" /> */}

          {/* <SelectCustom
            name="sex"
            options={SEX}
            initialValue="Female"
            // rq={true}
          /> */}
        </div>
        {/* <Age /> */}
        <MultiSelect
          className="summary"
          style={{
            display: params.isTags === "diagnostic" ? "block" : "none",
          }}
          name="indications"
          label="Indication(s) for Diagnostic Imaging"
          options={INDICATIONS}
          rq={false}
        />
        {/* <MultiSelect
          style={{
            display: params.isTags === "diagnostic" ? "block" : "none",
          }}
          name="ICDSIGNS"
          label="ICD-10 Codes (Signs/Symptoms)"
          options={ICDSIGNS}
          rq={false}
        /> */}
        <MultiSelect
          className="summary"
          style={{
            display:
              params.isTags === "work-up" || params.isTags === "therapeutic"
                ? "block"
                : "none",
          }}
          name="panelICD10"
          label="ICD-10 Codes (Cancer)"
          options={ICD10CODES}
          rq={false}
        />

        <List
          style={{
            display:
              // params.isTags === "therapeutic" || params.isTags === "diagnostic"
              // ? "block"
              "none",
          }}
          header={<div>CPT Codes</div>}
          bordered
          dataSource={data}
          renderItem={(item, indexItem) => (
            <List.Item>
              <Typography.Text mark>{index[indexItem]}</Typography.Text> {item}
            </List.Item>
          )}
        />
        <SelectCustom
          style={{
            display: params.isTags === "work-up" ? "block" : "none",
          }}
          name="tumorSize"
          label="Tumor Size (clinical)"
          options={TUMOR}
          rq={false}
        />
        <SelectCustom
          style={{
            display: params.isTags === "work-up" ? "block" : "none",
          }}
          name="lymph"
          label="Nodal status (clinical)"
          options={LYMPH}
          rq={false}
        />
        <SelectCustom
          style={{
            display: params.isTags === "work-up" ? "block" : "none",
          }}
          name="metastasisClinical"
          label="Metastasis (clinical)"
          options={METASTASISCLINICAL}
          rq={false}
        />
        <SkinSelect
          className="summary"
          style={{
            display: params.isTags === "work-up" ? "block" : "none",
          }}
        />
        <SelectCustom
          style={{
            display: params.isTags === "therapeutic" ? "block" : "none",
          }}
          name="tumorSizePathological"
          label="Tumor Size Pathological"
          options={TUMORPATHOLOGICAL}
          rq={false}
        />
        <SelectCustom
          style={{
            display: params.isTags === "therapeutic" ? "block" : "none",
          }}
          name="nodalPathological"
          label="Nodal Status Pathological"
          options={NODALPATHOLOGICAL}
          rq={false}
        />
        <SelectCustom
          style={{
            display: params.isTags === "therapeutic" ? "block" : "none",
          }}
          name="metastasisPathological"
          label="Metastasis Pathological/Clinical"
          options={METASTASISPATHOLOGICAL}
          rq={false}
        />
        <SelectCustom
          style={{
            display:
              params.isTags === "therapeutic" || params.isTags === "work-up"
                ? "block"
                : "none",
          }}
          name="estrogen"
          label="Estrogen Receptor Status"
          options={ESTROGEN}
          rq={false}
        />
        <SelectCustom
          style={{
            display:
              params.isTags === "therapeutic" || params.isTags === "work-up"
                ? "block"
                : "none",
          }}
          name="progesterone"
          label="Progesterone Receptor"
          options={ESTROGEN}
          rq={false}
        />
        <SelectCustom
          style={{
            display:
              params.isTags === "therapeutic" || params.isTags === "work-up"
                ? "block"
                : "none",
          }}
          name="HER2"
          label="HER2/neu status"
          options={HER2}
          rq={false}
        />
        <SelectCustom
          style={{
            display:
              params.isTags === "therapeutic" || params.isTags === "work-up"
                ? "block"
                : "none",
          }}
          name="ki67"
          label="ki-67"
          options={KI67}
          rq={false}
        />
        <SelectCustom
          name="menopausal"
          style={{
            display:
              params.isTags === "work-up" || params.isTags === "therapeutic"
                ? "block"
                : "none",
          }}
          label="Menopausal Status"
          options={MENOPAUSAL}
          rq={false}
        />
      </div>
    </div>
  );
};

export default DiagnosticModal;
