import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context/login/Context";
import Header from "../../components/layouts/Header";
import { Link } from "react-router-dom";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Typography,
} from "@mui/material";
import { PatientAPI } from "../../API/patient-API";
import { Button } from "antd";
import { Box, List, ListItem, ListItemText, Divider } from "@mui/material";
import { faFolderOpen } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory, useParams } from "react-router";
import moment from "moment";

export default function ByType() {
  const param: any = useParams();
  const [showDio, setShowDio] = useState(true);
  const [showWork, setShowWork] = useState(false);
  const [showT, setShowT] = useState(false);
  const [patient, setPatient] = useState([{}] as any);
  const { getCurrentClient } = useContext(UserContext);
  const history = useHistory();

  let currentClient = getCurrentClient();
  useEffect(() => {
    async function getData() {
      await PatientAPI.getPatient(param.id, currentClient).then((res) => {
        const resPatient = res.data.patient;
        setPatient({ ...resPatient });
        // setSearch(resPatient.patientId);
      });
    }
    getData();
  }, []);

  const handleDNext = () => {
    setShowDio(true);
    setShowWork(false);
    setShowT(false);
  };
  const handleWNext = () => {
    setShowDio(false);
    setShowWork(true);
    setShowT(false);
  };
  const handleTNext = () => {
    setShowDio(false);
    setShowWork(false);
    setShowT(true);
  };

  return (
    <div>
      <div style={{ position: "fixed", zIndex: +2, top: 0, width: "100vw" }}>
        <Header />
      </div>
      <div
        className="side-nav"
        style={{
          height: "100%",
          width: "240px",
          position: "fixed",
          top: "90px",
          left: "0",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "10%",
            marginLeft: "10px",
            alignItems: "left",
            justifyContent: "center",
            color: "white",
            fontWeight: 900,
            fontSize: "20px",
          }}
        >
          <Typography
            variant={"h5"}
            style={{ fontSize: "22px", color: "white" }}
          >
            <span style={{ fontWeight: "bold", fontSize: "22px" }}>
              Patient ID:
            </span>{" "}
            {patient.patientId}
          </Typography>
          <Typography
            variant={"caption"}
            style={{ fontSize: "16px", color: "white" }}
          >
            <span style={{ fontWeight: "bold", fontSize: "16px" }}>DOB:</span>{" "}
            {moment(patient.dob).format("MM/DD/YYYY")}
          </Typography>
        </div>

        {/* <Toolbar /> */}
        <Box>
          <List style={{ textAlign: "center" }}>
            <Link to={`/adminView`}>
              <ListItem
                button
                style={{
                  fontSize: "24px",
                  color: "#ffffff",
                  textAlign: "center",
                }}
              >
                <FontAwesomeIcon icon={faFolderOpen} />
                <ListItemText primary="Back" />
              </ListItem>
            </Link>
            <Divider />
            {/* <Link to="/patientBoard/IMV001">
                <ListItem
                  button
                  style={{
                    fontSize: "24px",
                    color: "#ffffff",
                    textAlign: "center",
                  }}
                >
                  <FontAwesomeIcon icon={faClipboard} />
                  <ListItemText primary="Notes" />
                </ListItem>
              </Link>
              <Divider />
              <Link to="/patientBoard/IMV001">
                <ListItem
                  button
                  style={{
                    fontSize: "24px",
                    color: "#ffffff",
                    textAlign: "center",
                  }}
                >
                  <FontAwesomeIcon icon={faExclamationCircle} />
                  <ListItemText primary="Alerts" />
                </ListItem>
              </Link>
              <Divider />
              <Link to="/patientBoard/IMV001">
                <ListItem
                  button
                  style={{
                    fontSize: "24px",
                    color: "#ffffff",
                    textAlign: "center",
                  }}
                >
                  <FontAwesomeIcon icon={faStickyNote} />
                  <ListItemText primary="Patient Log" />
                </ListItem>
              </Link> */}
          </List>
        </Box>
      </div>
      <div
        className="select-item"
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          // paddingTop: "15%",
          // paddingBottom: "15%",
          position: "fixed",
          left: 240,
          top: 90,
          height: "80%",
          width: "calc(100% - 240px)",
        }}
      >
        <FormControl component="fieldset">
          <FormLabel
            style={{ fontSize: "30px", color: "black" }}
            component="legend"
          >
            What is the Order Type?
          </FormLabel>
          <RadioGroup
            aria-label="What is the Order Type?"
            defaultValue="Diagnostic"
            name="radio-buttons-group"
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            <div style={{ flexDirection: "column", display: "flex" }}>
              <FormControlLabel
                value="Diagnostic"
                onClick={handleDNext}
                control={<Radio />}
                label="Diagnostic"
              />
              <FormControlLabel
                value="Work-up"
                onClick={handleWNext}
                control={<Radio />}
                label="Work-up"
              />
              <FormControlLabel
                value="Therapeutic"
                onClick={handleTNext}
                control={<Radio />}
                label="Therapeutic"
              />
            </div>
          </RadioGroup>
        </FormControl>
        {showDio && (
          <Button
            type="primary"
            className="accept-form-button"
            onClick={() =>
              history.push(`/new-patientorder/${param.id}/diagnostic`)
            }
          >
            Next
          </Button>
        )}
        {showWork && (
          <Button
            type="primary"
            className="accept-form-button"
            onClick={() =>
              history.push(`/new-patientorder/${param.id}/work-up`)
            }
          >
            Next
          </Button>
        )}
        {showT && (
          // <Link to='/neworder/treatment'>
          <Button
            type="primary"
            className="accept-form-button"
            onClick={() =>
              history.push(`/new-patientorder/${param.id}/therapeutic`)
            }
          >
            Next
          </Button>
          // </Link>
        )}
      </div>
    </div>
  );
}
