import { Form, Select } from "antd";
import React from "react";
import { PanelContext } from "../../context/panel-context/PanelContext";
const { Option } = Select;

const SelectCustom = (props: any) => {
  const { setFieldSelected, getFieldByName } = React.useContext(PanelContext);

  const onClick = (e: any) => {
    setFieldSelected({
      name: props.name,
      field: getFieldByName(props.name),
    });
  };

  return (
    <Form.Item
      name="sex"
      className="input status"
      label="Sex"
      rules={[
        {
          required: props.rq,
          message: "Please select a item",
        },
      ]}
      onClick={onClick}
      {...props}
    >
      <Select placeholder="Please select a item">
        {props.options.map((o: any, i: number) => (
          <Option key={i} value={o.value}>
            {o.value}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default SelectCustom;
