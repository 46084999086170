import reqAxios from "./reqAxios";

const fetchFiles = () => {
  return reqAxios().get("/documents");
};

const fetchFilesByPatient = (_id: any) => {
  return reqAxios().get(`/documents/patient/${_id}`);
};

const newFile = (data: any) => {
  return reqAxios().post("/documents", data);
};

const deleteFile = (_id: any) => {
  return reqAxios().delete(`/documents/${_id}`);
};

const getFileLink = (data: any) => {
  return reqAxios().post(`/documents/link`, data);
};

const getTokenUrl = () => {
  return reqAxios().get(`/documents/getTokenUrl`);
};

const getAccessToken = (data: any) => {
  return reqAxios().post(`/documents/getAccessToken`, data);
};

const getDriveFile = (data: any) => {
  return reqAxios().post(`/documents/drive?token=`, data);
};

export const FileAPI = {
  newFile,
  fetchFiles,
  deleteFile,
  fetchFilesByPatient,
  getAccessToken,
  getDriveFile,
  getTokenUrl,
  getFileLink,
};
