import { RightOutlined, CheckOutlined, LeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useContext } from "react";
import { useHistory } from "react-router";
import { PanelContext } from "../../../context/panel-context/PanelContext";

interface IProps {
  query: string;
}

const BreastChoose: React.FC<IProps> = (props) => {
  const history = useHistory();
  const { setIsDisable, isQuizFinish } = useContext(PanelContext);

  const changeRenderItem = () => {
    setIsDisable(false);
    history.goBack();
  };

  return (
    <>
      <div className={"pre-op-orders-container"}>
        <div className="back-btn" onClick={() => changeRenderItem()}>
          <LeftOutlined className="dashboard-item-arrow" />
          <span>Back</span>
        </div>
      </div>
      <div className="pre-op-orders-referrals-container">
        <div className="pre-op-orders-inner">
          <Button
            className={
              isQuizFinish[`${props.query.replace("item=", "")}left`]
                ? "new-order-btn middle done"
                : "new-order-btn middle"
            }
            onClick={() =>
              history.push(`therapeutic?${props.query}&breast=left`)
            }
          >
            <p>
              Left breast{" "}
              {isQuizFinish[`${props.query.replace("item=", "")}left`] ? (
                <CheckOutlined className="dashboard-item-arrow green" />
              ) : (
                <RightOutlined className="dashboard-item-arrow" />
              )}
            </p>
          </Button>
          <Button
            className={
              isQuizFinish[`${props.query.replace("item=", "")}right`]
                ? "new-order-btn middle done"
                : "new-order-btn middle"
            }
            onClick={() =>
              history.push(`therapeutic?${props.query}&breast=right`)
            }
          >
            <p>
              Right breast
              {isQuizFinish[`${props.query.replace("item=", "")}right`] ? (
                <CheckOutlined className="dashboard-item-arrow green" />
              ) : (
                <RightOutlined className="dashboard-item-arrow" />
              )}
            </p>
          </Button>
        </div>
      </div>
    </>
  );
};

export default BreastChoose;
