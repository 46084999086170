import reqAxios from "./reqAxios";

const fetchNotes = (clientId: string) => {
  return reqAxios().get(`/notes?clientId=${clientId}`);
};

const newNote = (data: any) => {
  return reqAxios().post("/notes", data);
};

const deleteNote = (_id: any) => {
  return reqAxios().delete(`/notes/${_id}`);
};

export const NoteAPI = {
  newNote,
  fetchNotes,
  deleteNote,
};
