import React, { useEffect, useContext } from "react";
// import OrderPanelSelect from "./Orderpanel-select";

import EditOrder from "./EditOrder";

import { PanelContext } from "../../context/panel-context/PanelContext";
import { OrderAPI } from "../../API/order-API";
import { useParams } from "react-router";
import { UserContext } from "../../context/login/Context";

const OrderPanel: React.FC = () => {
  const { setFieldSelected, setEditOrder, clickTags, setIsQuizFinish } =
    useContext(PanelContext);
  const param: any = useParams();
  const { getCurrentClient } = useContext(UserContext);
  let currentClient = getCurrentClient();
  const [isLoading, setLoading] = React.useState(true);

  useEffect(() => {
    async function getData() {
      await OrderAPI.fetchOrder(param.id, currentClient).then((res) => {
        const resOrder = buildEditFormData(res.data.order);
        setTimeout(() => {
          setEditOrder(resOrder);
          setIsQuizFinish({
            radiationleft: res.data.order.treatmentResult.radiation.left
              ? res.data.order.treatmentResult.radiation.left.value
                ? true
                : false
              : false,
            radiationright: res.data.order.treatmentResult.radiation.right
              ? res.data.order.treatmentResult.radiation.right.value
                ? true
                : false
              : false,
            medical: res.data.order.treatmentResult.medical
              ? res.data.order.treatmentResult.medical.value
                ? true
                : false
              : false,
            surgeryleft: res.data.order.treatmentResult.surgery.left
              ? res.data.order.treatmentResult.surgery.left.value
                ? true
                : false
              : false,
            surgeryright: res.data.order.treatmentResult.surgery.right
              ? res.data.order.treatmentResult.surgery.right.value
                ? true
                : false
              : false,
            imaging: res.data.order.treatmentResult.imaging
              ? res.data.order.treatmentResult.imaging.value
                ? true
                : false
              : false,
            surgeryWorkup: res.data.order.treatmentResult.surgeryWorkup
              ? res.data.order.treatmentResult.surgeryWorkup.value
                ? true
                : false
              : false,
            social: res.data.order.treatmentResult.social
              ? res.data.order.treatmentResult.social.value
                ? true
                : false
              : false,
            radOncology: res.data.order.treatmentResult.radOncology
              ? res.data.order.treatmentResult.radOncology.value
                ? true
                : false
              : false,
            medOncology: res.data.order.treatmentResult.medOncology
              ? res.data.order.treatmentResult.medOncology.value
                ? true
                : false
              : false,
            genetic: res.data.order.treatmentResult.genetic
              ? res.data.order.treatmentResult.genetic.value
                ? true
                : false
              : false,
            therapy: res.data.order.treatmentResult.therapy
              ? res.data.order.treatmentResult.therapy.value
                ? true
                : false
              : false,
            patientNav: res.data.order.treatmentResult.patientNav
              ? res.data.order.treatmentResult.patientNav.value
                ? true
                : false
              : false,
            nutrition: res.data.order.treatmentResult.nutrition
              ? res.data.order.treatmentResult.nutrition.value
                ? true
                : false
              : false,
          });
          setTimeout(() => {
            setLoading(false);
          }, 500);
        }, 500);
      });
    }
    getData();

    return () => {
      setFieldSelected(null);
      clickTags("diagnostic");
    };
  }, []);

  return (
    <div className="orderpanel-container">
      <div className={"wrapper"}>
        <div className="orderpanel-left-container">
          <EditOrder isLoading={isLoading} />
        </div>
        {/* <div className={`${!fieldSelected ? "popup-comment-none" : "orderpanel-right-container"}`}>
        <div className={"wrapper"}>
          {
            fieldSelected && <Comment />
          }
        </div>
      </div> */}
      </div>
    </div>
  );
};

export const buildEditFormData = (order: any) => {
  return {
    id: order.patientId,
    name: order.diagnosticPhase.name,
    dob: order.diagnosticPhase.dob,
    sex: order.diagnosticPhase.sex,
    pathology: order.diagnosticPhase.pathology,
    ICDSIGNS: order.diagnosticPhase.ICDSIGNS,
    indications: order.diagnosticPhase.indications,
    menopausal: order.diagnosticPhase.menopausal,
    lymph: order.diagnosticPhase.lymph,
    metastasisClinical: order.diagnosticPhase.metastasisClinical,
    metastasis: order.diagnosticPhase.metastasis,
    involvement: order.diagnosticPhase.involvement,
    other: order.diagnosticPhase.other,
    file: order.workup.file,
    tumorSize: order.workup.tumorSize,
    estrogen: order.workup.estrogen,
    progesterone: order.workup.progesterone,
    HER2: order.workup.HER2,
    ki67: order.workup.ki67,
    receptorStatus: order.workup.receptorStatus,
    panelICD10: order.workup.panelICD10,
    preopOrders: order.workup.preopOrders,
    preopRadiology: order.workup.preopRadiology,
    // referrals: order.workup.referrals,
    lumpectomy: order.treatmentResult.lumpectomy,
    advancement: order.treatmentResult.advancement,
    lymphsurgery: order.treatmentResult.lymphsurgery,
    mastectomy: order.treatmentResult.mastectomy,
    intraoperative: order.treatmentResult.intraoperative,
    plastics: order.treatmentResult.plastics,
    radiology: order.treatmentResult.radiology,
    specialties: order.treatmentResult.specialties,
    radiationleft: order.treatmentResult.radiation.left,
    radiationright: order.treatmentResult.radiation.right,
    medical: order.treatmentResult.medical,
    surgeryleft: order.treatmentResult.surgery.left,
    surgeryright: order.treatmentResult.surgery.right,
    imaging: order.treatmentResult.imaging,
    surgeryWorkup: order.treatmentResult.surgeryWorkup,
    radOncology: order.treatmentResult.radOncology,
    medOncology: order.treatmentResult.medOncology,
    therapy: order.treatmentResult.therapy,
    patientNav: order.treatmentResult.patientNav,
    nutrition: order.treatmentResult.nutrition,
    social: order.treatmentResult.social,
    genetic: order.treatmentResult.genetic,
  };
};

export default OrderPanel;
