import reqAxios from "./reqAxios";

const fetchPatientLogs = (clientId: string) => {
  return reqAxios().get(`/patientLogs?clientId=${clientId}`);
};

const fetchPatientLogsByPatient = (id: string, clientId: string) => {
  return reqAxios().get(`/patientLogs/patient/${id}?clientId=${clientId}`);
};

const newPatientLog = (data: any) => {
  return reqAxios().post("/patientLogs", data);
};

export const PatientLogAPI = {
  newPatientLog,
  fetchPatientLogsByPatient,
  fetchPatientLogs,
};
