import React, { useState, useContext } from "react";
import "moment-timezone";
import moment from "moment";
import { Spin, message } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { LoadingOutlined } from "@ant-design/icons";
import { useQuery, useQueryClient } from "react-query";
import { Box, Typography, Button, Modal } from "@mui/material";

import Title from "./Title";
import LogTable from "./LogTable";
import { AlertAPI } from "../../API/alert-API";
import { AlertLogAPI } from "../../API/alertLog-API";
import Header from "../../components/layouts/Header";
import { UserContext } from "../../context/login/Context";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "60%",
  maxHeight: "60%",
  minHeight: "40%",
  overflow: "scroll",
  backgroundColor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

const OrderAlerts = () => {
  const { user } = useAuth0();
  const queryClient = useQueryClient();
  const { profile, getCurrentClient } = useContext(UserContext);

  const [open, setOpen] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedAlert, setSelectedAlert] = useState([{}] as any);

  let currentClient = getCurrentClient();
  const { isLoading } = useQuery(
    ["active-alerts"],
    () => {
      return AlertAPI.fetchActiveAlerts(currentClient).then(({ data }) => data);
    },
    {
      onSuccess: (data) => setAlerts(data.alerts),
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    queryClient.refetchQueries(["active-alerts"]);
  };

  const filteredAlerts = alerts.filter(({ patientId }: any) => {
    return patientId.toLowerCase().includes(search.toLowerCase());
  });
  const sortedAlerts = filteredAlerts
    .sort((a: any, b: any) => a.createdAt - b.createdAt)
    .reverse();

  const assign = (alert: any) => {
    const payload = {
      data: {
        ...alert,
        userId: profile?._id,
        userFullName: user?.name,
      },
    };
    AlertAPI.updateAlert(payload, alert._id)
      .then(() => {
        const payload = {
          data: {
            logText: `Alert assigned to ${user?.name}`,
            clientId: currentClient,
            patientId: alert.patientId,
            eventType: "Assignment",
            alertId: alert._id,
          },
        };
        AlertLogAPI.newAlertLog(payload);
        queryClient.refetchQueries(["active-alerts"]);
        message.success("Alert successfully assigned!");
      })
      .catch((error) => message.error(`Assignment failed: ${error}`));
  };

  const deAssign = (alert: any) => {
    const payload = {
      data: {
        ...alert,
        userId: null,
        userFullName: "",
      },
    };
    AlertAPI.updateAlert(payload, alert._id)
      .then(() => {
        const payload = {
          data: {
            logText: `Alert de-assigned by ${user?.name}`,
            clientId: currentClient,
            patientId: alert.patientId,
            eventType: "Assignment",
            alertId: alert._id,
          },
        };
        AlertLogAPI.newAlertLog(payload);
        queryClient.refetchQueries(["active-alerts"]);
        message.success("Alert has been de-assigned!");
      })
      .catch((error) => message.error(`De-assignment failed: ${error}`));
  };

  return (
    <>
      <Header />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "30px",
        }}
      >
        <div
          style={{
            textAlign: "left",
            backgroundColor: "#ffffff",
            padding: "15px",
            boxShadow: "-2px 1px 3px 0px #d2d2d2",
            width: "80%",
          }}
        >
          <div style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "30px",
              }}
            >
              <Title>Alerts</Title>
              <div>
                <input
                  className="search"
                  value={search || ""}
                  onChange={(e) => setSearch(e.currentTarget.value)}
                  placeholder="Patient Id"
                />
              </div>
            </div>
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "80vw",
                  height: "20vh",
                }}
              >
                <Spin
                  size="large"
                  indicator={antIcon}
                  className={`loading-spin-btn ${"active"}`}
                />
              </div>
            ) : sortedAlerts.length === 0 ? (
              <div
                style={{
                  textAlign: "center",
                  fontWeight: 500,
                  marginTop: "100px",
                }}
              >
                <Typography variant={"h5"}>
                  There are currently no active alerts in the system
                </Typography>
              </div>
            ) : (
              <div>
                {sortedAlerts.map((alert: any) => (
                  <Box
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      borderLeft:
                        alert.isActive === true
                          ? "4px solid red"
                          : "4px solid #7ed957",
                      textAlign: "left",
                      backgroundColor: "#ffffff",
                      marginBottom: "30px",
                      padding: "10px",
                      boxShadow: "-2px 1px 3px 0px #d2d2d2",
                    }}
                    key={alert._id}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        style={{ marginBottom: "20px" }}
                        variant="body1"
                      >
                        {alert.alertType}
                      </Typography>
                      <Typography variant="body1">
                        <span style={{ fontWeight: "bold" }}>Patient ID:</span>{" "}
                        {alert.patientId}
                      </Typography>
                      {alert.userId && (
                        <Typography variant="caption">
                          <span style={{ fontWeight: "bold" }}>
                            Assigned To:
                          </span>{" "}
                          {alert.userFullName}
                        </Typography>
                      )}
                      <Typography variant="caption">
                        <span style={{ fontWeight: "bold" }}>
                          Date Created:
                        </span>{" "}
                        {moment(alert.createdAt)
                          .tz("America/Los_Angeles")
                          .format("MM/DD/YYYY, h:mma")}
                      </Typography>
                      {alert.isActive === false && (
                        <Typography variant="caption">
                          <span style={{ fontWeight: "bold" }}>
                            Resolve Date:
                          </span>{" "}
                          {moment(alert.alertResolvedDate)
                            .tz("America/Los_Angeles")
                            .format("MM/DD/YYYY, h:mma")}
                        </Typography>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                      }}
                    >
                      {!alert.userId && alert.isActive === true && (
                        <Button
                          style={{ backgroundColor: "#4276c5", color: "white" }}
                          onClick={() => assign(alert)}
                          variant="outlined"
                        >
                          Assign to me
                        </Button>
                      )}
                      {alert.userId && alert.isActive === true && (
                        <Button
                          style={{ backgroundColor: "#4276c5" }}
                          onClick={() => deAssign(alert)}
                          variant="contained"
                          color="error"
                        >
                          De-assign
                        </Button>
                      )}
                      {alert.userId === profile?._id &&
                        alert.isActive === true && (
                          <Button
                            style={{ backgroundColor: "#7ed957" }}
                            onClick={() => {
                              handleOpen();
                              setSelectedAlert(alert);
                            }}
                            variant="contained"
                          >
                            Resolve
                          </Button>
                        )}
                      {alert.isActive === false && (
                        <Button
                          key={alert._id}
                          style={{ backgroundColor: "#4276c5" }}
                          onClick={() => {
                            handleOpen();
                            setSelectedAlert(alert);
                          }}
                          variant="contained"
                        >
                          View
                        </Button>
                      )}
                    </div>
                  </Box>
                ))}
              </div>
            )}
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <LogTable alert={selectedAlert} close={handleClose} />
              </Box>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderAlerts;
