export const buildOptions = (arr: any) =>
  arr.map((text: any) => ({ value: text }));

export const buildOptionsIcd = (arr: any) =>
  arr.map((text: any, index: number) => ({ id: index + 1, value: text }));

export const SEX = buildOptions(["Male", "Female"]);

export const ICDSIGNS = buildOptions([
  "N63 Unspecified lump",
  "N64.51 Induration of breast",
  "N64.52 Nipple discharge",
  "N64.53 Retraction of nipple",
  "N64.59 Other signs and symptoms in breast",
  "R59.0 Localized enlarged lymph nodes",
  "R59.1 Generalized enlarged lymph nodes",
  "R59.9 Enlarged lymph nodes, unspecified",
  "R92.0 Mammographic microcalcification found on diagnostic imaging of breast",
  "R92.1 Mammographic calcification found on diagnostic imaging of breast",
  "R92.2 Inconclusive mammogram",
  "R92.8 Other abnormal and inconclusive findings on diagnostic imaging of breast",
  "Z85.3 Personal history of malignant neoplasm of breast",
  "R22.1 Chest wall mass",
  "Z15.01 Genetic susceptibility to malignant neoplasm of breast",
]);

export const INDICATIONS = buildOptions([
  "BI-RADS 0",
  "BI-RADS 3",
  "BI-RADS 4",
  "BI-RADS 5",
  "BI-RADS 6",
  "Breast Mass (R92.1)",
  "Architectural Distortion (R92.8)",
  "Mammographic microcalcification (R92.8)",
  "Mammographic calcification (R92.1)",
  "Radial Scar (N64.98)",
  "Inconclusive mammogram (R92.2)",
  "Other abnormal and inconclusive findings on diagnostic imaging of breast (R92.8)",
]);

export const MENOPAUSAL = buildOptions([
  "Pre-menopausal",
  "Peri-menopausal",
  "Post-menopausal",
]);

export const LYMPH = buildOptions([
  "No physical or imaging evidence of lymph node involvement",
  "Physical or Imaging Evidence of lymph node involvement",
  "cNX (regional lymph nodes cannot be assessed)",
  "cN0 (No regional lymph node metastasis)",
  "cN1 (Metastasis to  movable ipsilateral level I, II axillary lymph nodes)",
  "cN1mi (Micrometastases (approximately 200 cells, larger than 0.2 mm, but none larger than 2.0 mm)",
  "cN2 (Metastases to ipsilateral level I, II axillary nodes that are clinically fixed or matted; or in the ipsilateral internal mammary nodes in the absense of axillary lymph node metastases",
  "cN2a (Metastases to ipsilateral level I, II axillary nodes that are clinically fixed to one another (matted) or to other structures)",
  "cN2b (Metastases only in ipsilateral internal mammary nodes in the absence of axillary lymph node metastases)",
  `cN3 (Metastases in ipsilateral infraclavicular (Level III axillary) lymph node(s) with or without Level I, II axillary lymph node involvement; ",
  "cN3a [Metastases in ipsilateral infraclavicular lymph node(s)]
    (Or, in ipsilateral internal mammary lymph node(s) with level I, II  axillary lymph node metastases)
    (Or, metastases in ipsilateral supraclavicular lymph node(s) with or without axillary or internal mammary lymph node involvement)`,
  "cN3b [Metastases in ipsilateral internal mammary lymph node(s) and axillary node(s)]",
  "cN3c [Metastases in ipsilateral supraclavicular lymph node(s)}",
]);

//clinical tumor
export const TUMOR = buildOptions([
  "T1mic <2 mm",
  "T1a 2-5 mm",
  "T1b >/=5-10 mm",
  "T1c >/=10-20 mm",
  "T2 >/=20-50 mm",
]);

export const PATHOLOGY = buildOptions([
  "Invasive Breast Cancer: Ductal type ",
  "Invasive Breast Cancer: Lobular-type ",
  "Invasive Breast Cancer: Mixed-type",
  "Ductal Carcinoma in situ",
  "Lobular Carcinoma in situ",
]);

export const TUMORPATHOLOGICAL = buildOptions([
  "Tx (Primary tumor cannot be assessed)",
  "T0 (No evidence of primary tumor",
  "Tis (Ductal carcinoma in situ)",
  "Tis (Paget's)",
  "T1 (Tumor </= 2 cm",
  "T1mi (Tumor </= 1 mm)",
  "T1a (Tumor >1 but </= 5 mm)",
  "T1b  (Tumor >5 but </= 10 mm)",
  "T1c  (Tumor >10 but </= 20 mm)",
  "T2  (Tumor >20 but </= 50 mm)",
  "T3  (Tumor >50 mm)",
  "T4  (Direct extension to chest wall and/or to the skin (ulceration or macroscopic nodules)",
  "T4a (Extension to the chest wall invasion, not including pectoralis major)",
  "T4b (ulceration and/or ipsilateral macroscopic satellite nodules and/or edema (including peau d'orange) of the skin  that does not meet criteria for inflammatory carcinoma)",
  "T4c (Both T4a and T4b)",
  "T4d (Inflammatory breast cancer)",
]);

export const NODALPATHOLOGICAL = buildOptions([
  "pNX (regional lymph nodes cannot be assessed)",
  "pN0 (No regional lymph node metastasis identified or ITCs only)",
  "pN0 (i+) (ITCs only --malignany cell clusters no larger than 0.2 mm)",
  "pN0 (mol+) (positive molecular findings by RT=PCR; no ITCs detected",
  "pN1 (micrometastases; or metastases in 1-3 axillary lymph nodess; and/or clinically negatiev internal ma mmary nodes with micrometastases or macrometastases by sentinel lymph node biopsy)",
  "pN1mi (micrometastases (approximately 200 cells, larger than 0.2 mm, but none larger than 2.0 mm)",
  "pN1a (metastases in 1-3 axillary lymph nodes, at least one metastasis larger than 2.0 mm)",
  "pN1b (metastases in ipsilateral internal mamammary sentinel nodes, excluding ITCs)",
  "pN1c (pN1 and pN1b combined)",
  "pN2 (metastases in 4-9 axillary lymph ndoes; or positive ipsilateral internal mammary lymph nodes by imaging in the absence of axillary lymph node metastases)",
  "pN2a  (metastases in 4-9 axillary lymph nodes (at least one tumor deposit larger that 2.0 mm)",
  "pN2b (metastases in clinically detected internal mamammary lymph nodes with or without microscopic confirmation, with pathologically negative axillary nodes)",
  "pN3 (metastases in 10 or more axillary lymph nodes; or infraclavicular (Level III axillary)  lymph nodes; or positive ipsilateral internal mammary lymph nodes by imaging in the presence of one ore more positive Level I, II axillary lymph nodes; or in more than three axillary lymph nodes and micrometastases or macrometastases by sentinel lymph node biopsy in clinically negative ipsilateral internal mammary lymph nodes; or in ipsilateral supraclavicular lymph nodes)",
  "pN3a (metastases in 10 or more axillary lymph nodes --at least one tumor deposit loarger than 2.0 mm; or metastases to the ipsilateral--level III axillary--nodes)",
  "pN3b (pN1a or pN2a in the presence of cN2b (positive internal mammary nodes by imaging); or pN2a in the presence of pN1b)",
  "pN3c (metastates in ipsilateral supraclavicular lymph node(s)",
]);

export const METASTASISCLINICAL = buildOptions([
  "M0 (No evidence of distant metastasis",
  "M1 (Evidence of distant metastasis is present)",
]);

export const METASTASISPATHOLOGICAL = buildOptions([
  "pM0 (No clinical evidence or radiolographic evidence of distant metastases)",
  "pM1 (Any histologically proven metastases in distant organs; or if in non-regional nodes, metastases greater than 0.2 mm)",
]);

export const KI67 = buildOptions([
  "Low risk <10%",
  "Intermediate 10-20%",
  "Higher >20%",
  "Not reported",
]);

export const ICD10CODES = buildOptionsIcd([
  "C50.011 Malignant neoplasm of nipple and areola, right female breast",
  "C50.012 Malignant neoplasm of nipple and areola, left female breast",
  "C50.111 Malignant neoplasm of central portion of right female breast",
  "C50.112 Malignant neoplasm of central portion of left female breast",
  "C50.211 Malignant neoplasm of upper-inner quadrant of right female breast",
  "C50.212 Malignant neoplasm of upper-inner quadrant of left female breast",
  "C50.311 Malignant neoplasm of lower-inner quadrant of right female breast",
  "C50.312 Malignant neoplasm of lower-inner quadrant of left female breast",
  "C50.411 Malignant neoplasm of upper-outer quadrant of right female breast",
  "C50.412 Malignant neoplasm of upper-outer quadrant of left female breast",
  "C50.511 Malignant neoplasm of lower-outer quadrant of right female breast",
  "C50.512 Malignant neoplasm of lower-outer quadrant of left female breast",
  "C50.611 Malignant neoplasm of axillary tail of right female breast",
  "C50.612 Malignant neoplasm of axillary tail of left female breast",
  "C50.811 Malignant neoplasm of overlapping sites of right female breast",
  "C50.811 Malignant neoplasm of overlapping sites of right female breast",
  "C50.811 Malignant neoplasm of overlapping sites of right female breast",
  "C50.811 Malignant neoplasm of overlapping sites of right female breast",
  "C50.812 Malignant neoplasm of overlapping sites of left female breast",
  "C50.812 Malignant neoplasm of overlapping sites of left female breast",
  "C50.812 Malignant neoplasm of overlapping sites of left female breast",
  "C50.812 Malignant neoplasm of overlapping sites of left female breast",
  // 'C50.911 Malignant neoplasm of unspecified site of right female breast',
  // 'C50.912 Malignant neoplasm of unspecified site of left female breast',
  // 'C77.3 Secondary and unspecified malignant neoplasm of axilla and upper limb lymph nodes',
  // 'C77.8 Secondary and unspecified malignant neoplasm of lymph nodes of multiple regions',
  // 'C77.9 Secondary and unspecified malignant neoplasm of lymph node, unspecified',
  // 'D05.11 Intraductal carcinoma in situ of right breast',
  // 'D05.12 Intraductal carcinoma in situ of left breast',
  // 'V50.41 Prophylactic mastectomy',
  // 'Z40.01  Encounter for prophylactic removal of breast',
  // 'Z15.01  Genetic susceptibility to malignant neoplasm of breast'
]);

export const PREOPORDER_ITEMS = buildOptions([
  "CBC",
  "CMP",
  "Chem7",
  "PT/PTT/INR",
  "UA",
  "Type and Screen",
  "Type and Crossmatch",
  "CA 15-3",
  "CA 27-29",
  "Other (specify)",
]);

export const PREOPRADIO_ITEMS = buildOptions([
  "CXR",
  "CT",
  "CAP",
  "PET/CT",
  "Bone Scan",
  "ECHO",
  "DEXA scan",
  "Other (specify)",
]);

export const ROWSKEY = buildOptions(["1", "3", "5"]);

export const LUMPECTOMY = buildOptions([
  "19301 Partial mastectomy",
  "19302 Partial mastectomy and ALND",
]);

export const ADVANCEMENT = buildOptions([
  "14001 Advancement flap 10-30 cm2",
  "14301 Advancement flap 30-60 cm2",
  "14302 Advancement flap >60 cm2",
]);

export const LYMPHSURGERY = buildOptions([
  "38740 Sentinel node biopsy",
  "38745 Axillary node dissection",
  "38900 Blue due injection",
  "Lymph node surgery not planned",
  "Lymph node surgery not indicated",
]);

export const MASTECTOMY = buildOptions([
  "19303 Simple mastectomy",
  "19307 Modified Radical mastectomy",
]);

export const INTRAOPERATIVE = buildOptions([
  "76998 Intraoperative ultrasound",
  "76098 Specimen radiograph",
]);

export const PLASTICS = buildOptions([
  "19316 Mastopexy",
  "19328 Removal of intact mammary implant",
  "19340 Insertion of breast implant on same day of mastectomy (ie, immediate)",
  "19342 Insertion or replacement of breast implant on separate day from mastectomy",
  "19350 Nipple/areolar reconstruction",
  "19357 Tissue Expander placement in breast recontruction",
  "19361 Breast reconstruction; with latissimus dorsi flap",
  "19364 Breast reconstruction; with free flap (eg, TRAM, DIEP, SIEA, GAP flap)",
  "19367 Breast reconstruction; with single-pedicled transverse rectus abdominis myocutaneous (tram) flap",
  "19368 Breast reconstruction; with single-pedicled transverse rectus abdominis myocutaneous (tram) flap, requiring",
  "19369 Breast reconstruction; with bipedicled transverse rectus abdominis myocutaneous (tram) flap",
  "19318 Reduction mammaplasty",
]);

export const SKIN = [
  {
    title: "Normal Skin",
    value: "0-0",
    key: "0-0",
    id: "0-0",
  },
  {
    title: "Abnormal Skin",
    disabled: true,
    value: "0-1",
    key: "0-1",
    children: [
      {
        title: "Retraction",
        value: "0-1-0",
        key: "0-1-0",
      },
      {
        title: "Edema",
        value: "0-1-1",
        key: "0-1-1",
      },
      {
        title: "Erythema",
        value: "0-1-2",
        key: "0-1-2",
      },
      {
        title: "Ulceration",
        value: "0-1-3",
        key: "0-1-3",
      },
      {
        title: "Skin nodules",
        value: "0-1-4",
        key: "0-1-4",
      },
    ],
  },
];

export const ESTROGEN = buildOptions([
  "Positive (>10% by IHC)",
  "Positive (1-9% by IHC)",
  "Negative (<1%)",
]);

export const HER2 = buildOptions([
  "Positive (3+ by IHC or amplified by FISH)",
  "Negative (0-1+ by IHC or non-amplified by FISH)",
  "Equivocal (2+)",
]);

export const REFERRAL_SURGERY = buildOptions([
  "Name 1",
  "Name 2",
  "Name 3",
  "Name 4",
  "Name 5",
]);

export const REFERRAL_MEDONCOLOGY = buildOptions([
  "Name 1",
  "Name 2",
  "Name 3",
  "Name 4",
  "Name 5",
]);

export const REFERRAL_RADONCOLOGY = buildOptions([
  "Name 1",
  "Name 2",
  "Name 3",
  "Name 4",
  "Name 5",
]);

export const REFERRAL_PATIENT = buildOptions([
  "Name 1",
  "Name 2",
  "Name 3",
  "Name 4",
  "Name 5",
]);

export const REFERRAL_THERAPY = buildOptions([
  "Name 1",
  "Name 2",
  "Name 3",
  "Name 4",
  "Name 5",
]);

export const REFERRAL_SOCIAL = buildOptions([
  "Name 1",
  "Name 2",
  "Name 3",
  "Name 4",
  "Name 5",
]);

export const REFERRAL_NUTRITION = buildOptions([
  "Name 1",
  "Name 2",
  "Name 3",
  "Name 4",
  "Name 5",
]);

export const REFERRAL_GENETIC = buildOptions([
  "Name 1",
  "Name 2",
  "Name 3",
  "Name 4",
  "Name 5",
]);
